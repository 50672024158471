import styled from 'styled-components';

export const Container = styled.div`
  padding: 2px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  min-width: 60px;
`;

export const Date = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 12px;
  color: white;
  gap: 2px;
  font-size: 12px;

  span {
    text-transform: uppercase;
  }

  .calendar-time {
    font-size: 9px;
  }

  .calendar-inline-date {
    font-size: 10px;
    margin-top: 6px;
  }
`;
