import React from "react";
import { getDiferenceAndPercentage } from "./methods/getDiferenceAndPercentage";
import { getIcon } from "./methods/getIcon";
import {
  SummaryCard,
  SummaryCount,
  SummaryPercentage,
  SummaryTitle,
  SummaryTitlesWrapper,
} from "./styles";

export const NegativeCard = ({ data }) => {
  const { diferenceCount, percentage } = getDiferenceAndPercentage(
    data.current_month_count,
    data.latest_month_count
  );

  const icon = getIcon(percentage);

  return (
    <SummaryCard>
      <SummaryTitlesWrapper>
        <SummaryTitle color="#f5222d">Negativos</SummaryTitle>
      </SummaryTitlesWrapper>
      <SummaryCount>{data.current_month_count}</SummaryCount>
      <SummaryPercentage>
        {icon} {percentage}% ({diferenceCount})
      </SummaryPercentage>
    </SummaryCard>
  );
};
