import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spin } from "antd";

import { ScreensFormOne } from "./ScreensFormOne";
import { ScreensFormTwo } from "./ScreensFormTwo";

import { getSubdomain } from "~/helpers/getSubdomain";

import { Container, FormContainer, LoadingWrapper, ErrorTitle } from "./styles";

export const ScreensLeadForms = ({match}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [formProps , setFormProps] = useState({});
    const [isCheckingIfFormIsValid, setIsCheckingIfFormIsValid] = useState(true);

    const id = match.params.id;

    async function fetchData() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/forms/${id}`, {
                headers: {
                  "X-Tenant": getSubdomain(),
                },
            });
            setFormProps(response.data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsCheckingIfFormIsValid(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    if(isLoading) {
        return (
            <Container>
                <FormContainer>
                {isCheckingIfFormIsValid ? (
                    <LoadingWrapper>
                    <Spin />
                    </LoadingWrapper>
                ) : (
                    <ErrorTitle>Não foi possível carregar o formulário.</ErrorTitle>
                )}
                </FormContainer>
            </Container>
        )
    }

    if(formProps.type === "form1") {
        return <ScreensFormOne formProps={formProps} />
    }
    

    if(formProps.type === "form2") {
        return <ScreensFormTwo formProps={formProps} />
    }
}