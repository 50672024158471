import { ROUTES } from "~/constants/routes.constants";
import { VIDEOS } from "~/constants/videos.constants";

export const videos = [
  {
    id: 1,
    route: ROUTES["/"],
    ...VIDEOS["apresentacao-geral-do-sistema"],
  },
  {
    id: 2,
    route: ROUTES["/"],
    ...VIDEOS["dashboard"],
  },
  {
    id: 3,
    route: ROUTES["/clientes"],
    ...VIDEOS.clientes,
  },
  {
    id: 4,
    route: ROUTES["/tarefas"],
    ...VIDEOS["cadastro-de-tarefas"],
  },
  {
    id: 5,
    route: ROUTES["/checklist"],
    ...VIDEOS["checklist"],
  },
  {
    id: 6,
    route: ROUTES["/convencoes"],
    ...VIDEOS["convencoes-coletivas"],
  },
  {
    id: 7,
    route: ROUTES["/calendario/:id/:department"],
    ...VIDEOS["tarefas"],
  },
  {
    id: 8,
    route: ROUTES["/financeiro/mensalidades"],
    ...VIDEOS["mensalidades"],
  },
  {
    id: 9,
    route: ROUTES["/financeiro/extras"],
    ...VIDEOS["servicos-extras"],
  },
  {
    id: 10,
    route: ROUTES["/financeiro/comissoes"],
    ...VIDEOS["comissoes"],
  },
  {
    id: 11,
    route: ROUTES["/societario"],
    ...VIDEOS["departamento-societario-painel"],
  },
  {
    id: 12,
    route: ROUTES["/certificados"],
    ...VIDEOS.certificados,
  },
  {
    id: 13,
    route: ROUTES["/lembretes"],
    ...VIDEOS["lembretes"],
  },
  {
    id: 14,
    route: ROUTES["/malha-fina/:department"],
    ...VIDEOS["malha-fiscal"],
  },
  {
    id: 15,
    route: ROUTES["/documentos"],
    ...VIDEOS["documentos-enviados"],
  },
  {
    id: 16,
    route: ROUTES["/leads"],
    ...VIDEOS["leads"],
  },
  {
    id: 17,
    route: ROUTES["/relatorios/prazos"],
    ...VIDEOS["relatorios-prazos"],
  },
  {
    id: 18,
    route: ROUTES["/relatorios/emails"],
    ...VIDEOS["emails-enviados"],
  },
  {
    id: 19,
    route: ROUTES["/plugins"],
    ...VIDEOS["plugins"],
  },
  {
    id: 20,
    route: ROUTES["/checklist"],
    ...VIDEOS["tarefas-do-checklist"],
  }
];
