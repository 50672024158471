import React from 'react';
import styled from 'styled-components';
import { Input, Checkbox } from 'antd';

export const TodoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const TodoItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s;
    gap: 8px;

    &:hover {
        background-color: #f0f0f0;
    }
`;

export const TaskCheckbox = styled(Checkbox)`
    .ant-checkbox-inner {
        height: 24px;
        width: 24px;
    }

    &::after {
        transform: rotate(45deg) scale(1) translate(-50%, -65%)
    }
`;

export const TaskInput = styled(Input)`
  flex: 1;
  margin: 0 10px;
  border: none !important;
`;