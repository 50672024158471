const modalInactivate = {
    status: null,
    data: null
};

const drawerEdit = {
    show: false,
    data: null
};

const customersnew = (state = {
    modalInactivate,
    drawerEdit
}, action) => {
    switch (action.type) {
        case 'INACTIVATE_CUSTOMER_SUCCESS':
        return{
            ...state,
            modalInactivate: action.payload.data
        }

        case 'CLOSE_INACTIVE_CUSTOMER':
        return {
            ...state,
            modalInactivate
        }

        case 'INACTIVATE_CUSTOMER_REMOVE_TASKS_SUCCESS':
        return{
            ...state,
            modalInactivate
        }

        case 'EDIT_CUSTOMER':
        return{
            ...state,
            drawerEdit: {
                data: action.payload,
                show: true
            }
        }
        case 'CLOSE_EDIT_CUSTOMER':
        return{
            ...state,
            drawerEdit: {
                data: null,
                show: false
            }
        }

        case 'ADD_MONTHLY_HONORARY_SUCCESS':
        return{
            ...state,
            drawerEdit: {
                ...state.drawerEdit,
                data: action.payload.data.data,
            }
        }

        case 'REMOVE_MONTHLY_HONORARY_SUCCESS':
        if(state.drawerEdit.data !== null){

            return {
                ...state,
                drawerEdit: {
                    ...state.drawerEdit,
                    data: {
                        ...state.drawerEdit.data,
                        honorary_history: [...state.drawerEdit.data.honorary_history.filter(item => item.id !== action.meta.previousAction.id)]
                    }
                }
            } 

        }else{
            return state;
        }

        default:
        return state
    }
}

export default customersnew;