import React from 'react';

import Recover from '../components/Login/Recover';

import '../components/UI/Layout/bulma.css';
import '../components/UI/Layout/core.css';

const ScreensRecover = () => (
    <div>
        <Recover/>
    </div>
);

export default ScreensRecover;