export function checkIfIsNotStarted(item) {
  return (
      item.processed_at === null &&
      item.finalized_at === null &&
      !item.is_pending &&
      item.archived_at === null
  );
}

export function checkIfInProcess(item) {
  return (
      item.processed_at !== null &&
      item.finalized_at === null &&
      !item.is_pending &&
      item.archived_at === null
  );
}

export function checkIfIsDone(item) {
  return (
      item.finalized_at !== null && 
      !item.is_pending &&
      item.archived_at === null
  );
}

export function checkIfIsPending(item) {
  return item.is_pending && item.archived_at === null;
}

export function checkIfIsArchived(item) {
  return item.archived_at !== null && item.archived_at !== '0000-00-00 00:00:00' && item.archived_at !== undefined && item.is_pending === 0;
}

export const getBackgroundItem = (category) => {
  switch (parseInt(category)) {
    case 1: return '#52c41a'; //Abertura
    case 2: return '#faad14'; //Alteração
    case 3: return '#595959'; //Encerramento
    case 4: return '#1890ff'; //Tarefa
    case 5: return '#388E3C'; //Troca de Contabilidade
    case 6: return '#722ed1'; //Outros
    case 7: return '#f5222d'; //Troca de Contabilidade
  }
}

export const getTextItem = (category) => {
  switch (parseInt(category)) {
    case 1: return 'Abertura';
    case 2: return 'Alteração';
    case 3: return 'Encerramento';
    case 4: return 'Tarefa';
    case 5: return 'Troca de Contabilidade';
    case 6: return 'Outros';
    case 7: return 'Troca de Contabilidade';
  }
}