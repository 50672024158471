import { Icon } from 'antd';
import moment from 'moment';
import React from 'react';

import './styles.css';

export function CardTitleWithMonth({
  title,
  from,
  to,
  visualizationType,
  showFullScreenButton = false,
  openCalendarTasksModal = null,
  isCalendarTasksModalOpen,
}) {
  return (
    <div className="card-title-container">
      <span />
      <span className="title">{title}</span>
      <div className="month-container">
        <span className="month-text">
          {visualizationType === 'current_month' || from.isSame(to)
            ? moment().format('MMMM/YYYY')
            : `${from.format('MMMM/YYYY')} - ${to.format('MMMM/YYYY')}`}
        </span>
        {showFullScreenButton && openCalendarTasksModal && (
          <button
            className="fullscreen-button"
            type="button"
            onClick={() => openCalendarTasksModal()}
          >
            <Icon
              type={isCalendarTasksModalOpen ? 'fullscreen-exit' : 'fullscreen'}
            />
          </button>
        )}
      </div>
    </div>
  );
}
