import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Result } from "antd";

import { getAllGraphPermissions } from "~/utils/permissions";
import { checkForPermissions } from "./utils";

import "./auth.css";
import { PERMISSIONS } from '~/constants/permissions.constants';
import { handleLogout } from '~/utils/handle-logout';

export const Auth = ({ children }) => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const permissionsGroups = useSelector((state) => state.permissions.list);
  const [isPathRestricted, setIsPathRestricted] = useState(false);

  useEffect(() => {
    if (!user.id) return;

    try {
      const userPermissionGroup = permissionsGroups.find(
        (item) => item.id === user.permissions_group_id
      );
      const permissions = userPermissionGroup
        ? userPermissionGroup.permissions
        : user.permissions;
      const hasPermission = checkForPermissions(permissions);

      if (!hasPermission) {
        return setIsPathRestricted(true);
      }

      if (
        window.location.pathname === "/" &&
        !user.permissions.some((permission) =>
          getAllGraphPermissions().includes(permission)
        )
      ) {
        if (user.permissions.includes(PERMISSIONS.customers.view)) {
          history.push("/clientes");
        } else if (user.permissions.includes(PERMISSIONS.calendarTasks.create)) {
          history.push("/tarefas");
        } else if (user.permissions.includes(PERMISSIONS.calendarTasks.view)) {
          history.push("/calendario/1/abertura-e-encerramento");
        } else if (user.permissions.includes(PERMISSIONS.corporate.view)) {
          history.push("/societario");
        } else if (user.permissions.includes(PERMISSIONS.certificates.view)) {
          history.push("/certificados");
        } else if (user.permissions.includes(PERMISSIONS.conventions.view)) {
          history.push("/convencoes");
        }
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          handleLogout();
          history.push("/login");
        }
      }
    }
  }, [user, permissionsGroups]);

  if (isPathRestricted) {
    return (
      <div className="restricted-container">
        <Result
          status="403"
          title="Sem permissão"
          subTitle="Você não tem permissão para acessar esta página."
          extra={
            <Link to="/">
              <Button type="primary">Voltar</Button>
            </Link>
          }
        />
      </div>
    );
  }
  return (
    <div>
      {children}
    </div>
  );
};
