import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: url("/images/bg.svg");
`;

export const BotWrapper = styled.div`
    width: 100%;
    height: max-content;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    height: 100%;

    @media (min-width: 768px) {
        width: 500px;
    }
`;

export const BotHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #f0f0f0;
    background-color: #722ed1;
`;

export const BotTitle = styled.h1`
    font-size: 18px;
    line-height: 20px;
    margin: 0;
    text-align: center;
    width: 100%;
    color: #fff;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.2);
`;

export const ChatWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow-y: auto;
    max-height: calc(100vh - 71px);

    .example{
        background: #bebebe;
        border-radius: 10px;
    }
`;

export const ChatMessage = styled.div`
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    width: max-content;
    max-width: 80%;
    align-self: flex-start;
    animation: slide-up 0.5s;
    font-size: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    img{
        max-width: 150px;
        min-width: 50px;
    }

    &.user {
        background-color: #722ed1;
        color: #fff;
        align-self: flex-end;
    }

    &.bot {
        background-color: #f5f5f5;
        align-self: flex-start;
    }

    &.loading {
        background-color: #f5f5f5;
        align-self: flex-start;
        animation: shake 0.5s infinite;
    }
`;

export const FormWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    align-items: flex-start;
    gap: 10px;

    div {
        flex: 12;
    }

    input {
        height: 40px;
    }

    button {
        flex: 1;
        background-color: #722ed1;
        border-color: #722ed1;
        height: 40px;

        &:hover {
            background-color: #531dab;
            border-color: #531dab;
        }

        &:focus {
            background-color: #531dab;
            border-color: #531dab;
        }

        &:active {
            background-color: #531dab;
            border-color: #531dab;
        }
    }
`;