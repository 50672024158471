import React from 'react';
import uid from 'uid';
import { Icon, Button, Input } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import  InputCurrency, { currency2Float } from '~/components/UI/InputCurrency';

import { TodoWrapper, TodoItem } from './Styled';

export default function Additionals({
    additionals,
    setAdditionals,
}) {
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
    
        const updated = Array.from(additionals);
        const [reordered] = updated.splice(result.source.index, 1);
        updated.splice(result.destination.index, 0, reordered);
    
        setAdditionals(updated);
    }

    return (
        <div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="tasks">
                    {(provided) => (
                    <TodoWrapper {...provided.droppableProps} ref={provided.innerRef}>
                        {additionals.map((task, index) => (
                        <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                            {(provided) => (
                            <TodoItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <>
                                    <Input
                                        value={task.title}
                                        onChange={(e) => setAdditionals(additionals.map(t => t.id === task.id ? { ...t, title: e.target.value } : t))}
                                        placeholder="Descreva o item adicional..."
                                    />
                                    <InputCurrency
                                        allowNegative={true}
                                        value={task.amount}
                                        onChange={(value) => setAdditionals(additionals.map(t => t.id === task.id ? { ...t, amount: currency2Float(value) } : t))}
                                        placeholder="Valor..."
                                    />
                                    <Button type="link" onClick={() => setAdditionals(additionals.filter(t => t.id !== task.id))}>
                                        <Icon type="delete" style={{ color: 'red' }} />
                                    </Button>
                                </>
                            </TodoItem>
                            )}
                        </Draggable>
                        ))}
                        {provided.placeholder}
                    </TodoWrapper>
                    )}
                </Droppable>
            </DragDropContext>
            <Button onClick={() => setAdditionals([...additionals, { id: uid(), title: '', amount: 0}])} className="mt-10">
                <Icon type="folder-add" /> Item Adicional
            </Button>
        </div>
    );
}