import update from 'immutability-helper';
import get from 'lodash/get';

import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

import * as ACTIONS from './actions';

const DEFAULT_CACHE_KEY = CACHE_ACTIONS.reports;
const defaultState = {
    defaulters: {
        data: [],
        isLoading: false
    },
};

const ACTION_HANDLERS = {
	[DEFAULT_CACHE_KEY]: (state, action) => {
        return action.payload;
   },

    [ACTIONS.LOAD_DEFAULTERS.INIT]: (state) => {
        return update(state, {
            defaulters: {
                isLoading: { $set: true }
            }
        });
    },

    [ACTIONS.LOAD_DEFAULTERS.SUCCESS]: (state, action) => {
        return update(state, {
            defaulters: {
                data: { $set: get(action, 'payload.data', []) },
                isLoading: { $set: false }
            }
        });
    },
};

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if(!handler) return state;

    const newState = handler(state, action);
  
    const updateCache = [
        ACTIONS.LOAD_DEFAULTERS.SUCCESS,
    ].includes(action.type);

    if(updateCache){
        LocalCacheHandler.save({
            pathname: DEFAULT_CACHE_KEY,
            data: newState
        });
    }

    return newState;
};

export default reducer;