export const show = (category, data) => ({
    type: '@@DRAWERSOCIETARIO/SHOW',
    payload: {
        data,
        category
    }
});

export const close = () => ({
    type: '@@DRAWERSOCIETARIO/CLOSE',
    payload: {}
});

export const createItem = (data) => ({
    type: '@@SOCIETARIO/CREATE',
    payload: {
        request:{
            method: 'post',
            url:'/dashtasks',
            data
        }
    }
});

export const saveItem = (id, data) => ({
    type: '@@DRAWERSOCIETARIO/UPDATE',
    payload: {
        request:{
            method: 'post',
            url:`/dashtasks/${id}`,
            data: data
        }
    }
});

export const deleteItem = (id) => ({
    type: '@@DRAWERSOCIETARIO/DELETE',
    payload: {
        request:{
            method: 'delete',
            url:`/dashtasks/${id}`
        },
    },
    id: id
});

export const archiveItem = (id) => ({
    type: '@@DRAWERSOCIETARIO/ARCHIVE',
    payload: {
        request:{
            method: 'put',
            url:`/dashtasks/${id}/archive`
        },
    },
    id: id
});

export const unArchiveItem = (id) => ({
    type: '@@DRAWERSOCIETARIO/UNARCHIVE',
    payload: {
        request:{
            method: 'put',
            url:`/dashtasks/${id}/unarchive`
        },
    },
    id: id
});