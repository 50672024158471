import React from 'react';
import { Tip } from "~/components/Tip";
import { getSummaryData } from "./helpers";
import { Tooltip } from "antd";

export const columns = () => [
    {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
        width: 500,
        ellipsis: true,
    },
    {
        title: 'Departamento',
        dataIndex: 'department',
        key: 'department',
        width: 200,
        ellipsis: true,
        filters: [
            { text: 'Legalização', value: 1 },
            { text: 'Administrativo', value: 2 },
            { text: 'Contábil', value: 3 },
            { text: 'Fiscal', value: 4 },
            { text: 'Pessoal', value: 5 },
            { text: 'Sucesso do Cliente (CS)', value: 6 },
        ],
        onFilter: (value, record) => parseInt(record.department) === value,
        render: (text) => {
            switch (parseInt(text)) {
                case 1:
                    return 'Legalização';
                case 2:
                    return 'Administrativo';
                case 3:
                    return 'Contábil';
                case 4:
                    return 'Fiscal';
                case 5:
                    return 'Pessoal';
                case 6:
                    return 'Sucesso do Cliente (CS)';
                default:
                    return 'Desconhecido';
            }
        }
    },
    {
        title: <span>+3 <Tip>Entregues antes de 3 dias</Tip></span>,
        dataIndex: 'greenCount',
        key: 'greenCount',
        align: 'center',
        render: (text, record) => {
            const { greenCount } = getSummaryData(record.registers);
            return (
            <Tooltip
                title={`${greenCount} documento${greenCount > 1 ? 's foram entregues' : ' foi entregue'} antes de 3 dias do vencimento`}
            >
                <span style={{ color: '#389e0d' }}>
                {greenCount}
                </span>
            </Tooltip>
            );
        },
    },
    {
        title: <span>=3 <Tip>Entregues 3 dias antes</Tip></span>,
        dataIndex: 'orangeCount',
        key: 'orangeCount',
        align: 'center',
        render: (text, record) => {
            const { orangeCount } = getSummaryData(record.registers);
            return (
            <Tooltip
                title={`${orangeCount} documento${orangeCount > 1 ? 's foram entregues' : ' foi entregue'} 3 dias antes do vencimento`}
            >
                <span style={{ color: '#d4b106' }}>
                {orangeCount}
                </span>
            </Tooltip>
            );
        },
    },
    {
        title: <span>-3 <Tip>Entregues com menos de 3 dias</Tip></span>,
        dataIndex: 'redCount',
        key: 'redCount',
        align: 'center',
        render: (text, record) => {
            const { redCount } = getSummaryData(record.registers);
            return (
            <Tooltip
                title={`${redCount} documento${redCount > 1 ? 's foram entregues' : ' foi entregue'} com menos de 3 dias de antecedência ao vencimento`}
            >
                <span style={{ color: '#f5222d' }}>
                {redCount}
                </span>
            </Tooltip>
            );
        },
    },
    {
        title: <span>Total <Tip>Total de documentos entregues</Tip></span>,
        dataIndex: 'totalCount',
        key: 'totalCount',
        align: 'center',
        render: (text, record) => {
            const { greenCount, orangeCount, redCount } = getSummaryData(record.registers);
            return (
            <Tooltip placement="top" title="Total">
                <span style={{ color: '#1890ff' }}>
                {greenCount + orangeCount + redCount}
                </span>
            </Tooltip>
            );
        },
}];
