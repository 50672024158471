import { onboardingPermissions } from "./permissions";

export const getOnboardingSteps = (user) => {
  if (!user.permissions || !user.onboarding_status) {
    return {
      allStepsCount: 0,
      finishedStepsCount: 0,
    };
  }

  const allowedSteps = Object.entries(onboardingPermissions)
    .filter(([_, value]) => user.permissions.includes(value))
    .map(([key]) => key);

  const allowedStepsStatus = Object.entries(user.onboarding_status)
    .filter(([key]) => allowedSteps.includes(key))
    .map(([_, value]) => ({
      status: value,
    }));

  const finishedStepsCount = Object.values(allowedStepsStatus).filter(
    ({ status }) => status === "finish"
  ).length;

  return {
    allStepsCount: allowedSteps.length,
    finishedStepsCount,
  };
};
