import React from 'react';
import { getSubdomain } from '~/helpers/getSubdomain';
import { ApplicationVersion } from "~/index";
import { AccountStorage } from '~/storages/account';
import * as IndexedDB from 'idb-keyval';

const Version = () => {
    const resetLocalStorage = () => {
        localStorage.clear();
        IndexedDB.clear();
        window.location.reload();
    };

    const handleDeleteAndReload = async () => {
        console.log('Clearing cache...');
        try {
        // Make a DELETE request
        const response = await fetch(`${process.env.REACT_APP_API}/auth/clear-cache`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-Tenant': getSubdomain(),
                'Authorization': `Bearer ${AccountStorage.get().token}`,
              },
        });

        console.log(response);

        // Check if the DELETE request was successful
        if (response.ok) {
            // Reload the page if successful
            resetLocalStorage();
        } else {
            // Handle the error appropriately
            console.error('DELETE cache failed');
        }
        } catch (error) {
        // Handle network or other errors
        console.error('Error:', error);
        }
    };

  return (
    <span className="application-version" onClick={handleDeleteAndReload}>
      © {ApplicationVersion}
    </span>
  );
};

export default Version;
