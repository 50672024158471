import React, { useState, useEffect } from 'react';
import { Icon, Upload, message } from 'antd';

import { getSubdomain } from '~/helpers/getSubdomain';
import { AccountStorage } from '~/storages/account';

export const UploadImage = ({
    defaultImageUrl = '',
    callback = () => {},
    url = ''
}) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(defaultImageUrl);

    useEffect(() => {
        callback(imageUrl);
    }, [imageUrl]);

    useEffect(() => {
        setImageUrl(defaultImageUrl);
    }, []);

    const uploadButton = (
        <div>
          {loading ? <Icon type="loading" /> : <Icon type="plus" />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      );
    
      const dataFiles = {
        action: url,
        multiple: false,
        listType: "picture-card",
        showUploadList: false,
        headers: {
          Authorization: `Bearer ${AccountStorage.get().token}`,
          'X-Tenant': getSubdomain()
        },
        onChange(info) {
          if (info.file.status === 'uploading') {
            setLoading(true);
            return;
          }
          if (info.file.status === 'done') {
            // Get this url from response in real world.
            // context.getBase64(info.file.originFileObj, imageUrl =>
            //   context.setState({
            //     imageUrl,
            //     loading: false,
            //   }),
            // );
            setLoading(false);
            setImageUrl(info.file.response.url);
          }
        },
        beforeUpload(file) {
          const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
          if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
          }
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
          }
          return isJpgOrPng && isLt2M;
        }
      };

    return (
        <Upload
            {...dataFiles}
            className="form-image-uploader"
        >
            {imageUrl ? 
              loading ? <Icon type="loading" /> :
              <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
    );
}