import React from 'react';

import { ChartsContainer } from './styles';
import { TaskChart } from "./TaskChart";

export function TasksChart({ data }) {
  if (!data) return null;
  const { 
    legalization,
    administrative,
    accounting,
    fiscal,
    personal,
    audit
  } = data;
  return (
    <ChartsContainer>
      <TaskChart title="Legalização" data={legalization} />
      <TaskChart title="Administrativo" data={administrative} />
      <TaskChart title="Contábil" data={accounting} />
      <TaskChart title="Fiscal" data={fiscal} />
      <TaskChart title="Pessoal" data={personal} />
      <TaskChart title="Sucesso do Cliente (CS)" data={audit} />
    </ChartsContainer>
  )
}