export const formOptions = {
  change_date: {
    rules: [
      {
        required: true,
        message: 'Por favor, informe a data de alteração',
      },
    ],
  },
};
