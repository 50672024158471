import update from 'immutability-helper';
import get from 'lodash/get';

import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

import * as ACTIONS from './actions';
import * as drawerActions from '~/containers/DrawerReferralProgram/actions';

const defaultState = {
    data: null,
    isLoading: false,
};

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.referrallProgramCampaigns]: (state, action) => {
        return action.payload;
    },

    [ACTIONS.ALL.INIT]: (state) => {
        return update(state, {
            isLoading: { $set: true },
        })
    },

    [ACTIONS.ALL.SUCCESS]: (state, action) => {
        return update(state, {
            data: { $set: get(action, 'payload.data') },
            isLoading: { $set: false },
        })
    },

    [ACTIONS.ALL.FAILURE]: (state) => {
        return update(state, {
            isLoading: { $set: false },
        })
    },

    [drawerActions.CREATE.INIT]: (state) => {
        return update(state, {
            isLoading: { $set: true },
        })
    },

    [drawerActions.CREATE.SUCCESS]: (state, action) => {
        console.log(action);
        return update(state, {
            isLoading: { $set: false },
            data: { $set: [
                ...state.data,
                get(action, 'payload.data.data'),
            ]},
        })
    },

    [drawerActions.CREATE.FAILURE]: (state) => {
        return update(state, {
            isLoading: { $set: false },
        })
    },

    [drawerActions.SAVE.INIT]: (state) => {
        return update(state, {
            isLoading: { $set: true },
        })
    },

    [drawerActions.SAVE.SUCCESS]: (state, action) => {
        const index = state.data.findIndex(item => item.id === get(action, 'payload.data.data.id'));

        return update(state, {
            isLoading: { $set: false },
            data: {
                [index]: { $set: get(action, 'payload.data.data') },
            }
        })
    },

    [drawerActions.DELETE.INIT]: (state, action) => {
        const index = state.data.findIndex(item => item.id === get(action, 'payload.id'));

        return update(state, {
            isLoading: { $set: true },
            data: {
                [index]: { $set: {
                    ...state.data[index],
                    name: 'Deletando...',
                }},
            }
        })
    },

    [drawerActions.DELETE.SUCCESS]: (state, action) => {
        const index = state.data.findIndex(item => item.id === get(action, 'payload.id'));

        return update(state, {
            isLoading: { $set: false },
            data: {
                $splice: [[index, 1]],
            }
        })
    },
}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
  
    if (!handler) return state;

    const newState = handler(state, action);

    const updateCache = [
        ACTIONS.ALL.SUCCESS,
        drawerActions.CREATE.SUCCESS,
        drawerActions.SAVE.SUCCESS,
        drawerActions.DELETE.SUCCESS,
    ].includes(action.type);

    if (updateCache) {
            LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.referrallProgramCampaigns,
            data: newState
        });
    }

    return newState;
};

export default reducer;