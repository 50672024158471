import { getDaysCountBetweenDates } from "~/utils/date-handlers";
import { deliveryMethodsSnakeNames } from "./data";

export const getSummaryData = (registers) => {
  let greenCount = 0;
  let orangeCount = 0;
  let redCount = 0;

  registers.forEach((register) => {
    const { due_at, created_at, customer_delivery_method, checked_as_without_movement_at } = register;

    if (!!due_at && !!created_at) {
      const dateType = deliveryMethodsSnakeNames[customer_delivery_method];
      const date = checked_as_without_movement_at ? checked_as_without_movement_at : register[dateType];

      if (date) {
        const daysCount = getDaysCountBetweenDates(due_at, date);
  
        if (daysCount > 3) {
          greenCount++;
        } else if (daysCount === 3) {
          orangeCount++;
        } else {
          redCount++;
        }
      }
    }
  });

  return {
    greenCount,
    orangeCount,
    redCount,
  };
};

export const getDaysCountColor = (count) => {
  if (count > 3) return "#389e0d";
  if (count === 3) return "#d4b106";
  return "#f5222d";
};
