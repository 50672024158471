import styled from "styled-components";

export const ContractsList = styled.ul``;

export const ContractItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  gap: 8px;
`;

export const ContractFile = styled.a`
  display: block;
`;

export const AppUserContainer = styled.div``;
