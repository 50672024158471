import { Progress } from "antd";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import styled from "styled-components";

export const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ChartItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;

  .ant-table-wrapper {
    width: 100%;
  }

  .ant-table-body {
    margin: 0 !important;
  }
`;

export const ChartContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const TopContent = styled.div`
  display: flex;
  align-items: center;
  width: 140px;
`;

export const CentralContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
`;

export const ChartTitle = styled.span`
  font-weight: bold;
  user-select: none;
  text-transform: uppercase;
  font-size: 12px;
`;

export const ChartDueDateTitle = styled.span`
  font-size: 12px;
  user-select: none;
`;

export const ChartDueDate = styled.span`
  font-size: 12px;
  user-select: none;
  color: ${({ expired }) => (expired === "true" ? "#f5222d" : "initial")};
`;

export const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  align-items: center;
`;

export const ChartPercentTitle = styled.span`
  font-size: 12px;
  user-select: none;
`;

export const ChatProgress = styled(Progress).attrs({
  showInfo: false,
  strokeColor: "#52c41a",
})`
  width: 80%;
  max-width: 80px !important;

  > div {
    height: 8px;
    line-height: 0;
  }
`;

export const LeftArrow = styled(FaChevronLeft).attrs({
  size: 18,
})`
  cursor: ${({ visible }) => (visible === "true" ? "pointer" : "initial")};
  opacity: ${({ visible }) => (visible === "true" ? 1 : 0)};
  transition: transform 100ms;

  &:active {
    transform: scale(0.8);
  }
`;

export const RightArrow = styled(FaChevronRight).attrs({
  size: 18,
})`
  cursor: ${({ visible }) => (visible === "true" ? "pointer" : "initial")};
  opacity: ${({ visible }) => (visible === "true" ? 1 : 0)};
  transition: transform 100ms;

  &:active {
    transform: scale(0.8);
  }
`;

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;
