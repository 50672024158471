import React from 'react';
import Chart from 'react-apexcharts';
import { convertToBRL } from '~/utils/formatters';

export const DefaultersChart = ({ data }) => {
  if (!data) return null;

  if (data.length === 0) {
    return 'Não existem clientes inadimplentes no momento.';
  }

  const sortedData = data.sort((a, b) => b.amount - a.amount);

  return (
    <Chart
      options={{
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          formatter: (value) => convertToBRL(value),
        },
        xaxis: {
          categories: sortedData.map(({ name }) => name),
          labels: {
            formatter: (value) => {
              return value ? convertToBRL(value) : 0;
            },
          },
        },
        tooltip: {
          y: {
            formatter: (value) => convertToBRL(value),
          },
        },
      }}
      series={[
        {
          data: sortedData.map(({ amount }) => amount),
          name: 'Valor',
          color: '#f5222d',
        },
      ]}
      type="bar"
      height={350}
    />
  );
};
