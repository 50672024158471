import { PERMISSIONS } from '~/constants/permissions.constants';

const graphPermissions = [
  PERMISSIONS.graphs.monthlyPayments.view,
  PERMISSIONS.graphs.calendarTasks.view,
  PERMISSIONS.graphs.customersCount.view,
  PERMISSIONS.graphs.dashTasks.view,
  PERMISSIONS.graphs.extraPayments.view,
  PERMISSIONS.graphs.certificates.view,
  PERMISSIONS.graphs.leads.view,
  PERMISSIONS.graphs.customersByType.view
];

export const getAllGraphPermissions = () => {
  return graphPermissions;
}

export const onboardingPermissions = {
  1: PERMISSIONS.users.view,
  2: PERMISSIONS.customers.view,
  3: PERMISSIONS.calendarTasks.create,
  4: PERMISSIONS.calendarTasks.create,
  5: PERMISSIONS.calendarTasks.create,
  6: PERMISSIONS.leads.view,
  7: PERMISSIONS.leads.view,
  8: PERMISSIONS.leads.view,
  9: PERMISSIONS.corporate.view,
  10: PERMISSIONS.financial.monthlyPayments.view,
  11: PERMISSIONS.financial.monthlyPayments.view,
  12: PERMISSIONS.financial.monthlyPayments.view,
}