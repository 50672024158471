import React from 'react';
import { Form, Input, Button, Select, Icon, Row, Col } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Tip } from '~/components/Tip';
import { UploadImage } from '~/components/UploadImage';

import { ChatMessage, ChatWrapper } from '../ChatBot/styles.js';

import { leadFields } from './leadFields.js';

export const ChatBotBuilder = ({ fields = [], setFields = () => {} }) => {
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const updatedTasks = Array.from(fields);
    const [reorderedTask] = updatedTasks.splice(result.source.index, 1);
    updatedTasks.splice(result.destination.index, 0, reorderedTask);

    setFields(updatedTasks);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="fields">
        {(provided) => (
          <ChatWrapper {...provided.droppableProps} ref={provided.innerRef}>
            {fields.map((field, index) => (
              <Draggable key={field.id} draggableId={field.id.toString()} index={index}>
                {(provided) => (
                  <ChatMessage
                    className={`${field.from} example ${field.selected ? 'selected' : ''}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={() => {
                      if(field.selected) return;
                      setFields(fields.map(t => t.id === field.id ? { ...t, selected: !t.selected } : t));
                    }}
                  >
                    <>
                      {field.type === 'text'  && (
                        !field.selected ? field.text : (
                          <>
                          <Input
                            value={field.text}
                            onChange={(e) => setFields(fields.map(t => t.id === field.id ? { ...t, text: e.target.value } : t))}
                            placeholder="Insira o texto da mensagem"
                          />
                          <Button 
                            block 
                            type="success"
                            onClick={() => setFields(fields.map(t => t.id === field.id ? { ...t, selected: !t.selected } : t))}
                            style={{ marginTop: 10 }}>
                            Salvar
                          </Button>
                          <Button
                            block
                            type="link"
                            onClick={() => setFields(fields.filter(t => t.id !== field.id))}
                            style={{ color: 'red' }}>
                            Excluir
                          </Button>
                          </>
                        )
                      )}
                      {field.type === 'button' && (
                        !field.selected ? (
                          <>
                          {field.text}<br />
                          <Button block style={{ marginTop: 10 }}>
                            {field.label}
                          </Button>
                          </>
                        ) : (
                          <>
                          <Input
                            value={field.text || ''}
                            onChange={(e) => setFields(fields.map(t => t.id === field.id ? { ...t, text: e.target.value } : t))}
                            placeholder="Insira o texto acima do botão"
                          />
                          <Input
                            style={{ marginTop: 5 }}
                            value={field.label || ''}
                            onChange={(e) => setFields(fields.map(t => t.id === field.id ? { ...t, label: e.target.value } : t))}
                            placeholder="Insira o nome do botão"
                          />
                          <Input
                            style={{ marginTop: 5 }}
                            value={field.value || ''}
                            onChange={(e) => setFields(fields.map(t => t.id === field.id ? { ...t, value: e.target.value } : t))}
                            placeholder="Insira o link do botão"
                          />
                          <Button 
                            block 
                            type="success"
                            onClick={() => setFields(fields.map(t => t.id === field.id ? { ...t, selected: !t.selected } : t))}
                            style={{ marginTop: 10 }}>
                            Salvar
                          </Button>
                          <Button
                            type="link"
                            block
                            onClick={() => setFields(fields.filter(t => t.id !== field.id))}
                            style={{ color: 'red' }}>
                            Excluir
                          </Button>
                          </>
                        )
                      )}

                      {field.type === 'image' && (
                        !field.selected ? (
                          <>
                            <img src={field.url} alt="Imagem" style={{ maxWidth: '85px', maxHeight: '85px' }} />
                          </>
                        ) : (
                          <>
                          <UploadImage 
                            defaultImageUrl={field.url}
                            url={process.env.REACT_APP_API+'/auth/plugins/forms/image'}
                            callback={(url) => setFields(fields.map(t => t.id === field.id ? { ...t, url } : t))}
                          />
                          <Button 
                            block 
                            type="success"
                            onClick={() => setFields(fields.map(t => t.id === field.id ? { ...t, selected: !t.selected } : t))}
                            style={{ marginTop: 10 }}>
                            Salvar
                          </Button>
                          <Button
                            type="link"
                            block
                            onClick={() => setFields(fields.filter(t => t.id !== field.id))}
                            style={{ color: 'red' }}>
                            Excluir
                          </Button>
                          </>
                        )
                      )}

                      {((field.type === 'input' && !field.format) 
                        || (field.type === 'input' && field.format === 'phone')
                        || (field.type === 'input' && field.format === 'money')
                        || (field.type === 'input' && field.format === 'email')
                        || (field.type === 'input' && field.format === 'number')
                        || (field.type === 'input' && field.format === 'cnpj')
                        || (field.type === 'input' && field.format === 'text')
                      ) && (
                        !field.selected ? (
                          <>
                          <Input
                            value={'Resposta do usuário'}
                            placeholder="Resposta do usuário"
                            readOnly
                          />
                          {field.field_name && (
                          <span style={{ fontSize: 11, color: '#999', marginTop: 5 }}>
                            <Icon type="save" /> Salvo como: <strong>{leadFields.find(t => t.key === field.field_name).name}</strong>
                          </span>
                          )}
                          {field.custom_field_name && !field.field_name && (
                          <span style={{ fontSize: 11, color: '#999', marginTop: 5 }}>
                            <Icon type="save" /> Campo personalizado: <strong>{field.custom_field_name}</strong>
                          </span>
                          )}
                          </>
                        ) : (
                          <>
                         <Form.Item 
                            style={{ marginTop: 10 }}
                            label={<span>Salvar como <Tip>Selecione um nome para este campo</Tip></span>}>
                             <Input
                              value={field.custom_field_name || ''}
                              disabled={field.field_name ? true : false}
                              onChange={(e) => setFields(fields.map(t => t.id === field.id ? { ...t, custom_field_name: e.target.value } : t))}
                              placeholder="Nome do campo"
                            />
                          </Form.Item>
                          <Button 
                            block 
                            type="success"
                            onClick={() => setFields(fields.map(t => t.id === field.id ? { ...t, selected: !t.selected } : t))}
                            style={{ marginTop: 10 }}>
                            Salvar
                          </Button>
                          <Button
                            type="link"
                            block
                            onClick={() => setFields(fields.filter(t => t.id !== field.id))}
                            style={{ color: 'red' }}>
                            Excluir
                          </Button>
                          </>
                        )
                      )}

                      {field.type === 'input' && field.format == 'select' && (
                        !field.selected ? (
                          <>
                          <Select
                            value={'Selecione uma opção'}
                            placeholder="Selecione uma opção"
                            readOnly
                          >
                          </Select>
                          {field.field_name && (
                          <span style={{ fontSize: 11, color: '#999', marginTop: 5 }}>
                            <Icon type="save" /> Salvo como: <strong>{leadFields.find(t => t.key === field.field_name).name}</strong>
                          </span>
                          )}
                          {field.custom_field_name && !field.field_name && (
                          <span style={{ fontSize: 11, color: '#999', marginTop: 5 }}>
                            <Icon type="save" /> Campo personalizado: <strong>{field.custom_field_name}</strong>
                          </span>
                          )}
                          </>
                        ) : (
                          <>
                            {!field.settings && (
                            <Form.Item 
                              style={{ marginBottom: 0 }}
                              label={<span>Opções <Tip>Insira as opções que o usuário poderá selecionar</Tip></span>}>

                              {/* handle json options name/value */}
                              {field.options && typeof field.options === 'array' && field.options.map((option, index) => (
                                  <Row gutter={16}>
                                    <Col span={20}>
                                      <Input
                                        key={index}
                                        value={option.name}
                                        onChange={(e) => setFields(fields.map(t => t.id === field.id ? { ...t, options: field.options.map((o, i) => i === index ? { name: e.target.value, value: o.value } : o) } : t))}
                                        placeholder={`Opção ${index + 1}`}
                                        style={{ marginBottom: '3px' }}
                                      />
                                    </Col>
                                    <Col span={4}>
                                      <Icon
                                        type="delete"
                                        style={{ fontSize: 16, color: 'red', cursor: 'pointer', marginTop: 5 }}
                                        onClick={() => setFields(fields.map(t => t.id === field.id ? { ...t, options: field.options.filter((o, i) => i !== index) } : t))}
                                      />
                                    </Col>
                                  </Row>
                              ))}
                            </Form.Item>
                            )}
                            {!field.settings === 'array' && (
                            <Button
                              block
                              type="dashed"
                              style={{ marginTop: 5 }}
                              onClick={() => {
                                setFields(fields.map(t => t.id === field.id ? { ...t, options: [...t.options, { name: '', value: '' }] } : t))
                              }}
                            >
                              Adicionar opção
                            </Button>
                            )}

                          <Form.Item 
                            style={{ marginTop: 10 }}
                            label={<span>Salvar como <Tip>Selecione um nome para este campo</Tip></span>}>
                             <Input
                              value={field.custom_field_name || ''}
                              disabled={field.field_name ? true : false}
                              onChange={(e) => setFields(fields.map(t => t.id === field.id ? { ...t, custom_field_name: e.target.value } : t))}
                              placeholder="Nome do campo"
                            />
                          </Form.Item>
                          <Button 
                            block 
                            type="success"
                            onClick={() => setFields(fields.map(t => t.id === field.id ? { ...t, selected: !t.selected } : t))}
                            style={{ marginTop: 10 }}>
                            Salvar
                          </Button>
                          <Button
                            type="link"
                            block
                            onClick={() => setFields(fields.filter(t => t.id !== field.id))}
                            style={{ color: 'red' }}>
                            Excluir
                          </Button>
                          </>
                        )
                      )}
                     
                    </>
                  </ChatMessage>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ChatWrapper>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ChatBotBuilder;
