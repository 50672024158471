import update from 'immutability-helper';
import get from 'lodash/get';
// import set from 'lodash/set';

const ACTION_HANDLERS = {

    '@@CUSTOMER/SHOW': (state, action) => {
        return update(state, {
            drawer: {
                data: { $set: get(action, 'payload.data') },
                show: { $set: true },
                openedFrom: { $set: get(action, 'payload.openedFrom') },
            }
        })
    },

    '@@CUSTOMER/CLOSE': (state, action) => {
        return update(state, {
            drawer: {
                data: { $set: action.payload} ,
                show: { $set: false },
                openedFrom: { $set: null }
            }
        })
    },
}

const defaultState = {
    drawer: {
        data: {},
        show: false,
        openedFrom: null
    }
};

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
  
    return handler ? handler(state, action) : state;
};

export default reducer;