import update from "immutability-helper";

import {
  CLOSE_ONBOARDING,
  CLOSE_VIDEO_MODAL,
  FINISH_STEP,
  OPEN_ONBOARDING,
  OPEN_VIDEO_MODAL,
  SELECT_STEP,
} from "./actions";

const storagedOnboard = JSON.parse(localStorage.getItem("onboarding"));

function updateLocalStorage(newState) {
  localStorage.setItem("onboarding", JSON.stringify(newState));
}

const DEFAULT_STATE = {
  isActive: storagedOnboard ? storagedOnboard.isActive : false,
  step: storagedOnboard ? storagedOnboard.step : 0,
  videoModal: {
    isVisible: false,
    title: null,
    url: null,
  },
};

const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FINISH_STEP.INIT: {
      const nextStep = state.step + 1;
      updateLocalStorage({
        ...state,
        step: nextStep,
      });
      return update(state, {
        step: {
          $set: nextStep,
        },
      });
    }

    case SELECT_STEP.INIT: {
      const selectedStep = action.payload.step;
      updateLocalStorage({
        ...state,
        step: selectedStep,
      });
      return update(state, {
        step: {
          $set: selectedStep,
        },
      });
    }

    case OPEN_ONBOARDING.INIT: {
      updateLocalStorage({
        ...state,
        isActive: true,
      });
      return update(state, {
        isActive: {
          $set: true,
        },
      });
    }

    case CLOSE_ONBOARDING.INIT: {
      updateLocalStorage({
        ...state,
        isActive: false,
      });
      return update(state, {
        isActive: {
          $set: false,
        },
      });
    }

    case OPEN_VIDEO_MODAL.INIT: {
      return update(state, {
        videoModal: {
          $set: {
            isVisible: true,
            title: action.payload.title,
            url: action.payload.url,
          },
        },
      });
    }

    case CLOSE_VIDEO_MODAL.INIT: {
      return update(state, {
        videoModal: {
          $set: {
            isVisible: false,
            title: null,
            url: null,
          },
        },
      });
    }

    default:
      return state;
  }
};

export default reducer;
