import { Button, Modal, Tooltip } from 'antd';
import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import FileDownload from 'js-file-download';
import moment from 'moment';

import { HelperText } from '../../styles';
import { request } from '~/components/Instance';
import { deliveryMethodNames } from '~/containers/Customer/data';

export const ProtocolButton = ({
  props: {
    buttonsProps,
    buttonsState,
    buttonsLoadingState,
    hasNewFiles,
    currentTaskRecordId,
    checkIfHasButtonLoading,
    setButtonsLoadingState,
    updateDeliveryMethods,
    setButtonsState,
    defaultCustomerDeliveryMethod,
  },
}) => {
  async function downloadProtocol() {
    try {
      setButtonsLoadingState((state) => ({
        ...state,
        protocol: true,
      }));
      const response = await request(
        'POST',
        `calendar/task_record/${currentTaskRecordId}/protocol`,
        null,
        true,
        { responseType: 'blob' }
      );
      const now = moment().format('YYYY-MM-DD_hh-mm-ss');
      // TODO: Encontrar uma maneira de retornar o ID do protocolo
      FileDownload(
        response.data,
        `Protocolo_${now}.pdf`
      );
      updateDeliveryMethods(1);
      setButtonsState((state) => ({
        ...state,
        isProtocolButtonClicked: true,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setButtonsLoadingState((state) => ({
        ...state,
        protocol: false,
      }));
    }
  }

  function handleDownloadProtocol() {
    if (checkIfHasButtonLoading()) return;

    if (!buttonsProps.protocol.isDefaultMethod) {
      return Modal.confirm({
        title: `
          O método padrão de envio de documentos escolhido por este cliente é ${deliveryMethodNames[defaultCustomerDeliveryMethod]}. 
          Tem certeza que deseja continuar com o envio via ${deliveryMethodNames[0]}?
        `,
        content: 'Esta ação não poderá ser desfeita.',
        okText: 'Sim',
        okType: 'primary',
        cancelText: 'Cancelar',
        onOk: downloadProtocol,
      });
    }

    downloadProtocol();
  }

  return (
    <Tooltip title={buttonsProps.protocol.tooltipTitle}>
      <Button
        onClick={handleDownloadProtocol}
        loading={buttonsLoadingState.protocol}
        disabled={hasNewFiles}
        type={buttonsProps.protocol.isDefaultMethod ? 'primary' : 'default'}
      >
        <AiOutlineFilePdf />
        Protocolo
      </Button>
      {buttonsState.isProtocolButtonClicked && (
        <HelperText>Protocolo já gerado</HelperText>
      )}
    </Tooltip>
  );
};
