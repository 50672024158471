import { Icon } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-top: 20px;
`;

export const SummaryCard = styled.div`
  width: 300px;
  background-color: rgb(245, 245, 245);
  border: 1px solid #e8e8e8;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const SummaryTitlesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SummaryTitle = styled.span`
  margin-bottom: 0;
  font-size: 16px;
  color: ${({ color }) => color};
`;

export const SummaryCount = styled.span`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const SummaryPercentage = styled.span``;

export const SummaryUpArrow = styled(Icon).attrs({
  type: 'arrow-up',
  style: {
    color: '#52c41a',
  },
})``;

export const SummaryDownArrow = styled(Icon).attrs({
  type: 'arrow-down',
  style: {
    color: '#ff4d4f',
  },
})``;

export const SummaryPointerWrapper = styled.div`
  position: absolute;
  right: -40px;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  z-index: 2;
`;

export const SummaryPointer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  color: #fff
  background-color: #000;
  font-weight: 700;
  clip-path: polygon(0% 0%, 83% 0%, 100% 50%, 83% 100%, 0% 100%);
`;