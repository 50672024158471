import { message } from 'antd';
import moment from 'moment';
import {
  identificationNumberFormatter,
  phoneFormatter,
  removeCountryCodeFromPhone,
} from '~/utils/formatters';
import { fields, necessity, tax_software } from '../data';

export const formatFieldValue = (item, field) => {
  const fieldName = field.name;
  const fieldValue = item[field.name];

  if (fieldValue === null || fieldValue === undefined) return '';

  switch (fieldName) {
    case 'phone':
      const phoneWithoutCountryCode = removeCountryCodeFromPhone(fieldValue);
      return phoneFormatter(phoneWithoutCountryCode);
    case 'registered_at':
    case 'last_column_updated_at':
      return moment(fieldValue).format('DD/MM/YYYY');
    case 'necessity':
      return necessity[fieldValue].name;
    case 'identification_number':
      return identificationNumberFormatter(fieldValue);
    case 'billing':
    case 'first_honorary':
    case 'honorary':
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(fieldValue);
    case 'tax_software':
      return tax_software[fieldValue];
    case 'rate':
      return `${fieldValue}/3`;
    default:
      return fieldValue;
  }
};

export const handleCopyLeadToClipBoard = (event, item) => {
  event.stopPropagation();
  let finalString = '';
  fields.forEach((field) => {
    finalString += `${field.title}: ${formatFieldValue(item, field)}\n`;
  });

  navigator.clipboard.writeText(finalString);
  message.success('Dados do lead copiados para a área de transferência!');
};

export const getTaskColor = (status) => {
  return {
    Vencido: 'red',
    'A vencer': 'yellow',
    Hoje: 'green',
  }[status];
};
