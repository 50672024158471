import React from "react";

import {
  ByMonthText,
  Container,
  DiscountWrapper,
  Label,
  MoneySymbol,
  Plan,
  PlanDurationText,
  PlanName,
  PlanPrice,
  PlanPriceContaienr,
  PlanPriceWrapper,
  PlanSplit,
  PlansWrapper,
  RenewText,
  RenewTextWrapper,
  RightContent,
} from "./styles";

export const Plans = ({ formState, setFormState }) => {
  const { plan, coupon } = formState;

  function updatePlan(value) {
    setFormState((state) => ({
      ...state,
      plan: value,
    }));
  }

  function checkIfHasCoupon(plan) {
    return !!coupon && coupon.plans.includes(plan);
  }

  function getPriceWithDiscount(originalPrice) {
    const { discount } = coupon;

    const price = Math.floor(originalPrice - (originalPrice * discount) / 100);
    return new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  }

  const monthlyHasCoupon = checkIfHasCoupon("monthly");
  const semesterHasCoupon = checkIfHasCoupon("semester");

  return (
    <Container>
      <Label>Escolha um plano</Label>
      <PlansWrapper isMobileScreen={window.screen.width < 640}>
        <Plan
          isSelected={plan === "monthly"}
          onClick={() => plan !== "monthly" && updatePlan("monthly")}
          hasCoupon={monthlyHasCoupon}
          alignItemsCenter
        >
          <RenewTextWrapper>
            <PlanName>Mensal</PlanName>
            <RenewText>Renova automaticamente todo mês</RenewText>
          </RenewTextWrapper>
          <RightContent>
            <PlanPriceWrapper>
              <MoneySymbol>R$</MoneySymbol>
              <PlanPriceContaienr>
                <PlanPrice hasCoupon={monthlyHasCoupon}>467,00</PlanPrice>
                {monthlyHasCoupon && (
                  <PlanPrice withDiscount>
                    {getPriceWithDiscount(467)}
                  </PlanPrice>
                )}
              </PlanPriceContaienr>
              <ByMonthText> / mês</ByMonthText>
            </PlanPriceWrapper>
          </RightContent>
        </Plan>
        <Plan
          isSelected={plan === "semester"}
          hasCoupon={semesterHasCoupon}
          onClick={() => plan !== "semester" && updatePlan("semester")}
        >
          <RenewTextWrapper>
            <PlanName>Semestral</PlanName>
            <RenewText>Renova automaticamente a cada 6 meses</RenewText>
          </RenewTextWrapper>
          <RightContent isSemester>
            <PlanPriceWrapper>
              <PlanSplit>6x</PlanSplit>
              <MoneySymbol>R$</MoneySymbol>
              <PlanPriceContaienr>
                <PlanPrice hasCoupon={semesterHasCoupon}>420,30</PlanPrice>
                {semesterHasCoupon && (
                  <PlanPrice withDiscount>
                    {getPriceWithDiscount(420.30)}
                  </PlanPrice>
                )}
              </PlanPriceContaienr>
            </PlanPriceWrapper>
            <PlanDurationText>Por 6 meses</PlanDurationText>
          </RightContent>
          <DiscountWrapper>10% OFF</DiscountWrapper>
        </Plan>
      </PlansWrapper>
    </Container>
  );
};
