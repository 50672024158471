import React from 'react';
import { Row, Col, Form, Switch, Button, Progress } from 'antd';
import InputCurrency, { currency2Float } from '~/components/UI/InputCurrency';
import { InstallmentsTable } from '../Tables/InstallmentsTable';
import { PERMISSIONS } from '~/constants/permissions.constants';
import { get } from 'lodash';

const getInputName = (category) => {
    switch(category){
        case 1: return 'Honorário de abertura';
        case 2: return 'Honorário de alteração';
        case 3: return 'Honorário de encerramento';
        case 4: return 'Serviço extra';
        case 5: return 'Honorário de abertura';
        default: return 'Serviço extra';
    }
}

export const Financial = ({
    data,
    form,
    user,
    billsPayment,
    billsData,
    setBillsData,
    openBillForm,
    category
}) => {
    const payment = billsPayment;
    const { getFieldDecorator, getFieldValue } = form;

    const getPaymentPercentage = (payment) => {
        if (!payment) return 0;
    
        const paidInstallments = payment.installments.filter((installment) => installment.status === 'Pago');
        const percent = Math.round((100 * paidInstallments.length) / payment.installments.length);
        return percent;
    }

    const createBills = (val) => {
        if (val) {
          openBillForm({
            task_name: form.getFieldValue('task'),
            total: form.getFieldValue('amount')
          });
        } else {
          setBillsData(null);
        }
      }

    return (
        <Row style={{ margin: '-15px' }}>
            <Col span={8}>
              <Form.Item label={getInputName(category)}>
                {getFieldDecorator('amount', {
                  initialValue: get(data, 'custom_fields.amount', 0),
                  normalize: (value) => currency2Float(value)
                })(
                  <InputCurrency />
                )}
              </Form.Item>
            </Col>
            {!payment && (
              (getFieldValue('amount') > 0) && (
                <Col span={8} offset={1}>
                  <Form.Item label="Criar contas a pagar" valuePropName="checked">
                    <Switch
                        checked={!!billsData}
                        disabled={(!user.permissions.includes(PERMISSIONS.corporate.createBillsToPay))}
                        onChange={createBills}
                    />
                  </Form.Item>
                </Col>
              )
            )}
            {payment && (
              <Col span={8} offset={1}> 
                <Form.Item label="Status do Pagamento">
                  <Progress percent={getPaymentPercentage(payment)} />
                </Form.Item>
              </Col>
            )}
            {(payment || billsData) && (
              <Col span={24}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}}>
                  <span>Parcelas</span>
                  <Button
                    disabled={(!user.permissions.includes(PERMISSIONS.extraServices.edit))}
                    onClick={createBills}
                    type="primary"
                  >
                    Editar
                  </Button>
                </div>
                <InstallmentsTable
                  payment={payment}
                  billsData={billsData}
                />
              </Col>
            )}
        </Row>
    );
}

export default Financial;