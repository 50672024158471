import React from "react";
import { StyledFormControl } from './styles';

export const MaterialUIFormControl = ({ children, ...props }) => {
  return (
    <StyledFormControl fullWidth {...props}>
      {children}
    </StyledFormControl>
  );
};
