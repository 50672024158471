import React from 'react';
import styled from 'styled-components';

const Cod = styled.div`
    ${props => props.float && `float: ${props.float};`}
    margin-right: 30px;
    font-size: 13px;
    background-color: #f2f2f2;
    padding: 1px 5px 1px 5px;
    border-radius: 5px;
    border: solid 1px #ccc;
    display: inline;
`

export default Cod;