import { Form, Input, Radio } from 'antd';
import styled, { css } from 'styled-components';

export const Header = styled.div`
  margin: 0 -16px;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 10px;
`;

export const SearchInput = styled(Input.Search)`
  max-width: 480px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  gap: 6px;
`;

export const RadioButtons = styled(Radio.Group)``;

export const ActiveRadioButton = styled(Radio.Button)`
  ${({ isActive }) =>
    isActive &&
    css`
      border-color: #a0d911 !important;
      color: #a0d911 !important;
      box-shadow: -1px 0 0 0 #a0d911 !important;
    `}
`;

export const PendingRadioButton = styled(Radio.Button)`
  ${({ isActive }) =>
    isActive &&
    css`
      border-color: #ffa940 !important;
      color: #ffa940 !important;
      box-shadow: -1px 0 0 0 #ffa940 !important;
    `}
`;

export const ArchivedRadioButton = styled(Radio.Button)`
  ${({ isActive }) =>
    isActive &&
    css`
      border-color: #f5222d !important;
      color: #f5222d !important;
      box-shadow: -1px 0 0 0 #f5222d !important;
    `}
`;

export const ArchiveModalContainer = styled.div``;

export const ArchiveModalSubTitle = styled.span``;

export const ArchiveModalFormItem = styled(Form.Item).attrs({
  style: {
    marginBottom: 0,
    marginTop: 10
  }
})``

export const DeliveryMethodsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  > span {
    margin: 0;
  }
`;