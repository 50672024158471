import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Tree } from 'antd';

import * as permissionsActions from '../actions';
import { permissionsTree } from '~/components/Permissions/permissions-tree';

const FormItem = Form.Item;
const { TreeNode } = Tree;

class PermissionsFormModal extends React.Component {
  state = {
    isSaving: false,
  };

  renderTreeNodes = (permissions, isPremiumPlan, allowBetaFeatures) => {
    if(!isPremiumPlan){
      permissions = permissions.filter((item) => item.plans && item.plans.includes('starter'));
    }

    if(!allowBetaFeatures){
      permissions = permissions.filter((item) => !item.isBeta);
    }

    return permissions.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children, isPremiumPlan)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} />;
    });
  };

  onCreate = () => {
    const { form } = this.props;

    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      values.permissions = values.permissions.filter(
        (item) => !item.includes('_PARENT')
      );

      this.setState({ isSaving: true });
      try {
        const { data } = this.props.modal;

        if (data) {
          await this.props.updatePermissionsGroup(data.id, values);
        } else {
          await this.props.createPermissionsGroup(values);
        }

        this.props.closeModal();
      } finally {
        this.setState({ isSaving: false });
      }
    });
  };

  render() {
    const { closeModal, form, user } = this.props;
    const isPremiumPlan = user.tenant.plan === 'premium';
    const allowBetaFeatures = user.tenant.is_beta_tester;
    const { getFieldDecorator } = form;
    const { isVisible, data } = this.props.modal;
    const { isSaving } = this.state;

    return (
      <Modal
        visible={isVisible}
        title={data ? 'Editar grupo' : 'Cadastrar grupo'}
        okText={data ? 'Salvar' : 'Cadastrar'}
        onCancel={closeModal}
        onOk={this.onCreate}
        okButtonProps={{ loading: isSaving }}
        destroyOnClose
      >
        <Form layout="vertical">
          <FormItem label="Nome">
            {getFieldDecorator('name', {
              initialValue: data ? data.name : undefined,
              rules: [{ required: true, message: 'Por favor informe o nome!' }],
            })(<Input placeholder="Nome do grupo de permissões" />)}
          </FormItem>
          <FormItem label="Permissões">
            {getFieldDecorator('permissions', {
              initialValue: data ? data.permissions : [],
              rules: [
                {
                  required: true,
                  message: 'Selecione pelo menos uma permissão!',
                },
              ],
              valuePropName: 'checkedKeys',
              validateTrigger: 'onCheck',
            })(<Tree checkable>{this.renderTreeNodes(permissionsTree, isPremiumPlan, allowBetaFeatures)}</Tree>)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const mapStateProps = (state) => ({
  user: state.user,
  modal: state.permissions.modal,
});

const mapDispatchProps = (dispatch) => ({
  closeModal: () => dispatch(permissionsActions.closeModal()),
  createPermissionsGroup: (data) => dispatch(permissionsActions.create(data)),
  updatePermissionsGroup: (id, data) =>
    dispatch(permissionsActions.update(id, data)),
});

export default connect(
  mapStateProps,
  mapDispatchProps
)(Form.create()(PermissionsFormModal));
