import { createAsyncActions } from '~/utils/store';

export const LOAD_COUNT_CLICKS = createAsyncActions('@@COUNT_CLICKS/LOAD');
export const LOAD_COUNT_CLICKS_REPORTS = createAsyncActions('@@COUNT_CLICKS/LOAD');
export const CREATE_COUNT_CLICK = createAsyncActions('@@COUNT_CLICKS/CREATE');
export const UPDATE_COUNT_CLICK = createAsyncActions('@@COUNT_CLICKS/UPDATE');
export const DELETE_COUNT_CLICK = createAsyncActions('@@COUNT_CLICKS/DELETE');

export const LOAD_AUTOMATIONS = createAsyncActions('@@AUTOMATION/LOAD');
export const CREATE_AUTOMATION = createAsyncActions('@@AUTOMATION/CREATE');
export const UPDATE_AUTOMATION = createAsyncActions('@@AUTOMATION/UPDATE');
export const DELETE_AUTOMATION = createAsyncActions('@@AUTOMATION/DELETE');

export const loadCountClicks = () => ({
  type: LOAD_COUNT_CLICKS.INIT,
  payload: {
    request: {
      method: 'GET',
      url: '/plugins/count-clicks',
      showLoading: false,
    },
  },
});

export const loadReportsFromCountClicks = (year) => ({
  type: LOAD_COUNT_CLICKS_REPORTS.INIT,
  payload: {
    request: {
      method: 'GET',
      url: '/plugins/count-clicks/reports/'+year,
      showLoading: false,
    },
  },
});

export const createCountClick = ({ data }) => ({
  type: CREATE_COUNT_CLICK.INIT,
  payload: {
    request: {
      method: 'POST',
      url: '/plugins/count-clicks',
      data,
    },
  },
});

export const updateCountClick = ({ data }) => ({
  type: UPDATE_COUNT_CLICK.INIT,
  payload: {
    request: {
      method: 'PUT',
      url: `/plugins/count-clicks/${data.id}`,
      data,
    },
  },
});

export const deleteCountClick = ({ id }) => ({
  type: DELETE_COUNT_CLICK.INIT,
  payload: {
    request: {
      method: 'DELETE',
      url: `/plugins/count-clicks/${id}`,
    },
  },
});

export const loadAutomations = () => ({
  type: LOAD_AUTOMATIONS.INIT,
  payload: {
    request: {
      method: 'GET',
      url: '/plugins/automations',
    },
  },
});

export const createAutomation = (data) => ({
  type: CREATE_AUTOMATION.INIT,
  payload: {
    request: {
      method: 'POST',
      url: '/plugins/automations',
      data,
    },
  },
});

export const updateAutomation = ({ data, id }) => ({
  type: UPDATE_AUTOMATION.INIT,
  payload: {
    request: {
      method: 'PUT',
      url: `/plugins/automations/${id}`,
      data,
    },
  },
});

export const deleteAutomation = (id) => ({
  type: DELETE_AUTOMATION.INIT,
  payload: {
    request: {
      method: 'DELETE',
      url: `/plugins/automations/${id}`
    }
  }
});