import React, { useState, useEffect } from 'react';
import { Form, Popover, Row, Tabs, Radio, Divider, Col } from 'antd';
import get from 'lodash/get';

import TabDefault from './Default';
import TabAddress from './Address';
import TabOpening from './Opening';
import TabLastAccounting from './LastAccounting';
import TabMonthly from './Monthly';
import ModalMonthly from '~/containers/ModalMonthly';
import Contract from './Contract';
import { MalhaFiscalTab } from './MalhaFiscalTab';
import { AppUsers } from './AppUsers';
import { useSelector } from 'react-redux';
import { Partners } from './Partners';
import Services from './Services';

const TabPane = Tabs.TabPane;

const CustomerForm = (props) => {
    const {
        onboardingStep,
        isOnboardingActive,
        isVideoModalVisible
    } = useSelector((state) => ({
        onboardingStep: state.onboarding.step,
        isOnboardingActive: state.onboarding.isActive,
        isVideoModalVisible: state.onboarding.videoModal.isVisible,
    }));

    const [tabActive, setTabActive] = useState('1');
    const [modalMonthlyVisibe, setModalMonthlyVisible] = useState(false);
    const [honoraryToUpdate, setHonoraryToUpdate] = useState();
    const [monthlyHistory, setMonthlyHistory] = useState([]);
    const [deleted, setDeleted] = useState([]);
    const { data, category, isDrawerFullyVisible } = props;

    const addToMonthlyHistory = (newRow) => {
        const newHistory = [
            ...monthlyHistory,
            newRow
        ];

        setMonthlyHistory(newHistory);
        props.setPrepareData({honorary_history: newHistory});
    }

    const updateHonorary = (updatedHonorary) => {
        const updatedMonthlyHistory = monthlyHistory.map((item) => item.id !== updatedHonorary.id ? item : updatedHonorary);
        setMonthlyHistory(updatedMonthlyHistory);
        setHonoraryToUpdate(undefined);
        props.setPrepareData({honorary_history: updatedMonthlyHistory});
    };

    const removeToMonthlyHistory = (id) => {
        const toBeDeleted = monthlyHistory.filter(row => row.id === id)[0];
        const newHistory = monthlyHistory.filter(row => row.id !== id);
        setMonthlyHistory(newHistory);
        props.setPrepareData({honorary_history: newHistory});
        
        if(get(toBeDeleted, 'created_at')){
            setDeleted([...deleted, id]);
            props.setPrepareData({honorary_history_deleted: [...deleted, id]});
        }
    }

    const checkIfStepIsActive = (step) => {
        return !isVideoModalVisible && isOnboardingActive && onboardingStep === step;
    }

    useEffect(() => {
        setTabActive('1');
    }, [props.error]);

    useEffect(() => {
        setMonthlyHistory(get(data, 'customer.honorary_history', []));
    }, [get(data, 'customer.honorary_history')]);

    return (
        <div>
            <ModalMonthly 
                visible={modalMonthlyVisibe}
                setVisible={setModalMonthlyVisible}
                addToMonthlyHistory={addToMonthlyHistory}
                monthlyHistory={monthlyHistory}
                updateHonorary={updateHonorary}
                setHonoraryToUpdate={setHonoraryToUpdate}
                honoraryToUpdate={honoraryToUpdate}
            />
            <Form layout="vertical">
                <Row gutter={16}>
                    <Tabs 
                        type="card" 
                        activeKey={tabActive} 
                        onChange={(key) => setTabActive(key)}>
                        <TabPane tab="Dados" key="1" forceRender={true}>
                            <TabDefault {...props} data={data}/>
                        </TabPane>
                        <TabPane tab="Endereço" key="2" forceRender={true}>
                            <TabAddress {...props} data={data}/>
                        </TabPane>
                        <TabPane tab="Sócios" key="3" forceRender>
                            <Partners
                                partnersSets={data.partners_sets}
                                setPartnersSets={props.setPartnersSets}
                            />
                        </TabPane>
                        <TabPane tab="Abertura" key="4" forceRender={true}>
                            <TabOpening {...props} data={data}/>
                        </TabPane>
                        <TabPane
                            tab={(
                                <Popover
                                    placement="top"
                                    content="Clique aqui para abrir a aba Mensalidades"
                                    visible={
                                        tabActive !== '4' &&
                                        checkIfStepIsActive(10) &&
                                        monthlyHistory.length === 0 &&
                                        isDrawerFullyVisible
                                    }
                                >
                                    Mensalidades
                                </Popover>
                            )}
                            key="5" forceRender={true}
                        >
                            <TabMonthly 
                                {...props} 
                                setModalMonthlyVisible={setModalMonthlyVisible}
                                modalMonthlyVisibe={modalMonthlyVisibe}
                                tabActive={tabActive}
                                monthlyHistory={monthlyHistory}
                                checkIfStepIsActive={checkIfStepIsActive}
                                removeToMonthlyHistory={removeToMonthlyHistory}
                                data={data} 
                                setHonoraryToUpdate={setHonoraryToUpdate}
                            />
                        </TabPane>
                        {category === 5 && (
                        <TabPane tab="Antiga Contabilidade" key="6" forceRender={true}>
                            <TabLastAccounting {...props} data={data}/>
                        </TabPane>
                        )}
                        <TabPane tab="Malha Fiscal" key="7" forceRender>
                            <MalhaFiscalTab {...props} data={data} />
                        </TabPane>
                        {data.id && (
                        <TabPane tab="Usuários" key="8" forceRender={true}>
                            <AppUsers customer={data} />
                        </TabPane>
                        )}
                        {data.id && (
                        <TabPane tab="Serviços" key="10" forceRender={true}>
                            <Services 
                                {...props} 
                                data={data} 
                            />
                        </TabPane>
                        )}
                        <TabPane tab="Contrato" key="9" forceRender={true}>
                            <Contract
                                {...props}
                                data={data}
                                updateContractFilesState={props.updateContractFilesState}
                            />
                        </TabPane>
                    </Tabs>
                </Row>
            </Form>
        </div>
    )
}

export default Form.create({ name: 'form_customer' })(CustomerForm);