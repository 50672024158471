import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { loadForCurrentMonth } from '~/components/actions';
import { LoadingTopBar } from '~/components/UI/LoadingTopBar';
import Dashboard from '../components/Dashboard';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

export const ScreensDashboard = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await LocalCacheHandler.load({
        actionName: CACHE_ACTIONS.dashboard,
        dispatch,
        pathname,
      });
      const { payload } = await dispatch(
        loadForCurrentMonth({ showLoading: false })
      );
      await LocalCacheHandler.save({
        pathname,
        data: payload.data,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <LoadingTopBar isActive={isLoading} />
      <Dashboard />
    </>
  );
};
