import moment from "moment";
import React from "react";
import Chart from "react-apexcharts";

export function LeadsChart({ data, visualizationType }) {
  if (!data) return null;

  const formattedData = Object.values(data);

  const series = [
    {
      name: "Clientes positivos",
      data: formattedData.map((item) => item.positives),
      color: "#52c41a",
      type: "line",
    },
    {
      name: "Oportunidades",
      data: formattedData.map((item) => item.opportunities),
      color: "#722ed1",
      type: "line",
    },
    {
      name: "Clientes negativos",
      data: formattedData.map((item) => item.negatives),
      color: "#f5222d",
      type: "line",
    },
  ];

  if (visualizationType === "custom_months") {
    series[3] = {
      name: "Acessos",
      data: formattedData.map((item) => item.access),
      color: "#40a9ff",
      type: "column",
    };
  }

  return (
    <Chart
      height={350}
      series={series}
      options={{
        chart: {
          type: "line",
          stacked: false,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          categories: Object.keys(data).map((item) => {
            if (visualizationType === "current_month") {
              return moment(item).format("DD/MM");
            } else {
              return moment(`${item}-01`).format("MMM/YY");
            }
          }),
        },
        yaxis: [
          {
            show: false,
          },
          {
            show: false,
          },
          {
            show: false,
          },
          {
            opposite: true,
            show: true,
            decimalsInFloat: 0,
          },
        ],
        tooltip: {
          enabled: true,
          y: {
            formatter: (value) => {
              if (!Number.isInteger(value)) return 0;
              return value;
            }
          }
        },
      }}
    />
  );
}
