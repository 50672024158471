import React from "react";

import { Container, PlayIcon, ThumbImage } from "./styles";

export const VideoThumb = ({ imageUrl, ...props }) => {
  return (
    <Container {...props}>
      <ThumbImage src={imageUrl} alt="Thumbnail do vídeo" />
      <PlayIcon />
    </Container>
  );
};
