import moment from "moment";

export const getDaysCountBetweenDates = (startDate, endDate) => {
  if (!startDate || !endDate) return null;
  return moment(startDate).diff(moment(endDate).format("YYYY-MM-DD"), "days");
};

export const formatToBrDate = (date) => {
  if (!date) return null;
  return moment(date).format("DD/MM/YYYY");
};
