import { Modal, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getFormattedEvent } from '~/containers/Documents/helpers';
import { loadEmailsLogs } from '../../actions';

const { Column } = Table;

export const EmailsLogsModal = ({ isOpen, onClose, department, data }) => {
  const dispatch = useDispatch();
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    if (isOpen) {
      handleLoadEmailsLogs();
    }
  }, [isOpen]);

  async function handleLoadEmailsLogs() {
    const {
      payload: { data: logsFromApi },
    } = await dispatch(loadEmailsLogs(department, data));

    const formattedLogs = logsFromApi.map((log) => ({
      ...log,
      email_status: getFormattedEvent(log.event),
      sent_at: moment(log.sent_at).format('DD/MM/YYYY HH:mm:ss'),
    }));

    setLogs(formattedLogs);
  }

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      title="Logs dos e-mails"
      footer={false}
    >
      <Table bordered dataSource={logs} pagination={false} rowKey="id">
        <Column title="Data" key="sent_at" align="center" dataIndex="sent_at" />
        <Column
          title="Status"
          key="email_status"
          dataIndex="email_status"
          align="center"
          render={(status) => {
            if (!status) return null;
            return <Tag color={status.color}>{status.text}</Tag>;
          }}
        />
      </Table>
    </Modal>
  );
};
