export const formOptions = {
  trigger: {
    rules: [
      {
        required: true,
        message: 'Por favor, selecione um gatilho',
      },
    ],
  },
  conditionLogicalOperator: {
    rules: [
      {
        required: true,
        message: 'Obrigatório'
      }
    ]
  },
  conditionField: {
    rules: [
      {
        required: true,
        message: 'Obrigatório',
      },
    ],
  },
  conditionOperator: {
    rules: [
      {
        required: true,
        message: 'Obrigatório',
      },
    ],
  },
  conditionValue: {
    rules: [
      {
        required: true,
        message: 'Obrigatório',
      },
    ],
  },
  action: {
    rules: [
      {
        required: true,
        message: 'Por favor, selecione uma ação',
      },
    ],
  },
};
