import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Divider, Drawer, Form, Input, Modal, Row, Select, Switch } from "antd"
import moment from 'moment';
import { DatePicker } from '~/components/UI';
import InputCurrency, { currency2Float } from '~/components/UI/InputCurrency';
import { pad } from '~/components/UI/Utils';
import uid from 'uid';
import { PERMISSIONS } from '~/constants/permissions.constants';

const { Option } = Select;

class BillsForm extends React.Component {

  state = {
    payments: [],
    deleted: []
  }

  onCreate = () => {
    const { form } = this.props;

    form.validateFields((err, values) => {

        if (err) {
            return;
        }

        let sum = 0;
        Object.keys(values.payments).map(function(key, index) {
          sum = sum+values.payments[key].amount;
        });

        if(Math.trunc(values.total) !== Math.trunc(sum)){
          form.setFields({
            total: {
              value: values.total,
              errors: [new Error('Total não corresponde com a soma das parcelas')],
            },
          });
        }else{
          this.props.setBillsData(values);
          form.resetFields();
          this.onClose();
        }
    });
}

  onSave = () => {
    const { form } = this.props;

    form.validateFields((err, values) => {

        if (err) {
            return;
        }

        this.props.saveBill(
            this.props.billsPayment.id,
            {
              ...values,
              deleted: this.state.deleted,
              customer_id: this.props.data.customer_id,
              dash_task_id: this.props.data.id 
            }
          )
        .then(response => {
            this.props.setBillsPayment(response.payload.data.data);
            form.resetFields();
            this.onClose();
        });
    });  
  }

  onDelete = () => Modal.confirm({
    title: 'Você realmente deseja excluir este serviço extra?',
    content: 'Todas as parcelas serão removidas. Esta ação não poderá ser desfeita.',
    okText: 'Sim',
    okType: 'danger',
    cancelText: 'Cancelar',
    onOk: () => {
      this.props.deleteBill(this.props.billsPayment.id);
      this.props.setBillsData(null);
      this.props.setBillsPayment(null);
      this.onClose();
    }
  });

  onClose = () => {
    this.setState({payments: [], deleted: [], default: {}});
    this.props.setVisible(false);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.visible !== prevProps.visible) {
      let installments = null;
      if (this.props.billsPayment) {
        installments = this.props.billsPayment.installments;
      } else if (this.props.billsData) {
        installments = Object.values(this.props.billsData.payments);
      }

      if (installments) {
        let p = [];
        installments.map(installment => {
          p.push({...installment, uid: uid(10)});
        })
        this.setState({payments: p});
      }
    }
  }

  changePaymentSplit = (val) => {
    let p = [];
    for(let i=1; i <= val; i++){
      p.push({
        due_date: moment().add(i, 'months').format('YYYY-MM-DD'),
        amount: this.props.form.getFieldValue('total')/val,
        method: 1,
        uid: uid(10),
        cod: null,
        new: true
      });
    }
    var d = [];
    this.state.payments.map(function(payment){
      if(typeof payment.id !== 'undefined'){
        d.push(payment.id);
      }
    });

    this.setState({payments: p, deleted: [...this.state.deleted, ...d]});
  }

  render() {
    const { payments } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { visible, user } = this.props;

    const data = {
      ...this.state,
      ...this.props.data
    }

    const billsPayment = this.props.billsPayment;

    const canEdit = (user.permissions.includes(PERMISSIONS.extraServices.edit) && data.id !== null) || data.id === null;

    const optionsPayment = () => {
      let o = [];
      for (let i = 1; i <= 12; i++) {
          o.push(<Option key={i} value={i}>{i}x</Option>);
      }
      return o;
  }
    return (
      <Drawer
        title={(billsPayment || this.props.billsData)? (canEdit? 'Editar Serviço Extra' : 'Serviço Extra'): 'Cadastrar Serviço Extra'}
        width={800}
        placement="right"
        onClose={this.onClose}
        maskClosable={false}
        visible={visible}
        destroyOnClose
        style={{
            height: '100%',
            overflow: 'auto',
            paddingBottom: 53,
        }}
    >
    <Form layout="vertical">
        <Row gutter={16}>
            <Col span={24}>
                <Form.Item label="Nome da tarefa">
                {getFieldDecorator('task_name', {
                    initialValue: data.task_name,
                    rules: [{ required: true, message: 'Por favor informe o nome da tarefa!' }],
                })(<Input placeholder="Dê um nome a esta tarefa" disabled={!canEdit}/>)}
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item label="Apuração">
                {getFieldDecorator('apuration_at', {
                    initialValue: billsPayment? moment(billsPayment.apuration_at, 'YYYY-MM-DD'): moment(),
                    rules: [{ required: true, message: 'Por favor escolha uma data!' }],
                })(
                    <DatePicker disabled={!canEdit}/>
                )}
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item label="Valor Total">
                {getFieldDecorator('total', {
                    initialValue: data.total,
                    rules: [{ required: true, message: 'Por favor informe o valor total!' }],
                    normalize: (value) => currency2Float(value)
                })(<InputCurrency disabled={!canEdit}/>)}
                </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Parcelamento">
              {getFieldDecorator('payment_split', { 
                  initialValue: payments.length === 0? null:payments.length,
                  rules: [{ required: true, message: 'Por favor selecione a quantidade de parcelas.' }],
              })(<Select onChange={this.changePaymentSplit} disabled={!canEdit}>
                  {optionsPayment()}
              </Select>)}
              </Form.Item>
          </Col>
        </Row>
        <Divider>Pagamento</Divider>
        {payments && payments.map((row, index) => 
        <Row gutter={16} key={row.uid}>
          {row.id && (
          <Col span={2}>
            <Form.Item label="Pago">
              <Switch 
                defaultChecked={(row.paid_at!==null)}
                disabled={true}
              />
            </Form.Item>
          </Col>
        )}
        {row.id && (
          <Col span={3}>
          <Form.Item label="ID">
            <Input disabled={true} value={`SE${pad(row.id, 4)}`}/>
          </Form.Item>
          </Col>
        )}
      <Col span={5}>
        <Form.Item label={`Parcela ${(index+1)}`}>
          {getFieldDecorator(`payments[${row.uid}][due_date]`, {
            initialValue: moment(row.due_date, 'YYYY-MM-DD'),
            rules: [{ required: true, message: 'Por favor informe a data de vencimento!' }],
          })(<DatePicker 
          onChange={
            (e) => this.setState({
              payments: [...payments.map((item) => {
                if(item.id !== row.id){
                  return item;
                }else{
                  return {
                    ...item,
                    value: e? e.format('YYYY-MM-DD'):null
                  }
                }
              })]
          })}
          disabled={!canEdit}
          />)}
        </Form.Item>
      </Col>
      <Col span={4}>
      <Form.Item label="Valor">
          {getFieldDecorator(`payments[${row.uid}][amount]`, {
            initialValue: row.amount,
            rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
            normalize: (value) => currency2Float(value)
          })(
            <InputCurrency disabled={!canEdit}/>
          )}
        </Form.Item>
      </Col>
      <Col span={4}>
      <Form.Item label="Forma PGTO">
          {getFieldDecorator(`payments[${row.uid}][method]`, {
            initialValue: row.method,
            rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
          })(
            <Select disabled={!canEdit}>
              <Option key={1} value={1}>Dinheiro</Option>
              <Option key={2} value={2}>Boleto</Option>
              <Option key={3} value={3}>Cartão</Option>
              <Option key={4} value={4}>Transferência</Option>
              <Option key={5} value={5}>Pix</Option>
              <Option key={6} value={6}>Depósito</Option>
              <Option key={7} value={7}>Cheque</Option>
            </Select>
          )}
        </Form.Item>
      </Col>
      <Col span={4}>
      <Form.Item label="Cód">
          {getFieldDecorator(`payments[${row.uid}][cod]`, {
            initialValue: row.cod,
          })(
            <Input disabled={!canEdit}/>
          )}
        </Form.Item>
      </Col>
      <Form.Item label="Id" style={{display: 'none'}}>
          {getFieldDecorator(`payments[${row.uid}][id]`, {
            initialValue: billsPayment ? row.id:null,
          })(
            <Input disabled={!canEdit}/>
          )}
        </Form.Item>
    </Row>
    )}
    {billsPayment && user.permissions.includes(PERMISSIONS.extraServices.edit) && (
    <Row gutter={16}>
      <Col span={8}>
        <Form.Item label="Revisão financeira">
            {getFieldDecorator('revised')(
                <Switch defaultChecked={data.revised}/>
            )}
        </Form.Item>
      </Col>
    </Row>
    )}
    </Form>
    <div
        style={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'right',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
        }}
    >
      {data.id && canEdit && (<Button onClick={this.onDelete} type="danger" style={{marginRight: '8px'}}>Deletar</Button>)}
      <Button
      style={{
          marginRight: 8,
      }}
      onClick={this.onClose}
      >
      Cancelar
      </Button>
      {(billsPayment && user.permissions.includes(PERMISSIONS.extraServices.edit)) && (
      <Button onClick={this.onSave} type="primary">Salvar</Button>
      )}
      {!billsPayment&& (
      <Button onClick={this.onCreate} type="primary">Cadastrar</Button>
      )}
    </div>
    </Drawer>
    )
  }
}

const mapDispatchProps = dispatch => {
    return{
      saveBill: (id, data) => dispatch({
        type: 'SAVE_BILL',
        payload: {
          request:{
            method: 'post',
            url:`/extras/${id}`,
            data: data
          }
        }
      }),
      deleteBill: (id) => dispatch({
        type: 'DELETE_BILL',
        payload: {
          request:{
            method: 'delete',
            url:'/extras/'+id,
          }
        },
        id: id
      }),
    }
  }


export default connect(null, mapDispatchProps)(Form.create()(BillsForm));