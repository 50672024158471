export const loadTasks = ({ ...params }) => ({
  type: "LOAD_TASKS",
  payload: {
    request: {
      method: "GET",
      url: "/tasks",
      ...params,
      showLoading: false,
    },
  },
});
