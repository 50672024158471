export const saveTasksInBatch = (taskId, data) => ({
  type: "SAVE_TASKS_IN_BATCH",
  payload: {
    request: {
      method: "POST",
      url: `/calendar/task/${taskId}/files/batch`,
      data,
    },
  },
});

export const loadAllDepartmentsCalendarTasks = () => ({
  type: "LOAD_ALL_DEPARTMENTS_CALENDAR_TASKS",
  payload: {
    request: {
      method: "get",
      url: `/calendar/tasks`,
      showLoading: false,
    },
  },
});

export const clearAllDepartmentsCalendarState = () => ({
  type: "CLEAR_ALL_DEPARTMENTS_CALENDAR_STATE",
});

export const updateTasksCardsFromList = ({ customers, taskId }) => ({
  type: "CALENDAR_CARDS_TASKS_UPDATE_FROM_LIST",
  payload: {
    customers,
    taskId,
  },
});

export const updateContextTasksCardsFromList = (customers, taskId) => ({
  type: "CONTEXT_CALENDAR_CARDS_TASKS_UPDATE_FROM_LIST",
  payload: {
    customers,
    taskId,
  },
});

export const loadCalendarTasks = (id, date) => ({
  type: "LOAD_CALENDAR_TASKS_MONTH",
  payload: {
    request: {
      method: "get",
      url: `/calendar/tasks/department/${id}/${date
        .endOf("month")
        .format("YYYY-MM-DD")}`,
    },
  },
  id,
  date,
});

export const sendEmailWithLink = ({ taskRecordId }) => ({
  type: "SEND_EMAIL_WITH_LINK",
  payload: {
    request: {
      method: "POST",
      url: `/calendar/task_record/${taskRecordId}/email/link`,
    },
  },
});

export const sendEmailWithAttachment = ({ taskRecordId }) => ({
  type: "SEND_EMAIL_WITH_ATTACHMENT",
  payload: {
    request: {
      method: "POST",
      url: `/calendar/task_record/${taskRecordId}/email/attachment`,
    },
  },
});

export const generateAccessToFiles = ({ taskRecordId }) => ({
  type: "GENERATE_ACCESS_TO_FILES",
  payload: {
    request: {
      method: "GET",
      url: `/calendar/task_record/${taskRecordId}/files/access`,
    },
  },
});
