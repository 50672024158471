import { find, get } from 'lodash';
import { permissions } from './permissions';

export const verifyIfAllPermissionsOfAParentAreChecked = (
  targetPermissions,
  parentKey
) => {
  const allPermissionsCount = permissions.filter(
    (item) => item.parent.key === parentKey
  ).length;

  const permissionsCount = permissions.filter(
    (item) =>
      targetPermissions.includes(item.key) && item.parent.key === parentKey
  ).length;

  return allPermissionsCount === permissionsCount;
};

export const getParentPermission = (key) => {
  return get(find(permissions, { key }), 'parent');
};
