import React from "react";
import { Row, Col, Button, Divider, Modal, Input, Icon } from "antd";
import styled from "styled-components";

import uid from "uid";

import ChatBotBuilder from "~/components/ChatBotBuilder";

import { leadFields } from "~/components/ChatBotBuilder/leadFields";

const fieldOptions = {
    bot: [
        {
            type: "text",
            label: "Texto",
            placeholder: "Digite aqui",
        },
        {
            type: "image",
            label: "Imagem",
            placeholder: "Digite aqui",
            format: "image",
            url: "https://via.placeholder.com/150",
        },
        {
            text: 'Texto',
            type: "button",
            label: "Botão",
            placeholder: "Digite aqui",
            format: "button",
        },
    ],
    user: [
        {
            type: "input",
            label: "Campo de texto",
            placeholder: "Digite aqui",
        },
        {
            type: "input",
            label: "Campo de opções",
            placeholder: "Digite aqui",
            format: "select",
            options: []
        }
    ],
}

const FieldsWrapper = styled.div`
    background: #f0f2f5;
    padding: 10px;
    border-radius: 5px;

    .ant-btn {
        display: block;
        margin-bottom: 5px;
        width: 100%;
    }
`;


export const Fields = ({
    state,
    setState,
    form,
}) => {

    return (
        <>
            <Row gutter={16}>
                <Col span={7}>
                    <FieldsWrapper style={{ marginBottom: 10 }}>
                        <Divider orientation="left">Integrações</Divider>
                        <Button
                            type="dashed"
                            block
                            style={state.sync === 'Leads' ? { backgroundColor: '#1890ff', color: '#fff' }: {}}
                            onClick={
                                () => {
                                    setState({
                                        ...state,
                                        sync: state.sync === 'Leads' ? '' : 'Leads',
                                    });
                                }
                            }
                        >
                            <Icon type="notification" /> Leads
                        </Button>
                    </FieldsWrapper>
                    <FieldsWrapper>
                        <Divider orientation="left">Bot</Divider>
                        {fieldOptions.bot.map((option, index) => (
                            <Button 
                                key={index}
                                type="dashed"
                                block
                                onClick={
                                    () => {
                                        setState({
                                            ...state,
                                            fields: [
                                                ...state.fields,
                                                {
                                                    id: uid(),
                                                    type: option.type,
                                                    text: option.label,
                                                    placeholder: option.placeholder,
                                                    from: "bot",
                                                }
                                            ]
                                        });
                                    }
                                }
                            >
                                {option.label}
                            </Button>
                        ))}
                    </FieldsWrapper>
                    <FieldsWrapper>
                        <Divider orientation="left">Usuário</Divider>
                        {fieldOptions.user.map((option, index) => (
                            <Button 
                                key={index}
                                type="dashed"
                                block
                                onClick={
                                    () => {
                                        setState({
                                            ...state,
                                            fields: [
                                                ...state.fields,
                                                {
                                                    ...option,
                                                    id: uid(),
                                                    type: option.type,
                                                    text: option.label,
                                                    placeholder: option.placeholder,
                                                    from: "user",
                                                }
                                            ]
                                        });
                                    }
                                }
                            >
                                {option.label}
                            </Button>
                        ))}
                        <div style={{ height: 5, width: "100%" }} />
                        {state.sync === 'Leads' && leadFields.map((option, index) => (
                            <Button 
                                key={index}
                                type="dashed"
                                block
                                // diplay an modal to edit the labelSuggestion
                                onClick={
                                    () => {
                                        Modal.confirm({
                                            title: "Editar Pergunta",
                                            content: (
                                                <Input
                                                    defaultValue={option.labelSuggestion}
                                                    type="text" 
                                                    id="labelSuggestion"
                                                />
                                            ),
                                            onOk: () => {
                                                var inputValue = document.getElementById("labelSuggestion").value;
                                                setState({
                                                    ...state,
                                                    fields: [
                                                        ...state.fields,
                                                        {
                                                            id: uid(),
                                                            type: 'text',
                                                            text: inputValue,
                                                            from: "bot",
                                                        },
                                                        {
                                                            ...option,
                                                            id: uid(),
                                                            type: 'input',
                                                            field_name: option.key,
                                                            custom_field_name: option.name,
                                                            from: "user",
                                                        }
                                                    ]
                                                });
                                            }
                                        });
                                    }
                                }
                            >
                                {option.name}
                            </Button>
                        ))}
                    </FieldsWrapper>
                </Col>
                <Col span={17}>
                    <ChatBotBuilder 
                        fields={state.fields}
                        setFields={fields => setState({ ...state, fields })}
                    />
                </Col>
            </Row>
        </>
    );
}

export default Fields;