import React, { Component } from 'react';
import styled from 'styled-components';
import { Col, Button as ButtonAntd } from 'antd';

import bg from './images/bg3.jpg';

export const Background  = styled.div`
    background-color: #eff4f7;
    background-image: linear-gradient(141deg,#d0e0ec,#f5f7fa 71%,#fff);
    width: 100%;
    min-height: 100vh;
`

export const Title = styled.div`
    text-align: center;

    h1 { 
        font-size: 25px;
        font-weight: 100;

        > p {
            font-size: 20px;
        }
    }
`

export const InvitedCard = styled.div`
    background-color: #fff;
    width: 100%;
    border-radius: .1875rem;
    display: inline-block;
    position: relative;
    margin-bottom: 20px;
    transition: all .5s;
    border: 1px solid #e5e5e5!important;
    overflow: visible!important;
`

export const Image = styled(Col)`
    // width: 100%;
    min-height: 300px;
    background-size: cover;
    border-radius: 0.375rem 0.375rem 0px 0px;
    padding: 0px;
    background-position: 50% 50%;
    // background-color: #7f00ff;
    background-image: url(${bg});
    border-radius: 0.375rem 0px 0px 0.375rem;
    // -webkit-filter: hue-rotate(220deg);
`
export const Button = styled(ButtonAntd)`
    background-color: #7f00ff !important;
    color: #fff !important;
`