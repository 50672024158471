import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, Select, Switch, Icon, Tabs, message, Tooltip, List } from 'antd';
import uid from 'uid';
import moment from 'moment';

import { department, customerColumns } from './Utils';

import { DatePicker } from '../UI/DatePicker/Locale';
import { DocumentTypesTab } from './Tabs/DocumentTypes';
import { finishCurrentOnboardingStepAction } from '../Onboarding/helpers';

const { Option } = Select;

class DrawerForm extends React.Component {

  default = {
    id: null,
    name: null,
    department: null,
    frequency: null,
    repeat_annually: null,
    description: null,
    custom_fields: [],
    file_upload: null,
    first_date: null,
    second_date: null,
    third_date: null,
    fourth_date: null
  }

  state = {
    frequency: null,
    fields: [],
    default: this.default,
    documentTypes: [],
    selectedTab: '3',
    customer_columns: []
  }

  componentWillReceiveProps = (nextProps) => {
    if(nextProps.data !== null && nextProps.data.hasOwnProperty('custom_fields') && this.props.data === null){

      let fields = [];
  
      if(nextProps.data.custom_fields !== null){
        Object.keys(nextProps.data.custom_fields).map((row) => {
          fields.push({
            id: row, 
            value: nextProps.data.custom_fields[row][0], 
            type: nextProps.data.custom_fields[row][1], 
            name: ''
          });
        });
      }

      this.setState({
        fields: fields, 
        frequency: nextProps.data.frequency,
        documentTypes: nextProps.data.document_types || [],
        customer_columns: nextProps.data.customer_columns || []
      });
    }
  }

  checkIfStepIsActive = (step) => {
    const { isOnboardingActive, onboardingStep, isVideoModalVisible } = this.props;
    return !isVideoModalVisible && isOnboardingActive && onboardingStep === step;
}

  onCreate = () => {
    const { form, finishCurrentOnboardingStep, onboardingStep, user } = this.props;

    form.validateFields((err, values) => {
        const { documentTypes, customer_columns } = this.state;
        const { file_upload } = values;
    
        if (err) {
            return;
        }

        if (!!file_upload) {
          const isValidated = this.validateDocumentTypes();
          if (!isValidated) return;
        }

        this.props.createTask({
          ...values,
          document_types: documentTypes,
          customer_columns: customer_columns
        })
        .then(response => {
            form.resetFields();
            this.closeDrawer();
            finishCurrentOnboardingStep({
              isActive: this.checkIfStepIsActive(3),
              step: onboardingStep,
              user
            });
        });
    });
  }

  onSave = () => {
    const { form, data } = this.props;

    form.validateFields((err, values) => {
        const { documentTypes, customer_columns } = this.state;
        const { file_upload } = values;

        if (err) {
            return;
        }

        if (!!file_upload) {
          const isValidated = this.validateDocumentTypes();
          if (!isValidated) return;
        }

        this.props.saveTask(data.id, {
          ...values,
          document_types: documentTypes,
          customer_columns: customer_columns
        })
        .then(response => {
            form.resetFields();
            this.closeDrawer();
        });
    });
  }

  validateDocumentTypes = () => {
    const { documentTypes } = this.state;

    const hasDocumentTypes = documentTypes.length > 0;

    if (!hasDocumentTypes) {
      this.setState({
        selectedTab: '2'
      })
      message.warning('É necessário cadastrar pelo menos um tipo de documento!');
      return false;
    }

    const areAllDocumentTypesFilled = documentTypes.every((type) => !!type.description);

    if (!areAllDocumentTypesFilled) {
      this.setState({
        selectedTab: '2'
      })
      message.warning('Por favor, defina a descrição de todos os tipos de documento!')
      return false;
    }

    return true;
  }

  closeDrawer = () => {
    this.props.onClose();
    this.setState({
      fields: [],
      documentTypes: [],
      selectedTab: '3'
    });
    this.props.form.resetFields();
  }

  render() {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
    const { frequency, fields } = this.state;
    const data = {
      ...this.state.default,
      ...this.props.data,
    }

    const ruleDatePicker = { rules: [{ required: true, message: 'Por favor escolha uma data' }] };

    const isFileUploadSelected = !!getFieldValue('file_upload') || !!data.file_upload;
    const isDeadlineControlSelected = !!getFieldValue('deadline_control');

    return (
      <div>
        <Drawer
          title={data.id ? 'Editar Tarefa':'Criar Nova Tarefa'}
          width={720}
          placement="right"
          onClose={this.closeDrawer}
          maskClosable={false}
          visible={this.props.visible}
          destroyOnClose={true}
          style={{
            height: '100%',
            overflow: 'auto',
            paddingBottom: 53,
          }}
        >
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Tarefa">
                  {getFieldDecorator('name', {
                    initialValue: data.name,
                    rules: [{ required: true, message: 'Por favor informe o nome da tarefa!' }],
                  })(<Input placeholder="Insira o nome da tarefa" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Departamento">
                  {getFieldDecorator('department', data.id?{
                    initialValue: data.department,
                    rules: [{ required: true, message: 'Por favor selecione um departamento!' }],
                  }:{rules: [{ required: true, message: 'Por favor selecione um departamento!' }]})(
                    <Select placeholder="Selecione um departamento">
                      <Option value="1">{department(1)}</Option>
                      <Option value="2">{department(2)}</Option>
                      <Option value="3">{department(3)}</Option>
                      <Option value="4">{department(4)}</Option>
                      <Option value="5">{department(5)}</Option>
                      <Option value="6">{department(6)}</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Periodicidade">
                  {getFieldDecorator('frequency', data.id?{
                    initialValue: data.frequency,
                    rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                  }:{rules: [{ required: true, message: 'Por favor selecione uma opção!' }]})(
                    <Select 
                      placeholder="Defina a periodicidade" 
                      onChange={(value) => this.setState({frequency: value})}
                      disabled={(data.id !== null)}>
                      {/* <Option value={0}>Não se repete</Option> */}
                      <Option value={1}>Mensal</Option>
                      <Option value={2}>Trimestral</Option>
                      <Option value={3}>Semestral</Option>
                      <Option value={4}>Anual</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
                <Col span={12}>
                  <Form.Item label="Categoria">
                    {getFieldDecorator('category', {
                      initialValue: data.category ? data.category : undefined,
                      rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                    })(
                      <Select 
                        placeholder="Selecione uma categoria"
                      >
                        <Option value="taxes">Impostos</Option>
                        <Option value="obligations">Obrigações</Option>
                        <Option value="others">Outros</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              {/* {(frequency !== 0) && (
              <Col span={12}>
                <Form.Item label="Repetir anualmente">
                  {getFieldDecorator('repeat_annually', {
                    initialValue: !!data.repeat_annually,
                    valuePropName: 'checked'
                  })(
                    <Switch />
                  )}
                </Form.Item>
              </Col>
              )} */}
              {(frequency === 0) && (
              <Col span={12}>
                <Form.Item label={`Vencimento`}>
                  {getFieldDecorator('first_date', data.first_date ? {
                    initialValue: moment(data.first_date),
                    ...ruleDatePicker
                    }: ruleDatePicker)(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
              )}
            </Row>
            {(frequency === 1 || frequency === 4) && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={`1º Vencimento`}>
                  {getFieldDecorator('first_date', data.first_date ? {
                    initialValue: moment(data.first_date),
                    ...ruleDatePicker
                    }: ruleDatePicker
                  )(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
            </Row>
            )}

            {(frequency === 2) && (
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="1º Vencimento">
                  {getFieldDecorator('first_date', data.first_date ? {
                    initialValue: moment(data.first_date),
                    ...ruleDatePicker
                    }: ruleDatePicker)(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="2º Vencimento">
                  {getFieldDecorator('second_date', data.second_date ? {
                    initialValue: moment(data.second_date),
                    ...ruleDatePicker
                    }: ruleDatePicker)(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="3º Vencimento">
                  {getFieldDecorator('third_date', data.third_date ? {
                    initialValue: moment(data.third_date),
                    ...ruleDatePicker
                    }: ruleDatePicker)(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="4º Vencimento">
                  {getFieldDecorator('fourth_date', data.fourth_date ? {
                    initialValue: moment(data.fourth_date),
                    ...ruleDatePicker
                    }: ruleDatePicker)(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
              </Row>
            )}

            {(frequency === 3) && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="1º Vencimento">
                  {getFieldDecorator('first_date', data.first_date ? {
                    initialValue: moment(data.first_date),
                    ...ruleDatePicker
                    }: ruleDatePicker)(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="2º Vencimento">
                  {getFieldDecorator('second_date', data.second_date ? {
                    initialValue: moment(data.second_date),
                    ...ruleDatePicker
                    }: ruleDatePicker)(
                    <DatePicker disabled={(data.id !== null)}/>
                  )}
                </Form.Item>
              </Col>
            </Row>
            )}

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Competência">
                  {getFieldDecorator('competence', data.id?{
                    initialValue: data.competence,
                    rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                  }:{rules: [{ required: true, message: 'Por favor selecione uma opção!' }]})(
                    <Select 
                      placeholder="Defina a competência" 
                      >
                      <Option value="0">Igual ao vencimento</Option>
                      <Option value="-1">1 mês antes do vencimento</Option>
                      <Option value="-2">2 meses antes do vencimento</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Upload de documento">
                  {getFieldDecorator('file_upload', {
                    valuePropName: 'checked',
                    initialValue: !!data.file_upload
                  })(
                    <Switch onChange={(isChecked) => {
                      if (!isChecked && !!getFieldValue('deadline_control')) {
                        setFieldsValue({
                          deadline_control: false
                        })
                      }
                    }} />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Controle de prazos">
                  {getFieldDecorator('deadline_control', {
                    valuePropName: 'checked',
                    initialValue: !!data.deadline_control
                  })(
                    isFileUploadSelected
                    ? (
                      <Switch />
                    )
                    : (
                      <Tooltip title="Para ativar o controle de prazos é necessário ativar o upload de documento">
                        <Switch disabled />
                      </Tooltip>
                    )
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Descrição">
                {getFieldDecorator('description', {initialValue: data.description})(
                    <Input.TextArea rows={4} placeholder="Descreva detalhes sobre esta tarefa" />
                )}
                </Form.Item>
              </Col>
            </Row>

            <Tabs
              type="card"
              activeKey={this.state.selectedTab}
              onChange={(key) => this.setState({ selectedTab: key })}
            >
              <Tabs.TabPane tab="Colunas adicionais" key="3">
                <Row gutter={16} style={{marginBottom: '20px'}}>
                  <Col span={24}>
                    <List
                      itemLayout="horizontal"
                      dataSource={Object.keys(customerColumns)}
                      renderItem={item => (
                        <List.Item>
                          <List.Item.Meta
                            title={this.state.customer_columns.includes(item) ? <span style={{color: '#1890ff'}}>{customerColumns[item]}</span> : <span style={{opacity: 0.5, textDecoration: 'line-through'}}>{customerColumns[item]}</span>}
                          />
                          {this.state.customer_columns.includes(item) ? (
                            <Button type="default" onClick={() => this.setState({ customer_columns: this.state.customer_columns.filter((column) => column !== item) })}>
                              <Icon type="eye-invisible" /> Ocultar
                            </Button>
                          ) : (
                            <Button type="default" onClick={() => this.setState({ customer_columns: [...this.state.customer_columns, item] })}>
                              <Icon type="eye" /> Exibir
                            </Button>
                          )}
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Campos de preenchimento" key="1">
                {fields.map((row, index) => 
                  <Row gutter={16} key={row.id}>
                    <Col span={12}>
                      <Form.Item label={`Campo ${index+1}`}>
                        {getFieldDecorator(`fields[${row.id}][0]`, {
                          initialValue: row.value,
                          rules: [{ required: true, message: 'Por favor informe o nome do campo!' }],
                        })(<Input placeholder="Insira o nome do campo" 
                            onChange={
                              (e) => this.setState({
                                fields: [...fields.map((item) => {
                                  if(item.id !== row.id){
                                    return item;
                                  }else{
                                    return {
                                      ...item,
                                      value: e.target.value
                                    }
                                  }
                                })]
                            })}
                        />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                    <Form.Item label="Tipo">
                        {getFieldDecorator(`fields[${row.id}][1]`, {
                          initialValue: row.type,
                          rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                        })(
                          <Select 
                            placeholder="Tipo do campo"
                          >
                            <Option value="text">Texto</Option>
                            <Option value="money">Dinheiro</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={4} className="mt-30">
                      <Button type="danger" icon="delete" onClick={
                        () => this.setState({fields: [...fields.filter((f, fIndex) => fIndex !== index)] })}/>
                    </Col>
                  </Row>
                )}
                <Row gutter={16}>
                  <Col span={24} className="mb-50">
                    <Button onClick={() => this.setState({
                      fields: [
                        ...fields,
                        {
                          name: '',
                          type: 'text',
                          id: uid(10)
                        }
                      ]
                    })}>
                      <Icon type="plus" /> Adicionar novo campo
                    </Button>
                  </Col>
                </Row>
              </Tabs.TabPane>
              {isFileUploadSelected && (
                <Tabs.TabPane tab="Tipos de documento" key="2">
                  <DocumentTypesTab
                    types={this.state.documentTypes}
                    setState={this.setState.bind(this)}
                  />
                </Tabs.TabPane>
              )}
            </Tabs>

          </Form>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
          >
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.closeDrawer}
            >
              Cancelar
            </Button>
            {(data.id)?
            <Button onClick={this.onSave} type="primary">Salvar</Button>
            : 
            <Button onClick={this.onCreate} type="primary">Cadastrar</Button>
            }
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isOnboardingActive: state.onboarding.isActive,
    onboardingStep: state.onboarding.step,
    isVideoModalVisible: state.onboarding.videoModal.isVisible,
    user: state.user
  }
}

const mapDispatchProps = dispatch => {
  return{
    createTask: (data) => dispatch({
      type: 'CREATE_TASK',
      payload: {
        request:{
          method: 'post',
          url:'/tasks',
          data: data
        }
      }
    }),
    saveTask: (id, data) => dispatch({
      type: 'SAVE_TASK',
      payload: {
        request:{
          method: 'post',
          url:`/tasks/${id}`,
          data: data
        }
      }
    }),
    finishCurrentOnboardingStep: ({ isActive, step, user }) => {
      finishCurrentOnboardingStepAction({ isActive, dispatch, step, user })
    }
  }
}

export default connect(mapStateToProps, mapDispatchProps)(Form.create()(DrawerForm));