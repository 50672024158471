import { Drawer } from "antd";
import BraftEditor from 'braft-editor';
import styled from "styled-components";

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    height: calc(100vh - 55px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: strech;
  }
`;

export const Footer = styled.div`
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  text-align: left;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const CategoryLabelWrapper = styled.div`
  display: inherit;

  i {
    margin-left: 4px;
    font-size: 12px;
    opacity: 0.7;
  }
`;

export const StyledBraftEditor = styled(BraftEditor)`
  height: auto !important;

  div {
    word-break: keep-all !important;
  }

  .bf-content {
    height: 400px;
  }

`;