import { createAsyncActions } from '~/utils/store';

export const SHOW = '@@DRAWER_LEADS_RT/SHOW';
export const CLOSE = '@@DRAWER_LEADS_RT/CLOSE';

export const CREATE = createAsyncActions('@@DRAWER_LEADS_RT/CREATE');
export const SAVE = createAsyncActions('@@DRAWER_LEADS_RT/SAVE');
export const DELETE = createAsyncActions('@@DRAWER_LEADS_RT/DELETE');

export const show = (data = null) => ({
    type: SHOW,
    payload: {
        data
    }
});

export const close = () => ({
    type: CLOSE,
    payload: {}
});

export const create = (data) => ({
    type: CREATE.INIT,
    payload: {
        request:{
            method: 'post',
            url:'/leads-rt',
            data,
            showLoading: false
        }
      }
});

export const save = (id, data) => ({
    type: SAVE.INIT,
    payload: {
        request:{
            method: 'post',
            url:`/leads-rt/${id}`,
            data,
            showLoading: false
        }
    }
});

export const remove = (lead) => ({
    type: DELETE.INIT,
    payload: {
        request:{
            method: 'delete',
            url:`/leads-rt/${lead.id}`
        },
        lead
    }
});