import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Layout } from '~/components/UI';
import { EmailsLogs } from '~/containers/EmailsLogs';
import { loadEmailsLogs } from '~/containers/EmailsLogs/actions';
import { EmailLogsModal } from '~/containers/Documents/Modals/EmailLogsModal';

export const ScreensEmailsLogs = () => {
  const dispatch = useDispatch();
  const [selectedDocument, setSelectedDocument] = useState(null);

  // data is loading by default on AppContext
  return (
    <div>
      {/* <LoadingTopBar isActive={isLoading} /> */}
      <Layout>
        <EmailsLogs 
          refresh={() => dispatch(
            loadEmailsLogs({
              showLoading: false,
            }))} 
          setSelectedDocument={setSelectedDocument}
        />
        <EmailLogsModal
          isOpen={selectedDocument !== null}
          documentId={selectedDocument}
          closeModal={() => setSelectedDocument(null)}
        />
      </Layout>
    </div>
  );
};
