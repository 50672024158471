/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown, Icon, Menu, Popover, Progress, Table } from "antd";
import moment from "moment";
import React from "react";

import { frequency } from "~/components/RegisterTasks/Utils";
import { CustomProgress, CustomProgressWrapper } from "./styles";

const { Column } = Table;

export const TasksInList = ({
  filteredTasks,
  openList,
  openGrid,
  openTasksInBatchDrawer,
  isFourthOnboardingStepActive,
  lastCreatedTask,
  isListOpen,
}) => {
  function getRowClassName(record) {
    const isExecuted = record.pendent_tasks_count === 0;
    const from = moment(record.due_date);
    const to = moment();

    if (isExecuted) {
      return "row-green";
    } else if (to.diff(from, "days") > 0) {
      return "row-red";
    } else if (to.diff(from, "days") <= 0) {
      return "row-orange";
    }
  }

  const formattedTasks = filteredTasks.map((task) => {
    return {
      ...task,
      due_day: task.due_date.substring(8),
      frequency: frequency(task.frequency),
      executed_tasks_percentage:
        task.tasks_count > 0
          ? Math.round((100 * task.executed_tasks_count) / task.tasks_count, 2)
          : 0,
      is_expired: moment().diff(task.due_date, "days") > 0,
    };
  });

  return (
    <div className={`table-clabs${isFourthOnboardingStepActive ? ' onboarding-active' : ''}`}>
      <Table
        bordered
        rowKey="id"
        dataSource={formattedTasks}
        pagination={false}
        scroll={{ y: "calc(100vh - 300px)", x: isFourthOnboardingStepActive ? false : 1400 }}
        rowClassName={(record) => getRowClassName(record)}
      >
        <Column
          title="Nome"
          key="name"
          dataIndex="name"
          width={200}
          fixed="left"
        />
        <Column title="Periodicidade" key="frequency" dataIndex="frequency" />
        <Column title="Clientes" key="tasks_count" dataIndex="tasks_count" />
        <Column
          title="Concluídos / Pendentes"
          key="executed_and_pendent_tasks"
          render={(
            _,
            {
              executed_tasks_count,
              executed_tasks_percentage,
              pendent_tasks_count,
              is_expired,
            }
          ) => {
            return (
              <CustomProgressWrapper>
                <span>{executed_tasks_count}</span>
                <CustomProgress
                  percent={executed_tasks_percentage}
                  expired={is_expired ? "true" : "false"}
                />
                <span>{pendent_tasks_count}</span>
              </CustomProgressWrapper>
            );
          }}
        />
        <Column title="Vencimentos" key="due_day" dataIndex="due_day" />
        <Column
          title="Percentual de conclusão"
          key="executed_tasks_percentage"
          dataIndex="executed_tasks_percentage"
          width={200}
          fixed="right"
          render={(taskPercentage) => (
            <Progress
              percent={taskPercentage}
              format={(percent) => `${percent}%`}
            />
          )}
        />
        <Column
          key="actions"
          width={100}
          fixed="right"
          render={(_, record) => {
            const showOnboarding =
              isFourthOnboardingStepActive &&
              lastCreatedTask &&
              lastCreatedTask.id === record.id &&
              !isListOpen;

            return (
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="list" onClick={() => openList(record.id)}>
                      <Icon type="bars"></Icon> Visualizar tarefas em modo lista
                    </Menu.Item>
                    <Menu.Item key="table" onClick={() => openGrid(record)}>
                      <Icon type="table"></Icon> Visualizar tarefas em modo
                      tabela
                    </Menu.Item>
                    {record.file_upload && (
                      <Menu.Item
                        onClick={() => {
                          openTasksInBatchDrawer({ parentTask: record });
                        }}
                      >
                        <Icon type="upload" /> Executar tarefas em lote
                      </Menu.Item>
                    )}
                  </Menu>
                }
                trigger={["click"]}
              >
                <Popover
                  placement="left"
                  content="Clique aqui e abra as tarefas em modo lista"
                  visible={showOnboarding}
                  overlayStyle={{ zIndex: 1051 }}
                >
                  <a className="ant-dropdown-link" href="#">
                    Ações <Icon type="down" />
                  </a>
                </Popover>
              </Dropdown>
            );
          }}
        />
      </Table>
    </div>
  );
};
