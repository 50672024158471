import React from 'react';
import PropTypes from 'prop-types';
import uid from 'uid';
import { Button, Col, Form, Input, Modal, Radio, Row } from 'antd';
import moment from 'moment/moment';
import { useState } from 'react';

import { DatePicker } from '~/components/UI';
import { useEffect } from 'react';

const TaskModal = ({ visible, closeModal, data, setTasks, form }) => {
  const [taskType, setTaskType] = useState(1);

  const { getFieldDecorator } = form;

  const isEditing = !!data;

  useEffect(() => {
    if (visible && isEditing) {
      setTaskType(data.type);
    }
  }, [visible])

  function saveTask() {
    form.validateFields((errors, values) => {
      if (errors) return;

      if (isEditing) {
        const updatedTask = {
          ...data,
          ...values,
          due_at: values.due_at.format('YYYY-MM-DD HH:mm'),
          is_modified: true,
          type: taskType,
        };

        setTasks((tasks) => {
          return tasks.map((task) => {
            if (task.id !== data.id) {
              return task;
            }

            return updatedTask;
          });
        });
      } else {
        const newTask = {
          ...values,
          id: uid(10),
          due_at: values.due_at.format('YYYY-MM-DD HH:mm'),
          is_new: true,
          type: taskType,
        };

        setTasks((tasks) => {
          return [...tasks, newTask];
        });
      }

      form.resetFields();
      closeModal();
    });
  }

  function removeTask(id) {
    Modal.confirm({
      title: 'Você realmente deseja remover este follow up?',
      content: 'Esta ação não poderá ser desfeita',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      onOk: () => {
        setTasks((tasks) => {
          return tasks.map((task) => {
            if (task.id !== id) {
              return task;
            }

            return {
              ...task,
              is_deleted: true,
            };
          });
        });
        closeModal();
      },
    });
  }

  return (
    <Modal
      title={`${isEditing ? 'Editar' : 'Cadastrar'} follow up`}
      visible={visible}
      onCancel={closeModal}
      footer={
        <>
          <Button onClick={closeModal}>Cancelar</Button>
          {isEditing && (
            <Button type="danger" onClick={() => removeTask(data.id)}>
              Excluir
            </Button>
          )}
          <Button type="primary" onClick={saveTask}>
            Salvar
          </Button>
        </>
      }
    >
      <Radio.Group
        value={taskType}
        size="small"
        style={{ marginBottom: 10 }}
        onChange={(event) => setTaskType(event.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value={1}>Follow Up</Radio.Button>
        <Radio.Button value={2}>Reunião</Radio.Button>
      </Radio.Group>
      <Form name="lead-task" layout="vertical">
        <Row gutter={16} className="mb-20">
          <Col span={12}>
            <Form.Item label="Observação">
              {getFieldDecorator('name', {
                initialValue: isEditing
                  ? data.name
                  : `${taskType === 1 ? 'Follow Up ' : ''}`,
                rules: [
                  {
                    required: true,
                    message: 'Por favor informe a observação!',
                  },
                ],
              })(<Input placeholder="Observação" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={`Data${taskType === 2 ? ' e hora' : ''}`}>
              {getFieldDecorator('due_at', {
                initialValue: isEditing ? moment(data.due_at) : null,
                rules: [
                  {
                    required: true,
                    message: 'Por favor informe uma data!',
                  },
                ],
              })(<DatePicker {...(taskType === 2 && { showTime: { format: 'HH:mm' } })} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

TaskModal.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  data: PropTypes.object,
  setTasks: PropTypes.func,
};

export default Form.create()(TaskModal);
