import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Layout } from '~/components/UI';
import { LoadingTopBar } from '~/components/UI/LoadingTopBar';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { Feedbacks } from '~/containers/Feedbacks';
import { loadFeedbacks } from '~/containers/Feedbacks/actions';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

export const ScreensFeedbacks = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await LocalCacheHandler.load({
        actionName: CACHE_ACTIONS.feedbacks,
        dispatch,
        pathname,
      });
      const { payload } = await dispatch(
        loadFeedbacks({
          initialDate: moment()
            .startOf('month')
            .format('YYYY-MM-DD'),
          finalDate: moment()
            .endOf('month')
            .format('YYYY-MM-DD'),
          showLoading: false,
        })
      );
      await LocalCacheHandler.save({
        pathname,
        data: payload.data.feedbacks,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <Feedbacks />
      </Layout>
    </div>
  );
};
