import update from 'immutability-helper';
import get from 'lodash/get';
import { orderBy } from 'natural-orderby';
import * as LEADS_RT from './actions';
import * as LEAD_RT from '../DrawerLeadsRT/actions';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';


const orderDesc = (leads) => orderBy(leads, [l => get(l, 'tasks[0].due_at'), l => get(l, 'tasks[0].id')], ['asc', 'asc']); 
const orderDescByColumnLog = (leads) => orderBy(leads, [l => get(l, 'last_column_updated_at'), l => get(l, 'id')], ['desc', 'desc']);

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.leadsRT]: (state, action) => {
        return action.payload;
    },

    [LEADS_RT.LOAD_LEADS.SUCCESS]: (state, action) => {
        const handledState = action.payload.data.leads;
        return {
            ...state,
            columns: defaultState.columns.map((column) => {
                const currentColumn = handledState.find(({ id }) => `${id}` === column.id);
                return {
                    ...column,
                    leads: column.id === '1'
                        ? orderDesc(currentColumn.leads)
                        : orderDescByColumnLog(currentColumn.leads),
                    total: currentColumn.total,
                }
            }),
            isLoading: false
        };
    },

    [LEADS_RT.LOAD_MORE_LEADS.SUCCESS]: (state, action) => {
        return {
            ...state,
            columns: defaultState.columns.map((column) => {
                const requestedColumnId = action.meta.previousAction.payload.request.columnId;
                if (column.id !== requestedColumnId) {
                    return column;
                }
                const leads = [
                    ...column.leads,
                    ...action.payload.data.leads
                ];
                return {
                    ...column,
                    leads: column.id === '1'
                        ? orderDesc(leads)
                        : orderDescByColumnLog(leads),
                }
            })
        }
    },

    [LEADS_RT.FETCHQTDD.SUCCESS]: (state, action) => {
        return {
            ...state,
            columns: defaultState.columns.map((column) => ({
                ...column,
                // selectedMonth: get(action, `payload.data.data.qtdd[${column.id}]`)
            })),
            currentMetrics: action.payload.data.data.metrics,
            currentSummary: {
                ...state.currentSummary,
                ...action.payload.data.data.summary
            }
        }
    },

    [LEAD_RT.CREATE.SUCCESS]: (state, action) => {
        return update(state, {
            columns: {
                $apply: c => c.map((column) => {
                    if(column.id !== '1') return column;
                    const createdLead = get(action, 'payload.data.data'); 
                    return {
                        ...column,
                        leads: orderDesc([
                            createdLead,
                            ...column.leads
                        ])
                    }
                })
            }
        })
    },

    [LEAD_RT.SAVE.SUCCESS]: (state, action) => {
        const updatedLead = get(action, 'payload.data.data');
        return update(state, {
            columns: {
                $apply: c => c.map((column) => {
                    if (column.id === `${updatedLead.stage}`) {
                        return {
                            ...column,
                            leads: (column.id === '1') ? orderDesc(column.leads.map(l => l.id === updatedLead.id? updatedLead : l)): orderDescByColumnLog(column.leads.map(l => l.id === updatedLead.id? updatedLead : l)),
                        }
                    } else{
                        return column;
                    }
                })
            },
        })
    },

    [LEAD_RT.DELETE.SUCCESS]: (state, action) => {
        const lead = get(action, 'meta.previousAction.payload.lead');
    
        return update(state, {
          columns: {
            $apply: (c) =>
              c.map((column) => {
                if (column.id === `${lead.stage}`) {
                  return {  
                    ...column,
                    leads: column.leads.filter((l) => l.id !== lead.id),
                  };
                } else {
                  return column;
                }
              }),
          },
        });
    },

    [LEADS_RT.MOVE.INIT]: (state, action) => {

        const sourceClone = Array.from(action.props[0]);
        const destClone = Array.from(action.props[1]);
        const droppableSource = action.props[2];
        const droppableDestination = action.props[3];
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        return update(state, {
            columns: {
                $apply: c => c.map((column) => {
                    if(column.id !== droppableSource.droppableId 
                        && column.id !== droppableDestination.droppableId) 
                        return column;

                    else if(column.id === droppableSource.droppableId)
                        return {
                            ...column,
                            leads: sourceClone
                        }
                    else if(column.id === droppableDestination.droppableId)
                    return {
                        ...column,
                        leads: destClone
                    }
                
                })
            }
        })
    },

    [LEADS_RT.MOVE.SUCCESS]: (state, action) => {
        const lead = get(action, 'payload.data.data');

        return update(state, {
            columns: {
                $apply: c => c.map((column) => {
                    if(column.id === `${lead.stage}`){
                        return {
                            ...column,
                            leads: lead.stage === '5' ?orderDesc(column.leads.map(l => l.id === lead.id? lead : l)): orderDescByColumnLog(column.leads.map(l => l.id === lead.id? lead : l))
                        }
                    }else{
                        return column;
                    }
                })
            }
        });
    },
}

const defaultState = {
    isLoading: true,
    columns: [
        { id: '1', name:'Lead', style: { background: '#f5f5f5', color: '#722ed1', fontSize: '20px', textAlign: 'center' }, leads: [], selectedMonth: null },
        { id: '6', name:'Follow Up',  style: { background: '#f5f5f5', color: '#1890ff', fontSize: '20px', textAlign: 'center'  }, leads: [], selectedMonth: null},
        { id: '2', name:'Proposta Assinada',  style: { background: '#f5f5f5', color: '#000', fontSize: '20px', textAlign: 'center'  }, leads: [], selectedMonth: null},
        { id: '3', name:'Recuperação Em Processo',  style: { background: '#f5f5f5', color: '#faad14', fontSize: '20px', textAlign: 'center'  }, leads: [], selectedMonth: null},
        { id: '4', name:'Recuperação Concluída', style: { background: '#f5f5f5', color: '#52c41a', fontSize: '20px', textAlign: 'center'  }, leads: [], selectedMonth: null},
        { id: '5', name:'Cobrança', style: { background: '#f5f5f5', color: '#f5222d', fontSize: '20px', textAlign: 'center' }, leads: [], selectedMonth: null},     
    ],
    updatedLead: null,
}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if(!handler) return state;

    const newState = handler(state, action);
  
    const updateCache = [
        LEADS_RT.LOAD_LEADS.SUCCESS,
        LEAD_RT.CREATE.SUCCESS,
        LEAD_RT.SAVE.SUCCESS,
        LEAD_RT.DELETE.SUCCESS,
        LEADS_RT.MOVE.SUCCESS,
    ].includes(action.type);

    if(updateCache){
        LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.leadsRT,
            data: newState
        });
    }

    return newState;
};

export default reducer;