import { Radio } from 'antd';
import styled, { css } from 'styled-components';

export const LateRadioButton = styled(Radio.Button)`
  ${({ isActive = false }) =>
    isActive &&
    css`
      border-color: #f5222d !important;
      color: #f5222d !important;
    `}
`;

export const PendingRadioButton = styled(Radio.Button)`
  ${({ isActive = false }) =>
    isActive &&
    css`
      border-color: #fa8c16 !important;
      color: #fa8c16 !important;
      box-shadow: -1px 0 0 0 #fa8c16 !important;
    `}
`;

export const ConcludedRadioButton = styled(Radio.Button)`
  ${({ isActive = false }) =>
    isActive &&
    css`
      border-color: #a0d911 !important;
      color: #a0d911 !important;
      box-shadow: -1px 0 0 0 #a0d911 !important;
    `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export const HeaderLeftContent = styled.div`
  display: flex;
  align-items: end;
  gap: 6px;
  width: 100%;
`;