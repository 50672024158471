import update from "immutability-helper";
import { LocalCacheHandler } from "~/utils/local-cache-handler";

import {
  CREATE_KNOWLEDGE,
  DELETE_KNOWLEDGE,
  LOAD_KNOWLEDGES,
  UPDATE_KNOWLEDGE,
} from "./actions";
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const DEFAULT_STATE = {
  list: [],
  isLoading: true,
};

const ACTION_HANDLERS = {
  [CACHE_ACTIONS.knowledges]: (state, action) => {
    return action.payload;
  },

  [LOAD_KNOWLEDGES.SUCCESS]: (state, action) => {
    return {
      ...state,
      list: action.payload.data.knowledges,
      isLoading: false,
    };
  },

  [CREATE_KNOWLEDGE.SUCCESS]: (state, action) => {
    const { knowledge } = action.payload.data;
    return update(state, {
      list: {
        $set: [...state.list, knowledge],
      },
    });
  },

  [UPDATE_KNOWLEDGE.SUCCESS]: (state, action) => {
    const { knowledge } = action.payload.data;
    return update(state, {
      list: {
        $set: state.list.map((item) => {
          if (item.id !== knowledge.id) {
            return item;
          }

          return {
            ...item,
            ...knowledge,
          };
        }),
      },
    });
  },

  [DELETE_KNOWLEDGE.SUCCESS]: (state, action) => {
    const { id: knowledgeId } = action.meta.previousAction.payload;
    return update(state, {
      list: {
        $set: state.list.filter(({ id }) => id !== knowledgeId),
      },
    });
  },
};

const reducer = (state = DEFAULT_STATE, action) => {
  const handler = ACTION_HANDLERS[action.type];
  if (!handler) return state;

  const newState = handler(state, action);

  const updateCache = [
    LOAD_KNOWLEDGES.SUCCESS,
    CREATE_KNOWLEDGE.SUCCESS,
    UPDATE_KNOWLEDGE.SUCCESS,
    DELETE_KNOWLEDGE.SUCCESS,
  ].includes(action.type);

  if (updateCache) {
    LocalCacheHandler.save({
      pathname: CACHE_ACTIONS.knowledges,
      data: newState,
    });
  }

  return newState;
}

export default reducer;
