export const loadUser = () => ({
  type: "LOAD_USER",
  payload: {
    request: {
      method: "GET",
      url: "/user",
    },
  },
});

export const loadUsers = (data) => ({
  type: "LOAD_USERS",
  payload: {
    request: {
      method: "get",
      url: "/users",
      data,
    },
  },
});
