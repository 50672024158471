import React, { useState, useEffect } from 'react';
import { Switch, Form, Row, Divider, Col, Table, Button, Input, Icon, Collapse, Badge } from 'antd';

import Tip from '~/components/Tip';

const { Panel } = Collapse;

const Services = ({
    form: { getFieldDecorator, getFieldValue },
    data,
    setPrepareData,
}) => {
    const [newCnae, setNewCnae] = useState('');
    const [cnaes, setCnaes] = useState(data.cnaes ? data.cnaes.split(',') : []);

    useEffect(() => {
        setPrepareData({cnaes});
    }, [cnaes]);

    useEffect(() => {
        setPrepareData({allow_nf: getFieldValue('allow_nf') ? 1 : 0});
    }, [getFieldValue('allow_nf')]);

    const handleAddCnae = () => {
        if (newCnae) {
            setCnaes([...cnaes, newCnae]);
            setNewCnae('');
        }
    };

    const handleRemoveCnae = (cnae) => {
        setCnaes(cnaes.filter(item => item !== cnae));
    };

    const columns = [
        {
            title: 'CNAEs cadastrados',
            dataIndex: 'cnae',
            key: 'cnae',
            with: '90%',
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <Button
                    type="danger"
                    onClick={() => handleRemoveCnae(record.cnae)}
                >
                    <Icon type="delete" />
                </Button>
            ),
            width: '10%',
        },
    ];

    return (
        <Row gutter={16} className="mb-50">
            <Divider orientation="left">Serviços habilitados</Divider>
            <Col span={8}>
                <Form.Item label={<span>Emissão de NF-e <Tip>Se habilitado, o cliente poderá solicitar a emissão de NF-e através do painel do cliente. As solicitações serão listadas no módulo "Solicitações".</Tip></span>}>
                    {getFieldDecorator('allow_nf', {
                        valuePropName: 'checked',
                        initialValue: data.allow_nf
                    })(
                        <Switch />
                    )}
                </Form.Item>
            </Col>
            {getFieldValue('allow_nf') && (
                <Col span={16}>
                    <Collapse defaultActiveKey={['1']}>
                        <Panel 
                            header={
                                <span>
                                    CNAEs <Tip>Adicione os CNAEs que o cliente poderá solicitar a emissão de NF-e.</Tip>
                                </span>
                            } 
                            key="1"
                            extra={
                                <Badge count={cnaes.length} style={{ backgroundColor: '#52c41a' }} />
                            }
                        >
                            <Form.Item>
                                <Input.Group compact>
                                    <Input
                                        style={{ width: 'calc(100% - 100px)' }}
                                        value={newCnae}
                                        onChange={(e) => setNewCnae(e.target.value)}
                                        placeholder="Adicionar CNAE"
                                    />
                                    <Button
                                        type="primary"
                                        onClick={handleAddCnae}
                                    >
                                        Adicionar
                                    </Button>
                                </Input.Group>
                            </Form.Item>
                            <div className={`table-clabs`}>
                                <Table
                                    dataSource={cnaes.map(cnae => ({ key: cnae, cnae }))}
                                    columns={columns}
                                    pagination={false}
                                    size="small"
                                    rowKey="cnae"
                                    showHeader={false}
                                />
                            </div>
                        </Panel>
                    </Collapse>
                </Col>
            )}
        </Row>
    );
};

export default Services;