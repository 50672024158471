import React from 'react';
import moment from 'moment';

import BlueCalendar from './images/blue-calendar.png';
import RedCalendar from './images/red-calendar.png';
import GreenCalendar from './images/green-calendar.png';
import YellowCalendar from './images/yellow-calendar.png';

import { Container, Date } from './styles';
import { getNextTask } from '../handlers';

export function LeadCalendarIcon({ item: lead }) {
  const leadStage = lead.stage;

  const calendarData = {
    backgroundColor: null,
    icon: null,
    date: null,
    time: null
  };

  const backgroundColorsBasedOnStage = {
    3: '#AED4B8',
    4: '#E9C7C7',
    5: '#F0F0F0',
  };

  calendarData.backgroundColor = backgroundColorsBasedOnStage[leadStage];

  if ([3, 4].includes(leadStage)) {
    const calendarsBasedOnStage = {
      3: GreenCalendar,
      4: RedCalendar,
    };

    calendarData.icon = calendarsBasedOnStage[leadStage];
    calendarData.date = moment(lead.last_column_updated_at).isValid()
      ? moment(lead.last_column_updated_at)
      : null;
  } else {
    const iconBasedOnTaskStatus = {
      Vencido: RedCalendar,
      'A vencer': BlueCalendar,
      Hoje: GreenCalendar,
    };

    const nextTask = getNextTask(lead.tasks);

    if (nextTask) {
      calendarData.icon = iconBasedOnTaskStatus[nextTask.status];
      calendarData.date = moment(nextTask.due_at);

      if (nextTask.type === 2) {
        calendarData.time = moment(nextTask.due_at).format('HH:mm');
        calendarData.icon = YellowCalendar;
      }
    }
  }

  if (!calendarData.date) return null;

  return (
    <Container backgroundColor={calendarData.backgroundColor}>
      <img src={calendarData.icon} alt="Calendar" />
      <Date>
        {calendarData.time ? (
          <span className="calendar-inline-date">{calendarData.date.format('MMM')} - {calendarData.date.format('DD')}</span>
        ) : (
          <>
          <span>{calendarData.date.format('MMM')}</span>
          <span>{calendarData.date.format('DD')}</span>
          </>
        )}
        {calendarData.time && (
          <span className="calendar-time">{calendarData.time}</span>
        )}
      </Date>
    </Container>
  );
}
