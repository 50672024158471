import { createAsyncActions } from '~/utils/store';

export const LOAD = createAsyncActions('@@PLG_FORMS/LOAD');
export const CREATE = createAsyncActions('@@PLG_FORMS/CREATE');
export const UPDATE = createAsyncActions('@@PLG_FORMS/UPDATE');
export const DELETE = createAsyncActions('@@PLG_FORMS/DELETE');

export const load = () => ({
  type: LOAD.INIT,
  payload: {
    request: {
      method: 'GET',
      url: '/plugins/forms',
      showLoading: false,
    },
  },
});

export const create = (data) => ({
  type: CREATE.INIT,
  payload: {
    request: {
      method: 'POST',
      url: '/plugins/forms',
      data,
    },
  },
});

export const update = (data) => ({
  type: UPDATE.INIT,
  payload: {
    request: {
      method: 'PUT',
      url: `/plugins/forms/${data.id}`,
      data,
    },
  },
});

export const remove = (id) => ({
    type: DELETE.INIT,
    payload: {
      request: {
        method: 'DELETE',
        url: `/plugins/forms/${id}`,
      },
      id,
    },
});