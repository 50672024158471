import React, { useEffect, useRef, useState } from "react";
import { Container } from "./styles";

export const LoadingTopBar = ({ isActive }) => {
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const timeoutRef = useRef();
  const isMounted = React.useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (progressPercentage === 0 || progressPercentage === 100) return;

    if (progressPercentage === 99) {
      progressPercentageStateHandler(1);
      return;
    }

    timeoutRef.current = setTimeout(() => {
      progressPercentageStateHandler(progressPercentage + 5);
    }, 1000);
  }, [progressPercentage]);

  useEffect(() => {
    if (!isActive && progressPercentage > 0) {
      clearTimeout(timeoutRef.current);
      progressPercentageStateHandler(100);
      new Promise((resolve) => setTimeout(() => resolve("Done"), 3000)).then(
        () => {
          isVisibleStateHandler(false);
        }
      );
    }
    if (isActive) {
      isVisibleStateHandler(true);
      progressPercentageStateHandler(1);
    }
  }, [isActive]);

  function progressPercentageStateHandler(value) {
    if (isMounted.current) {
      setProgressPercentage(value);
    }
  }

  function isVisibleStateHandler(value) {
    if (isMounted.current) {
      setIsVisible(value);
    }
  }

  return <Container percentage={progressPercentage} isVisible={isVisible} />;
};
