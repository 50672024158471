import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const defaultState = {
  list: [],
  isLoading: true
}

const procurations = (state = defaultState, action) => {
  switch (action.type) {
    case CACHE_ACTIONS.procurations: {
      return {
        ...state,
        list: action.payload.procurations,
        isLoading: false
      }
    }

    case "LOAD_PROCURATIONS_SUCCESS":
      return {
        ...state,
        list: action.payload.data,
        isLoading: false
      };

    case 'REGISTER_PROCURATION_SUCCESS':
      return {
        ...state,
        list: [
          ...state.list,
          ...action.payload.data.data
        ]
      };

    case 'DELETE_PROCURATION_SUCCESS':
      return {
        ...state,
        list: state.list.filter(item => item.id !== action.meta.previousAction.id)
      };

    case 'SAVE_PROCURATION_SUCCESS':
      return {
        ...state,
        list: state.list.map(item => item.id !== action.payload.data.data.id? item : action.payload.data.data)
      };
      
    default:
    return state
  }
}

export default procurations;