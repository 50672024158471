import { createAsyncActions } from '~/utils/store';

export const SHOW = '@@DRAWER_BOARDS/SHOW';
export const CLOSE = '@@DRAWER_BOARDS/CLOSE';

export const CREATE = createAsyncActions('@@DRAWER_BOARDS/CREATE');
export const SAVE = createAsyncActions('@@DRAWER_BOARDS/SAVE');
export const DELETE = createAsyncActions('@@DRAWER_BOARDS/DELETE');

export const show = (data = null) => ({
    type: SHOW,
    payload: {
        data
    }
});

export const close = () => ({
    type: CLOSE,
    payload: {}
});

export const create = (data) => ({
    type: CREATE.INIT,
    payload: {
        request:{
            method: 'post',
            url:'/boards',
            data,
            showLoading: false
        }
    }
});

export const save = (id, data) => ({
    type: SAVE.INIT,
    payload: {
        request:{
            method: 'post',
            url:`/boards/${id}`,
            data,
            showLoading: false
        }
    }
});

export const remove = (data) => ({
    type: DELETE.INIT,
    payload: {
        request:{
            method: 'delete',
            url:`/boards/${data.id}`,
        },
        data
    }
});