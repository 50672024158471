/* eslint-disable no-useless-computed-key */
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

const defaultState = {
    list: [],
    isLoading: true
}

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.certificates]: (state, action) => {
        return action.payload;
    },

    ['LOAD_CERTIFICATES_SUCCESS']: (state, action) => {
        return {
            ...state,
            list: action.payload.data,
            isLoading: false
        };
    },

    ['REGISTER_CERTIFICATE_SUCCESS']: (state, action) => {
        return {
            ...state,
            list: [
                ...state.list,
                { ...action.payload.data.data }
            ]
        };
    },

    ['DELETE_CERTIFICATE_SUCCESS']: (state, action) => {
        return {
            ...state,
            list: state.list.filter(item => item.id !== action.meta.previousAction.id)
        };
    },

    ['SAVE_CERTIFICATE_SUCCESS']: (state, action) => {
        return {
            ...state,
            list: state.list.map(item => item.id !== action.payload.data.data.id? item : {
                ...item,
                ...action.payload.data.data
            })
        };
    },
}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if(!handler) return state;

    const newState = handler(state, action);

    const updateCache = [
        'LOAD_CERTIFICATES_SUCCESS',
        'REGISTER_CERTIFICATE_SUCCESS',
        'DELETE_CERTIFICATE_SUCCESS',
        'SAVE_CERTIFICATE_SUCCESS'
    ].includes(action.type);

    if(updateCache){
        LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.certificates,
            data: newState
        });
    }

    return newState;
}

export default reducer;