/* eslint-disable default-case */
import { Button, Drawer, Table, Tag } from 'antd';
import moment from 'moment';
import React from 'react';

const { Column } = Table;

const getBackgroundItem = (category) => {
  switch (parseInt(category)) {
    case 1: return '#52c41a';
    case 2: return '#faad14';
    case 3: return '#595959';
    case 4: return '#1890ff';
    case 5: return '#f5222d';
  }
}
const getTextItem = (category) => {
  switch (parseInt(category)) {
    case 1: return 'Abertura';
    case 2: return 'Alteração';
    case 3: return 'Encerramento';
    case 4: return 'Tarefa';
    case 5: return 'Troca de Contabilidade';
    case 6: return 'Outros';
  }
}

export const PendingDashTasksDrawer = ({
  isVisible,
  onClose,
  pendingDashTasks,
  showDrawer,
}) => {
  return (
    <Drawer 
      width={600} 
      zIndex={998}
      title="Pendentes" 
      visible={isVisible} 
      onClose={onClose}>
      <Table rowKey="id" dataSource={pendingDashTasks} bordered>
        <Column 
          title="Nome do lead" 
          key="lead_name" 
          dataIndex="lead.name"
          render={(name, data) => <span style={{fontSize: '1.2em'}}>{name}<br/>
            <Tag
              className="mt-sm"
              color={getBackgroundItem(
                parseInt(data.category)
              )}
            >
              {getTextItem(parseInt(data.category))}
            </Tag>
          </span>}
        />
        <Column
          title="Data de criação"
          key="created_at"
          dataIndex="created_at"
          render={(createdAt) => moment(createdAt).fromNow()}
        />
        <Column
          width={140}
          align="center"
          key="action"
          render={(_, data) => {
            return (
              <Button
                type="primary"
                onClick={() => showDrawer(parseInt(data.category), { 
                  lead_id: data.lead_id,
                  name: data.lead.name,
                  email: data.lead.email,
                  phone: data.lead.phone,
                  city: data.lead.city,
                  state: data.lead.state,
                  company_type: data.lead.company_type,
                  type_taxation: data.lead.type_taxation,
                  first_honorary: data.lead.first_honorary,
                  honorary: data.lead.honorary,
                  honorary_agreed: data.lead.honorary,
                  billing: data.lead.billing,
                  nfs_input: data.lead.nfs_input,
                  nfs_output: data.lead.nfs_output,
                  identification_number: data.lead.identification_number,
                  n_staffs: data.lead.n_staffs,
                  type_staffs: data.lead.type_staffs,
                  acquisition: data.lead.acquisition,
                  ...data
                })}
              >
                Preencher
              </Button>
            )}
          }
        />
      </Table>
    </Drawer>
  );
};
