import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { LoadingTopBar } from '~/components/UI/LoadingTopBar';
import DrawerLinks from '~/containers/DrawerLinks';
import Links from '~/containers/Links';
import { loadLinkCategories, loadLinks } from '~/containers/Links/actions';
import Layout from '../components/UI/Layout/Layout';
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

export const ScreensLinks = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);
  
  async function loadData() {
    try {
      await LocalCacheHandler.load({
        actionName: CACHE_ACTIONS.links,
        dispatch,
        pathname,
      });
      const [
        { payload: linksPayload },
        { payload: linkCategoriesPayload },
      ] = await Promise.all([
        dispatch(loadLinks({ showLoading: false })),
        dispatch(loadLinkCategories({ showLoading: false })),
      ]);
      await LocalCacheHandler.save({
        pathname,
        data: {
          links: linksPayload.data.links,
          linkCategories: linkCategoriesPayload.data.categories,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <Links />
        <DrawerLinks />
      </Layout>
    </div>
  );
};
