export const loadPermissionsGroups = ({ ...params }) => ({
  type: "LOAD_PERMISSIONS_GROUPS",
  payload: {
    request: {
      method: "GET",
      url: "/permissions",
      ...params,
      showLoading: false,
    },
  },
});

export const create = (data) => ({
  type: 'CREATE_PERMISSIONS_GROUP',
  payload: {
    request: {
      method: 'post',
      url: '/permissions',
      data,
    },
  },
});

export const update = (id, data) => ({
  type: 'UPDATE_PERMISSIONS_GROUP',
  payload: {
    request: {
      method: 'put',
      url: `/permissions/${id}`,
      data,
    },
  },
});

export const remove = (id) => ({
  type: 'DELETE_PERMISSIONS_GROUP',
  payload: {
    request: {
      method: 'delete',
      url: `/permissions/${id}`,
      id
    }
  }
});

export const openModal = (data) => {
  return {
    type: 'OPEN_PERMISSIONS_MODAL',
    payload: {
      data,
    },
  };
};

export const closeModal = () => ({
  type: 'CLOSE_PERMISSIONS_MODAL',
  payload: {
    data: null,
  },
});
