import { Icon, Input } from 'antd';
import styled from 'styled-components';

export const SearchInput = styled(Input.Search)`
  max-width: 480px;
  margin-bottom: 20px !important;
`;

export const RatingIcon = styled(Icon).attrs(({ rating }) => ({
  style: {
    fontSize: 20,
    color: rating === 0 ? '#f5222dd9' : '#52c41ad9',
  },
  theme: 'filled',
  type: rating === 0 ? 'dislike' : 'like',
}))``;
