import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import 'moment/locale/pt-br';
import InputCurrency, { currency2Float } from '~components/UI/InputCurrency';
import get from 'lodash/get';
import find from "lodash/find";
import Moment from 'react-moment';
import { orderBy } from 'natural-orderby';
import moment from 'moment';
import Attachments from '~/containers/Attachments';
import { DatePicker } from '~components/UI/DatePicker/Locale';

import {
  Input,
  Button,
  Form,
  Row,
  Col,
  Drawer,
  Divider,
  Select,
  Modal,
  Tabs,
  InputNumber,
  Timeline,
  Tooltip,
  Icon,
  Rate,
  Radio,
  Switch
} from 'antd';

import { Cod } from '~/components/UI';
import InputMask from '~components/UI/InputMask';
import * as drawerLeads from './actions';
import Download from '~/components/Components/Download';
import { CompanyTypes } from '~components/SelectWithAdd';

import {
  taxationTypes,
} from '~containers/Customer/data';

import { estados } from '~/utils/states_cities.json';

import { removeCountryCodeFromPhone } from '~/utils/formatters';
import { IndicationsTab } from './Tabs/IndicationsTab';
import TaskModal from './Modals/TaskModal';
import { finishCurrentOnboardingStepAction } from '~/components/Onboarding/helpers';
import { LostReasons, Acquisitions, Tags } from "~/components/SelectWithAdd";
import Tip from "~/components/Tip";
import { WhatsappIcon } from '~/icons/WhatsappIcon';
import { WhatsappLink } from './styles';

const { Option } = Select;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

function processInitialValue(initialValue) {
  if (Array.isArray(initialValue) || initialValue === null) {
      // If it's already an array or null, keep the value
      return initialValue;
  } else if (typeof initialValue === 'string') {
      try {
          // Try to parse the string as JSON
          let parsedValue = JSON.parse(initialValue);
          if (Array.isArray(parsedValue)) {
              return parsedValue;
          } else {
              // If the parsed value is not an array, split by commas
              return initialValue.split(',').map(item => item.trim());
          }
      } catch (e) {
          // If JSON parsing fails, split by commas
          return initialValue.split(',').map(item => item.trim());
      }
  } else {
      // Handle any other unexpected types, if necessary
      return null;  // or throw an error, depending on your needs
  }
}

const DrawerLead = (props) => {
  const [loading, setLoading] = useState(false);
  const [tabActive, setTabActive] = useState('1');
  const [tasks, setTasks] = useState([]);
  const [status, setStatus] = useState();
  const [indications, setIndications] = useState([]);
  const [leadChoseNotToIndicate, setLeadChoseNotToIndicate] = useState(false);
  const [forceIsRevised, setForceIsRevised] = useState(false);
  const [taskModal, setTaskModal] = useState({
    isOpen: false,
    data: null
  });

  const {
    form,
    drawer,
    handleClose,
    finishCurrentOnboardingStep,
    onboardingStep,
    isOnboardingActive,
    user,
    isVideoModalVisible,
    users,
  } = props;

  const { getFieldDecorator, getFieldValue } = form;
  const { data } = drawer;

  const filteredUsers = users.filter(user => user.email !== 'suporte@contlabs.com.br');

  useEffect(() => {
    setTasks(get(data, 'tasks', []));
    setIndications(get(data, 'indications', []));
    setLeadChoseNotToIndicate(get(data, 'chose_not_to_indicate', false));
  }, [data]);

  const handleCreate = () => {
    const { data } = drawer;
    setStatus(data.status);
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      setLoading(true);
      props.handleCreate({
        ...data,
        ...values,
        tasks,
        indications: leadChoseNotToIndicate ? [] : indications,
        chose_not_to_indicate: leadChoseNotToIndicate,
        status,
        source: 'panel'
      })
        .then(() => {
          closeDrawerLeads();
          finishCurrentOnboardingStep({
            isActive: checkIfStepIsActive(7),
            step: onboardingStep,
            user
          });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }

  const checkIfStepIsActive = (step) => {
    return !isVideoModalVisible && isOnboardingActive && onboardingStep === step;
  }

  const getTaskColor = (status) => {
    return {
      'Vencido': 'red',
      'A vencer': 'yellow',
      'Hoje': 'green',
    }[status]
  }

  const handleSave = () => {

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      setLoading(true);
      props.handleSave(get(data, 'id'), {
        ...values,
        tasks,
        indications: leadChoseNotToIndicate ?
          indications.filter((indication) => !indication.is_new) :
          indications,
        chose_not_to_indicate: leadChoseNotToIndicate,
        status,
        force_is_revised: forceIsRevised
      })
        .then(() => {
          closeDrawerLeads();
        })
        .finally(() => {
          setLoading(false);
        });
    });

  }

  const handleDelete = () => {
    Modal.confirm({
      title: 'Você realmente deseja excluir este lead?',
      content: 'Esta ação não poderá ser revertida.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => props.handleDelete(data)
    });
  }

  const formatLeadName = (name) => {
    // keep only letters and numbers
    name = name.replace(/[^a-zA-Z0-9 ]/g, '');

    // remove double or more spaces
    name = name.replace(/\s{2,}/g, ' ');

    if(name === ''){
      return '';
    }

    const removeSpecialChars = (str) => {
      if (str === undefined) {
        return '';
      }
    
      return str.replace(/[\u0300-\u036f]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD83C-\uDBFF\uDC00-\uDFFF]/g, '');
    }
    
    return name.split(' ')
      .map(item => item.startsWith('CRM') ?
        item :
        `${removeSpecialChars(item[0]).toLowerCase()}${item.slice(1)}`).join('-');
  }

  const getFilename = (data) => {
    return `lead-${data.id}-${formatLeadName(data.name)}.pdf`
  }

  const openTaskModal = (data = null) => {
    setTaskModal({
      isOpen: true,
      data
    })
  }

  const closeTaskModal = () => {
    setTaskModal({
      isOpen: false,
      data: null
    });
  }

  const closeDrawerLeads = () => {
    setTabActive('1');
    setIndications([]);
    form.resetFields();
    setLeadChoseNotToIndicate(false);
    handleClose();
  }
  return (
    <Drawer
      title={(data.id) ? <span>Editar Lead <Cod float="right">#CRM{data.id}</Cod></span> : 'Cadastrar Lead'}
      width={900}
      placement="right"
      onClose={closeDrawerLeads}
      maskClosable={false}
      visible={drawer.show}
      destroyOnClose={true}
      style={{
        height: '100%',
        overflow: 'auto',
        paddingBottom: 53,
      }}>
      <TaskModal
        visible={taskModal.isOpen}
        closeModal={closeTaskModal}
        data={taskModal.data}
        setTasks={setTasks}
      />
      <Form layout="vertical">
        <Tabs
          type="card"
          activeKey={tabActive}
          onChange={(key) => setTabActive(key)}>
          <TabPane tab="Lead" key={1} forceRender={true}>
            <Row gutter={16}>
              <Col span={16}>
                <Form.Item label="Nome">
                  {getFieldDecorator('name', {
                    initialValue: get(data, 'name'),
                    rules: [{ required: true, message: 'Por favor informe o nome do lead!' }],
                  })(<Input placeholder="Nome do lead" />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={<span>Responsável <Tip>Responsável pela prospecção do lead.</Tip></span>}>
                  {getFieldDecorator('assigned_to', {
                    initialValue: get(data, 'assigned_to') ? get(data, 'assigned_to') : user.id,
                    rules: [{ required: true, message: 'Por favor informe o responsável pelo lead!' }],
                  })(
                  <Select placeholder="Responsável">
                    {filteredUsers.map(user => (
                      <Option key={user.id} value={user.id}>{user.name}</Option>
                    ))}
                  </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Email">
                  {getFieldDecorator('email', {
                    initialValue: get(data, 'email'),
                  })(<Input placeholder="Email" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Telefone">
                  {getFieldDecorator('phone', { initialValue: removeCountryCodeFromPhone(data.phone) })(
                    <InputMask
                      mask="(99) 9999tt999?"
                      formatChars={{ "9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]" }}
                      maskChar={null}
                      placeholder="Telefone da empresa"
                      suffix={getFieldValue('phone') ?
                        <Tooltip title="Ir para o Whatsapp" placement="topRight">
                          <WhatsappLink href={`https://wa.me/55${getFieldValue('phone')}`} target="_blank">
                            <WhatsappIcon />
                          </WhatsappLink>
                        </Tooltip> : <span />
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={<span>Origem <Tip>A origem é o canal que direcionou o lead até a sua empresa. Por exemplo: Seu site, blog, Instagram, entre outros. <br /><br/> Dica: Se a origem desejada não estiver na lista, adicione digitando no campo e em seguida clique em cadastrar.</Tip></span>}>
                    {getFieldDecorator("acquisition_id", {
                    initialValue: data ? data.acquisition_id : null,
                    })(
                      <Acquisitions />
                    )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={<span>Necessidade <Tip>Se preenchido, após positivar o lead será criado um ticket no societário automáticamente.</Tip></span>}>
                  {getFieldDecorator('necessity', { initialValue: get(data, 'necessity') })(
                    <Select placeholder="Necessidade">
                      <Option value={2}>Abertura de Empresa</Option>
                      <Option value={1}>Troca de contabilidade</Option>
                      <Option value={2}>Abertura de Empresa</Option>
                      <Option value={3}>Encerramento de empresa</Option>
                      <Option value={4}>BPO Financeiro</Option>
                      <Option value={6}>MEI {'>'} ME</Option>
                      <Option value={5}>Outros</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {getFieldValue('necessity') === 5 && (
                <Col span={24}>
                  <Form.Item label="Especifique">
                    {getFieldDecorator('necessity_obs', {
                      initialValue: get(data, 'necessity_obs'),
                      // rules: [{ required: true, message: 'Por favor especifique a necessidade!' }],
                    })(<Input placeholder="Especifique a necessidade" />)}
                  </Form.Item>
                </Col>
              )}
              <Col span={16}>
                <Form.Item label={<span>Tags <Tip>Crie e agrupe leads utilizando tags customizáveis.</Tip></span>}>
                    {getFieldDecorator("tags", (get(data, 'tags', null) && get(data, 'tags', null).length > 0) ? {
                    initialValue: processInitialValue(get(data, 'tags', null))
                    }:{})(
                      <Tags />
                    )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={<span>Último honorário pago</span>}>
                  {getFieldDecorator('last_accounting_honorary', {
                    initialValue: get(data, 'last_accounting_honorary'),
                    normalize: (value) => currency2Float(value)
                  })(
                    <InputCurrency />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={`Data de Criação`}>
                  {getFieldDecorator('registered_at', {
                    initialValue: get(data, 'registered_at', false) ? moment(data.registered_at) : null
                  })(
                    <DatePicker />
                  )}
                </Form.Item>
              </Col>
              {(data.stage === 3 || data.stage === 4) && (
                <Col span={12}>
                  <Form.Item label="Data de conclusão">
                    {getFieldDecorator('last_column_updated_at', {
                      initialValue: get(data, 'last_column_updated_at', false) ? moment(data.last_column_updated_at) : null
                    })(
                      <DatePicker />
                    )}
                  </Form.Item>
                </Col>
              )}
              <Col span={8}>
                <Form.Item label={<span>Motivo da perda <Tip>É o motivo pelo qual o lead informou que não fechará o contrato. <br /><br/> Dica: Se o motivo desejado não estiver na lista, adicione digitando no campo e em seguida clique em cadastrar.</Tip></span>}>
                    {getFieldDecorator("lost_reason", {
                    initialValue: get(data, 'lost_reason')
                    })(
                      <LostReasons />
                    )}
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="Proposta enviada?">
                  {getFieldDecorator('proposal_sent', {
                    initialValue: get(data, 'proposal_sent', false),
                    valuePropName: 'checked'
                  })(
                    <Switch />
                  )}
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="Revisado">
                  {getFieldDecorator('is_revised', {
                    initialValue: get(data, 'is_revised', false),
                    valuePropName: 'checked'
                  })(
                    <Switch onChange={(value) => setForceIsRevised(value)} />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Button type="primary" icon="plus"  onClick={() => openTaskModal()}>Follow Up ou Reunião</Button>
              <Divider orientation="left">Follow Up e Reuniões</Divider>
              <Timeline>
                {orderBy(tasks, [t => t.due_at, t => t.id], ['desc', 'desc']).filter((task) => !task.is_deleted).map(task =>
                  <Timeline.Item key={task.id} dot={<div class="ant-timeline-item-head" style={{ 
                    backgroundColor: getTaskColor(task.status),
                    left: '0',
                    top: '0'
                    }} />}>
                    <p style={{
                      marginBottom: '0px',
                      fontWeight: 600,
                      fontSize: '16px'
                    }}>{task.name} <Button  type="link" icon="edit" onClick={() => openTaskModal(task)} ></Button></p>                    
                    <Moment format={`DD/MM ddd ${task.type === 2 ? '[às] HH:mm [hrs]' : ''}`}>
                      {task.due_at}
                    </Moment>
                  </Timeline.Item>
                )}
              </Timeline>
            </Row>
          </TabPane>
          <TabPane tab="Empresa" key={2} forceRender={true}>
            <Row gutter={16}>
              <Divider orientation="left">Geral</Divider>
              <Col span={12}>
                <Form.Item label="Estado">
                  {getFieldDecorator('state', { initialValue: get(data, 'state') })(
                    <Select
                      placeholder="Estado"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {estados.map((item, index) => <Option key={index} value={item.sigla}>{item.nome}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Cidade">
                  {getFieldDecorator('city', { initialValue: get(data, 'city') })(
                    <Select
                      placeholder="Cidade"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {get(find(estados, { sigla: getFieldValue('state') }), 'cidades', []).map((item, index) => <Option key={index} value={item}>{item}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="CNPJ">
                  {getFieldDecorator('identification_number', { initialValue: get(data, 'identification_number') })(
                    <InputMask mask="99.999.999/9999-99" placeholder="CNPJ" />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={<span>Atividade <Tip>Dica: Se a atividade da empresa não estiver na lista, adicione digitando o nome da atividade e em seguida clique em cadastrar.</Tip></span>}>
                  {getFieldDecorator('company_type_id', { initialValue: get(data, 'company_type_id') })(
                    <CompanyTypes />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Regime">
                  {getFieldDecorator('type_taxation', { initialValue: get(data, 'type_taxation') })(
                    <Select placeholder="Selecione um tipo de tributação">
                      {taxationTypes.map((item, index) => <Option key={index} value={item}>{item}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label={<span>Faturamento <small>(mensal)</small></span>}>
                  {getFieldDecorator('billing', {
                    initialValue: get(data, 'billing'),
                    normalize: (value) => currency2Float(value)
                  })(
                    <InputCurrency />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={<span>NFs Entrada <small>(mensal)</small></span>}>
                  {getFieldDecorator('nfs_input', {
                    initialValue: get(data, 'nfs_input'),
                  })(
                    <InputNumber style={{ width: `100%` }} />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={<span>NFs Saída <small>(mensal)</small></span>}>
                  {getFieldDecorator('nfs_output', {
                    initialValue: get(data, 'nfs_output'),
                  })(
                    <InputNumber style={{ width: `100%` }} />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Qtd de sócios">
                  {getFieldDecorator('n_partners', {
                    initialValue: get(data, 'n_partners'),
                  })(
                    <InputNumber style={{ width: `100%` }} />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Qtdd Funcionários">
                  {getFieldDecorator('n_staffs', {
                    initialValue: get(data, 'n_staffs'),
                  })(
                    <InputNumber style={{ width: `100%` }} />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Qtd de Boletos">
                  {getFieldDecorator('number_bills', { initialValue: get(data, 'number_bills') })(
                    <InputNumber style={{ width: `100%` }} />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Sistema fiscal">
                  {getFieldDecorator('tax_software', {
                    initialValue: data.tax_software,
                  })(
                    <Radio.Group>
                      <Radio.Button value={0} size="small">SAT</Radio.Button>
                      <Radio.Button value={1} size="small">ECF</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="mb-20">
              <Col span={8}>
                <Form.Item label={<span>Honorário de abertura</span>}>
                  {getFieldDecorator('first_honorary', {
                    initialValue: get(data, 'first_honorary'),
                    normalize: (value) => currency2Float(value)
                  })(
                    <InputCurrency />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={<span>Mensalidade</span>}>
                  {getFieldDecorator('honorary', {
                    initialValue: get(data, 'honorary'),
                    normalize: (value) => currency2Float(value)
                  })(
                    <InputCurrency />
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={<span>Descrição</span>}>
                  {getFieldDecorator('description', {
                    initialValue: get(data, 'description'),
                  })(
                    <TextArea placeholder="Descrição" />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={`Classificação`}>
                  {getFieldDecorator('rate', data.rate ? {
                    initialValue: data.rate
                  } : {})(
                    <Rate count={3} />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Indicações" key={3} forceRender={true}>
            <IndicationsTab
              leadChoseNotToIndicate={leadChoseNotToIndicate}
              setLeadChoseNotToIndicate={setLeadChoseNotToIndicate}
              indications={indications}
              setIndications={setIndications}
            />
          </TabPane>
          {data.id && (
          <TabPane 
            tab="Anexos" 
            key={4} 
            style={{ padding: 16 }}>
            <Attachments 
              source={'leads'}
              entity={data.id}
              initialFiles={[]}
            />
          </TabPane>
          )}
        </Tabs>
      </Form>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }}
      >
        {get(data, 'id') && (
          <Download url={`/leads/print/${data.id}`} filename={getFilename(data)}>
            <Button style={{ marginRight: 8 }} type="primary">
              <Icon type="printer" /> Imprimir
            </Button>
          </Download>
        )}
        {get(data, 'id') && (<Button onClick={handleDelete} type="danger" style={{ marginRight: '8px' }}>Deletar</Button>)}
        <Button
          style={{
            marginRight: 8,
          }}
          onClick={closeDrawerLeads}
        >
          Cancelar
        </Button>
        {(get(data, 'id')) && (
          <Button onClick={handleSave} type="primary" loading={loading}>Salvar</Button>
        )}
        {!get(data, 'id') && (
          <Button onClick={handleCreate} type="primary" loading={loading}>Cadastrar</Button>
        )}
      </div>
    </Drawer>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  users: state.users,
  drawer: state.drawerLeads,
  onboardingStep: state.onboarding.step,
  isOnboardingActive: state.onboarding.isActive,
  isVideoModalVisible: state.onboarding.videoModal.isVisible,
});

const mapDispatchProps = dispatch => ({
  handleClose: () => dispatch(drawerLeads.close()),
  handleCreate: (payload) => dispatch(drawerLeads.create(payload)),
  handleSave: (id, payload) => dispatch(drawerLeads.save(id, payload)),
  handleDelete: (payload) => dispatch(drawerLeads.remove(payload)),
  finishCurrentOnboardingStep: ({ isActive, step, user }) => {
    finishCurrentOnboardingStepAction({ isActive, dispatch, step, user })
  }
});

export default connect(mapStateToProps, mapDispatchProps)(Form.create()(DrawerLead));