import React from 'react';

import Login from '../components/Login/Login.js';

import '../components/UI/Layout/bulma.css';
import '../components/UI/Layout/core.css';

const ScreensLogin = () => (
    <div>
        <Login />
    </div>
);

export default ScreensLogin;