import { Button, message, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import uid from 'uid';
import { useDispatch } from 'react-redux';
import * as pdfjsLib from 'pdfjs-dist/webpack';

import { FilesDragger } from '../../../FilesDragger';
import { Body, Footer, LoadingWrapper, StyledDrawer } from './styles';
import { UploadedFilesTable } from './Tables/UploadedFilesTable';
import { saveTasksInBatch } from '~/components/CalendarTask/actions';
import { validateTasks } from './handlers';

export function BatchTasksDrawer({
  isVisible,
  closeDrawer,
  departmentId,
  parentTask,
  customersOfParentTask,
  isLoading = false,
}) {
  const [tasks, setTasks] = useState([]);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isHandlingDocuments, setIsHandlingDocuments] = useState(false);
  const canvasRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    async function handleCurrentFiles() {
      if (currentFiles.length === 0) return;

      const { competence_date, custom_fields, document_types } = parentTask;

      const existingCustomerIds = tasks
        .map(({ customer_id }) => customer_id)
        .filter(Boolean);

      const hasDocumentTypes = document_types && document_types.length > 0;
      const hasOneDocumentType = document_types && document_types.length === 1;

      let formattedCustomFields = null;
      if (custom_fields) {
        formattedCustomFields = {};
        Object.keys(custom_fields).forEach((key) => {
          formattedCustomFields[key] = undefined;
        });
      }
      const [competencyMonth, competencyYear] = competence_date.split('/');
      const type = hasDocumentTypes ? document_types[0].description : undefined;
      const description = hasDocumentTypes
        ? `${document_types[0].description} ${competence_date}`
        : undefined;

      setIsHandlingDocuments(true);

      const newTasks = await Promise.all(
        currentFiles.map(async (file) => {
          const textList = await getTextAndNumbersListFromPdf(file.originFileObj);
          const task =
            textList.length > 0
              ? customersOfParentTask.find(({ customer }) => {
                  console.log(customer);
                   return (textList.includes(normalizeToName(customer.name)) || textList.includes(parseFloat(customer.identification_number)))
                  }
                )
              : undefined;

          let customerId = task ? task.customer.id : undefined;

          if (customerId) {
            if (
              hasOneDocumentType &&
              existingCustomerIds.includes(customerId)
            ) {
              customerId = undefined;
            } else {
              existingCustomerIds.push(customerId);
            }
          }

          const isOnlyDocument = customerId
            ? existingCustomerIds.filter((id) => id === customerId).length > 1
            : false;

          return {
            id: uid(),
            file,
            is_executed: true,
            customer_id: customerId,
            department: Number(departmentId),
            type,
            description,
            competency_at: `${competencyYear}-${competencyMonth}-01`,
            due_at: undefined,
            amount: 0,
            task_record_id: undefined,
            custom_fields: formattedCustomFields,
            is_only_document: isOnlyDocument,
          };
        })
      );

      setIsHandlingDocuments(false);

      setTasks([...tasks, ...newTasks]);
    }

    handleCurrentFiles();
  }, [currentFiles]);

  async function getTextAndNumbersListFromPdf(originFileObj) {
    try {
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.readAsArrayBuffer(originFileObj);

            reader.onload = async function () {
                try {
                    const pdf = await pdfjsLib.getDocument(reader.result).promise;
                    const page = await pdf.getPage(1);
                    const canvas = canvasRef.current;
                    const context = canvas.getContext('2d');
                    const viewport = page.getViewport(
                        canvas.width / page.getViewport(1.0).width
                    );
                    canvas.height = viewport.height;
                    page.render({
                        canvasContext: context,
                        viewport,
                    });
                    const textContent = await page.getTextContent();
                    const numbers = extractNumbers(textContent.items.map(({ str }) => str));
                    const textItems = textContent.items.map(({ str }) => normalizeToName(str));
                    await pdf.destroy();
                    resolve([...textItems, ...numbers]);
                } catch (error) {
                    resolve([]);
                }
            };

            reader.onerror = function () {
                resolve([]);
            };
        });
    } catch (error) {
        return [];
    }
  }

  function extractNumbers(items) {
      const numbers = [];
      for (const item of items) {
          const number = parseFloat(item);
          if (!isNaN(number)) {
              numbers.push(number);
          }
      }
      return numbers;
  }

  function normalizeToName(value) {
    if (!value) return '';
    return value
      .replace(/[0-9]/g, '')
      .toLocaleLowerCase()
      .replaceAll(' ', '')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  function onClose(tasksHaveBeenSent = false) {
    closeDrawer(tasksHaveBeenSent);
    setTasks([]);
    setCurrentFiles([]);
  }

  async function saveTasksInBatchRequest(taskId, data) {
    return await dispatch(saveTasksInBatch(taskId, data));
  }

  async function handleSave() {
    const { isValid } = validateTasks(tasks);

    if (!isValid) {
      return message.warning('Por favor, preencha todos os campos!');
    }

    setIsSaving(true);

    try {
      const formData = new FormData();

      tasks.forEach((task, index) => {
        const currentTask = `tasks[${index}]`;

        const customFields = task.custom_fields
          ? Object.entries(task.custom_fields)
          : [];

        formData.append(`${currentTask}[amount]`, task.amount);
        formData.append(`${currentTask}[competency_at]`, task.competency_at);
        formData.append(`${currentTask}[customer_id]`, task.customer_id);
        formData.append(`${currentTask}[description]`, task.description);
        formData.append(`${currentTask}[department]`, task.department);
        formData.append(`${currentTask}[due_at]`, task.due_at);

        if (!task.is_only_document) {
          formData.append(
            `${currentTask}[is_executed]`,
            task.is_executed ? '1' : '0'
          );
        }

        formData.append(`${currentTask}[type]`, task.type);
        formData.append(`${currentTask}[file]`, task.file.originFileObj);

        if (task.task_record_id) {
          formData.append(
            `${currentTask}[task_record_id]`,
            task.task_record_id
          );
        }

        if (!task.is_only_document) {
          customFields.forEach(([key, value]) => {
            formData.append(`${currentTask}[custom_fields][${key}]`, value);
          });
        }

        formData.append(
          `${currentTask}[is_only_document]`,
          task.is_only_document ? '1' : '0'
        );
      });

      await saveTasksInBatchRequest(parentTask.id, formData);
      onClose(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  }

  if (!parentTask) return null;

  return (
    <StyledDrawer
      title="Em lote"
      visible={isVisible}
      onClose={() => onClose()}
      width="80%"
      maskClosable={false}
    >
      <Body>
        {isLoading ? (
          <LoadingWrapper>
            <Spin />
          </LoadingWrapper>
        ) : (
          <FilesDragger setCurrentFiles={setCurrentFiles} />
        )}
        <UploadedFilesTable
          tasks={tasks}
          setTasks={setTasks}
          parentTask={parentTask}
          customersOfParentTask={customersOfParentTask}
          isHandlingDocuments={isHandlingDocuments}
        />
      </Body>
      <Footer>
        <Button onClick={() => onClose()} disabled={isSaving}>
          Voltar
        </Button>
        <Button
          onClick={handleSave}
          type="primary"
          loading={isSaving}
          disabled={tasks.length === 0}
        >
          {isSaving ? 'Enviando...' : 'Enviar'}
        </Button>
      </Footer>
      <canvas ref={canvasRef} />
    </StyledDrawer>
  );
}
