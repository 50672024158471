import { Modal, Icon } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import * as onboardingActions from "~/components/Onboarding/actions";

const StyledBtn = styled.a`
  display: block;
  margin-top: 20px;
  text-align: center;
  color: #fff;
  padding: 10px;
  width: 100%;
  background-color: #3d1973
  border-radius: 4px;

  &:hover {
    background-color: #3d1973;
    color: #fff;
  }
`;

export const VideoModal = () => {
  const dispatch = useDispatch();
  const { videoModal } = useSelector((state) => ({
    videoModal: state.onboarding.videoModal,
  }));

  function closeModal() {
    dispatch(onboardingActions.closeVideoModal());
  }

  return (
    <Modal
      title={videoModal.title}
      visible={videoModal.isVisible}
      onCancel={closeModal}
      width={816}
      footer={false}
    >
      {videoModal.url && (
        <iframe
          title={videoModal.title}
          src={videoModal.url}
          width="768"
          height="480"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      )}
      <StyledBtn href="https://www.youtube.com/watch?v=5SdunIvBd2U&list=PLg3ELwUTq7at0vZJJLURDdaOSIrAiPoE0&index=1" target="_blank" rel="noopener noreferrer">
        <Icon type="youtube" /> Acessar a playlist completa no youtube
      </StyledBtn>
    </Modal>
  );
};
