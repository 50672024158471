import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

const defaultState = {}

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.registerTasks]: (state, action) => {
        return action.payload;
    },

    'LOAD_USERS_SUCCESS': (state, action) => {
        const users = action.payload.data;
        return users.reduce((acc, user) => {
            acc[user.id] = user;
            return acc;
        }, {});
    },

    'REGISTER_USER_SUCCESS': (state, action) => {
        return {
            ...state,
            [action.payload.data.id]: action.payload.data
        }
    },

    'DISABLE_USER_SUCCESS': (state, action) => {
        return {
            ...state,
            [action.meta.previousAction.id]: {
                ...state[action.meta.previousAction.id],
                status: 'Desativado'
            }
        }
    },

    'ACTIVE_USER_SUCCESS': (state, action) => {
        return {
            ...state,
            [action.meta.previousAction.id]: {
                ...state[action.meta.previousAction.id],
                status: 'Ativo'
            }
        }
    },

    'DELETE_USER_SUCCESS': (state, action) => {
        const newState = {...state};
        delete newState[action.meta.previousAction.id];
        return newState;
    },

    'SAVE_PERMISSIONS_USER_SUCCESS': (state, action) => {
        return {
            ...state,
            [action.meta.previousAction.id]: action.payload.data
        }
    }
}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if(!handler) return state;

    const newState = handler(state, action);
  
    const updateCache = [
        'LOAD_USERS_SUCCESS',
    ].includes(action.type);

    if(updateCache){
        LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.usersById,
            data: newState
        });
    }

    return newState;
}

export default reducer;