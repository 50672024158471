import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Form, Button, Row, Col, Input, InputNumber, Select, Divider, Modal, Switch } from 'antd';
import moment from 'moment';
import { compare } from 'natural-orderby';
import uid from 'uid';
import get from 'lodash/get';

import InputCurrency, { currency2Float } from '../UI/InputCurrency';
import { pad } from '../UI/Utils';
import { DatePicker } from '../UI/DatePicker/Locale';
import NumericInput from '../UI/InputNumber';

import * as billsToReceiveActions from '~/components/BillsToReceive/actions';
import { PERMISSIONS } from '~/constants/permissions.constants';

const { Option } = Select;

class BillsForm extends React.Component {
    state = {
        payments: [],
        deleted: []
    }

    onCreate = () => {
        const { form } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            let sum = 0;
            Object.keys(values.payments).map(function(key, index) {
              sum = sum+values.payments[key].amount;
            });

            if(Math.trunc(values.total) !== Math.trunc(sum)){
              form.setFields({
                total: {
                  value: values.total,
                  errors: [new Error('Total não corresponde com a soma das parcelas')],
                },
              });
            }else{

              this.props.registerBill({...values, dash_task_id: this.props.billForm.data.dash_task_id})
              .then(response => {
                  form.resetFields();
                  context.onClose();
                  if(context.props.filter.filter_by !== null){
                    context.props.loadExtras(context.props.filter);
                  }
              });

            }
        });
        
    }

    onSave = () => {
        const { form, filter } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            this.props.saveBill(this.props.billForm.data.id, {...values, deleted: this.state.deleted})
            .then(response => {
                form.resetFields();
                context.onClose();
                context.props.loadExtras(filter);
            });
        });
        
    }

    componentWillReceiveProps = (nextProps) => {
      if(nextProps.billForm.data !== null && nextProps.billForm.data !== this.props.billForm.data){
        const { payments } = nextProps.billForm.data;
        if( payments !== null && payments !== undefined){
          let p = [];
          payments.map(payment => {
            p.push({...payment, uid: uid(10)});
          })
          this.setState({payments: p});
        }
      }
    }

    onDelete = () => Modal.confirm({
      title: 'Você realmente deseja excluir este serviço extra?',
      content: 'Todas as parcelas serão removidas. Esta ação não poderá ser desfeita.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => this.props.deleteBill(this.props.billForm.data.id)
    });

    changePaymentSplit = (val) => {
      let p = [];
      for(let i=1; i <= val; i++){
        p.push({
          due_date: moment().add(i, 'months').format('YYYY-MM-DD'),
          amount: this.props.form.getFieldValue('total')/val,
          method: 1,
          uid: uid(10),
          cod: null,
          new: true
        });
      }
      //Verifica se possui ID, e adiciona no array para ser excluido
      var d = [];
      this.state.payments.map(function(payment){
        if(typeof payment.id !== 'undefined'){
          d.push(payment.id);
        }
      });
      this.setState({payments: p, deleted: [...this.state.deleted, ...d]});
    }

    onClose = () => {
      this.setState({payments: [], deleted: [], default: {}});
      this.props.onClose();
    }

    render() {
        const { payments } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { customers, billForm, user } = this.props;

        const data = {
            ...this.state.default,
            ...billForm.data
        }

        const canEdit = (user.permissions.includes(PERMISSIONS.extraServices.edit) && data.id !== null) || data.id === null;
        const canDelete = user.permissions.includes(PERMISSIONS.extraServices.delete);

        const optionsPayment = () => {
            let o = [];
            for (let i = 1; i <= 12; i++) {
                o.push(<Option key={i} value={i}>{i}x</Option>);
            }
            return o;
        }

        const SOC = get(data, 'dash_task_id');

        return (
        <div>
          {billForm.open && (
            <Drawer
                title={(data.id)? (canEdit? 'Editar Serviço Extra' : 'Serviço Extra'): 'Cadastrar Serviço Extra'}
                width={800}
                placement="right"
                onClose={this.onClose}
                maskClosable={false}
                visible={billForm.open}
                destroyOnClose={true}
                style={{
                    height: '100%',
                    overflow: 'auto',
                    paddingBottom: 53,
                }}
            >
           <Form layout="vertical">
                <Row gutter={16}>
                    <Col span={!SOC? 18 : 24}>
                        <Form.Item label="Empresa">
                            {getFieldDecorator('customer_id', {
                                initialValue: data.customer_id? data.customer_id:null,
                                rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                            })(
                                <Select
                                    showSearch
                                    placeholder="Selecione uma empresa"
                                    optionFilterProp="children"
                                    onChange={(val) => this.setState({default: {...this.state.default, customer_id: val}})}
                                    disabled={(data.customer_id? true: false)}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                {customers!==null && customers.map(row => 
                                    <Option value={row.id} key={row.id}>{`${row.cod} - ${row.name}`}</Option>
                                )}
                              </Select>
                            )}
                        </Form.Item>
                    </Col>
                    {!SOC && (
                    <Col span={6}>
                        <Form.Item label="Societário">
                        {getFieldDecorator('dash_task_id', {
                            initialValue: get(data, 'dash_task_id'),
                        })(<NumericInput addonBefore={"#SOC"} placeholder=""/>)}
                        </Form.Item>
                    </Col>
                    )}
                    <Col span={24}>
                        <Form.Item label="Nome da tarefa">
                        {getFieldDecorator('task_name', {
                            initialValue: data.task_name,
                            rules: [{ required: true, message: 'Por favor informe o nome da tarefa!' }],
                        })(<Input placeholder="Dê um nome a esta tarefa" disabled={!canEdit}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Apuração">
                        {getFieldDecorator('apuration_at', {
                            initialValue: data.id? moment(data.apuration_at, 'YYYY-MM-DD'): moment(),
                            rules: [{ required: true, message: 'Por favor escolha uma data!' }],
                        })(
                            <DatePicker disabled={!canEdit}/>
                        )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Valor Total">
                        {getFieldDecorator('total', {
                            initialValue: data.total,
                            rules: [{ required: true, message: 'Por favor informe o valor total!' }],
                            normalize: (value) => currency2Float(value)
                        })(<InputCurrency disabled={!canEdit}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Parcelamento">
                      {getFieldDecorator('payment_split', { 
                          initialValue: payments.length === 0? null:payments.length,
                          rules: [{ required: true, message: 'Por favor selecione a quantidade de parcelas.' }],
                      })(<Select onChange={this.changePaymentSplit} disabled={!canEdit}>
                          {optionsPayment()}
                      </Select>)}
                      </Form.Item>
                  </Col>
                </Row>
                <Divider>Pagamento</Divider>
                {payments && payments.map((row, index) => 
                <Row gutter={16} key={row.uid}>
                  {row.id && (
                  <Col span={2}>
                    <Form.Item label="Pago">
                      <Switch 
                        defaultChecked={(row.paid_at!==null)}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                )}
                {row.id && (
                  <Col span={3}>
                  <Form.Item label="ID">
                    <Input disabled={true} value={`SE${pad(row.id, 4)}`}/>
                  </Form.Item>
                  </Col>
                )}
              <Col span={5}>
                <Form.Item label={`Parcela ${(index+1)}`}>
                  {getFieldDecorator(`payments[${row.uid}][due_date]`, {
                    initialValue: moment(row.due_date, 'YYYY-MM-DD'),
                    rules: [{ required: true, message: 'Por favor informe a data de vencimento!' }],
                  })(<DatePicker 
                  onChange={
                    (e) => this.setState({
                      payments: [...payments.map((item) => {
                        if(item.id !== row.id){
                          return item;
                        }else{
                          return {
                            ...item,
                            value: e? e.format('YYYY-MM-DD'):null
                          }
                        }
                      })]
                  })}
                  disabled={!canEdit}
                  />)}
                </Form.Item>
              </Col>
              <Col span={4}>
              <Form.Item label="Valor">
                  {getFieldDecorator(`payments[${row.uid}][amount]`, {
                    initialValue: row.amount,
                    rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                    normalize: (value) => currency2Float(value)
                  })(
                    <InputCurrency disabled={!canEdit}/>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
              <Form.Item label="Forma PGTO">
                  {getFieldDecorator(`payments[${row.uid}][method]`, {
                    initialValue: row.method,
                    rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                  })(
                    <Select disabled={!canEdit}>
                      <Option key={1} value={1}>Dinheiro</Option>
                      <Option key={2} value={2}>Boleto</Option>
                      <Option key={3} value={3}>Cartão</Option>
                      <Option key={4} value={4}>Transferência</Option>
                      <Option key={5} value={5}>Pix</Option>
                      <Option key={6} value={6}>Depósito</Option>
                      <Option key={7} value={7}>Cheque</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
              <Form.Item label="Cód">
                  {getFieldDecorator(`payments[${row.uid}][cod]`, {
                    initialValue: row.cod,
                  })(
                    <Input disabled={!canEdit}/>
                  )}
                </Form.Item>
              </Col>
              <Form.Item label="Id" style={{display: 'none'}}>
                  {getFieldDecorator(`payments[${row.uid}][id]`, {
                    initialValue: data.id?row.id:null,
                  })(
                    <Input disabled={!canEdit}/>
                  )}
                </Form.Item>
            </Row>
            )}
            {get(data, 'id') && user.permissions.includes(PERMISSIONS.extraServices.edit) && (
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Revisão financeira">
                    {getFieldDecorator('revised')(
                        <Switch defaultChecked={data.revised}/>
                    )}
                </Form.Item>
              </Col>
            </Row>
            )}
            </Form>
            <div
                style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                }}
            >
              {data.id && canEdit && canDelete && (<Button onClick={this.onDelete} type="danger" style={{marginRight: '8px'}}>Deletar</Button>)}
              <Button
              style={{
                  marginRight: 8,
              }}
              onClick={this.onClose}
              >
              Cancelar
              </Button>
              {(data.id && user.permissions.includes(PERMISSIONS.extraServices.edit)) && (
              <Button onClick={this.onSave} type="primary">Salvar</Button>
              )}
              {data.id === undefined && (
              <Button onClick={this.onCreate} type="primary">Cadastrar</Button>
              )}
            </div>
            </Drawer>
          )}
        </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        customers: state.customers.list.filter(row => row.enabled === 1 && row.inactived_at === null && row.is_customer === true).sort((a,b) => compare()(a.cod, b.cod)),
        billForm: state.customerExtras.form,
        filter: state.customerExtras.filter
    }
}

const mapDispatchProps = dispatch => {
    return{
      registerBill: (data) => dispatch({
        type: 'REGISTER_BILL',
        payload: {
          request:{
            method: 'post',
            url:'/extras',
            data: data
          }
        }
      }),
      saveBill: (id, data) => dispatch({
        type: 'SAVE_BILL',
        payload: {
          request:{
            method: 'post',
            url:`/extras/${id}`,
            data: data
          }
        }
      }),
      loadExtras: (filter) => dispatch(billsToReceiveActions.loadExtras(filter)),
      deleteBill: (id) => dispatch({
        type: 'DELETE_BILL',
        payload: {
          request:{
            method: 'delete',
            url:'/extras/'+id,
          }
        },
        id: id
      }),
      onClose: () => dispatch({
        type: 'CLOSE_BILL_FORM',
        payload: null
      }),
    }
  }
  
export default connect(mapStateToProps, mapDispatchProps)(Form.create()(BillsForm));