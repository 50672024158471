import uid from 'uid';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import 'moment/locale/pt-br';
import get from 'lodash/get';
import moment from 'moment';
import { DatePicker } from '~components/UI/DatePicker/Locale';

import {
  Input,
  Button,
  Form,
  Row,
  Col,
  Drawer,
  Modal,
  Tooltip,
  Icon,
  Steps,
  Divider,
  Dropdown,
  Menu,
  Tabs
} from 'antd';

import {
  Cod,
  DrawerFooter
} from '~/components/UI';
import Checklist from '~/components/IRPF/Checklist';
import InputMask from '~components/UI/InputMask';
import * as drawerActions from './actions';

import { HonorariesTable } from './Tables/HonorariesTable';
import HonoraryModal from './Modals/HonoraryModal';
import { WhatsappIcon } from '~/icons/WhatsappIcon';
import { WhatsappLink } from './styles';

const { Step } = Steps;
const { TabPane } = Tabs;

const getBtnType = status => ['ant-btn ant-btn-danger', 'btn btn-success', 'btn btn-warning'][status]

const getStatusText = (status) => ({
  0: 'Pendente',
  2: 'Em processo',
  1: 'Concluido',
})[status];

const getProcessStep = (step) => {
  if (step === 0) return 0;
  if (step === 1) return 2;
  if (step === 2) return 1;
}

const DrawerIRPF = (props) => {

  const [loading, setLoading] = useState(false);
  const [checklist, setChecklist] = useState({
    newList: [],
    list: [],
    deletedList: []
  });
  const [activeTab, setActiveTab] = useState('1');
  const [isHonoraryModalVisible, setIsHonoraryModalVisible] = useState(false);
  const [honoraries, setHonoraries] = useState([]);
  const [honoraryToUpdate, setHonoraryToUpdate] = useState(null);
  const [honoraryOfCurrentYear, setHonoraryOfCurrentYear] = useState();

  const {
    form,
    drawer,
    handleClose,
    // deleteCustomer,
    changeStatus,
    currentIRPFYear,
    startService,
    archiveCustomer,
    activateCustomer
  } = props;

  const { getFieldDecorator, getFieldValue } = form;
  const { data } = drawer;

  useEffect(() => {
    if (honoraries.length === 0) return;

    const activeHonoraries = honoraries.filter((honorary) => !honorary.is_deleted);

    if (activeHonoraries.length > 0) {
      const currentHonorary = activeHonoraries.find((honorary) => Number(honorary.year) === currentIRPFYear.year());
      setHonoraryOfCurrentYear(currentHonorary);
    }
  }, [honoraries])

  useEffect(() => {
    if (!data.id) return;

    if (data.honoraries.length > 0) {
      const currentHonorary = data.honoraries.find((honorary) => Number(honorary.year) === currentIRPFYear.year());
      setHonoraryOfCurrentYear(currentHonorary);
      setHonoraries(data.honoraries);
    }

    if (data.checklist.length > 0) {
      setChecklist({
        ...checklist,
        list: data.checklist
      });
    }
  }, [data])

  function resetStates() {
    setActiveTab('1');
    setHonoraries([]);
    setChecklist({
      newList: [],
      list: [],
      deletedList: []
    });
    setHonoraryToUpdate(null);
  }

  const handleCreate = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      const honorariesToBeCreated = honoraries;
      const hasHonoraryOfCurrentYear = honoraries
        .some((honorary) => !honorary.is_deleted && Number(honorary.year) === currentIRPFYear.year());

      if (!hasHonoraryOfCurrentYear) {
        honorariesToBeCreated.push({
          uid: uid(10),
          year: currentIRPFYear.year(),
          amount: 0,
          paid_at: null,
          is_new: true,
        });
      }

      setLoading(true);
      props.handleCreate({
        ...values,
        checklist,
        honoraries: honorariesToBeCreated,
        selected_year: currentIRPFYear.year()
      })
        .then(() => {
          setLoading(false);
          form.resetFields();
          resetStates();
          handleClose();
        });
    });
  }

  const handleSave = () => {

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      setLoading(true);
      props.handleSave(get(data, 'id'), {
        ...values,
        checklist,
        honoraries,
        selected_year: currentIRPFYear.year()
      })
        .then(() => {
          setLoading(false);
          form.resetFields();
          resetStates();
          handleClose();
        });

    });

  }

  const handleArchive = () => {
    Modal.confirm({
      title: 'Você realmente deseja arquivar este cliente?',
      content: 'Esta ação poderá ser revertida.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => archiveCustomer(data.id, currentIRPFYear.year())
    });
  }

  const handleActivate = () => {
    activateCustomer(data.id, currentIRPFYear.year())
  }

  // const handleDelete = () => {
  //   Modal.confirm({
  //     title: 'Você realmente deseja excluir este cliente?',
  //     content: 'Esta ação não poderá ser revertida.',
  //     okText: 'Sim',
  //     okType: 'danger',
  //     cancelText: 'Cancelar',
  //     onOk: () => deleteCustomer(data)
  //   });
  // }

  const handlePendent = () => {
    Modal.confirm({
      title: 'Você realmente deseja marcar este este item como Pendente?',
      content: 'Esta ação poderá ser revertida depois.',
      okText: 'Sim',
      okType: 'primary',
      cancelText: 'Cancelar',
      onOk: () => changeStatus(data, 0, currentIRPFYear.year()) //Pendente
    });
  }

  const handleProcess = () => {
    Modal.confirm({
      title: 'Você realmente deseja marcar este este item como Em processo?',
      content: 'Esta ação poderá ser revertida depois.',
      okText: 'Sim',
      okType: 'primary',
      cancelText: 'Cancelar',
      onOk: () => changeStatus(data, 2, currentIRPFYear.year()) //In Process
    });
  }

  const handleDone = () => {
    Modal.confirm({
      title: 'Você realmente deseja marcar este este item como Concluido?',
      content: 'Esta ação poderá ser revertida depois.',
      okText: 'Sim',
      okType: 'primary',
      cancelText: 'Cancelar',
      onOk: () => changeStatus(data, 1, currentIRPFYear.year()) //Concluído
    });
  }

  const showHonoraryModal = () => {
    setIsHonoraryModalVisible(true);
  }

  const closeHonoraryModal = () => {
    setHonoraryToUpdate(null);
    setIsHonoraryModalVisible(false)
  }

  const deleteHonorary = (uid, year) => {
    setHonoraries((currentHonoraries) => currentHonoraries.map((honorary) => honorary.uid !== uid ? honorary : {
      ...honorary,
      is_deleted: true,
      is_new: false,
      is_updated: false,
    }));

    setChecklist((currentChecklist) => ({
      ...currentChecklist,
      deletedList: currentChecklist.list
        .filter((checklist) => Number(checklist.year) === Number(year))
        .map((item) => item.uid),
      newList: currentChecklist.list
        .filter((checklist) => Number(checklist.year) !== Number(year) && !checklist.created_at)
        .map((item) => item.uid)
    }));
  }

  const handleStartService = () => {
    Modal.confirm({
      title: 'Você realmente deseja iniciar o atendimento?',
      content: 'Esta ação não poderá ser revertida depois.',
      okText: 'Sim',
      okType: 'primary',
      cancelText: 'Cancelar',
      onOk: () => startService(data.id, honoraryOfCurrentYear, currentIRPFYear.year())
    });
  };

  return (
    <Drawer
      title={(data.id) ? <span>Editar Cliente PF {data.id && `(${currentIRPFYear.year()})`} <Cod float="right">#IRPF{data.id}</Cod></span> : 'Cadastrar Cliente PF'}
      width={600}
      placement="right"
      onClose={() => {
        resetStates();
        handleClose();
      }}
      maskClosable={false}
      visible={drawer.show}
      destroyOnClose={true}
      style={{
        height: '100%',
        overflow: 'auto',
        paddingBottom: 53,
      }}>
      {honoraryOfCurrentYear && data.id && (
        <div>
          <Steps progressDot current={getProcessStep(honoraryOfCurrentYear.process_step)} size="small">
            <Step
              title="Pendente"
              description={honoraryOfCurrentYear.created_at ? moment(honoraryOfCurrentYear.created_at).format('DD/MM/YYYY') :
                  <Button
                    className="mt-10"
                    type="primary"
                    size="small"
                    onClick={handleStartService}>
                    Iniciar atendimento {currentIRPFYear.year()}
                  </Button>
              }
            />
            <Step
              title="Em processo"
              description={honoraryOfCurrentYear.in_process_at ? moment(honoraryOfCurrentYear.in_process_at).format('DD/MM/YYYY') : ''}
            />
            <Step
              title="Concluido"
              description={honoraryOfCurrentYear.done_at ? moment(honoraryOfCurrentYear.done_at).format('DD/MM/YYYY') : ''}
            />
          </Steps>
          <Divider />
        </div>
      )}
      <Form layout="vertical">
        <Tabs type='card' activeKey={activeTab} onChange={(tab) => setActiveTab(tab)}>
          <TabPane tab="Dados" key={1}>
            <Row gutter={16}>
              <Col span={16}>
                <Form.Item label="Nome">
                  {getFieldDecorator('name', {
                    initialValue: get(data, 'name'),
                    rules: [{ required: true, message: 'Por favor informe o nome completo do cliente!' }],
                  })(<Input placeholder="Nome completo" />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="CPF">
                  {getFieldDecorator('document_number', {
                    initialValue: get(data, 'document_number'),
                  })(<InputMask mask="999.999.999-99" placeholder="CPF" />)}
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Acesso GOV">
                  {getFieldDecorator('e_cac', {
                    initialValue: get(data, 'e_cac'),
                  })(<Input placeholder="Acesso GOV" />)}
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Senha">
                  {getFieldDecorator('password', {
                    initialValue: get(data, 'password'),
                  })(<Input.Password placeholder="Senha" />)}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={`Data de Nascimento`}>
                  {getFieldDecorator('birth_date', {
                    initialValue: get(data, 'birth_date') ? moment(get(data, 'birth_date')) : null
                  })(
                    <DatePicker />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Email">
                  {getFieldDecorator('email', {
                    initialValue: get(data, 'email'),
                  })(<Input placeholder="Email" />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="WhatsApp">
                  {getFieldDecorator('mobile_phone', { initialValue: get(data, 'mobile_phone') })(
                    <InputMask
                      mask="(99) 9999tt999?"
                      formatChars={{ "9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]" }}
                      maskChar={null}
                      placeholder="Número de WhatsApp"
                      suffix={getFieldValue('mobile_phone') ?
                        <Tooltip title="Ir para o Whatsapp" placement="topRight">
                          <WhatsappLink href={`https://wa.me/55${getFieldValue('mobile_phone')}`} target="_blank">
                            <WhatsappIcon />
                          </WhatsappLink>
                        </Tooltip> : <span />
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label={<span>Origem</span>}>
                  {getFieldDecorator('origem', {
                    initialValue: get(data, 'origem'),
                  })(
                    <Input placeholder="Origem" />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={<span>Número do Beneficio</span>}>
                  {getFieldDecorator('benefit_number', {
                    initialValue: get(data, 'benefit_number'),
                  })(
                    <Input placeholder="Número do Beneficio" />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Recibo">
                  {getFieldDecorator('receipt_number', {
                    initialValue: get(data, 'receipt_number'),
                  })(
                    <Input placeholder="Número do recibo" />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Bloco de notas">
              {getFieldDecorator('notes', {
                initialValue: get(data, 'notes')
              })(
                <Input.TextArea placeholder="Digite suas anotações" />
              )}
            </Form.Item>
          </TabPane>
          <TabPane tab="Honorários" key={2}>
            <Button
              type="primary"
              className="mb-20"
              onClick={showHonoraryModal}
            >
              Novo Honorário
            </Button>
            <HonorariesTable
              honoraries={honoraries.filter((honorary) => !honorary.is_deleted)}
              setHonoraries={setHonoraries}
              setHonoraryToUpdate={setHonoraryToUpdate}
              deleteHonorary={deleteHonorary}
              honoraryOfCurrentYear={honoraryOfCurrentYear}
              isCreating={!data.id}
            />
            <HonoraryModal
              isVisible={isHonoraryModalVisible || !!honoraryToUpdate}
              onClose={closeHonoraryModal}
              setHonoraries={setHonoraries}
              honoraries={honoraries}
              honoraryToUpdate={honoraryToUpdate}
              checklist={checklist}
              setChecklist={setChecklist}
              currentIRPFYear={currentIRPFYear.year()}
              isCreating={!data.id}
            />
          </TabPane>
          <TabPane tab="Itens do IR" key={3}>
            <Row gutter={16} className="mb-20">
              <Col span={24}>
                <Checklist
                  title="Item"
                  currentHonoraryYear={currentIRPFYear.year()}
                  honoraryOfCurrentYear={honoraryOfCurrentYear}
                  defaultData={get(data, 'checklist', [])}
                  checklist={checklist}
                  setChecklist={setChecklist}
                  isCreating={!data.id}
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Form>
      <DrawerFooter>
        {get(data, 'id') && (
          <Button
            icon={data.archived_at ? 'check-circle' : 'delete'}
            className="pull-left"
            onClick={data.archived_at ? handleActivate : handleArchive}
            type={data.archived_at ? 'primary' : 'danger'}
            style={{ marginRight: '8px' }}
          >
            {data.archived_at ? 'Ativar' : 'Arquivar'}
          </Button>
        )}
        {' '}
        {(honoraryOfCurrentYear && honoraryOfCurrentYear.created_at) && (
          <Dropdown onChange={(value) => console.log(value)} overlay={<Menu>
            <Menu.Item key="0" onClick={handlePendent}>Pendente</Menu.Item>
            <Menu.Item key="2" onClick={handleProcess}>Em Processo</Menu.Item>
            <Menu.Item key="1" onClick={handleDone}>Concluido</Menu.Item>
          </Menu>}>
            <Button className={getBtnType(data.status)}>
              {getStatusText(honoraryOfCurrentYear.process_step)} <Icon type="down" />
            </Button>
          </Dropdown>
        )}
        {' '}
        <Button
          style={{
            marginRight: 8,
          }}
          onClick={() => {
            resetStates()
            handleClose();
          }}
        >
          Fechar
        </Button>
        {' '}
        {(get(data, 'id')) && (
          <Button onClick={handleSave} type="primary" loading={loading}>Salvar</Button>
        )}
        {!get(data, 'id') && (
          <Button onClick={handleCreate} type="primary" loading={loading}>Cadastrar</Button>
        )}
      </DrawerFooter>
    </Drawer>
  )
}

const mapStateToProps = state => ({
  drawer: state.drawerIRPF,
  currentIRPFYear: state.irpf.current_year,
});

const mapDispatchProps = dispatch => ({
  handleClose: () => dispatch(drawerActions.close()),
  handleCreate: (payload) => dispatch(drawerActions.create(payload)),
  handleSave: (id, payload) => dispatch(drawerActions.save(id, payload)),
  // deleteCustomer: (payload) => dispatch(drawerActions.remove(payload)),
  archiveCustomer: (honorary_id, year) => dispatch(drawerActions.archive(honorary_id, year)),
  activateCustomer: (honorary_id, year) => dispatch(drawerActions.activate(honorary_id, year)),
  changeStatus: (payload, status, year) => dispatch(drawerActions.changeStatus(payload, status, year)),
  startService: (irpf_id, current_honorary, year) => dispatch(drawerActions.startService(irpf_id, current_honorary, year))
});

export default connect(mapStateToProps, mapDispatchProps)(Form.create()(DrawerIRPF));