import { Button, Drawer, Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { finishCurrentOnboardingStepAction } from '~/components/Onboarding/helpers';
import { DocumentsTable } from '../../Tables/DocumentsTable';
import DocumentUpload from '../DocumentUpload';
import {
  BackButton,
  BottonsContainer,
  Footer,
  NewDocumentButton,
} from './styles';
import { DeliveryMethodButtons } from '../../DeliveryMethodButtons';

class DocumentsListComponent extends Component {
  state = {
    documentDrawer: {
      isOpen: false,
      data: null,
    },
    isSavingDocument: false,
    files: [],
  };

  closeDocumentDrawer = () => {
    this.setState({ documentDrawer: { isOpen: false, data: null } });
  };

  openDocumentDrawer = (data = null) => {
    this.setState({ documentDrawer: { isOpen: true, data } });
  };

  componentDidUpdate = (prevProps) => {
    const { visible: prevVisible } = prevProps;
    const { visible } = this.props;

    if (prevVisible !== visible) {
      if (visible) {
        this.setState({ files: [] });
      }
    }
  };

  onCreateDocument = (document) => {
    const { files } = this.state;

    this.setState({
      files: [...files, document],
    });
  };

  onUpdateDocument = (document) => {
    const { files } = this.state;

    this.setState({
      files: files.map((existingDocument) => {
        return existingDocument.id !== document.id
          ? existingDocument
          : document;
      }),
    });
  };

  checkIfStepIsActive = (step) => {
    const {
      isOnboardingActive,
      onboardingStep,
      isVideoModalVisible,
    } = this.props;
    return (
      !isVideoModalVisible && isOnboardingActive && onboardingStep === step
    );
  };

  saveFiles = async (taskRecordId, formData) => {
    const { saveFiles, loadTaskList } = this.props;

    try {
      await saveFiles(taskRecordId, formData);
      await loadTaskList();
      this.setState({ files: [] });
    } finally {
      this.setState({ isSavingDocument: false });
    }
  };

  handleSubmit = async () => {
    const { files } = this.state;
    const { selectedTaskRecord, task } = this.props;

    const formData = new FormData();

    files.forEach((currentFile) => {
      formData.append('files[]', currentFile.file);

      const { file, ...onlyFilesInfo } = currentFile;

      formData.append('files_info[]', JSON.stringify(onlyFilesInfo));
    });

    formData.append(
      'task_record_info',
      JSON.stringify({
        task_id: task.id,
        customer_id: selectedTaskRecord.customer.id,
        task_due_at: task.due_date,
      })
    );

    this.setState({ isSavingDocument: true });

    if (!!selectedTaskRecord.executed_at) {
      await this.saveFiles(selectedTaskRecord.task_record_id, formData);
    } else {
      Modal.confirm({
        title: 'Deseja marcar essa tarefa como executada?',
        content: 'Você poderá marcar como não executada depois.',
        okText: 'Sim',
        cancelText: 'Não',
        onOk: async () => {
          formData.append('mark_as_executed', 1);
          await this.saveFiles(selectedTaskRecord.task_record_id, formData);
          this.props.finishCurrentOnboardingStep({
            isActive: this.checkIfStepIsActive(5),
            step: this.props.onboardingStep,
            user: this.props.user,
          });
        },
        onCancel: async () => {
          formData.append('mark_as_executed', 0);
          await this.saveFiles(selectedTaskRecord.task_record_id, formData);
        },
      });
    }
  };

  render() {
    const { onClose, visible, selectedTaskRecord, task } = this.props;
    const { files, documentDrawer, isSavingDocument } = this.state;
    const {
      openDocumentDrawer,
      closeDocumentDrawer,
      onCreateDocument,
      onUpdateDocument,
      handleSubmit,
    } = this;

    const taskRecordFiles = selectedTaskRecord ? selectedTaskRecord.files : [];

    const allFiles = [...taskRecordFiles, ...files];

    return (
      <Drawer
        width={900}
        destroyOnClose
        title="Documentos"
        placement="right"
        onClose={onClose}
        visible={visible}
        maskClosable={false}
      >
        {selectedTaskRecord && (
          <>
            <DocumentsTable
              files={allFiles}
              selectedTaskRecord={selectedTaskRecord}
              openDocumentDrawer={openDocumentDrawer}
            />
            {taskRecordFiles.length > 0 && (
              <BottonsContainer>
                <DeliveryMethodButtons
                  filesInState={files}
                  data={selectedTaskRecord}
                  updateCustomers={this.props.updateCustomers}
                />
              </BottonsContainer>
            )}
          </>
        )}
        <NewDocumentButton onClick={() => openDocumentDrawer()} type="primary">
          Adicionar
        </NewDocumentButton>
        <Footer>
          <BackButton onClick={onClose}>Voltar</BackButton>
          <Button
            onClick={handleSubmit}
            type="primary"
            disabled={files.length === 0}
            loading={isSavingDocument}
          >
            Salvar
          </Button>
        </Footer>
        <DocumentUpload
          visible={documentDrawer.isOpen}
          data={documentDrawer.data}
          onClose={closeDocumentDrawer}
          onCreateDocument={onCreateDocument}
          onUpdateDocument={onUpdateDocument}
          task={task}
        />
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    onboardingStep: state.onboarding.step,
    isOnboardingActive: state.onboarding.isActive,
    isVideoModalVisible: state.onboarding.videoModal.isVisible,
    user: state.user,
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    saveFiles: (taskRecordId, data) =>
      dispatch({
        type: 'CALENDAR_TASK_SAVE_FILES',
        payload: {
          request: {
            method: 'post',
            url: `/calendar/task_record/${taskRecordId}/files`,
            data,
          },
        },
        id: taskRecordId,
      }),
    finishCurrentOnboardingStep: ({ isActive, step, user }) => {
      finishCurrentOnboardingStepAction({ isActive, dispatch, step, user });
    },
  };
};

export const DocumentsList = connect(
  mapStateToProps,
  mapDispatchProps
)(DocumentsListComponent);
