import React from 'react';
import { useSelector } from "react-redux";
import { Tag, Tooltip, Table } from 'antd';
import styled from 'styled-components';

import { WhatsappIcon, phoneMask } from '~/icons/WhatsappIcon';
import { getSubdomain } from '~/helpers/getSubdomain';

import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

import Tip from '~/components/Tip';

const frontBaseUrl = `http://${getSubdomain()}.${process.env.REACT_APP_DOMAIN}`;

const WhatsappLink = styled.a`
  > img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`;

const Audience = ({
    customersChecked,
    setCustomersChecked,
    data
}) => {
    const domesticaId = useSelector((state) => state.settings["leads.company_type"].find(row => row.value === 'Doméstica'));
    const customers = useSelector((state) => state.customers.list.filter(row => row.enabled === 1 && row.inactived_at === null && row.is_customer === true && row.company_type_id !== domesticaId.id));

    const loading = useSelector((state) => state.customers.isLoading);

    const getWhatsappMessage = (customer) => {
        var msg =  `Olá, ${customer.name}! Tudo bem? \n\n${data.name}\n\n\n${data.description}\n\n\nClique no link abaixo para indicar: ${frontBaseUrl}/i/${data.token}?c=${customer.id}`;
        return encodeURIComponent(msg);
    }

    let filteredCustomers = customers.map(row => {
        return {
            ...row,
            key: row.id,
            children: row.partners_sets && row.partners_sets.length > 0 && row.partners_sets[row.partners_sets.length - 1].partners 
                ? row.partners_sets[row.partners_sets.length - 1].partners 
                : false
        };
    });

    // render as a table
     return (
        <Table
            rowKey="id"
            bordered
            rowClassName="row-clabs"
            size="middle" 
            dataSource={(filteredCustomers.length > 0 && !loading)? filteredCustomers : []}
            loading={(customers.length === 0 || loading)}
            pagination={getDefaultPaginationConfig({
                storageKey: STORAGE.defaultPageSize.referralProgramAudience
            })}
            rowSelection={{
                selectedRowKeys: customersChecked,
                onChange: (selectedRowKeys) => {
                    setCustomersChecked(selectedRowKeys);
                }
            }}
            defaultExpandAllRows={true}
            columns={[
                {
                    title: 'Empresa & Sócios',
                    dataIndex: 'name',
                    key: 'name',
                    sorter: (a, b) => a.name && a.name.localeCompare(b.name),
                    render: (name, customer) => {
                        return (
                            <>
                                {name} {!customer.children && customer.cod && (
                                    <Tip>Dica! Cadastre os sócios da empresa no cadastro do cliente (aba Sócios) para inclui-los na campanha.</Tip>
                                )}
                            </>
                        );
                    }
                },
                {
                    title: 'Tipo de Tributação',
                    dataIndex: 'type_taxation',
                    key: 'type_taxation',
                    align: 'center',
                    filterMultiple: false,
                    filters: [
                        { text: 'Doméstica', value: 'Doméstica' },
                        { text: 'Lucro Presumido', value: 'Lucro Presumido' },
                        { text: 'Lucro Real', value: 'Lucro Real' },
                        { text: 'MEI', value: 'MEI' },
                        { text: 'Profissional Liberal', value: 'Profissional Liberal' },
                        { text: 'Simples Nacional', value: 'Simples Nacional' }, 
                    ],
                    onFilter: (value, record) => record.type_taxation && record.type_taxation === value,
                    render: (type_taxation) => type_taxation ? (
                        <Tag color="purple">{type_taxation}</Tag>
                    ) : '',
                    sorter: (a, b) => a.type_taxation && a.type_taxation.localeCompare(b.type_taxation)
                },
                {
                    title: 'Whatsapp',
                    dataIndex: 'phone',
                    key: 'phone',
                    align: 'center',
                    render: (phone, customer) => { 
                        if(customer.children) {
                            return;
                        }

                        return (
                        <div style={{textAlign: `center`}}>
                            {phone && (
                                <Tooltip title="Enviar convite para o Whatsapp do cliente">
                                    <WhatsappLink
                                        onClick={
                                            (e) => {
                                                console.log(customer)
                                                if (customersChecked.indexOf(customer.id) === -1) {
                                                    setCustomersChecked([...customersChecked, customer.id]);
                                                }
                                            }
                                        }
                                        href={`https://api.whatsapp.com/send?phone=55${
                                            phone.replace(/\D/g, '')
                                        }&text=${getWhatsappMessage(customer)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <WhatsappIcon /> {phoneMask(phone.replace(/\D/g, ''))}
                                    </WhatsappLink>
                                </Tooltip>
                            )}
                        </div>
                    )}
                }
            ]}
        />
    );
};

export default Audience;