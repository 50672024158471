import React from "react";
import { getDiferenceAndPercentage } from "./methods/getDiferenceAndPercentage";
import { getIcon } from "./methods/getIcon";
import {
  SummaryCard,
  SummaryCount,
  SummaryPercentage,
  SummaryPointer,
  SummaryPointerWrapper,
  SummaryTitle,
} from "./styles";

export const ClicksCard = ({ data, movedPercentage }) => {
  const { diferenceCount, percentage } = getDiferenceAndPercentage(
    data.current_month_count,
    data.latest_month_count
  );
  const icon = getIcon(percentage);
  return (
    <SummaryCard>
      <SummaryTitle color="#000">Cliques</SummaryTitle>
      <SummaryCount>{data.current_month_count}</SummaryCount>
      <SummaryPercentage>
        {icon}
        {percentage}% ({diferenceCount})
      </SummaryPercentage>
      <SummaryPointerWrapper>
        <SummaryPointer>{movedPercentage}%</SummaryPointer>
      </SummaryPointerWrapper>
    </SummaryCard>
  );
};
