export const handleContractFiles = (contractFiles, formData) => {
  if (contractFiles.length > 0) {
    contractFiles.forEach((file) => {
      const contractType =
        file.contract_type === 'contract'
          ? 'new_contract_files[]'
          : 'new_rescission_files[]';

      if (file.local_url) {
        formData.append(contractType, file.local_url);
      } else if (file.is_deleted) {
        formData.append('deleted_contract_files_ids[]', file.id);
      }
    });
  }
};
