import { Form, Input, Modal, Select, Switch } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import uid from 'uid';

export const CategoryModal = Form.create()(
  ({
    isOpen,
    closeModal,
    data,
    form,
    permissions,
    onCreateCategory,
    onModifyCategory,
  }) => {
    const { getFieldDecorator } = form;

    const isEditing = !!data;

    useEffect(() => {
      if (data) {
        const { name, show_in_menu, permissions_groups_ids } = data;

        form.setFieldsValue({
          name,
          show_in_menu,
          permissions_groups_ids: permissions_groups_ids || [],
        });
      }
    }, [isOpen]);

    function saveCategory() {
      form.validateFields((errors, values) => {
        if (errors) return;

        if (isEditing) {
          onModifyCategory({
            ...data,
            ...values,
            is_modified: true,
          });
        } else {
          onCreateCategory({
            ...values,
            uid: uid(10),
            is_new: true,
          });
        }

        closeModal();
      });
    }

    return (
      <Modal
        visible={isOpen}
        title={isEditing ? 'Editar' : 'Cadastrar'}
        onOk={saveCategory}
        onCancel={closeModal}
        okText="Salvar"
        destroyOnClose
      >
        <Form layout="vertical">
          <Form.Item label="Nome">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Por favor informe o nome',
                },
              ],
            })(<Input placeholder="Nome" />)}
          </Form.Item>
          <Form.Item label="Permissões">
            {getFieldDecorator('permissions_groups_ids')(
              <Select placeholder="Selecine uma opção" mode="multiple">
                {permissions.map((permission) => (
                  <Select.Option key={permission.id} value={permission.id}>
                    {permission.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Exibir no menu principal?">
            {getFieldDecorator('show_in_menu', {
              valuePropName: 'checked',
              initialValue: false,
            })(<Switch />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);
