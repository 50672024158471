import React from "react"; 

import { Layout } from "~/components/UI";
import { Plugins } from "~/containers/Plugins";

export const ScreensPlugins = () => {
  return (
    <div>
      <Layout>
        <Plugins />
      </Layout>
    </div>
  );
};
