export const department = key => {
    switch(parseInt(key)){
        case 1: return 'Legalização';
        case 2: return 'Administrativo';
        case 3: return 'Contábil';
        case 4: return 'Fiscal';
        case 5: return 'Pessoal';
        case 6: return 'Sucesso do Cliente (CS)';
        default: return '';
    }
}

export const frequency = key => {
    switch(parseInt(key)){
        case 0: return 'Não se repete';
        case 1: return 'Mensal';
        case 2: return 'Trimestral';
        case 3: return 'Semestral';
        case 4: return 'Anual';
        default: return '';
    }
}

export const customerColumns = {
    // cod: 'Cód',
    // name: 'Razão Social',
    identification_number: 'CNPJ',
    phone: 'Telefone',
    email: 'E-mail',
    // type_taxation: 'Regime Tributário',
    company_type: 'Atividade',
    postal_code: 'CEP',
    street: 'Rua',
    street_number: 'Número',
    neighborhood: 'Bairro',
    city: 'Cidade',
    state: 'Estado',
    complement: 'Complemento',
    gov_login: 'Login Gov',
    gov_password: 'Senha Gov',
}