import React from 'react';
import styled from 'styled-components';

const DotContainer = styled.div`
    display: flex;
    gap: 4px;
`;

const Dot = styled.div`
    width: 5px;
    height: 5px;
    background-color: #ccc
    border-radius: 50%;
    animation: jump 0.6s infinite alternate;

    &:nth-child(2) {
        animation-delay: 0.2s;
    }

    &:nth-child(3) {
        animation-delay: 0.4s;
    }

    @keyframes jump {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(-5px);
        }
    }
`;

export const Typing = () => {
    return (
        <div>
            <DotContainer>
                <Dot />
                <Dot />
                <Dot />
            </DotContainer>
        </div>
    );
};