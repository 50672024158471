import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Empty, Icon, Menu, Modal, Table } from 'antd';
import { useDispatch } from 'react-redux';

import { AutomationModal } from './components/AutomationModal';
import * as pluginsActions from '~/containers/Plugins/actions';
import { availableAutomations } from './components/AutomationModal/availableAutomations';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;

export const AutomationsModal = ({ isVisible, onClose }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [automations, setAutomations] = useState([]);
  const [automationModal, setAutomationModal] = useState({
    isVisible: false,
    automationId: null,
  });

  useEffect(() => {
    if (isVisible) {
      loadInitialData();
    }
  }, [isVisible]);

  async function loadInitialData() {
    setIsLoading(true);
    try {
      const {
        payload: { data: automationsFromApi },
      } = await dispatch(pluginsActions.loadAutomations());
      setAutomations(automationsFromApi);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function hideAutomationModal() {
    setAutomationModal({
      isVisible: false,
      automationId: null,
    });
  }

  function showAutomationModal(automationId) {
    setAutomationModal({
      isVisible: true,
      automationId,
    });
  }

  function deleteAutomation(id) {
    Modal.confirm({
      title: 'Você realmente deseja remover esta automação?',
      content: 'Esta ação não poderá ser desfeita.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      onOk: async () => {
        await dispatch(pluginsActions.deleteAutomation(id));
        setAutomations((state) =>
          state.filter((automation) => automation.id !== id)
        );
      },
    });
  }

  return (
    <Modal
      title="Automações"
      visible={isVisible}
      onCancel={onClose}
      footer={false}
      width={650}
    >
      {automations.length === 0 && !isLoading ? (
        <Empty
          imageStyle={{
            height: 60,
          }}
          description="Não existem automações cadastradas no momento."
        >
          <Button type="primary" onClick={() => showAutomationModal()}>
            Cadastrar
          </Button>
        </Empty>
      ) : (
        <Table
          dataSource={automations}
          rowKey="id"
          bordered
          loading={isLoading}
          pagination={getDefaultPaginationConfig({
            storageKey: STORAGE.defaultPageSize.automations
          })}
        >
          <Column
            title="Gatilho"
            key="trigger"
            dataIndex="trigger"
            render={(value) => {
              const automation = availableAutomations.find(
                ({ trigger }) => trigger.value === value
              );
              const description = automation
                ? automation.trigger.description
                : null;
              return description;
            }}
          />
          <Column
            title="Ação"
            key="action"
            dataIndex="action"
            render={(value, record) => {
              const automation = availableAutomations.find(
                ({ trigger }) => trigger.value === record.trigger
              );
              const action = automation
                ? automation.actions.find((action) => action.value === value)
                : null;
              const description = action ? action.description : null;
              return description;
            }}
          />
          <Column
            width={80}
            key="actions"
            align="center"
            render={(_, { id }) => (
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="edit"
                      onClick={() => showAutomationModal(id)}
                    >
                      <Icon type="form"></Icon> Editar
                    </Menu.Item>
                    <Menu.Item
                      key="delete"
                      onClick={() => deleteAutomation(id)}
                    >
                      <Icon type="delete"></Icon> Excluir
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
              >
                <Button type="link">
                  Ações <Icon type="down" />
                </Button>
              </Dropdown>
            )}
          />
        </Table>
      )}
      <AutomationModal
        isVisible={automationModal.isVisible}
        onClose={hideAutomationModal}
        automations={automations}
        setAutomations={setAutomations}
        automationId={automationModal.automationId}
      />
    </Modal>
  );
};
