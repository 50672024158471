import { Button, Drawer, Switch } from "antd";
import styled from "styled-components";

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Title = styled.span``;

export const BottonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DocumentButtonWrapper = styled.div`
  margin-bottom: 10px;
`;

export const SwitchLabel = styled.span`
  font-size: 12px;
`;

export const SwtichStyled = styled(Switch)`
  margin-right: 4px !important;
`;

export const DeliveryMethodButtonContainer = styled.div`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
`;

export const ButtonsGroupContainer = styled(Button.Group)`
  display: flex !important;
  margin-left: 16px !important;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-right: 16px;
`;

export const HelperText = styled.div`
  font-size: 9px;
  margin-top: 2px;
  color: #787878;
  text-align: center;
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    height: calc(100vh - 55px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
`;

export const Footer = styled.div`
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  text-align: left;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 30px;
`;
