import update from 'immutability-helper';
import get from 'lodash/get';

import * as REFUNDS from './actions';
import * as DRAWER_REFUNDS from '../DrawerRefunds/actions';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const defaultState = {
    payments: [],
    fetched: false,
    filter: {},
    isLoading: true
};

const ACTION_HANDLERS = {
    [REFUNDS.LOAD_REFUNDS.INIT]: (state, action) => {
        return update(state, {
            filter: { $set: get(action, "payload.filter") },
        });
    },

    [CACHE_ACTIONS.refunds]: (state, action) => {
        return update(state, {
            payments: {
                $set: action.payload,
            },
            isLoading: {
                $set: false
            }
        });
    },
    
    [REFUNDS.LOAD_REFUNDS.SUCCESS]: (state, action) => {
        return update(state, {
            payments: {
                $set: action.payload.data.payments,
            },
            fetched: { $set: true },
            isLoading: {
                $set: false
            }
        });
    },

    [DRAWER_REFUNDS.DELETE.SUCCESS]: (state, action) => {
        return update(state, {
            payments: (oldPayments) => oldPayments.filter(p => p.refund.id !== get(action, 'meta.previousAction.payload.id')),
        })
    },

    [REFUNDS.SET_PAID.SUCCESS]: (state, action) => {
        return update(state, {
            payments: (oldPayments) => oldPayments.map(p => p.id !== get(action, 'payload.data.payment.id')? p:get(action, 'payload.data.payment')),
        })
    },

}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
  
    return handler ? handler(state, action) : state;
};

export default reducer;