import { Icon } from "antd";
import React from "react";
import { DraggerStyled } from "./styles";

export function FilesDragger({ setCurrentFiles, disabled = false }) {
  return (
    <DraggerStyled
      disabled={disabled}
      accept="image/png,image/jpg,image/jpeg,.pdf"
      fileList={[]}
      multiple
      beforeUpload={() => false}
      onChange={({ fileList }) => {
        setCurrentFiles(fileList);
      }}
    >
      <p className="ant-upload-drag-icon">
        <Icon type="inbox" />
      </p>
      <p className="ant-upload-text">
        Clique aqui ou arraste os arquivos para fazer o upload.
      </p>
    </DraggerStyled>
  );
}
