/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Table } from 'antd';

import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';
import * as referralProgramActions from '~/containers/DrawerReferralProgram/actions';

import { columns } from './columns';

const TableList = ({
    campaigns
}) => {
    const dispatch = useDispatch();

    return (
        <div className={`table-clabs`}>
            <Table 
                columns={columns} 
                dataSource={campaigns}
                rowKey="id"
                bordered={true}
                rowClassName="row-clabs clickable-row"
                pagination={getDefaultPaginationConfig({
                    storageKey: STORAGE.defaultPageSize.referralProgram
                })}
                onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        dispatch(referralProgramActions.show(record));
                      }
                    };
                }}
            />
        </div>
    );
};

export default TableList;