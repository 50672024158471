export const formOptions = {
  name: {
    rules: [
      {
        required: true,
        message: 'Por favor, informe o nome completo',
      },
    ],
  },
  percentage: {
    rules: [
      {
        required: true,
        message: 'Por favor, informe o percentual',
      },
    ],
  },
};
