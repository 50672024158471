import update from 'immutability-helper';

import { CREATE_HONORARY_FLAG, LOAD_HONORARIES, REMOVE_HONORARY_FLAG } from './actions';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const DEFAULT_STATE = {
  list: [],
  isLoading: true,
};

const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CACHE_ACTIONS.honoraries: {
      return update(state, {
        $set: {
          list: action.payload,
          isLoading: false
        }
      });
    }

    case LOAD_HONORARIES.SUCCESS: {
      return update(state, {
        $set: {
          list: action.payload.data.customers,
          isLoading: false,
        },
      });
    }

    case CREATE_HONORARY_FLAG.INIT: {
      const { customer_id } = action.payload;
      return update(state, {
        list: {
          $set: state.list.map((customer) => {
            if (customer.id !== customer_id) {
              return customer;
            }
            return {
              ...customer,
              is_flagged: true,
            };
          }),
        },
      });
    }

    case CREATE_HONORARY_FLAG.FAIL: {
      const { customer_id } = action.meta.previousAction.payload;
      return update(state, {
        list: {
          $set: state.list.map((customer) => {
            if (customer.id !== customer_id) {
              return customer;
            }
            return {
              ...customer,
              is_flagged: false,
            };
          }),
        },
      });
    }

    case REMOVE_HONORARY_FLAG.INIT: {
      const { customer_id } = action.payload;
      return update(state, {
        list: {
          $set: state.list.map((customer) => {
            if (customer.id !== customer_id) {
              return customer;
            }
            return {
              ...customer,
              is_flagged: false
            }
          })
        }
      });
    }

    case REMOVE_HONORARY_FLAG.FAIL: {
      const { customer_id } = action.meta.previousAction.payload;
      return update(state, {
        list: {
          $set: state.list.map((customer) => {
            if (customer.id !== customer_id) {
              return customer;
            }
            return {
              ...customer,
              is_flagged: true
            }
          })
        }
      });
    }

    default:
      return state;
  }
};

export default reducer;
