import React from "react";

import { ChartsContainer } from "./styles";
import { TaskChartByMonth } from "./TaskChartByMonth";

export function TasksChartByMonth({ data }) {
  if (!data) return null;
  const {
    legalization,
    administrative,
    accounting,
    fiscal,
    personal,
    audit,
  } = data;
  return (
    <ChartsContainer>
      <TaskChartByMonth title="Legalização" data={legalization} />
      <TaskChartByMonth title="Administrativo" data={administrative} />
      <TaskChartByMonth title="Contábil" data={accounting} />
      <TaskChartByMonth title="Fiscal" data={fiscal} />
      <TaskChartByMonth title="Pessoal" data={personal} />
      <TaskChartByMonth title="Sucesso do Cliente (CS)" data={audit} />
    </ChartsContainer>
  );
}