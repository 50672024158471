import styled from 'styled-components';

export const Container = styled.div`
  input {
    font-size: 16px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
`;