import { Icon, Input, Tag } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelStyled = styled.span`
  flex: 1;
`;

export const TagStyled = styled(Tag).attrs({
  color: 'green',
})`
  font-size: 14px;
`;

export const WhatsappLink = styled.a`
  > img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`;

export const LockIconStyled = styled(Icon).attrs({
  type: 'lock',
})`
  color: rgba(0, 0, 0, 0.25);
`;

export const InputStyled = styled(Input)`
  flex: 2;
`;