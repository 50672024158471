import {
  Drawer,
  Form,
  Select,
  Input,
  DatePicker,
  Button,
  Icon,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import InputCurrency, { currency2Float } from '~/components/UI/InputCurrency';
import { UploadStyled } from '../../styles';
import { DocumentViewerModal } from '../Modals/DocumentViewerModal';
import { selectIfOne } from '~/utils/formatters';

const { Option } = Select;
const { TextArea } = Input;
const { MonthPicker } = DatePicker;

class DocumentUpload extends React.Component {
  state = {
    selectedFileList: [],
    documentViewerModal: {
      isOpen: false,
      data: null
    }
  }

  handleSubmit = () => {
    const { form, data } = this.props;

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (data) {
        this.props.onUpdateDocument({
          ...data,
          ...values,
          file: values.file.file ? values.file.file : data.file,
          competency_at: moment(values.competency_at).format('YYYY-MM-DD'),
          due_at: moment(values.due_at).format('YYYY-MM-DD'),
        })
      } else {
        this.props.onCreateDocument({
          ...values,
          id: values.file.file.uid,
          file: values.file.file,
          competency_at: moment(values.competency_at).format('YYYY-MM-DD'),
          due_at: moment(values.due_at).format('YYYY-MM-DD'),
          created_by_user: this.props.user.name,
        });
      }

      form.resetFields();
      this.props.onClose();
    });
  };

  openDocumentViewerModal = (data) => {
    this.setState({
      documentViewerModal: {
        isOpen: true,
        data
      }
    });
  }

  closeDocumentViewerModal = () => {
    this.setState({
      documentViewerModal: {
        isOpen: false,
        data: null
      }
    })
  }

  onChangeType = (type) => {
    const { setFieldsValue, getFieldValue } = this.props.form;
    const competencyAt = getFieldValue('competency_at');
    setFieldsValue({
      description: `${type} ${moment(competencyAt).format('MM/YYYY')}`
    });
  }

  onChangeCompetencyAt = (date) => {
    const { setFieldsValue, getFieldValue } = this.props.form;
    const { task } = this.props;
    const type = getFieldValue('type');
    setFieldsValue({
      description: task.document_types.length > 0
        ? `${type || task.document_types[0].description} ${date.format('MM/YYYY')}`
        : date.format('MM/YYYY')
    });
  }

  componentDidUpdate = (prevProps) => {
    const { visible: prevVisible } = prevProps;
    const { visible, data } = this.props;

    if (prevVisible !== visible) {
      if (visible) {
        if (data) {
          this.setState({ selectedFileList: [data.file] })
        }
      } else {
        this.setState({ selectedFileList: [] })
      }
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { visible, onClose, task, data } = this.props;
    const { selectedFileList } = this.state;

    const [competenceMonth, competenceYear] = task.competence_date.split('/');

    return (
      <Drawer
        destroyOnClose
        title="Adicionar documento"
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <Form.Item label="Departamento">
          {getFieldDecorator('department', {
            rules: [
              {
                required: true,
                message: 'Por favor, informe o departamento',
              },
            ],
            initialValue: data ? data.department : task.department,
          })(
            <Select placeholder="Selecione um departamento">
              <Option value="1">Legalização</Option>
              <Option value="2">Administrativo</Option>
              <Option value="3">Contábil</Option>
              <Option value="4">Fiscal</Option>
              <Option value="5">Pessoal</Option>
              <Option value="6">Sucesso do Cliente (CS)</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Tipo de documento">
          {getFieldDecorator('type', {
            rules: [
              {
                required: true,
                message: 'Por favor, informe um tipo'
              }
            ],
            initialValue: data ? data.type : selectIfOne(task.document_types),
          })(
            <Select placeholder="Selecione um tipo" onChange={this.onChangeType}>
              {!!task.document_types && task.document_types.map((type) => (
                <Option
                  key={type.id}
                  value={type.description}
                >
                  {type.description}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Descrição do documento">
          {getFieldDecorator('description', {
            rules: [
              {
                required: true,
                message: 'Por favor, informe a descrição do documento',
              },
            ],
            initialValue: data
              ? data.description
              : !!task.document_types && task.document_types.length > 0 
                ? `${task.document_types[0].description} ${task.competence_date}`
                : task.competence_date,
          })(<TextArea placeholder="Descrição do documento" />)}
        </Form.Item>
        <Form.Item label="Mês de competência">
          {getFieldDecorator('competency_at', {
            rules: [
              {
                required: true,
                message: 'Por favor, informe o mês de competência',
              },
            ],
            initialValue: data ? moment(data.competency_at) : moment(`${competenceYear}-${competenceMonth}-01`),
          })(<MonthPicker
              format="MM/YYYY"
              allowClear={false}
              onChange={this.onChangeCompetencyAt}
            />
          )}
        </Form.Item>
        <Form.Item label="Data de vencimento">
          {getFieldDecorator('due_at', {
            rules: [
              {
                required: true,
                message: 'Por favor, informe a data de vencimento',
              },
            ],
            initialValue: data ? moment(data.due_at) : undefined
          })(<DatePicker format="DD/MM/YYYY" />)}
        </Form.Item>
        <Form.Item label="Valor">
          {getFieldDecorator('amount', {
            normalize: (value) => currency2Float(value),
            initialValue: data ? data.amount : undefined
          })(<InputCurrency />)}
        </Form.Item>
        <Form.Item label="Selecione um arquivo">
          {getFieldDecorator('file', {
            rules: [
              {
                required: true,
                message: 'Por favor, selecione um arquivo',
              },
            ],
            initialValue: data ? data.file : undefined
          })(
            <UploadStyled
              fileList={selectedFileList}
              onChange={({ fileList, file }) => {
                this.setState({ selectedFileList: [file] })
                if (fileList.length > 1) {
                  fileList.shift();
                }
              }}
              beforeUpload={() => false}
            >
              <Button style={styles.uploadButton}>
                <Icon type="upload" /> Selecionar arquivo
              </Button>
            </UploadStyled>
          )}

          {selectedFileList.length > 0 && (
            <Button
              type="primary"
              style={styles.documentViewerButton}
              onClick={() => this.openDocumentViewerModal(selectedFileList[0])}
            >
              Visualizar
            </Button>
          )}
        </Form.Item>
        <div style={styles.footer}>
          <Button style={styles.footerButton} onClick={onClose}>
            Voltar
          </Button>
          <Button style={styles.footerButton} onClick={this.handleSubmit} type="primary">
            Salvar
          </Button>
        </div>
        <DocumentViewerModal
          isOpen={this.state.documentViewerModal.isOpen}
          closeModal={this.closeDocumentViewerModal}
          data={this.state.documentViewerModal.data}
        />
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(Form.create()(DocumentUpload));

const styles = {
  footer: {
    width: '100%',
    borderTop: '1px solid #e8e8e8',
    paddingTop: '10px',
    textAlign: 'left',
    background: '#fff',
    borderRadius: '0 0 4px 4px',
    display: 'flex',
    gap: 8
  },
  footerButton: {
    flex: 1,
  },
  uploadButton: {
    width: '100%'
  },
  documentViewerButton: {
    width: '100%',
    marginTop: 10
  }
};
