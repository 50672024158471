import { Select } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 20px;
`;

export const Header = styled.div`
  margin: 0 -16px;
  margin-bottom: 10px;
  padding: 15px;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Main = styled.div``;

export const FiltersContainer = styled.div`
  padding: 10px;
  background-color: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  flex: 1;
`;

export const FilterSelect = styled(Select)`
  width: 360px !important;
`;