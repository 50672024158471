import moment from "moment";

export const getNearestDate = ({ dates, targetDate }) => {
  if (!dates || dates.length === 0 || !targetDate) return null;

  let nearestDate = {
    date: dates[0],
    days: moment(targetDate).diff(dates[0], "days"),
  };

  dates.forEach((date) => {
    const days = Math.abs(moment(targetDate).diff(date, "days"));
    if (days < nearestDate.days) {
      nearestDate = {
        date,
        days,
      };
    }
  });

  return nearestDate.date;
};
