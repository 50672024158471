import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const extras = (state = {data: [], form: {open: false, data: {}}, filter: {filter_by: null, from: null, to: null, status: null}, isLoading: true }, action) => {
    switch (action.type) {
        case "LOAD_EXTRAS_PAYMENTS":
            return {
                ...state,
                filter: action.payload.filter,
            };

        case CACHE_ACTIONS.customerExtras: {
            return {
                ...state,
                isLoading: false,
                data: action.payload
            }
        }
      
        case "LOAD_EXTRAS_PAYMENTS_SUCCESS":
            return {
                ...state,
                data: action.payload.data.data,
                form: {
                    open: false,
                    data: {},
                },
                isLoading: false
            };

        case 'DELETE_BILL_SUCCESS':
        return {
            ...state,
            data: [...state.data.filter(item => item.extra.id !== action.meta.previousAction.id)],
            form: {
                open: false,
                data: {}
            }
        };

        // form
        case 'OPEN_BILL_FORM':
        return {
            ...state,
            form: {
                open: true,
                data: action.payload
            }
        }
        case 'CLOSE_BILL_FORM':
        return {
            ...state,
            form: {
                open: false,
                data: {}
            }
        }

        default:
        return state
    }
}

export default extras;