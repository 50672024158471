import React from "react";
import { Form, Input, Modal, Select } from "antd";

const { Option } = Select;
const { TextArea } = Input;

const TasksModal = ({
  data,
  onSaveTask,
  isOpen,
  onClose,
  getFieldDecorator,
}) => {
  return (
    <Modal
      title={data.uid ? "Editar tarefa" : "Nova tarefa"}
      destroyOnClose
      visible={isOpen}
      onCancel={onClose}
      okText={data.uid ? "Salvar" : "Adicionar"}
      onOk={onSaveTask}
      maskClosable={false}
    >
      <Form>
        <Form.Item label="Departamento">
          {getFieldDecorator("department", {
            initialValue: data.department ? Number(data.department) : undefined,
            rules: [
              {
                required: true,
                message: "Por favor, selecione um departamento",
              },
            ],
          })(
            <Select placeholder="Selecione um departamento">
              <Option value={1}>Legalização</Option>
              <Option value={2}>Administrativo</Option>
              <Option value={3}>Contábil</Option>
              <Option value={4}>Fiscal</Option>
              <Option value={5}>Pessoal</Option>
              <Option value={6}>Sucesso do Cliente (CS)</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Tarefa">
          {getFieldDecorator("task", {
            initialValue: data.task,
            rules: [
              {
                required: true,
                message: "Por favor, digite a descrição da tarefa",
              },
            ],
          })(<TextArea placeholder="Descrição da tarefa" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TasksModal;
