import React, { Component } from 'react';
import { Form, Col, Row, Upload, Icon, Button, Modal } from 'antd';
import { ContractFile, ContractItem, ContractsList } from './styles';

class Contract extends Component {
  deleteContractFile = (file) => {
    Modal.confirm({
      title: `Você realmente deseja remover o contrato ${file.filename}?`,
      content: 'Esta ação não poderá ser desfeita.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => {
        const { updateContractFilesState } = this.props;

        updateContractFilesState((prevState) => {
          if (!file.local_url) {
            return prevState.map((fileInState) => {
              if (fileInState.id !== file.id) {
                return fileInState;
              }

              return {
                ...fileInState,
                is_deleted: true,
              };
            });
          }

          return prevState.filter((fileInState) => fileInState.id !== file.id);
        });
      },
    });
  };

  createContractFiles = ({ file }, type) => {
    this.props.updateContractFilesState((prevState) => [
      ...prevState,
      {
        id: file.uid,
        filename: file.name,
        contract_type: type,
        local_url: file,
      },
    ]);
  };

  render() {
    const { contract_files } = this.props.data;

    return (
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Contrato">
            <Upload
              onChange={(info) => this.createContractFiles(info, 'contract')}
              beforeUpload={() => false}
              fileList={[]}
            >
              <Button>
                <Icon type="upload" /> Selecionar arquivo
              </Button>
            </Upload>
            {contract_files.length > 0 && (
              <ContractsList>
                {contract_files
                  .filter(({ is_deleted, contract_type }) => {
                    return !is_deleted && contract_type === 'contract';
                  })
                  .map((file) => (
                    <ContractItem key={file.id}>
                      <ContractFile
                        href={
                          file.contract_url ||
                          URL.createObjectURL(file.local_url)
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {file.filename}
                      </ContractFile>
                      <Button
                        size="small"
                        type="danger"
                        icon="delete"
                        onClick={() => this.deleteContractFile(file)}
                      />
                    </ContractItem>
                  ))}
              </ContractsList>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Distrato">
            <Upload
              onChange={(info) => this.createContractFiles(info, 'rescission')}
              beforeUpload={() => false}
              fileList={[]}
            >
              <Button>
                <Icon type="upload" /> Selecionar arquivo
              </Button>
            </Upload>
            {contract_files.length > 0 && (
              <ContractsList>
                {contract_files
                  .filter(({ is_deleted, contract_type }) => {
                    return !is_deleted && contract_type === 'rescission';
                  })
                  .map((file) => (
                    <ContractItem key={file.id}>
                      <ContractFile
                        href={
                          file.contract_url ||
                          URL.createObjectURL(file.local_url)
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {file.filename}
                      </ContractFile>
                      <Button
                        size="small"
                        type="danger"
                        icon="delete"
                        onClick={() => this.deleteContractFile(file)}
                      />
                    </ContractItem>
                  ))}
              </ContractsList>
            )}
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

export default Contract;
