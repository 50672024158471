import React, { Component } from 'react';
import Axios from 'axios';
import { Form, Input, Icon, Divider, Spin, message } from 'antd';
import { Button, Title } from './Styled';
import logo from './images/logo-color.png';
import { getSubdomain } from '~/helpers/getSubdomain';
import { DEFAULT_MESSAGES } from '~/constants/default-messages.constants';
import { AccountStorage } from '~/storages/account';


class InvitedForm extends Component {
    state = {
        status: 'validating',
        spin: 'Validando convite..',
        error: null,
        email: null
    }

    componentDidMount = () => this.validate(this.props.invitation);

    validate = (invitation) => {
        const context = this;
        Axios.post(`${process.env.REACT_APP_API}/auth/invited/validate`,{
            invitation
        },
        {
            headers: {
                'X-Tenant': getSubdomain()
            }
        }
        )
        .then(response => {
            context.setState({
                status: 'valid', 
                email: response.data.data
            });
        })
        .catch(function (error) {
            const { response } = error;
            if (response && (response.status === 404 || response.status >= 500)) {
                message.error(DEFAULT_MESSAGES['high-demand']);
            } else {
                context.setState({
                    status: 'error', 
                    error: 'Convite inválido ou expirado.'
                });
            }
        });

    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { form, invitation } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            this.setState({
                status: 'submiting', 
                spin: 'Aguarde...'
            });

            Axios.post(`${process.env.REACT_APP_API}/auth/invited/register`,{
                ...values,
                invitation
            },
            {
                headers: {
                    'X-Tenant': getSubdomain()
                }
            }
            )
            .then(response => {
                AccountStorage.set({ token: response.data.api_token });
                window.location = '/';
            })
            .catch(function (error) {
                const { response } = error;
                if (response && (response.status === 404 || response.status >= 500)) {
                    message.error(DEFAULT_MESSAGES['high-demand']);
                } else {
                    context.setState({
                        status: 'error', 
                        error: 'Não foi possível concluir sua solicitação. Atualize a página e tente novamente.'
                    });
                }
            });

        });
        
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { status, email, error, spin } = this.state;

        return (
            <Spin tip={spin} size="large" spinning={(status === 'validating' || status === 'submiting')}>
                <Form style={{minHeight: `400px`}} onSubmit={this.handleSubmit}>
                    <Title>
                        <p>
                            <img src={logo} alt="Contlabs" height="40" />
                        </p>
                        <Divider />
                        {status === 'valid' && (
                        <h1>Você foi convidado!
                            <p><b>{email}</b></p>
                            <p><small>Preencha o formulário abaixo para continuar</small></p>
                        </h1>
                        )}
                        {status === 'error' && (
                        <div>
                            <h1 style={{color: `#f5222d`}}><Icon type="warning"/> {error}</h1>
                            <a href={'https://contlabs.com.br'}>Ir para a página inicial</a>
                        </div>
                        )}
                    </Title>
                    {status === 'valid' && (
                    <div>
                        <Form.Item label="Nome">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'O nome é obrigatório.' }]
                            })(
                                <Input 
                                    type="name" 
                                    size="large"
                                    placeholder="Digite seu nome completo"
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Senha">
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'A senha é obrigatório.' }]
                            })(
                                <Input.Password
                                    type="password" 
                                    size="large"
                                    placeholder="Defina uma senha"
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />
                            )}
                        </Form.Item>
                        <Button size="large" htmlType="submit" block>
                            Acessar
                        </Button>
                    </div>
                    )}
                </Form>
            </Spin>
        )
    }
}

export default Form.create()(InvitedForm);