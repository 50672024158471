export const generateRandomPassword = (length) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyz';
  let password = '';

  for (let i = 0; i < length; i++) {
    const charIndex = Math.floor(Math.random() * chars.length);
    password += chars[charIndex];
  }

  return password;
};
