import { Button, Checkbox, Input, Skeleton, Tag } from "antd";
import styled, { css } from "styled-components";

export const Container = styled.div`
  margin: 0 -16px;
  height: 100%;
  position: relative;
`;

export const Header = styled.header`
  padding: 15px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 8px 0px;
`;

export const RegistrationButton = styled(Button)`
  padding: 0 30px;
`
export const SideBar = styled.div`
  width: 300px;
  background-color: white;
  padding: 14px 16px;
  box-shadow: rgb(0 0 0 / 10%) 0px 8px 8px 0px;
  position: absolute;
  bottom: 0;
  top: 84px;
`;

export const SideBarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const FilterBy = styled.span`
  font-size: 16px;
  color: #262626;
`;

export const ClearFilters = styled(Button)`
  color: #722ED1 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 0 !important;

  &:hover {
    filter: brightness(60%);
  }
`;

export const FilterInput = styled(Input.Search)``;

export const CategoryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
`;

export const CategoryTitle = styled.span`
  font-size: 16px;
  color: #262626;
`;

export const EditCategory = styled(Button)`
  color: #722ED1 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 0 !important;

  &:hover {
    filter: brightness(60%);
  }
`;

export const CategoriesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const CategoryItem = styled.li`
`;

export const CategoryCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #722ED1 !important;
    border-color: #722ED1 !important;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin-left: 300px;
`;

export const Cards = styled.div`
  padding: 16px;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 8px;
  overflow-y: auto;

  ${({ hasNotLinks }) => hasNotLinks && css`
    grid-template-columns: 1fr !important;
  `}

  @media only screen and (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media only screen and (min-width: 1920px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: 2048px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media only screen and (min-width: 2500px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const Card = styled.button`
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  background-color: white;
  padding: 12px;
  display: flex;
  gap: 12px;
  transition: filter 300ms;
  cursor: pointer;
  position: relative;

  &:hover {
    filter: brightness(96%);
  }
`;

export const FavIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: baseline;
`;

export const CardTitle = styled.a`
  color: #722ED1;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 20px;
  z-index: 999;
  text-align: initial;

  &:hover {
    color: #722ED1;
    text-decoration: underline;
  }
`;

export const CardDescription = styled.span`
  margin-bottom: 6px;
  color: #8C8C8C;
  font-size: 14px;
  text-align: start;
`;

export const CardCategory = styled(Tag)``;

export const CardTime = styled.span`
  color: #8C8C8C;
  align-self: flex-end;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  position: absolute;
  bottom: 12px;
  right: 12px;
`;

export const LoadingSkeleton = styled(Skeleton).attrs({
  active: true,
  title: false,
  paragraph: {
    width: '100%',
    rows: 1,
  },
})`
  .ant-skeleton-content .ant-skeleton-paragraph {
    border: 1px solid #e6e6e6;
    max-width: 430px;
    margin-bottom: 0;
  }

  .ant-skeleton-content .ant-skeleton-paragraph li:first-child {
    height: 100px;
    max-width: 430px;
  }
`;
