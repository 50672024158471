import update from 'immutability-helper';
import get from 'lodash/get';

import * as ACTIONS from './actions';
import * as selectedIndicationActions from '~/containers/DrawerReferralIndication/actions';

const defaultState = {
    data: {
        id: null,
    },
    show: false,
    isSaving: false,
};

const ACTION_HANDLERS = {

    [ACTIONS.SHOW]: (state, action) => {
        return update(state, {
            data: { $set: get(action, 'payload.data') ? get(action, 'payload.data') : defaultState.data},
            show: { $set: true },
            isSaving: { $set: false },
        })
    },

    [ACTIONS.CLOSE]: (state) => {
        return update(state, {
            data: { $set: defaultState.data} ,
            show: { $set: false },
            isSaving: { $set: false },
        })
    },

    [ACTIONS.CREATE.INIT]: (state) => {
        return update(state, {
            isSaving: { $set: true },
        })
    },

    [ACTIONS.CREATE.SUCCESS]: (state) => {
        return update(state, {
            data: { $set: defaultState.data} ,
            show: { $set: false },
            isSaving: { $set: false },
        })
    },

    [ACTIONS.SAVE.INIT]: (state) => {
        return update(state, {
            isSaving: { $set: true },
        })
    },

    [ACTIONS.SAVE.SUCCESS]: (state) => {
        return update(state, {
            data: { $set: defaultState.data} ,
            show: { $set: false },
            isSaving: { $set: false },
        })
    },

    [ACTIONS.DELETE.INIT]: (state) => {
        return update(state, {
            data: { $set: defaultState.data} ,
            show: { $set: false },
        })
    },

    [selectedIndicationActions.SAVE.SUCCESS]: (state, action) => {
        const newLog = get(action, 'payload.data.data');
        return {
            ...state,
            data: {
                ...state.data,
                logs: [
                    ...state.data.logs.map(log => {
                        if(log.id === newLog.id){
                            return newLog;
                        }
                        return log;                         
                    })
                ]
            }
        }
    }

}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
  
    return handler ? handler(state, action) : state;
};

export default reducer;