import { createAsyncActions } from '~/utils/store';

export const ALL = createAsyncActions('@@REFERRAL_PROGRAM_CAMPAIGNS/ALL');

export const all = (data) => ({
    type: ALL.INIT,
    payload: {
        request:{
            method: 'GET',
            url:'/referral-programs',
            data,
            showLoading: false
        }
    }
});