import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { Container, ThumbnailAvatar } from "./styles";
import * as onboardingActions from "~/components/Onboarding/actions";
import { videos } from "./data";
import { VideoModal } from "~/components/VideoModal";
import { ROUTES } from "~/constants/routes.constants";

export const VideosAvatars = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const currentVideos = videos.filter(({ route }) => {
    if (route === pathname) {
      return true;
    }

    if (
      pathname.startsWith("/calendario") &&
      route === ROUTES["/calendario/:id/:department"]
    ) {
      return true;
    }

    if (
      pathname.startsWith("/malha-fina") &&
      route === ROUTES["/malha-fina/:department"]
    ) {
      return true;
    }

    return false;
  });

  if (currentVideos.length === 0) return null;

  const currentViewedVideos = JSON.parse(
    localStorage.getItem("viewed_videos_ids") || "[]"
  );

  function openVideoModal({ id, ...params }) {
    saveToStorage(id);
    dispatch(onboardingActions.openVideoModal(params));
  }

  function saveToStorage(id) {
    if (currentViewedVideos.some((existingId) => existingId === id)) {
      return;
    }
    const viewedVideos = [...currentViewedVideos, id];
    localStorage.setItem("viewed_videos_ids", JSON.stringify(viewedVideos));
  }

  return (
    <Container>
      {currentVideos.map(({ title, thumbnailUrl, url, id }) => {
        const isViewed = currentViewedVideos.some(
          (existingId) => existingId === id
        );
        return (
          <ThumbnailAvatar
            key={id}
            src={thumbnailUrl}
            viewed={isViewed ? "true" : "false"}
            onClick={() => openVideoModal({ title, url, id })}
          />
        );
      })}
      <VideoModal />
    </Container>
  );
};
