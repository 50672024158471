import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Table, Switch, Tag } from 'antd';
import { frequency } from '../RegisterTasks/Utils';
import Moment from 'react-moment';

const { Column } = Table;

class CustomerInactivate extends Component {

    state = {
        tasks: []
    }

    render() {
        const { tasks } = this.state;

        return (
            <Modal
                visible={(this.props.modal.status === 'exists_tasks_records')}
                title="Inativar Cliente"
                okText="Inativar"
                style={{top: `20px`}}
                bodyStyle={{padding: '0px'}}
                onCancel={this.props.closeModal}
                onOk={() => this.props.onInactivate(this.props.modal.data[0].customer_id, tasks)}>
                    <Table 
                        rowKey="id"
                        dataSource={this.props.modal.data} 
                        pagination={false}
                        rowClassName="row-clabs"
                        size="middle" 
                        locale={{
                            filterConfirm: 'Ok',
                            filterReset: 'Resetar' ,
                            emptyText: 'Nenhum registro corresponde ao filtro'
                        }}
                    >
                        <Column
                            title=""
                            dataIndex="id"
                            key="id"
                            render={(text, record) => (
                                <Switch 
                                    onClick={(value) => {
                                        this.setState({tasks: value? [
                                            ...tasks,
                                            record.id
                                        ]: tasks.filter(row => row !== record.id)});
                                    }}
                                    checked={(tasks.indexOf(record.id) !== -1)}
                                />
                            )}
                        />
                        <Column
                            title="Seleciona as tarefas deste cliente que devem ser mantidas para a próxima data"
                            dataIndex="task.name"
                            key="task.name"
                            render={(text, record) => (<span>{record.task.name} <Tag color="blue"><Moment format="DD/MM/YYYY">{record.due_date}</Moment></Tag> <Tag color="purple">{frequency(record.task.frequency)}</Tag></span>)}
                        />
                </Table>
            </Modal>
        )
    }
}

const mapDispatchProps = dispatch => {
    return{
        closeModal: () => dispatch({
            type: 'CLOSE_INACTIVE_CUSTOMER',
            payload: null
        }),
        onInactivate: (customer_id, tasks) => dispatch({
            type: 'INACTIVATE_CUSTOMER_REMOVE_TASKS',
            payload: {
            request:{
                method: 'put',
                url: `/customer/${customer_id}/inactivate/tasks`,
                data: {
                    tasks
                }
            }
        }
      })
    }
}

const mapStateToProps = state => ({
    modal: state.customersnew.modalInactivate
})

export default connect(mapStateToProps, mapDispatchProps)(CustomerInactivate);