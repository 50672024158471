import { Drawer } from "antd";
import styled from "styled-components";

export const Body = styled.div``;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    height: calc(100vh - 55px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
`;

export const LoadingWrapper = styled.div`
  padding: 10px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d9d9d9;
  cursor: wait;
  border-radius: 4px;
  background-color: #fafafa;
  margin-bottom: 20px;
`;

export const Footer = styled.div`
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  text-align: left;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 30px;
`;
