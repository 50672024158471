import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';

import Layout from "../components/UI/Layout/Layout";
import CerticatesList from "../components/Certificates/CerticatesList";
import CertificatesForm from "../components/Certificates/CertificatesForm";
import { loadCertificates } from "~/components/Certificates/actions";
import { LoadingTopBar } from "~/components/UI/LoadingTopBar";
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

export const ScreensCertificates = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const [drawer, setDrawer] = useState({
    isVisible: false,
    data: null,
  });

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await LocalCacheHandler.load({
        actionName: CACHE_ACTIONS.certificates,
        dispatch,
        pathname
      });
      const { payload } = await dispatch(
        loadCertificates({ showLoading: false })
      );
      await LocalCacheHandler.save({
        pathname,
        data: payload.data
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function showDrawer(data = null) {
    setDrawer({
      isVisible: true,
      data,
    });
  }

  function hideDrawer() {
    setDrawer({
      isVisible: false,
      data: null,
    });
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <CerticatesList showEdit={showDrawer} showModal={showDrawer} />
        <CertificatesForm
          visible={drawer.isVisible || drawer.data}
          onClose={hideDrawer}
          data={drawer.data}
        />
      </Layout>
    </div>
  );
};
