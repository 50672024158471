import React from 'react';
import { Button, Tooltip, Modal } from 'antd';
import { AiOutlineMail } from 'react-icons/ai';
import { useDispatch } from 'react-redux';

import { HelperText } from '../../styles';
import { deliveryMethodNames } from '~/containers/Customer/data';
import * as calendarTasksActions from '~/components/CalendarTask/actions';

export const EmailButton = ({
  props: {
    buttonsProps,
    buttonsState,
    buttonsLoadingState,
    hasNewFiles,
    currentTaskRecordId,
    checkIfHasButtonLoading,
    setButtonsLoadingState,
    updateDeliveryMethods,
    setButtonsState,
    defaultCustomerDeliveryMethod,
  },
}) => {
  const dispatch = useDispatch();

  async function sendEmailWithAttachment() {
    try {
      setButtonsLoadingState((state) => ({
        ...state,
        email: true,
      }));
      await dispatch(
        calendarTasksActions.sendEmailWithAttachment({
          taskRecordId: currentTaskRecordId,
        })
      );
      updateDeliveryMethods(0);
      setButtonsState((state) => ({
        ...state,
        isEmailButtonClicked: true,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setButtonsLoadingState((state) => ({
        ...state,
        email: false,
      }));
    }
  }

  function handleSendEmailWithAttachment() {
    if (checkIfHasButtonLoading()) return;

    if (!buttonsProps.email.isDefaultMethod) {
      return Modal.confirm({
        title: `
          O método padrão de envio de documentos escolhido por este cliente é ${deliveryMethodNames[defaultCustomerDeliveryMethod]}. 
          Tem certeza que deseja continuar com o envio via ${deliveryMethodNames[0]}?
        `,
        content: 'Esta ação não poderá ser desfeita.',
        okText: 'Sim',
        okType: 'primary',
        cancelText: 'Cancelar',
        onOk: sendEmailWithAttachment,
      });
    }

    sendEmailWithAttachment();
  }

  return (
    <Tooltip title={buttonsProps.email.tooltipTitle}>
      <Button
        onClick={handleSendEmailWithAttachment}
        loading={buttonsLoadingState.email}
        disabled={hasNewFiles}
        type={buttonsProps.email.isDefaultMethod ? 'primary' : 'default'}
      >
        <AiOutlineMail />
        E-mail
      </Button>
      {(buttonsProps.email.isDefaultMethod ||
        buttonsState.isEmailButtonClicked) && (
        <HelperText>E-mail já enviado</HelperText>
      )}
    </Tooltip>
  );
};
