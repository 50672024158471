import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  top: 20px !important;
  
  .ant-modal-body {
    min-height: 654px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  svg {
    font-size: 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const AvatarWrapper = styled.div`
  padding-top: 48px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
`;

export const SubjectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Subject = styled.h1`
  font-size: 22px;
  font-weight: 500;
  margin: 0;
`;

export const SubjectIconsWrapper = styled.div`
  display: flex;
  gap: 14px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
`;

export const LeftInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NameAndEmailFromWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Name = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
`;

export const EmailFrom = styled.small`
  color: #5e5e5e;
  font-size: 14px;
`;

export const EmailTo = styled.span``;

export const RightInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const Date = styled.time``;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;
  padding: 30px 0px;
`;