import * as LEADS_TABLE from './actions';
import * as LEAD from '../DrawerLeads/actions';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.leadsTable]: (state, action) => {
        return action.payload;
    },

    [LEADS_TABLE.SEARCH.INIT]: (state, action) => {
        return {
            ...state,
            isLoading: true,
        }
    },

    [LEADS_TABLE.SEARCH.SUCCESS]: (state, action) => {
        return {
            ...state,
            isLoading: false,
            data: action.payload.data.data,
        }
    },
    
    [LEAD.CREATE.SUCCESS]: (state, action) => {
        return {
            ...state,
            data: [
                action.payload.data.data,
                ...state.data,
            ]
        }
    },

    [LEAD.SAVE.SUCCESS]: (state, action) => {
        if(!state.data || state.data.length === 0) return state;

        return {
            ...state,
            data: state.data.map((lead) => {
                if(lead.id === action.payload.data.data.id){
                    return action.payload.data.data;
                }
                return lead;
            })
        }
    },

    [LEAD.DELETE.SUCCESS]: (state, action) => {
        return {
            ...state,
            data: state.data.filter((lead) => lead.id !== action.payload.data.data.id)
        }
    },
}

const defaultState = {
    isLoading: false,
    data: [],
}


const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if(!handler) return state;

    const newState = handler(state, action);
  
    const updateCache = [
        LEADS_TABLE.SEARCH.SUCCESS,
        LEAD.CREATE.SUCCESS,
        LEAD.SAVE.SUCCESS,
        LEAD.DELETE.SUCCESS,
    ].includes(action.type);

    if(updateCache){
        LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.leadsTable,
            data: newState
        });
    }

    return newState;
};

export default reducer;