import { createAsyncActions } from '~/utils/store';

export const SAVE = createAsyncActions('@@DRAWER_MONTHLY_PAYMENT/SAVE'); //SET_MONTHLY_PAID

export const save = (data) => ({
    type: SAVE.INIT,
    payload: {
        request:{
            method: 'put',
            url:`/monthly/payments/${data.id}`,
            data,
            showLoading: false
        }
    },
    id: data.id
});