import states_cities from '~/utils/states_cities.json';

const states = states_cities.estados.map((state) => {
    return {
        name: state.nome,
        value: state.nome,
    };
});

export const inputFormats = {
    text: 'text',
    email: 'email',
    phone: 'phone',
    cnpj: 'cnpj',
    money: 'money',
    number: 'number',
    select: 'select',
};

export const leadFields = [
    {
        name: 'Nome',
        key: 'name',
        type: 'input',
        format: inputFormats.text,
        labelSuggestion: 'Qual o seu nome?',
    },
    {
        name: 'Email',
        key: 'email',
        type: 'input',
        format: inputFormats.email,
        labelSuggestion: 'Qual o seu email?',
    },
    {
        name: 'Telefone',
        key: 'phone',
        type: 'input',
        format: inputFormats.phone,
        labelSuggestion: 'Qual o seu telefone?',
    },
    {
        name: 'Cidade',
        key: 'city',
        type: 'input',
        format: inputFormats.text,
        labelSuggestion: 'Qual a sua cidade?',
    },
    {
        name: 'Estado',
        key: 'state',
        type: 'input',
        format: inputFormats.select,
        labelSuggestion: 'Qual o seu estado?',
        options: states,
    },
    {
        name: 'CNPJ',
        key: 'identification_number',
        type: 'input',
        format: inputFormats.cnpj,
        labelSuggestion: 'Qual o CNPJ da sua empresa?',
    },
    {
        name: 'Atividade',
        key: 'company_type_id',
        type: 'input',
        format: inputFormats.select,
        options: [],
        settings: 'leads.company_type',
        labelSuggestion: 'Qual a atividade da sua empresa?',
    },
    {
        name: 'Regime tributário',
        key: 'type_taxation',
        type: 'input',
        format: inputFormats.select,
        labelSuggestion: 'Qual o regime tributário da sua empresa?',
        options: [
            {
                name: 'Simples Nacional',
                value: 'Simples Nacional',
            },
            {
                name: 'Lucro Real',
                value: 'Lucro Real',
            },
            {
                name: 'Lucro Presumido',
                value: 'Lucro Presumido',
            },
            {
                name: 'MEI',
                value: 'MEI',
            },
            {
                name: 'Profissional Liberal',
                value: 'Profissional Liberal'
            },
            {
                name: 'Doméstica',
                value: 'Doméstica'
            }
        ],
    },
    {
        name: 'Necessidade',
        key: 'necessity',
        type: 'input',
        format: inputFormats.select,
        labelSuggestion: 'Qual a sua necessidade?',
        options: [
            {
                name: 'Troca de contabilidade',
                value: 1,
            },
            {
                name: 'Abertura de Empresa',
                value: 2,
            },
            {
                name: 'Encerramento de empresa',
                value: 3,
            },
            {
                name: 'BPO Financeiro',
                value: 4,
            },
            {
                name: 'Outros',
                value: 5,
            },
            {
                name: 'MEI > ME',
                value: 6,
            }
        ],
    },
    {
        name: 'Faturamento',
        key: 'billing',
        type: 'input',
        format: inputFormats.money,
        labelSuggestion: 'Qual o faturamento mensal da sua empresa?',
    },
    {
        name: 'NFs de entrada',
        key: 'nfe_input',
        type: 'input',
        format: inputFormats.number,
        labelSuggestion: 'Quantas NFs de entrada sua empresa emite por mês?',
    },
    {
        name: 'NFs de saída',
        key: 'nfe_output',
        type: 'input',
        format: inputFormats.number,
        labelSuggestion: 'Quantas NFs de saída sua empresa emite por mês?',
    },
    {
        name: 'Qtdd de boletos',
        key: 'number_bills',
        type: 'input',
        format: inputFormats.number,
        labelSuggestion: 'Quantos boletos sua empresa emite por mês?',
    },
    {
        name: 'Qtdd de funcionários',
        key: 'n_staffs',
        type: 'input',
        format: inputFormats.number,
        labelSuggestion: 'Quantos funcionários sua empresa possui?',
    },
    {
        name: 'Qtdd de sócios',
        key: 'n_partners',
        type: 'input',
        format: inputFormats.number,
        labelSuggestion: 'Quantos sócios sua empresa possui?',
    },
    {
        name: 'Sistema fiscal',
        key: 'tax_software',
        type: 'input',
        format: inputFormats.select,
        labelSuggestion: 'Qual sistema fiscal sua empresa utiliza?',
        options: [
            {
                name: 'SAT',
                value: 0,
            },
            {
                name: 'ECF',
                value: 1,
            },
        ],
    }
];
