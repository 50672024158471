import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Input, Select, Divider, Switch, Radio, Progress, Button } from 'antd';
import InputCurrency, { currency2Float } from '~/components/UI/InputCurrency';
import SelectWithAdd from '~/components/UI/SelectWithAdd';
import { InstallmentsTable } from '../Tables/InstallmentsTable';
import { PERMISSIONS } from '~/constants/permissions.constants';

import AssignTo from '~/components/AssignTo';

const { Option, OptGroup } = Select;

const FormChange = (props) => {
  const [defaultState, setDefaultState] = useState({
    id: null,
    customer_id: null,
    description: null,
    amount: 0.00,
    create_payments: false,
    paid_off: false,
    pendency: null,
    assigned_to: null,
  });

  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
  const { customers, user, data, category } = props;

  const isRequired = category === 2 ? true : false;

  useEffect(() => {
    setDefaultState((prevState) => ({ ...prevState, ...data }));
  }, [data]);

  const getInitialCustomer = (data) => {
    if (!data.deleted_customer) {
      return data.customer_id;
    }
    return `${data.deleted_customer.cod} - ${data.deleted_customer.name}`;
  };

  return (
    <Form>
      <Row gutter={16} className="mb-30">
        {(category === 2 && (defaultState.customer_id === null || defaultState.id === null)) && (
          <Col span={24}>
            <Form.Item label="Empresa">
              {getFieldDecorator('customer_id', defaultState.customer_id ? {
                initialValue: getInitialCustomer(data),
                rules: [{ required: isRequired, message: 'Por favor selecione uma opção!' }],
              } : {
                rules: [{ required: isRequired, message: 'Por favor selecione uma opção!' }],
              })(
                <SelectWithAdd
                  placeholder="Selecione uma empresa"
                  disabled={!!defaultState.id}
                  onChange={val => setDefaultState({ ...defaultState, customer_id: val })}
                  setForm={(val) => setFieldsValue({ customer_id: val })}
                >
                  <OptGroup label="Clientes">
                    {customers.filter(row => row.is_customer).map(row =>
                      <Option value={row.id} key={row.id}>{row.cod ? `${row.cod} - ${row.name}` : row.name}</Option>
                    )}
                  </OptGroup>
                  <OptGroup label="Não Clientes">
                    {customers.filter(row => !row.is_customer).map(row =>
                      <Option value={row.id} key={row.id}>{row.cod ? `${row.cod} - ${row.name}` : row.name}</Option>
                    )}
                  </OptGroup>
                </SelectWithAdd>
              )}
            </Form.Item>
          </Col>
        )}
        <Col span={24}>
          <Form.Item label="Solicitação da diretoria">
            {getFieldDecorator('description', {
              initialValue: defaultState.description,
              rules: [{ required: isRequired, message: 'Campo obrigatório' }],
            })(
              <Input.TextArea rows={4} placeholder="Descreva em detalhes" disabled={(defaultState.id != null && data.created_by !== user.id) && data.description !== null} />
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Pendência">
            {getFieldDecorator('pendency', {
              initialValue: defaultState.pendency,
              rules: [{ required: true, message: 'Selecione quem é responsavél pela pendência' }],
            })(
              <Radio.Group onChange={(e) => setDefaultState({ ...defaultState, pendency: e.target.value })}>
                <Radio.Button value={0} size="small">Escritório</Radio.Button>
                <Radio.Button value={1} size="small">Orgão Público</Radio.Button>
                <Radio.Button value={2} size="small">Cliente</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
            <AssignTo form={props.form} initialValue={defaultState.assigned_to} />
        </Col>
        <Col span={24}>
          <Form.Item label="Resposta do executor">
            {getFieldDecorator('answer', {
              initialValue: defaultState.answer,
            })(
              <Input.TextArea rows={4} placeholder="Descreva detalhes adicionais" />
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FormChange;