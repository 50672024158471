export const loadDashTasks = ({ ...params }) => ({
  type: 'LOAD_DASHTASKS',
  payload: {
    request: {
      method: 'GET',
      url: '/dashtasks',
      ...params
    }
  }
})

export const registerBill = (data) => ({
  type: 'REGISTER_BILL',
  payload: {
    request:{
      method: 'post',
      url:'/extras',
      data: data
    }
  }
});

export const saveBill = (id, data) => ({
  type: 'SAVE_BILL',
  payload: {
    request:{
      method: 'post',
      url:`/extras/${id}`,
      data: data
    }
  }
});