import React from 'react';
import moment from 'moment';

import { toBRL } from '~/components/UI/InputCurrency';

export const tableColumns = {
    getColumns: function() {
        return [
            {
                title: 'Empresa',
                dataIndex: 'customer_name',
                key: 'customer_name',
                width: 400,
                ellipsis: true,
                render: (text, record) => <>{record.customer_cod} - {record.customer_name}</>,
                sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
            },
            {
                title: 'Inadimplente desde',
                dataIndex: 'since_at',
                key: 'since_at',
                width: 150,
                ellipsis: true,
                render: (text, record) => moment(record.since_at).format('DD/MM/YYYY'),
                align: 'center',
                sorter: (a, b) => a.since_at.localeCompare(b.since_at),
                
            },
            {
                title: 'Qtdd de parcelas',
                dataIndex: 'installments',
                key: 'installments',
                width: 150,
                ellipsis: true,
                align: 'center',
                sorter: (a, b) => a.installments - b.installments,
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
                width: 150,
                ellipsis: true,
                render: (text, record) => 'R$' + toBRL(parseFloat(record.total)),
                align: 'center',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.total - b.total,
            },
        ];
    }
}