export const loadChecklists = ({ ...params }) => ({
  type: "LOAD_CHECKLISTS",
  payload: {
    request: {
      method: "get",
      url: "/checklists",
      ...params,
    },
  },
});

export const createChecklist = (data) => ({
  type: "CREATE_CHECKLIST",
  payload: {
    request: {
      method: "post",
      url: "/checklist",
      data,
    },
  },
});

export const updateChecklist = (data, id) => ({
  type: "UPDATE_CHECKLIST",
  payload: {
    request: {
      method: "put",
      url: `/checklist/${id}`,
      data,
    },
  },
});

export const deleteChecklist = (id) => ({
  type: "DELETE_CHECKLIST",
  payload: {
    request: {
      method: "delete",
      url: `/checklist/${id}`,
    },
  },
  id
});

export const loadChecklistTasks = () => ({
  type: "LOAD_CHECKLIST_TASKS",
  payload: {
    request: {
      method: "GET",
      url: "/checklists/tasks",
    },
  },
});

export const saveChecklistTasks = (tasks) => ({
  type: "SAVE_CHECKLIST_TASKS",
  payload: {
    request: {
      method: "POST",
      url: "/checklists/tasks",
      data: {
        tasks
      }
    },
  },
});