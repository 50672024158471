import { Col, Row } from "antd";
import React, { useState } from "react";
import iziToast from "izitoast";
import Axios from "axios";
import { Button, TextField } from "@material-ui/core";

import { expiryDateMask } from "~/helpers/masks";
import { Plans } from "../Plans";
import { DEFAULT_MESSAGES } from "~/constants/default-messages.constants";
import { CouponIcon, CouponLink, CouponTag } from "./styles";
import { ButtonsWrapper, Container } from "./styles";
import { MaterialUIFormControl } from "~/components/UI/Material/FormControl";

export const PaymentStep = ({ formState, setFormState, setStep }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [isCouponVisible, setIsCouponVisible] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [formErrorState, setFormErrorState] = useState({
    postal_code: null,
    address_number: null,
    security_code: null,
    card_number: null,
    identification_number: null,
    expiry_date: null,
    coupon: null,
  });

  const hasFormError = Object.values(formErrorState).some((value) => !!value);
  const isMobileScreen = window.screen.width < 640;

  const {
    plan,
    postal_code,
    address_number,
    identification_number,
    card_number,
    expiry_date,
    security_code,
    card_name,
  } = formState;

  const isSubmitButtonDisabled =
    !plan ||
    !postal_code ||
    !address_number ||
    !identification_number ||
    !card_number ||
    !expiry_date ||
    !security_code ||
    !card_name ||
    hasFormError;

  function notify(message) {
    iziToast.error({
      title: "Ops,",
      message,
      titleColor: "#fff",
      messageColor: "#fff",
      backgroundColor: "#FF7273",
      progressBarColor: "#444F60",
      position: "topCenter",
      transitionIn: "fadeInDown",
      close: false,
      zindex: 99999,
      timeout: 15000,
    });
  }

  async function handleApplyCoupon() {
    setIsCouponLoading(true);
    try {
      const { data } = await Axios.get(
        `${process.env.REACT_APP_API}/auth/coupon?code=${coupon}`
      );
      if (data.coupon) {
        setFormState((state) => ({
          ...state,
          coupon: data.coupon,
        }));
        setCoupon("");
      } else {
        setFormErrorState((state) => ({
          ...state,
          coupon: "Cupom inválido",
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsCouponLoading(false);
    }
  }

  async function handleSubmit() {
    setIsLoading(true);

    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API}/auth/signup`,
        formState
      );
      setIsLoading(false);

      const { apiToken } = data;
      const { id } = formState;

      window.location.assign(
        `http://${id}.${process.env.REACT_APP_DOMAIN}?token=${apiToken}`
      );
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          notify(error.response.data.error[0].description);
        } else if (error.response.status === 422) {
          notify(Object.values(error.response.data.errors)[0][0]);
        } else if (
          error.response.status === 404 ||
          error.response.status >= 500
        ) {
          notify(DEFAULT_MESSAGES["high-demand"]);
        }
      } else {
        notify(
          "Não foi possível realizar o cadastro. Tente novamente mais tarde."
        );
      }
      setIsLoading(false);
    }
  }

  function updateFormState(event) {
    event.persist();
    const { value, name } = event.target;

    switch (name) {
      case "postal_code":
        validatePostalCode(value);
        break;
      case "address_number":
        validateAddressNumber(value);
        break;
      case "card_number":
        validateCardNumber(value);
        break;
      case "security_code":
        validateSecurityCode(value);
        break;
      case "identification_number":
        validateIdentificationNumber(value);
        break;
      case "expiry_date":
        validateExpiryDate(value);
        setFormState((state) => ({
          ...state,
          expiry_date: expiryDateMask(value),
        }));
        return;

      default:
        break;
    }

    setFormState((state) => ({
      ...state,
      [name]: value,
    }));
  }

  function handleGoBack() {
    setStep(0);
  }

  function validateCardNumber(value) {
    setFormErrorState((state) => ({
      ...state,
      card_number: Number.isNaN(Number(value))
        ? "Por favor, informe um número de cartão válido"
        : null,
    }));
  }

  function validateSecurityCode(value) {
    setFormErrorState((state) => ({
      ...state,
      security_code: Number.isNaN(Number(value))
        ? "Por favor, informe um código de segurança válido"
        : null,
    }));
  }

  function validateIdentificationNumber(value) {
    setFormErrorState((state) => ({
      ...state,
      identification_number: /[a-zA-Z]/g.test(value)
        ? "Por favor, informe um CPF ou CNPJ válido"
        : null,
    }));
  }

  function validateExpiryDate(value) {
    setFormErrorState((state) => ({
      ...state,
      expiry_date:
        value.length !== 5 ? "Por favor, informe uma data válida" : null,
    }));
  }

  function validatePostalCode(value) {
    setFormErrorState((state) => ({
      ...state,
      postal_code: Number.isNaN(Number(value))
        ? "Por favor, informe um CEP válido"
        : null,
    }));
  }

  function validateAddressNumber(value) {
    setFormErrorState((state) => ({
      ...state,
      address_number: Number.isNaN(Number(value))
        ? "Por favor, informe um número válido"
        : null,
    }));
  }

  function onChangeCoupon({ target }) {
    setCoupon(target.value.toUpperCase().replace(" ", ""));
    if (formErrorState.coupon) {
      setFormErrorState((state) => ({
        ...state,
        coupon: null,
      }));
    }
  }

  function onRemoveCoupon() {
    setFormState((state) => ({
      ...state,
      coupon: null,
    }));
  }

  return (
    <Container>
      <Plans formState={formState} setFormState={setFormState} />
      <Row gutter={16}>
        <Col span={isMobileScreen ? 24 : 12}>
          <MaterialUIFormControl>
            <TextField
              label="Número do cartão"
              name="card_number"
              onChange={updateFormState}
              value={formState.card_number}
            />
          </MaterialUIFormControl>
        </Col>
        <Col span={isMobileScreen ? 24 : 12}>
          <MaterialUIFormControl>
            <TextField
              label="Validade (MM/AA)"
              name="expiry_date"
              onChange={updateFormState}
              value={formState.expiry_date}
              error={!!formErrorState.expiry_date}
              helperText={formErrorState.expiry_date}
            />
          </MaterialUIFormControl>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={isMobileScreen ? 24 : 12}>
          <MaterialUIFormControl>
            <TextField
              label="Nome impresso no cartão"
              name="card_name"
              onChange={updateFormState}
              value={formState.card_name}
            />
          </MaterialUIFormControl>
        </Col>
        <Col span={isMobileScreen ? 24 : 12}>
          <MaterialUIFormControl>
            <TextField
              label="Código de segurança"
              name="security_code"
              onChange={updateFormState}
              value={formState.security_code}
              error={!!formErrorState.security_code}
              helperText={formErrorState.security_code}
              inputProps={{ maxLength: 4 }}
            />
          </MaterialUIFormControl>
        </Col>
      </Row>
      <MaterialUIFormControl>
        <TextField
          label="CPF / CNPJ"
          name="identification_number"
          onChange={updateFormState}
          value={formState.identification_number}
        />
      </MaterialUIFormControl>
      <Row gutter={16}>
        <Col span={isMobileScreen ? 24 : 12}>
          <MaterialUIFormControl>
            <TextField
              label="CEP"
              name="postal_code"
              onChange={updateFormState}
              value={formState.postal_code}
            />
          </MaterialUIFormControl>
        </Col>
        <Col span={isMobileScreen ? 24 : 12}>
          <MaterialUIFormControl>
            <TextField
              label="Número"
              name="address_number"
              onChange={updateFormState}
              value={formState.address_number}
            />
          </MaterialUIFormControl>
        </Col>
      </Row>
      {isCouponVisible ? (
        <Row gutter={16}>
          {formState.coupon ? (
            <CouponTag closable onClose={onRemoveCoupon}>
              <CouponIcon />
              {formState.coupon.code}
            </CouponTag>
          ) : (
            <>
              <Col span={6}>
                <MaterialUIFormControl>
                  <TextField
                    label="Cupom de desconto"
                    name="coupon"
                    onChange={onChangeCoupon}
                    value={coupon}
                    error={!!formErrorState.coupon}
                    helperText={formErrorState.coupon}
                  />
                </MaterialUIFormControl>
              </Col>
              <Col span={6}>
                <Button
                  variant="contained"
                  // size="large"
                  color="primary"
                  fullWidth
                  onClick={handleApplyCoupon}
                  disabled={!coupon || isCouponLoading}
                >
                  {isCouponLoading ? "Aplicando..." : "Aplicar"}
                </Button>
              </Col>
            </>
          )}
        </Row>
      ) : (
        <CouponLink
          className="no-account is-secondary"
          onClick={() => setIsCouponVisible(true)}
        >
          Possui um cupom de desconto?
        </CouponLink>
      )}

      <ButtonsWrapper>
        <Button
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          disabled={isSubmitButtonDisabled || isLoading}
          onClick={handleSubmit}
        >
          {isLoading ? "Estamos processando seu pagamento..." : "Cadastrar-se"}
        </Button>
        <Button
          onClick={handleGoBack}
          variant="outlined"
          size="large"
          color="primary"
          fullWidth
        >
          Voltar
        </Button>
      </ButtonsWrapper>
    </Container>
  );
};
