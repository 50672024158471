import React from 'react';
import styled from 'styled-components';

export const LeadCard = styled.div`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 15px 15px 15px 45px
    width: 100%;
    position: relative;
    font-size: 1.2em;
    transition: box-shadow 0.3s;
    font-weight: 500;

    &:hover {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }
`;

export const LeadCardId = styled.div`
    color: #999;
    font-size: 12px;
    position: absolute;
    right: 10px;
    top: 10px;
`;

export const LeadSource = styled.div`
    color: #999;
    font-size: 16px;
    position: absolute;
    top: 15px;
    left: 20px;
    color: #999;
`;