
export const msgs = [
    {
        image: '$logo$',
        from: 'bot'
    },
    {
        text: '$description$',
        from: 'bot'
    },
    { 
        text: 'Vamos começar? Digite o <strong>nome da empresa</strong> que deseja indicar.',
        from: 'bot'
    },
    { 
        input: 'text',
        field_name: 'company_name',
        from: 'user'
    },
    { 
        text: 'Qual a <strong>atividade</strong> dessa empresa?',
        from: 'bot'
    },
    { 
        input: 'text',
        field_name: 'company_activity',
        from: 'user'
    },
    { 
        text: 'Qual o <strong>nome do sócio ou contato</strong> que deveremos entrar em contato?',
        from: 'bot'
    },
    { 
        input: 'text',
        field_name: 'company_contact',
        from: 'user'
    },
    { 
        text: 'Qual o <strong>whatsapp ou telefone</strong> dessa pessoa?',
        from: 'bot'
    },
    { 
        input: 'phone',
        field_name: 'company_contact_phone',
        from: 'user'
    },
    { 
        text: 'Muito bem! Agora precisamos de algumas informações suas para que possamos <strong>entrar em contato mais tarde para fazer o comissionamento</strong>.',
        from: 'bot'
    },
    { 
        text: 'Qual seu <strong>nome</strong>?',
        from: 'bot'
    },
    { 
        input: 'text' ,
        field_name: 'indicated_by',
        from: 'user'
    },
    { 
        text: 'Qual seu <strong>Whatsapp?</strong>',
        from: 'bot'
    },
    { 
        input: 'phone',
        field_name: 'indicated_by_phone',
        from: 'user'
    },
    {
        text: '<strong>Obrigado por sua indicação!</strong> Entraremos em contato com a empresa e com você para informar sobre o andamento da negociação. Caso a empresa se torne cliente, entraremos em contato para realizar o pagamento do comissionamento. Obrigado!',
        from: 'bot'
    },
];