import { Input, Modal } from 'antd';
import React from 'react';
import { useState } from 'react';

import {
  ArchiveModalContainer,
  ArchiveModalFormItem,
  ArchiveModalSubTitle,
} from '../styles';

export const ArchiveDocumentModal = ({
  isOpen,
  closeModal,
  archiveDocument,
  id,
}) => {
  const [reasonToArchive, setReasonToArchive] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  async function handleArchive() {
    setIsLoading(true);
    try {
      await archiveDocument(id, { reason_to_archive: reasonToArchive });
      setReasonToArchive(null);
      closeModal();
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      title="Você realmente deseja arquivar este documento?"
      visible={isOpen}
      onCancel={closeModal}
      okText="Arquivar"
      okType="danger"
      cancelText="Cancelar"
      okButtonProps={{ loading: isLoading }}
      onOk={handleArchive}
    >
      <ArchiveModalContainer>
        <ArchiveModalSubTitle>
          Esta ação não poderá ser desfeita.
        </ArchiveModalSubTitle>
        <ArchiveModalFormItem label="Qual o motivo? (opcional)" colon={false}>
          <Input.TextArea
            placeholder="Descreva o motivo"
            onChange={(event) => {
              setReasonToArchive(event.target.value);
            }}
            value={reasonToArchive}
          />
        </ArchiveModalFormItem>
      </ArchiveModalContainer>
    </Modal>
  );
};
