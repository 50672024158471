import { Button, Modal, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getFormattedEvent } from '../helpers';
import { ResendEmailModal } from './ResendEmailModal';
import * as documentsActions from '../actions';
import { EmailBodyPreviewModal } from '~/containers/EmailsLogs/components/EmailBodyPreviewModal';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;

export const EmailLogsModal = ({ isOpen, closeModal, documentId }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const [resendEmailModal, setResendEmailModal] = useState({
    isOpen: false,
    data: null,
  });
  const [emailBodyPreviewModal, setEmailBodyPreviewModal] = useState({
    isOpen: false,
    data: null,
    initialLogId: [],
  });

  useEffect(() => {
    if (documentId) {
      void loadLogs();
    }
  }, [documentId]);

  async function loadLogs() {
    try {
      setIsLoading(true);
      const {
        payload: { data: logsFromApi },
      } = await loadLogsRequest(documentId);
      const formattedData = logsFromApi.map((item) => ({
        ...item,
        formatted_sent_at: moment(item.sent_at).format('DD/MM/YYYY HH:mm'),
        formatted_event: getFormattedEvent(item.event),
      }));
      setLogs(formattedData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function loadLogsRequest(id) {
    return dispatch(documentsActions.loadDocumentLogs(id));
  }

  function closeResendEmailModal() {
    setResendEmailModal({
      isOpen: false,
      data: null,
    });
  }

  function openResendEmailModal(data = null) {
    setResendEmailModal({
      isOpen: true,
      data,
    });
  }

  const openEmailBodyPreviewModal = ({ initialLogId, data }) => {
    setEmailBodyPreviewModal({
      isOpen: true,
      data,
      initialLogId,
    });
  };

  const closeEmailBodyPreviewModal = () => {
    setEmailBodyPreviewModal({
      isOpen: false,
      data: null,
      initialLogId: null,
    });
  };

  return (
    <Modal
      title="Logs"
      visible={isOpen}
      onCancel={closeModal}
      footer={false}
      width={800}
    >
      <Table
        bordered
        dataSource={logs}
        loading={isLoading}
        rowKey="id"
        pagination={getDefaultPaginationConfig({
          storageKey: STORAGE.defaultPageSize.documentsEmailsLogs
        })}
      >
        <Column
          title="Tipo"
          align="center"
          dataIndex="formatted_event"
          key="formatted_event"
          render={(formatted_event) =>
            formatted_event && (
              <Tag color={formatted_event.color}>{formatted_event.text}</Tag>
            )
          }
        />
        <Column title="Enviado para" dataIndex="email" key="email" />
        <Column
          title="Data"
          dataIndex="formatted_sent_at"
          key="formatted_sent_at"
        />
        <Column
          key="actions"
          width={100}
          align="center"
          dataIndex="email"
          render={(emailTo) => (
            <Button
              type="primary"
              icon="mail"
              onClick={() => openResendEmailModal({ emailTo, documentId })}
            >
              Reenviar
            </Button>
          )}
        />
        <Column
          title="Prévia"
          key="preview"
          width={80}
          align="center"
          dataIndex="id"
          render={(id, record) => {
            if (record.event === 'pendent') {
              const deliveryLogs = logs
                .filter(({ event }) => event === 'delivered')
                .sort((a, b) => moment(a.sent_at).diff(b.sent_at));

              if (deliveryLogs.length > 0) {
                const latestDeliveryLog = deliveryLogs[0];
                return (
                  <Button
                    icon="eye"
                    onClick={() =>
                      openEmailBodyPreviewModal({
                        initialLogId: id,
                        data: {
                          id,
                          ...latestDeliveryLog,
                        },
                      })
                    }
                  />
                );
              }
            }

            return null;
          }}
        />
      </Table>
      <ResendEmailModal
        isOpen={resendEmailModal.isOpen}
        closeModal={closeResendEmailModal}
        data={resendEmailModal.data}
        loadLogs={loadLogs}
      />
      <EmailBodyPreviewModal
        isOpen={emailBodyPreviewModal.isOpen}
        onClose={closeEmailBodyPreviewModal}
        initialLogId={emailBodyPreviewModal.initialLogId}
        data={emailBodyPreviewModal.data}
      />
    </Modal>
  );
};
