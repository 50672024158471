import { VIDEOS } from "~/constants/videos.constants";

export const steps = [
  {
    step: 1,
    title: "Convidar usuários (funcionários)",
    video: VIDEOS["gerenciar-usuario"],
    plans: ["starter", "premium"],
  },
  {
    step: 2,
    title: "Importar clientes",
    video: VIDEOS.clientes,
    plans: ["starter", "premium"],
  },
  {
    step: 3,
    title: "Cadastrar tarefas",
    video: VIDEOS["cadastro-de-tarefas"],
    plans: ["starter", "premium"],
  },
  {
    step: 4,
    title: "Adicionar clientes em uma tarefa",
    plans: ["starter", "premium"],
    dependsOf: 3,
  },
  {
    step: 5,
    title: "Marcar uma tarefa como executada",
    dependsOf: 4,
    plans: ["starter", "premium"],
    video: VIDEOS.tarefas,
  },
  {
    step: 6,
    title: "Instalar a extensão do Contlabs",
    plans: ["premium"],
  },
  {
    step: 7,
    title: "Criar um lead",
    video: VIDEOS.leads,
    plans: ["premium"],
  },
  {
    step: 8,
    title: "Mover um lead para concluido",
    plans: ["premium"],
    dependsOf: 7,
  },
  {
    step: 9,
    title: "Cadastrar tarefa no societário",
    video: VIDEOS["departamento-societario-painel"],
    plans: ["premium"],
  },
  {
    step: 10,
    title: "Criar honorário de um cliente",
    plans: ["starter", "premium"],
    dependsOf: 2,
  },
  {
    step: 11,
    title: "Gerar mensalidades",
    plans: ["starter", "premium"],
    dependsOf: 10,
    video: VIDEOS.mensalidades,
  },
  {
    step: 12,
    title: "Marcar honorário como pago",
    plans: ["starter", "premium"],
    dependsOf: 11,
  },
];
