import { Tag } from "antd";
import styled from "styled-components";

export const HideLeadsContainer = styled.div`
  border: 1px solid #e8e8e8;
  padding: 8px;
  background-color: #f5f5f5;
  align-self: baseline;
  width: 120px;
`;

export const HideLeadsTitle = styled.h2`
  color: #000000a6;
`;

export const HideFormLeadsButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px;
  border: none;
`;

export const HideFormLeadsTag = styled(Tag).attrs({
  color: "red",
  style: {
    cursor: "pointer",
  },
})``;

export const HideFormLeadsCount = styled.span`
  color: #000000a6;
  font-size: 12px;
`;