const DEFAULT_STATE = {
  list: [],
  isLoading: true
}

const allDepartmentsCalendarTasks = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'LOAD_ALL_DEPARTMENTS_CALENDAR_TASKS': {
          if (!state.isLoading) {
            return {
              ...state,
              isLoading: true
            }
          }

          return state;
        }

        case 'LOAD_ALL_DEPARTMENTS_CALENDAR_TASKS_SUCCESS':
          const tasks = action.payload.data;

          return {
            list: tasks,
            isLoading: false
          }

        case 'CONTEXT_CALENDAR_CARDS_TASKS_UPDATE_FROM_LIST':
          const executedListTasks = action.payload.customers.filter((customer) => customer.executed_at).length;
          const taskId = action.payload.taskId;

          const updatedListFromList = state.list.map((currentTask) => currentTask.id !== taskId ? currentTask : {
            ...currentTask,
            executed_tasks_count: executedListTasks,
            pendent_tasks_count: currentTask.tasks_count - executedListTasks
          });

          return {
            list: updatedListFromList,
          }

        case 'CONTEXT_CALENDAR_CARDS_TASKS_UPDATE_FROM_GRID':
          const { customers, currentMonth } = action.payload;

          const executedGridTasks = customers
            .map((customer) => customer.months[currentMonth])
            .filter((customer) => customer.executed_at)
            .length;

          const priorityTasksCount = customers
            .map(customer => customer.months[currentMonth])
            .filter((customer) => customer.priority)
            .length;

          const updatedListFromGrid = state.list.map((currentTask) => currentTask.id !== action.payload.taskId ? currentTask : {
            ...currentTask,
            executed_tasks_count: executedGridTasks,
            pendent_tasks_count: currentTask.tasks_count - executedGridTasks,
            priority_tasks_count: priorityTasksCount
          });

          return {
            list: updatedListFromGrid,
          }

        case 'CLEAR_ALL_DEPARTMENTS_CALENDAR_STATE':
          return DEFAULT_STATE;

        case 'CREATE_TASK_SUCCESS': {
          const createdTask = action.payload.data.data;
          return {
            ...state,
            list: [
              ...state.list,
              {
                ...createdTask,
                tasks_count: 0,
                executed_tasks_count: 0,
                pendent_tasks_count: 0,
                priority_tasks_count: 0,
              }
            ]
          }
        }

        case 'SAVE_TASK_SUCCESS': {
          const updatedTask = action.payload.data.data;

          return {
            ...state,
            list: state.list.map((item) => {
              if (item.id !== updatedTask.id) {
                return item;
              }

              return {
                ...item,
                ...updatedTask
              }
            })
          }
        }

        case 'DELETE_TASK_SUCCESS': {
          return {
            ...state,
            list: state.list.filter((item) => {
              if (item.id !== action.meta.previousAction.id) {
                return true;
              }

              return false;
            })
          }
        }
      
        default:
        return state
    }
}

export default allDepartmentsCalendarTasks;