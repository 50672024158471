import React, { useEffect } from 'react';
import { Col, Form, Input, Modal, Row, Switch } from 'antd';
import uid from 'uid';
import moment from 'moment';

import { formOptions } from './form-options';
import InputMask from '~/components/UI/InputMask';
import { StyledDatePicker, StyledInputNumber } from './styles';

export const PartnerModal = Form.create()(
  ({ isVisible, onClose, partnerId, partners, setPartners, form, isViwing }) => {
    const {
      getFieldDecorator,
      resetFields,
      validateFields,
      setFieldsValue,
    } = form;

    const selectedPartner = partners.find(({ id }) => id === partnerId);
    const currentTotalPercentage = partners.reduce(
      (total, { percentage }) => parseFloat(total) + parseFloat(percentage),
      0
    );
    const remainingPercentage = selectedPartner
      ? 100 - (parseFloat(currentTotalPercentage) - parseFloat(selectedPartner.percentage))
      : 100 - parseFloat(currentTotalPercentage);

    useEffect(() => {
      if (selectedPartner) {
        const {
          name,
          identification_number,
          birthdate,
          phone,
          percentage,
        } = selectedPartner;
        setFieldsValue({
          name,
          identification_number,
          birthdate: birthdate ? moment(birthdate) : undefined,
          phone,
          percentage,
          is_legal_representative: selectedPartner.is_legal_representative || false,
        });
      }
    }, [selectedPartner]);

    function handleSubmit() {
      validateFields((error, values) => {
        if (error) {
          return;
        }

        const birthdate = values.birthdate
          ? values.birthdate.format('YYYY-MM-DD')
          : undefined;

        if (selectedPartner) {
          const updatedPartner = {
            ...selectedPartner,
            ...values,
            birthdate,
          };

          setPartners((state) => {
            return state.map((partner) => {
              if (partner.id !== updatedPartner.id) {
                return partner;
              }

              return updatedPartner;
            });
          });
        } else {
          const newPartner = {
            id: uid(10),
            ...values,
            birthdate,
          };

          setPartners((state) => [...state, newPartner]);
        }
        closeModal();
      });
    }

    function closeModal() {
      resetFields();
      onClose();
    }

    return (
      <Modal
        title={isViwing ? 'Editar sócio' : 'Novo sócio'}
        visible={isVisible}
        destroyOnClose
        onCancel={closeModal}
        okText="Salvar"
        cancelText="Voltar"
        onOk={handleSubmit}
      >
        <Form layout="vertical">
          <Form.Item label="Nome completo">
            {getFieldDecorator(
              'name',
              formOptions.name
            )(<Input 
                placeholder="Informe o nome completo" 
                // disabled={isViwing}
              />)}
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="CPF">
                {getFieldDecorator('identification_number')(
                  <InputMask
                    mask="999.999.999-99"
                    placeholder="Informe o CPF"
                    // disabled={isViwing}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Data de nascimento">
                {getFieldDecorator('birthdate')(
                  <StyledDatePicker
                    disabledDate={(current) => current > moment()}
                    format="DD/MM/YYYY"
                    // disabled={isViwing}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Whatsapp">
                {getFieldDecorator('phone')(
                  <InputMask
                    mask="(99) 9999tt999?"
                    formatChars={{ '9': '[0-9]', t: '[0-9-]', '?': '[0-9 ]' }}
                    maskChar={null}
                    placeholder="Informe o Whatsapp"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Percentual"
                help={
                  currentTotalPercentage > 0
                    ? `Percentual total atual: ${currentTotalPercentage}%`
                    : undefined
                }
              >
                {getFieldDecorator(
                  'percentage',
                  formOptions.percentage
                )(
                  <StyledInputNumber
                    min={0}
                    max={remainingPercentage}
                    placeholder="Informe o percentual"
                    // disabled={isViwing}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Responsável legal">
              {getFieldDecorator('is_legal_representative', {
                valuePropName: 'checked',
                initialValue: false,
              })(<Switch />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
