import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, Select, Radio } from 'antd';
import { compare } from 'natural-orderby';
import moment from 'moment';

import InputMask from '../UI/InputMask';
import { DatePicker } from '../UI/DatePicker/Locale';
// import InputCurrency from '../UI/InputCurrency';

const { Option } = Select;

class ProcurationsForm extends React.Component {
    state = {
        default: {
            id: null,
            customer_id: null,
            owner_name: null,
            nature: null,
            type: null,
            phone: null,
            emission_date: null,
            due_date: null,
            password:null
        },
        activeForm:{}
    };

    onCreate = () => {
        const { form } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            if(values.owner_name === null || values.owner_name === ''){
                values = {
                    ...values,
                    owner_name: this.props.customers.filter(row => row.id==values.customer_id)[0].name
                }
            }

            this.props.registerProcuration(values)
            .then(response => {
                form.resetFields();
                context.props.onClose();
                context.setState({activeForm: {}});
            });
        });
        
    }

    onSave = () => {
        const { form } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            if(values.owner_name === null || values.owner_name === ''){
                values = {
                    ...values,
                    owner_name: this.props.customers.filter(row => row.id==values.customer_id)[0].name
                }
            }

            this.props.saveProcuration(this.props.data.id, values)
            .then(response => {
                form.resetFields();
                context.props.onClose();
                context.setState({activeForm: {}});
            });
        });
        
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { customers } = this.props;

        const data = {
            ...this.state.default,
            ...this.props.data,
            ...this.state.activeForm
        }

        return (
        <div>
            <Drawer
                title={(data.id)? 'Editar lembrete': 'Cadastrar lembrete'}
                width={600}
                placement="right"
                onClose={() => {
                    this.props.form.resetFields();
                    this.props.onClose();
                    this.setState({activeForm: {}});
                }}
                maskClosable={false}
                visible={!!this.props.visible}
                destroyOnClose={true}
                style={{
                    height: '100%',
                    overflow: 'auto',
                    paddingBottom: 53,
                }}
            >
            <Form layout="vertical">
                <Row gutter={16}>
                    <Col span={12}>
                    <Form.Item label="Natureza">
                        {getFieldDecorator('nature', {
                                initialValue: data.nature,
                                rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                            })(
                            <Radio.Group onChange={(e) => this.setState({activeForm: {...data, nature: e.target.value}})}>
                                <Radio.Button value="PF">Pessoa Física</Radio.Button>
                                <Radio.Button value="PJ">Pessoa Jurídica</Radio.Button>
                            </Radio.Group>
                        )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Tipo de lembrete">
                            {getFieldDecorator('type', {
                                initialValue: data.type,
                                rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                            })(
                                <Select
                                    showSearch
                                    placeholder="Selecione uma opção"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    <Option value="Anuidade CRM">Anuidade CRM</Option>
                                    <Option value="Anuidade CREA">Anuidade CREA</Option>
                                    <Option value="Anuidade OAB">Anuidade OAB</Option>
                                    <Option value="Anuidade CRO">Anuidade CRO</Option>
                                    <Option value="CAU">CAU</Option>
                                    <Option value="CREA">CREA</Option>
                                    <Option value="CREFITO">CREFITO</Option>
                                    <Option value="CREMESP">CREMESP</Option>
                                    <Option value="CRC">CRC</Option>
                                    <Option value="CRO">CRO</Option>
                                    <Option value="CRM OUTRO ESTADO">CRM OUTRO ESTADO</Option>
                                    <Option value="Procuração PF">Procuração PF</Option>
                                    <Option value="Procuração PJ">Procuração PJ</Option>
                                    <Option value="OAB">OAB</Option>
                                    <Option value="Orgãos de Classe">Orgãos de Classe</Option>
                                    <Option value="Orgãos em geral">Orgãos em geral</Option>
                                    <Option value="TFE">TFE</Option>
                                    <Option value="TFA">TFA</Option>
                                    <Option value="TRSS">TRSS</Option>
                            </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Empresa">
                            {getFieldDecorator('customer_id', {
                                initialValue: data.id? data.customer_id==null?'not_have':data.customer_id:null,
                                rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                            })(
                                <Select
                                    showSearch
                                    placeholder="Selecione uma empresa"
                                    optionFilterProp="children"
                                    disabled={(data.id!==null)}
                                    onChange={(val) => this.setState({default: {...this.state.default, customer_id: val}})}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                <Option value="not_have">Não possui ou não é cliente</Option>
                                {customers!==null && customers.map(row => 
                                    <Option value={row.id} key={row.id}>{`${row.cod} - ${row.name}`}</Option>
                                )}
                              </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Nome do Sócio">
                        {getFieldDecorator('owner_name', {
                            initialValue: data.owner_name,
                            rules: [{ required: (data.nature=='PF'), message: 'Por favor informe o nome do Sócio!' }],
                        })(<Input placeholder="Insira o nome do Sócio" />)}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Emissão">
                        {getFieldDecorator('emission_date', {
                            initialValue: data.id? moment(data.emission_date, 'YYYY-MM-DD'): null,
                            rules: [{ required: true, message: 'Por favor escolha uma data!' }],
                        })(
                            <DatePicker/>
                        )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Vencimento">
                        {getFieldDecorator('due_date', {
                            initialValue: data.id? moment(data.due_date, 'YYYY-MM-DD'): null,
                            rules: [{ required: true, message: 'Por favor escolha uma data!' }],
                        })(
                            <DatePicker/>
                        )}
                        </Form.Item>
                    </Col>
                    {(data.customer_id === 'not_have' || data.customer_id === null || data.phone !==null) && (
                    <Col span={8}>
                        <Form.Item label="Telefone/Celular">
                        {getFieldDecorator('phone', {
                            initialValue: data.phone_formated
                        })(<InputMask 
                                mask="(99) 9999tt999?" 
                                formatChars={{"9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]"}} 
                                maskChar={null}
                                placeholder="Insira o número de telefone ou celular" 
                            />)}
                        </Form.Item>
                    </Col>
                    )}
                    <Col span={8}>
                        <Form.Item label="Agendado para">
                            {getFieldDecorator('scheduled_at', {
                                initialValue: data.scheduled_at ? moment(data.scheduled_at, 'YYYY-MM-DD') : null
                            })(
                                <DatePicker />
                            )}
                        </Form.Item>
                    </Col>
                    {/* <Col span={8}>
                        <Form.Item label="Valor">
                            {getFieldDecorator('amount', {
                                initialValue: data.amount
                            })(
                                <InputCurrency />
                            )}
                        </Form.Item>
                    </Col> */}
                    <Col span={24}>
                        <Form.Item label="Senha Cadastrada">
                        {getFieldDecorator('password', {
                            initialValue: data.password,
                        })(<Input placeholder="Insira a senha cadastrada" />)}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div
                style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                }}
            >
                <Button
                style={{
                    marginRight: 8,
                }}
                onClick={this.props.onClose}
                >
                Cancelar
                </Button>
                {(data.id)?
                <Button onClick={this.onSave} type="primary">Salvar</Button>
                :
                <Button onClick={this.onCreate} type="primary">Cadastrar</Button>
                }
            </div>
            </Drawer>
        </div>
        );
    }
}


const mapDispatchProps = dispatch => {
    return{
        registerProcuration: (data) => dispatch({
        type: 'REGISTER_PROCURATION',
        payload: {
          request:{
            method: 'post',
            url:'/procurations',
            data: data
          }
        }
      }),
      saveProcuration: (id, data) => dispatch({
        type: 'SAVE_PROCURATION',
        payload: {
          request:{
            method: 'post',
            url:`/procurations/${id}`,
            data: data
          }
        }
      })
    }
}

const mapStateToProps = state => {
    return {
        customers: state.customers.list.filter(row => row.enabled === 1 && row.inactived_at === null && row.is_customer === true).sort((a,b) => compare()(a.cod, b.cod))
    }
}
  
export default connect(mapStateToProps, mapDispatchProps)(Form.create()(ProcurationsForm));