export const tasksPaths = {
  tarefas: '/tarefas',
  checklist: '/checklist',
  convencoes: '/convencoes',
  irpf: '/IRPF',
};

export const monthlyTasksPaths = {
  administrativo: '/calendario/2/administrativo',
  contabil: '/calendario/3/contabil',
  fiscal: '/calendario/4/fiscal',
  pessoal: '/calendario/5/pessoal',
  auditoria: '/calendario/6/auditoria',
};

export const financialPaths = {
  mensalidades: '/financeiro/mensalidades',
  extras: '/financeiro/extras',
  comissoes: '/financeiro/comissoes',
};

export const corporatePaths = {
  societario: '/societario',
  legalizacao: '/calendario/1/legalizacao',
  certificados: '/certificados',
  lembretes: '/lembretes',
};

export const fiscalMeshPaths = {
  federal: '/malha-fina/federal',
  estadual: '/malha-fina/estadual',
  municipal: '/malha-fina/municipal',
  fgts: '/malha-fina/FGTS',
  irpf: '/malha-fina/IRPF',
};

export const reportsPaths = {
  defaulters: '/relatorios/inadimplentes',
  prazos: '/relatorios/prazos',
  honoraries: '/relatorios/honorarios',
  emails: '/relatorios/emails',
  failedEmails: '/relatorios/emails/erros',
  feedbacks: '/relatorios/kpis',
}