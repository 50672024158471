import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Select, Divider, Icon } from 'antd';
import { request } from '../Instance';

class SelectWithAdd extends Component {

    state={
        search: '',
    }

    register = () => {
        request('post', '/customer', {
            name: this.state.search,
            enabled: 1,
            is_customer: 0
        }).then(response => {
            this.props.registerItem(response.data.data);
            this.props.setForm(response.data.data.id);
            this.setState({search: ''});
        });
    }

    render() {
        const { search } = this.state;

        return (
            <Select
                {...this.props}
                showSearch
                placeholder={this.props.placeholder}
                optionFilterProp="children"
                onChange={this.props.onChange}
                onSearch={(val) => this.setState({search: val})}
                dropdownRender={menu => (
                    <div>
                        {menu}
                        {search && (<div
                            onMouseDown={e => e.preventDefault()}
                            onClick={this.register}>
                            <Divider style={{ margin: '4px 0' }} />
                            <div style={{ padding: '8px', cursor: 'pointer' }}>
                                <Icon type="plus" /> Cadastrar <b>{search}</b>
                            </div>
                        </div>)}
                    </div>
                )}
            >
            {this.props.children}
            </Select>
        )
  }
}

const mapDispatchProps = dispatch => {
    return{
        registerItem: (data) => dispatch({
            type: 'REGISTER_CUSTOMER_SUCCESS',
            payload: {
                data: { data: data }
            }
        })
    }
}
  
export default connect(null, mapDispatchProps)(SelectWithAdd);