import { Button } from 'antd';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderLeftContent = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
  width: 100%;
`;

export const CellButtonsWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const CellButton = styled(Button).attrs({
  size: 'small',
  type: 'link'
})``;