export const loadConventions = ({ ...params }) => ({
  type: "LOAD_CONVENTIONS",
  payload: {
    request: {
      method: "GET",
      url: "/conventions",
      ...params,
    },
  },
});
