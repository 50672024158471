import { Card } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 20px;
`;

export const Header = styled.div`
  margin: 0 -16px;
  margin-bottom: 10px;
  padding: 15px;
  background-color: white;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 0;
`;

export const Main = styled.div``;

export const FeedbackCard = styled(Card)`
  width: 33%;

  .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 335px;
  }
`;