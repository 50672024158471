import { Icon, Progress, Tag } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Roboto';
  user-select: none;
  border-radius: 5px 5px 1px 1px;
  border-top: 1px solid #f5f5f5;
  border-left: 1px solid #f5f5f5;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: ${({ isConversationStarted, isOpportunityColumn, isRevised }) => {
    if (isRevised) return '#d9f7be';

    return isConversationStarted || !isOpportunityColumn ? '#fff' : '#ffffb8';
  }};
  cursor: pointer;
  margin-bottom: 8px;
`;

export const CalendarIcon = styled(Icon).attrs({
  type: 'calendar',
  style: {
    color: 'black',
  },
})``;

export const CompanyTypeAndNecessityContainer = styled.div`
  display: flex;
  margin-top: 4px;
  gap: 8px;
  flex-wrap: wrap;
`;

export const CompanyTypeTag = styled(Tag).attrs({
  style: {
    fontSize: 10,
    marginRight: 0,
    cursor: 'pointer'
  },
})``;

export const CopyButton = styled.button`
  cursor: pointer;
  border: 0;
  background-color: transparent;
  position: absolute;
  padding: 0;
  right: 8px;
  top: 8px;
`;

export const CopyIcon = styled(Icon).attrs({
  type: 'copy',
  style: {
    color: 'black',
  },
})``;

export const Date = styled.span`
  font-size: 12px;
`;

export const DateContainer = styled.div`
  display: flex;
  gap: 6px;
  padding: ${({ hasSourceIcon }) => (hasSourceIcon ? '0' : '8px')};
`;

export const LeadInfo = styled.div``;

export const LeadName = styled.span`
  font-size: 16px;
`;

export const LeftTopContent = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
  position: absolute;
  left: 0;
  top: 0;
`;

export const MainContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const NecessityTag = styled(Tag).attrs({
  style: {
    fontSize: 10,
    marginRight: 0,
    cursor: 'pointer'
  },
})``;

export const PercentageContainer = styled.div`
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 20px;
`;

export const ProgressStyled = styled(Progress).attrs({
  strokeColor: '#000306',
  showInfo: false,
  size: 'small',
})``;

export const SourceIconContainer = styled.div`
  background-color: #595959;
  padding: 1px 8px;
  border-radius: 6.25px 0px;
`;

export const Value = styled.span`
  color: #118632;
  float: right;
  margin-top: 4px;
`;

export const Content = styled.div`
  margin: 40px 8px;
`;