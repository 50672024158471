import { createAsyncActions } from '~/utils/store';

export const SHOW = '@@DRAWER_REFERRAL_PROGRAM_INDICATION/SHOW';
export const CLOSE = '@@DRAWER_REFERRAL_PROGRAM_INDICATION/CLOSE';

export const SAVE = createAsyncActions('@@DRAWER_REFERRAL_PROGRAM_INDICATION/SAVE');

export const show = (data = null) => ({
    type: SHOW,
    payload: {
        data
    }
});

export const close = () => ({
    type: CLOSE,
    payload: {}
});

export const save = (id, data) => ({
    type: SAVE.INIT,
    payload: {
        request:{
            method: 'put',
            url:`/referral-programs/logs/${id}`,
            data,
            showLoading: false
        }
    }
});