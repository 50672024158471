import { createAsyncActions } from "~/utils/store";

export const SAVE_PHONES_BY_DEPARTMENT = createAsyncActions(
  "@@PHONES_BY_DEPARTMENT/SAVE"
);

export const savePhonesByDepartment = (data) => ({
  type: SAVE_PHONES_BY_DEPARTMENT.INIT,
  payload: {
    request: {
      method: "PUT",
      url: "/tenant/phones",
      data,
    },
  },
});
