import { Button, Icon, message, Modal, Table } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import * as linksActions from '../actions';
import { CategoryModal } from './CategoryModal';

const { Column } = Table;

const CategoriesModal = ({
  isCategoriesModalOpen,
  closeModal,
  linkCategories,
  permissions,
  saveLinkCategories,
  links,
}) => {
  const [categoryModal, setCategoryModal] = useState({
    isOpen: false,
    data: null,
  });
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isCategoriesModalOpen) {
      setCategories(linkCategories);
    }
  }, [isCategoriesModalOpen]);

  function openCategoryModal(data = null) {
    setCategoryModal({
      isOpen: true,
      data,
    });
  }

  function closeCategoryModal() {
    setCategoryModal({
      isOpen: false,
      data: null,
    });
  }

  function deleteCategoryFromState(uid, id) {
    const hasLinkWithThisCategory = links.some(
      (link) => link.link_category_id === id
    );

    if (hasLinkWithThisCategory) {
      message.warning(
        'Não é possível remover esta categoria, pois existe um link associado a ela',
        4
      );
      return;
    }

    Modal.confirm({
      title: 'Você realmente deseja remover esta categoria?',
      content: 'Esta ação não poderá ser desfeita.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => {
        setCategories(
          categories.map((category) => {
            if (category.uid !== uid) {
              return category;
            }

            return {
              ...category,
              is_deleted: true,
            };
          })
        );
      },
    });
  }

  function createCategoryToState(createdCategory) {
    setCategories([...categories, createdCategory]);
  }

  function modifyCategoryInState(updatedCategory) {
    setCategories(
      categories.map((category) => {
        if (category.uid !== updatedCategory.uid) {
          return category;
        }

        return updatedCategory;
      })
    );
  }

  async function saveCategories() {
    try {
      setIsLoading(true);
      const updatedCategories = categories.filter(
        (category) => category.is_modified
      );
      await saveLinkCategories({ categories }, updatedCategories);
      closeModal();
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      visible={isCategoriesModalOpen}
      title="Editar categorias"
      onOk={saveCategories}
      onCancel={closeModal}
      okText="Salvar"
      okButtonProps={{ loading: isLoading }}
      destroyOnClose
      width={700}
    >
      <Table
        rowKey="uid"
        size="small"
        dataSource={categories.filter((category) => !category.is_deleted)}
        pagination={false}
      >
        <Column title="Nome" dataIndex="name" key="title" />
        <Column
          title="Exibir no menu principal?"
          dataIndex="show_in_menu"
          key="show_in_menu"
          render={(showInMenu) => (showInMenu ? 'Sim' : 'Não')}
        />
        <Column
          dataIndex="uid"
          align="center"
          key="edit"
          render={(_, record) => (
            <Button type="primary" onClick={() => {
              openCategoryModal(record)
            }}>
              <Icon type="edit" />
            </Button>
          )}
        />
        <Column
          dataIndex="uid"
          align="center"
          key="delete"
          render={(uid, record) => (
            <Button
              type="danger"
              onClick={() => deleteCategoryFromState(uid, record.id)}
            >
              <Icon type="delete" />
            </Button>
          )}
        />
      </Table>
      <Button
        type="primary"
        style={{ marginTop: 20 }}
        onClick={() => openCategoryModal()}
      >
        Nova categoria
      </Button>
      <CategoryModal
        isOpen={categoryModal.isOpen}
        data={categoryModal.data}
        closeModal={closeCategoryModal}
        permissions={permissions}
        onCreateCategory={createCategoryToState}
        onModifyCategory={modifyCategoryInState}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isCategoriesModalOpen: state.links.categoriesModal.isOpen,
  linkCategories: state.links.categories,
  permissions: state.permissions.list,
  links: state.links.list,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(linksActions.closeCategoriesModal()),
  saveLinkCategories: (data, updatedCategories) =>
    dispatch(linksActions.saveLinkCategories(data, updatedCategories)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesModal);
