import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useLocation } from 'react-router-dom';

import { Layout } from "~components/UI";
import RefundsList from "./List";
import RefundsForm from "../DrawerRefunds";
import * as refundsActions from "./actions";
import { LoadingTopBar } from "~/components/UI/LoadingTopBar";
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

export const ScreensRefunds = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await LocalCacheHandler.load({
        actionName: CACHE_ACTIONS.refunds,
        dispatch,
        pathname
      });
      const { payload } = await dispatch(
        refundsActions.loadRefunds({
          filter_by: "due_date",
          from: moment().format("YYYY-MM-01"),
          to: moment()
            .endOf("month")
            .format("YYYY-MM-DD"),
          status: "all",
          showLoading: false,
        })
      );
      await LocalCacheHandler.save({
        pathname,
        data: payload.data.payments
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <RefundsList />
        <RefundsForm />
      </Layout>
    </div>
  );
};
