import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import { ThemeProvider } from "@material-ui/core";

import logo from "../logo.png";
import { RegisterStep } from "./components/RegisterStep";
import { PaymentStep } from "./components/PaymentStep";
import { StepsStyled, StepsWrapper } from "./styles";
import { materialUITheme } from '~/themes/material-ui.theme';

export const SignUp = () => {
  const {
    fullname = "",
    email = "",
    phone = "",
    company = "",
    id = ""
  } = JSON.parse(localStorage.getItem("form_state")) || {};

  const [step, setStep] = useState(0);
  const [formState, setFormState] = useState({
    id,
    fullname,
    company,
    email,
    password: "",
    desired_modules: [],
    identification_number: "",
    card_number: "",
    expiry_date: "",
    security_code: "",
    card_name: "",
    postal_code: "",
    address_number: "",
    phone,
    plan: null,
    coupon: null
  });

  useEffect(() => {
    localStorage.setItem(
      "form_state",
      JSON.stringify({
        fullname: formState.fullname,
        email: formState.email,
        phone: formState.phone,
        company: formState.company,
        id: formState.id,
      })
    );
  }, [formState]);

  return (
    <ThemeProvider theme={materialUITheme}>
      <section className="hero is-fullheight is-light-grey is-bold">
        <div className="hero-body">
          <div className="container">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ maxWidth: 760, width: '100%' }}>
                <div className="login-form-wrapper">
                  <div className="animated preFadeInLeft fadeInLeft">
                    <div className="flex-card auth-card is-light-raised light-bordered card-overflow">
                      <div
                        className="auth-card-header header-secondary"
                        style={{ maxWidth: 300, margin: "auto" }}
                      >
                        <a href="/">
                          <img src={logo} alt="ContLabs" />
                        </a>
                      </div>
                      <StepsWrapper isMobileScreen={window.screen.width < 480}>
                        <StepsStyled current={step} size="small">
                          <Steps.Step title="Cadastro" />
                          <Steps.Step title="Pagamento" />
                        </StepsStyled>
                      </StepsWrapper>
                      {step === 0 ? (
                        <RegisterStep
                          formState={formState}
                          setFormState={setFormState}
                          setStep={setStep}
                        />
                      ) : (
                        <PaymentStep
                          formState={formState}
                          setFormState={setFormState}
                          setStep={setStep}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ThemeProvider>
  );
};
