import React, { useState, useRef, useEffect } from 'react';
import { Radio, Tabs, Icon, Tooltip, Menu, Dropdown, Input, Modal } from 'antd';
const { TextArea } = Input;

const { TabPane } = Tabs;

const WhatsappTextEditor = (props) => {
    const [showHtmlModal, setShowHtmlModal] = useState(false);
    const [html, setHtml] = useState('');
    const [selectedText, setSelectedText] = useState('');
    const [selectionStart, setSelectionStart] = useState(0);
    const [selectionEnd, setSelectionEnd] = useState(0);

    const textareaRef = useRef(null);

    const text = props.getFieldValue && props.getFieldValue(props.name) ? props.getFieldValue(props.name) : '';

    const formatText = (format) => {

        if(format === 'html') {
            setShowHtmlModal(true);
            return;
        }

        const textarea = textareaRef.current;

        const text = textarea.state.value;
        const textBeforeSelection = text.substring(0, selectionStart);
        const textAfterSelection = text.substring(selectionEnd, text.length);


        let newText;
        if (format === 'br') {
            newText = `${textBeforeSelection}\n${selectedText}${textAfterSelection}`;
        } else {
            newText = `${textBeforeSelection}${format}${selectedText}${format}${textAfterSelection}`;
        }

        if(props.onSetValue) {
            props.onSetValue(newText);
        }

        // Move cursor after the selected text
        textarea.focus();
        if (format === 'br') {
            textarea.selectionStart = selectionStart + 1;
        } else {
            textarea.selectionStart = selectionStart + format.length;
            textarea.selectionEnd = selectionStart + format.length + selectedText.length;
        }

        // reset selection
        setSelectedText('');
        setSelectionStart(0);
        setSelectionEnd(0);
    };

    const formattingOptions = [
        { label: 'Bold', value: '*', icon: 'bold' },
        { label: 'Italic', value: '_', icon: 'italic' },
        { label: 'Strikethrough', value: '~', icon: 'strikethrough' },
        { label: 'Monospace', value: '```', icon: 'code' },
        { label: 'Break line', value: 'br', icon: 'align-left' },
    ];
  
    const menu = (
        <Menu onClick={({ key }) => formatText(key)}>
        {formattingOptions.map(option => (
            <Menu.Item key={option.value}>
            <Icon type={option.icon} />
            {option.label}
            </Menu.Item>
        ))}
        </Menu>
    );
  

  const previewText = () => {
    const htmlText = text
      .replace(/\*([^*]+)\*/g, '<strong>$1</strong>') // bold
      .replace(/_([^_]+)_/g, '<em>$1</em>') // italic
      .replace(/~([^~]+)~/g, '<del>$1</del>') // strikethrough
      .replace(/```([^`]+)```/g, '<code>$1</code>') // monospace
      .replace(/\n/g, '<br>'); // line breaks

    return htmlText;
  };

  const convertHtmlToWhatsappFormatting = () => {
    const whatsappFormattedText = html
      .replace(/<strong>([^<]+)<\/strong>/g, '*$1*') // bold
      .replace(/<em>([^<]+)<\/em>/g, '_$1_') // italic
      .replace(/<del>([^<]+)<\/del>/g, '~$1~') // strikethrough
      .replace(/<code>([^<]+)<\/code>/g, '```$1```') // monospace
      .replace(/<br>/g, '\n'); // line breaks

    // remove any additional html tags
    const whatsappFormattedTextWithoutHtml = whatsappFormattedText.replace(/<[^>]*>/g, '');

    setShowHtmlModal(false);
    setHtml('');

    if(props.onSetValue) {
      props.onSetValue(whatsappFormattedTextWithoutHtml);
    }
  }

  return (
    <div>
        <Modal
          title="Insira o código HTML"
          visible={showHtmlModal}
          cancelText="Fechar"
          okText="Converter"
          onOk={() => convertHtmlToWhatsappFormatting()}
          onCancel={() => setShowHtmlModal(false)}
        >
            <TextArea 
                rows={4}
                value={html}
                onChange={(e) => setHtml(e.target.value)}
            />
        </Modal>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Texto" key="1">
            <div style={{ marginBottom: '8px' }}>
            <Radio.Group onChange={(e) => formatText(e.target.value)} value="">
                <Radio.Button value="*">
                    <Tooltip title="Bold">
                        <Icon type="bold" />
                    </Tooltip>
                </Radio.Button>
                <Radio.Button value="_">
                    <Tooltip title="Itálico">
                        <Icon type="italic" />
                    </Tooltip>
                </Radio.Button>
                <Radio.Button value="~">
                    <Tooltip title="Tachado">
                        <Icon type="strikethrough" />
                    </Tooltip>
                </Radio.Button>
                <Radio.Button value="```">
                    <Tooltip title="Espaço único">
                        <Icon type="code" />
                    </Tooltip>
                </Radio.Button>
                <Radio.Button value="br">
                    <Tooltip title="Quebra de linha">
                        <Icon type="align-left" />
                    </Tooltip>
                </Radio.Button>
                <Radio.Button value="html">
                    <Tooltip title="Converter para formatação do Whatsapp">
                        <Icon type="html5" />
                    </Tooltip>
                </Radio.Button>
            </Radio.Group>
           </div>
           <Dropdown overlay={menu} trigger={['contextMenu']}>
                <TextArea
                    ref={textareaRef}
                    onSelectCapture={(e) => {
                        setSelectedText(e.target.value.substring(e.target.selectionStart, e.target.selectionEnd));
                        setSelectionStart(e.target.selectionStart);
                        setSelectionEnd(e.target.selectionEnd);
                    }}
                    placeholder="Digite o texto"
                    rows={24}
                    cols={50}
                    {...props}
                />
            </Dropdown>
        </TabPane>
        <TabPane tab="Visualização" key="2">
          <div dangerouslySetInnerHTML={{ __html: previewText() }} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default WhatsappTextEditor;
