import { message } from 'antd';
import { SAVE_PHONES_BY_DEPARTMENT } from '~/components/Account/WhatsappByDepartment/actions';
import { FINISH_STEP } from '~/components/Onboarding/actions';
import { AccountStorage } from '~/storages/account';
import { getHoursBetweenTimestamps } from '~/utils/get-hours-between-timestamps';

const defaultState = {
    name: null,
    tenant: {
        name: null
    }
}
const user = (state = defaultState, action) => {
    switch (action.type) {
        case 'LOAD_USER':
            const { created_at, user } = AccountStorage.get();

            if (!!created_at && !!user) {
                const differenceInHours = getHoursBetweenTimestamps(created_at, Date.now());
                if (differenceInHours <= 8) {
                    return user;
                }
            }

            return defaultState;

        case 'LOAD_USER_SUCCESS': {
            const loadedUser = action.payload.data;

            const { token } = AccountStorage.get();
            AccountStorage.set({ token, user: loadedUser });

            return action.payload.data;
        }
            
        case 'UPDATE_USER': {
            const updatedUser = {
                ...state,
                ...action.payload
            }

            const { token, created_at } = AccountStorage.get();
            AccountStorage.set({ token, created_at, user: updatedUser });

            return updatedUser;
        }
        case 'UPDATE_COMPANY':
            return {
                ...state,
                tenant:{
                    ...state.tenant,
                    ...action.payload
                }
            }
        case 'CHANGE_PASSWORD':
            return {
                ...state
            }
        case 'SAVE_CONFIG_SUCCESS': {
            return {
                ...state,
                tenant: action.payload.data.tenant
            }   
        }

        case FINISH_STEP.INIT: {
            const { status } = action.payload.request.data;
            return {
                ...state,
                onboarding_status: status
            }
        }

        case FINISH_STEP.SUCCESS: {
            message.success("Etapa concluída com sucesso!");
            return state;
        }

        case SAVE_PHONES_BY_DEPARTMENT.SUCCESS: {
            const {
                legalization,
                administrative,
                accounting,
                fiscal,
                personal,
                audit
            } = action.meta.previousAction.payload.request.data;

            return {
                ...state,
                tenant: {
                    ...state.tenant,
                    legalization_phone: legalization,
                    administrative_phone: administrative,
                    accounting_phone: accounting,
                    fiscal_phone: fiscal,
                    personal_phone: personal,
                    audit_phone: audit,
                }
            }
        }

        default:
        return state
    }
}

export default user;