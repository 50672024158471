export const parents = {
  DASHBOARD: {
    title: 'Dashboard',
    order: 0,
    plans: ['starter', 'premium']
  },
  SOLICITATIONS: {
    title: 'Solicitações',
    order: 1,
    plans: ['premium']
  },
  CLIENTES: {
    title: 'Clientes',
    order: 2,
    plans: ['starter', 'premium']
  },
  TAREFAS: {
    title: 'Tarefas',
    order: 3,
    plans: ['starter', 'premium']
  },
  FINANCEIRO: {
    title: 'Financeiro',
    order: 4,
    plans: ['starter', 'premium']
  },
  SOCIETARIO: {
    title: 'Dep. Societário',
    order: 5,
    plans: ['premium']
  },
  ITENS_ENVIADOS: {
    title: 'Itens enviados',
    order: 6,
    plans: ['starter', 'premium']
  },
  LEADS: {
    title: 'Leads',
    order: 7,
    plans: ['premium']
  },
  LEADSRT: {
    title: 'Leads - RT',
    order: 8,
    plans: ['premium']
  },
  USUARIOS: {
    title: 'Usuários',
    order: 9,
    plans: ['starter', 'premium']
  },
  LINKS: {
    title: 'Links',
    order: 10,
    plans: ['premium']
  },
  RELATORIOS: {
    title: 'Relatórios',
    order: 11,
    plans: ['premium']
  },
  BOARDS: {
    title: 'Marketing',
    order: 12,
    plans: ['premium'],
    isBeta: true
  },
  SOLICITATIONS: {
    title: 'Solicitações',
    order: 13,
    plans: ['premium'],
    isBeta: true
  },
  // REFFERAL_PROGRAM: {
  //   title: 'Indicação Premiada',
  //   order: 13,
  //   plans: ['premium'],
  //   isBeta: true
  // },
  PLUGINS: {
    title: 'Plugins',
    order: 15,
    plans: ['premium']
  },
  BASE_DE_CONHECIMENTO: {
    title: 'Base de conhecimento',
    order: 16,
    plans: ['premium']
  }
};