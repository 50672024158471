import { Col, Modal, Row } from 'antd';
import React from 'react';
import { TaskChartByMonth } from '../../Charts/TasksChartByMonth/TaskChartByMonth';

export function CalendarTasksModal({
  isOpen,
  closeCalendarTasksModal,
  tasksData,
}) {
  function getColSpan() {
    const tasksLength = Object.keys(tasksData).length;
    if (tasksLength === 1) {
      return 24;
    }

    if (tasksLength > 2) {
      return 8;
    }

    return 12;
  }

  return (
    <Modal
      width="100%"
      style={{ maxWidth: 1200 }}
      visible={isOpen}
      title="Tarefas do mês"
      centered
      onCancel={() => closeCalendarTasksModal()}
      footer={false}
    >
      {isOpen && (
        <Row>
          {Object.entries(tasksData).map(([month, data]) => (
            <Col span={getColSpan()} key={month}>
              <TaskChartByMonth month={month} data={data} />
            </Col>
          ))}
        </Row>
      )}
    </Modal>
  );
}
