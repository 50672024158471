import React, { useState } from "react";
import { Form, Drawer, Input, Radio, Tabs, Button } from "antd";
import { useDispatch } from "react-redux";
import InputMask from "~/components/UI/InputMask";

import * as plgFormsActions from "~/containers/Plugins/Forms/actions";

import DrawerFooter from '~/components/UI/Drawer';

import Details from "./Tabs/Details";
import Fields from "./Tabs/Fields";
import Logs from "./Tabs/Logs";


export const DrawerForm = Form.create()(({ 
    onClose,
    data,
    form
}) => {
    const dispatch = useDispatch();

    const [state, setState] = useState({
        ...data,
        fields: data.fields || [],
    });
    const [isSaving, setIsSaving] = useState(false);

    const onSave = () => {
        form.validateFields(async (error, values) => {
            if (error) {
                return;
            }

            const formData = {
                ...state,
                ...values,
            }

            if(state.id){
                try{
                    setIsSaving(true);
                    await dispatch(plgFormsActions.update({ ...formData }));
                    setIsSaving(false);
                    onClose();
                } catch(error){
                    console.log(error);
                }
                return;
            }

            try{
                setIsSaving(true);
                await dispatch(plgFormsActions.create({ ...formData }));
                setIsSaving(false);
                onClose();
            }catch(error){
                console.log(error);
            }

        });
    }

    return (
        <Drawer
            destroyOnClose
            title={state.id ? 'Formulário: ' + state.name: "Adicionar Formulário"}
            width={1050}
            onClose={onClose}
            visible={true}
        >
            <Form>
                <Tabs defaultActiveKey="1" type="card">
                    <Tabs.TabPane tab="Detalhes" key="1">
                        <Details 
                            data={state} 
                            form={form}
                        />
                    </Tabs.TabPane>
                    {(form.getFieldValue("type") === "chatbot" || (data && data.type === "chatbot" && form.getFieldValue("type") === undefined)) && (
                        <Tabs.TabPane tab="Campos" key="2">
                            <Fields 
                                state={state} 
                                setState={setState} 
                                form={form} 
                            />
                        </Tabs.TabPane>
                    )}
                    {state.logs && state.logs.length > 0 && (
                        <Tabs.TabPane tab={"Logs (" + state.logs.length + ")"} key="3">
                            <Logs form={state} />
                        </Tabs.TabPane>
                    )}
                </Tabs>

                {form.getFieldValue("type") && form.getFieldValue("type") !== "chatbot" && (
                    <Form.Item
                        label="Redirecionar usuário para o Whatsapp após envio do formulário?"
                        colon={false}
                        >
                        {form.getFieldDecorator("redirect_to_whatsapp", {
                            initialValue: data ? data.redirect_to_whatsapp : false,
                        })(
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value={true}>Sim</Radio.Button>
                                <Radio.Button value={false}>Não</Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>
                )}
                {form.getFieldValue("redirect_to_whatsapp") && (
                    <div>
                        <Form.Item label="Número" required>
                            {form.getFieldDecorator("phone", {
                                initialValue: data ? data.phone : "",
                                rules: [
                                {
                                    validator: (rule, value, callback) => {
                                    if (
                                        !value &&
                                        form.getFieldValue("redirect_to_whatsapp")
                                    ) {
                                        callback("Por favor, informe o número!");
                                    }
                                    callback();
                                    },
                                },
                                ],
                            })(
                                <InputMask
                                    mask="(99) 999tt999?"
                                    formatChars={{ "9": "[0-9]", t: "[0-9-]", "?": "[0-9 ]" }}
                                    maskChar={null}
                                    placeholder="Número do Whatsapp"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Mensagem" required>
                        {form.getFieldDecorator("message", {
                            initialValue: data ? data.message : "",
                            rules: [
                            {
                                validator: (rule, value, callback) => {
                                if (
                                    !value &&
                                    form.getFieldValue("redirect_to_whatsapp")
                                ) {
                                    callback("Por favor, informe a mensagem!");
                                }
                                callback();
                                },
                            },
                            ],
                        })(<Input.TextArea placeholder="Mensagem" />)}
                        </Form.Item>
                    </div>
                )}
            </Form>
            <DrawerFooter>
                <Button onClick={onClose}>
                    Cancelar
                </Button>{' '}
                <Button 
                    type="primary" 
                    loading={isSaving}
                    onClick={onSave}>
                    Salvar
                </Button>
            </DrawerFooter>
        </Drawer>
    );
});

export default DrawerForm;