export const fieldsOptions = {
  title: {
    rules: [
      {
        required: true,
        message: "Por favor, informe o título",
      },
    ],
  },
  categories: {
    rules: [
      {
        required: true,
        message: "Por favor, informe uma ou mais categorias",
      },
    ],
  },
};
