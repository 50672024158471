import { Steps } from "antd";
import styled from "styled-components";

export const StepsWrapper = styled.div`
  max-width: 300px;
  margin: ${({ isMobileScreen }) => isMobileScreen ? '0' : '-16px auto 32px'};
`;

export const StepsStyled = styled(Steps)`
  font-family: Arial, sans-serif !important;

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: #7f00ff !important;
    border-color: #7f00ff !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #7f00ff !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #7f00ff !important;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #7f00ff;
  }
`;
