import { Calendar } from "antd";
import moment from "moment";
import React from "react";
import { getNextTask } from "../handlers";
import {
  CalendarCellCircle,
  CalendarCellContainer,
  CalendarCellContent,
  CalendarCellText,
  CalendarCellTextContainer,
  CalendarContainer,
} from "../styles";

export const LeadCalendar = ({ columns, openCalendarDrawer, setDate }) => {
  const followUpLeads = columns.find((column) => column.name === "Follow Up");

  const allLeadMeetings = columns.flatMap((column) => {
    return column.leads
      .filter((lead) => lead.tasks.length > 0)
      .flatMap((lead) =>
        lead.tasks
          .filter((task) => task.type === 2)
          .map((meeting) => ({
            ...meeting,
            lead: {
              name: lead.name,
              phone: lead.phone,
            },
          }))
      );
  });

  const getCalendarCircleColor = (date) => {
    return moment().isAfter(date, "day") ? "red" : "green";
  };

  const dateCellRender = (currentDate) => {
    const leadsWithTasks = followUpLeads.leads.filter((lead) => {
      if (lead.tasks.length === 0) return false;

      const nextTask = getNextTask(lead.tasks);

      if (!nextTask || nextTask.type === 2) return false;

      return (
        moment(nextTask.due_at).format("YYYY-MM-DD") ===
        currentDate.format("YYYY-MM-DD")
      );
    });

    const leadMeetings = allLeadMeetings.filter(
      (meeting) =>
        moment(meeting.due_at).format("YYYY-MM-DD") ===
        currentDate.format("YYYY-MM-DD")
    );

    if (leadsWithTasks.length === 0 && leadMeetings.length === 0) return null;

    const tasksCount = leadsWithTasks.length;
    const meetingsCount = leadMeetings.length;

    return (
      <CalendarCellContainer
        onClick={() => {
          openCalendarDrawer({
            followUps: leadsWithTasks,
            meetings: leadMeetings,
          });
        }}
      >
        <CalendarCellContent>
          <CalendarCellCircle taskColor={getCalendarCircleColor(currentDate)} />
          <CalendarCellTextContainer>
            {tasksCount > 0 && (
              <CalendarCellText>
                {`${tasksCount} LEAD${tasksCount > 1 ? "S" : ""}`}
              </CalendarCellText>
            )}
            {meetingsCount > 0 && (
              <CalendarCellText>
                {`${meetingsCount} REUNI${meetingsCount > 1 ? "ÕES" : "ÃO"}`}
              </CalendarCellText>
            )}
          </CalendarCellTextContainer>
        </CalendarCellContent>
      </CalendarCellContainer>
    );
  };

  return (
    <CalendarContainer>
      <Calendar
        mode="month"
        dateCellRender={dateCellRender}
        onPanelChange={(value) => setDate(value.format("MM-YYYY"))}
      />
    </CalendarContainer>
  );
};
