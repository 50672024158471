export const validateTasks = (tasks) => {
  const status = {
    isValid: true,
  };

  tasks.forEach(
    ({
      customer_id,
      type,
      description,
      due_at,
      custom_fields,
      is_only_document,
    }) => {
      if (!customer_id || !type || !description || !due_at) {
        status.isValid = false;
      }

      if (custom_fields && !is_only_document) {
        const areAllCustomFieldsFilled = Object.values(custom_fields).every(
          (value) => !!value
        );

        if (!areAllCustomFieldsFilled) {
          status.isValid = false;
        }
      }
    }
  );

  return status;
};
