import React, { useState, useEffect } from "react";
import { Form, Drawer, Table, Button, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";

import * as plgFormsActions from "~/containers/Plugins/Forms/actions";
import DrawerForm from "~/containers/Plugins/Forms/DrawerForm";

import { tableColumns } from "./tableColumns";

export const Forms = Form.create()(({ isVisible, onClose, form }) => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state);
  const {
    forms,
    isLoading,
  } = useSelector((state) => state.plgForms);

  const [selected, setSelected] = useState(null);

  // load initial data
  useEffect(() => {
    dispatch(plgFormsActions.load());
  }, []);

  return (
    <Drawer
      title="Formulários"
      destroyOnClose
      visible={isVisible}
      onClose={onClose}
      width={1100}
    >
      <Row>
        <Col span={24} style={{ textAlign: 'right' }} className="mb-20">
          <Button type="primary" onClick={() => {
            setSelected({ 
              id: null, 
              acquisition_id: null, 
              redirect_to_whatsapp: null 
            });
          }}>
            Novo Formulário
          </Button>
        </Col>
      </Row>
      <Table
        rowKey="id"
        dataSource={forms}
        bordered
        pagination={false}
        className="mb-20"
        loading={isLoading}
        columns={tableColumns(
          settings, 
          (id) => dispatch(plgFormsActions.remove(id)),
          (data) => setSelected(data)
        )}
      />
      {selected !== null && (
        <DrawerForm
          onClose={() => setSelected(null)}
          data={selected}
        />
      )}
    </Drawer>
  );
});

export default Forms;