import React, { Component } from 'react';
import { Input } from 'antd';
import ReactInputMask from 'react-input-mask';
import PropTypes from 'prop-types';

class InputMask extends Component {
  render() {
    const { getRef, ...rest } = this.props;
    return (
    <ReactInputMask {...rest}>
      { (inputProps) => <Input 
        {...inputProps} 
        ref={getRef ? getRef : null}
        disabled={rest.disabled ? rest.disabled : null} 
        /> }
    </ReactInputMask>
    )
  }
}

InputMask.propTypes = {
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  formatChars: PropTypes.object,
  alwaysShowMask: PropTypes.bool,
  // inputRef: PropTypes.func
};

export default InputMask;