import React, { useState } from "react";
import {
  Button,
  DatePicker,
  Input,
  Modal,
  Select,
  Table,
  Tooltip,
  Icon,
  message,
} from "antd";
import { useSelector } from "react-redux";

import { limitCharacterLength } from "~/utils/formatters";
import { TitleWrapper, ColumnTitleWrapper, UploadedFilesField } from "./styles";
import InputCurrency from "~/components/UI/InputCurrency";
import { DocumentViewerModal } from "../../../List/Modals/DocumentViewerModal";

const { Column } = Table;
const { Option } = Select;

export const UploadedFilesTable = ({
  files,
  setFiles,
  selectedDepartmentId,
  form,
}) => {
  const { getFieldDecorator, getFieldsValue, setFieldsValue } = form;
  const {
    customers: { list: customers },
    documents: { types: documentTypes },
  } = useSelector((state) => state);

  const [documentViewerModal, setDocumentViewerModal] = useState({
    isVisible: false,
    data: null,
  });

  function openDocumentViewerModal(data) {
    setDocumentViewerModal({
      isVisible: true,
      data,
    });
  }

  function closeDocumentViewerModal() {
    setDocumentViewerModal({
      isVisible: false,
      data: null,
    });
  }

  function deleteFile(fileId) {
    Modal.confirm({
      title: "Você realmente deseja excluir esse documento?",
      content: "Esta ação não poderá ser desfeita.",
      okText: "Sim",
      okType: "danger",
      cancelText: "Cancelar",
      onOk: () => {
        setFiles((filesInState) => {
          return filesInState.filter((file) => file.id !== fileId);
        });
      },
    });
  }

  function replicate(fieldName) {
    const fieldsValue = getFieldsValue();

    if (Object.keys(fieldsValue).length === 0) {
      message.error("Por favor, adicione ao menos um documento para replicar.");
      return;
    }

    const firstValue = Object.values(fieldsValue)[0][fieldName];

    if (!firstValue) {
      message.error("Por favor, preencha a primeira linha para replicar.");
      return;
    }

    const formattedFields = {};

    Object.keys(fieldsValue).forEach((key) => {
      formattedFields[`[${key}][${fieldName}]`] = firstValue;
    });

    setFieldsValue(formattedFields);
  }

  const documentTypesOfCurrentDepartment = selectedDepartmentId
    ? documentTypes.filter((type) => Number(type.department) === Number(selectedDepartmentId))
    : documentTypes;

  return (
    <>
      <Table
        rowKey="id"
        dataSource={files}
        bordered
        scroll={{ x: true, y: "calc(100vh - 500px)" }}
        pagination={false}
      >
        <Column
          title="Arquivo"
          align="center"
          fixed="left"
          dataIndex="file"
          key="file"
          width={220}
          render={(file) => {
            return (
              <UploadedFilesField>
                <Tooltip title={file.name}>
                  <Button
                    onClick={() => openDocumentViewerModal(file.originFileObj)}
                    type="link"
                  >
                    {limitCharacterLength(file.name, 15)}
                  </Button>
                </Tooltip>
              </UploadedFilesField>
            );
          }}
        />
        <Column
          title={
            <ColumnTitleWrapper>
              <TitleWrapper>Cliente</TitleWrapper>
              <Button size="small" onClick={() => replicate('customer_id')}>
                <Icon type="sync" /> Replicar
              </Button>
            </ColumnTitleWrapper>
          }
          dataIndex="customer_id"
          key="customer_id"
          width={400}
          render={(_, { id }) => (
            <UploadedFilesField>
              {getFieldDecorator(`[${id}][customer_id]`, {
                rules: [
                  {
                    required: true,
                    message: "Por favor, informe o cliente",
                  },
                ],
              })(
                <Select
                  style={{ width: 360 }}
                  placeholder="Selecione um cliente"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {customers.map((customer) => (
                    <Option key={customer.id} value={customer.id}>
                      {customer.cod
                        ? `${customer.cod} - ${customer.name}`
                        : customer.name}
                    </Option>
                  ))}
                </Select>
              )}
            </UploadedFilesField>
          )}
        />
        <Column
          title={
            <ColumnTitleWrapper>
              <TitleWrapper>Departamento</TitleWrapper>
              <Button size="small" onClick={() => replicate('department')}>
                <Icon type="sync" /> Replicar
              </Button>
            </ColumnTitleWrapper>
          }
          dataIndex="department"
          key="department"
          align="center"
          width={290}
          render={(_, { id }) => (
            <UploadedFilesField>
              {getFieldDecorator(`[${id}][department]`, {
                rules: [
                  {
                    required: true,
                    message: "Por favor, informe o departamento"
                  }
                ],
                initialValue: selectedDepartmentId
              })(
                <Select style={{ width: 230 }} placeholder="Selecione um departamento">
                  <Option value="1">Legalização</Option>
                  <Option value="2">Administrativo</Option>
                  <Option value="3">Contábil</Option>
                  <Option value="4">Fiscal</Option>
                  <Option value="5">Pessoal</Option>
                  <Option value="6">Sucesso do Cliente (CS)</Option>
                </Select>
              )}
            </UploadedFilesField>
          )}
        />
        <Column
          title={
            <ColumnTitleWrapper>
              <TitleWrapper>Tipo de documento</TitleWrapper>
              <Button size="small" onClick={() => replicate('type')}>
                <Icon type="sync" /> Replicar
              </Button>
            </ColumnTitleWrapper>
          }
          dataIndex="type"
          key="type"
          align="center"
          width={300}
          render={(_, { id }) => (
            <UploadedFilesField>
              {getFieldDecorator(`[${id}][type]`, {
                rules: [
                  {
                    required: true,
                    message: "Por favor, informe o tipo",
                  },
                ],
              })(
                <Select style={{ width: 240 }} placeholder="Selecione um tipo">
                  {documentTypesOfCurrentDepartment.map(
                    ({ id, description }) => (
                      <Option key={id} value={description}>
                        {description}
                      </Option>
                    )
                  )}
                </Select>
              )}
            </UploadedFilesField>
          )}
        />
        <Column
          title={
            <ColumnTitleWrapper>
              <TitleWrapper>Descrição</TitleWrapper>
              <Button size="small" onClick={() => replicate('description')}>
                <Icon type="sync" /> Replicar
              </Button>
            </ColumnTitleWrapper>
          }
          dataIndex="description"
          key="description"
          width={200}
          render={(_, { id }) => (
            <UploadedFilesField>
              {getFieldDecorator(`[${id}][description]`, {
                rules: [
                  {
                    required: true,
                    message: "Por favor, informe a descrição",
                  },
                ],
              })(<Input placeholder="Descrição" />)}
            </UploadedFilesField>
          )}
        />
        <Column
          title={
            <ColumnTitleWrapper>
              <TitleWrapper>Mês de competência</TitleWrapper>
              <Button size="small" onClick={() => replicate('competency_at')}>
                <Icon type="sync" /> Replicar
              </Button>
            </ColumnTitleWrapper>
          }
          dataIndex="competency_at"
          key="competency_at"
          align="center"
          width={180}
          render={(_, { id }) => (
            <UploadedFilesField>
              {getFieldDecorator(`[${id}][competency_at]`, {
                rules: [
                  {
                    required: true,
                    message: "Por favor, informe o mês de competência",
                  },
                ],
              })(<DatePicker format="DD/MM/YYYY" />)}
            </UploadedFilesField>
          )}
        />
        <Column
          title={
            <ColumnTitleWrapper>
              <TitleWrapper>Data de vencimento</TitleWrapper>
              <Button size="small" onClick={() => replicate('due_at')}>
                <Icon type="sync" /> Replicar
              </Button>
            </ColumnTitleWrapper>
          }
          width={220}
          dataIndex="due_at"
          align="center"
          key="due_at"
          render={(_, { id }) => (
            <UploadedFilesField>
              {getFieldDecorator(`[${id}][due_at]`, {
                rules: [
                  {
                    required: true,
                    message: "Por favor, informe a data de vencimento",
                  },
                ],
              })(<DatePicker format="DD/MM/YYYY" />)}
            </UploadedFilesField>
          )}
        />
        <Column
          title={
            <ColumnTitleWrapper>
              <TitleWrapper>Valor</TitleWrapper>
              <Button size="small" onClick={() => replicate('amount')}>
                <Icon type="sync" /> Replicar
              </Button>
            </ColumnTitleWrapper>
          }
          dataIndex="amount"
          key="amount"
          align="center"
          width={170}
          render={(amount, { id }) => (
            <UploadedFilesField>
              {getFieldDecorator(`[${id}][amount]`, {
                trigger: "onChangeEvent",
                getValueFromEvent: (event, maskedValue, floatValue) => {
                  return floatValue;
                },
              })(<InputCurrency />)}
            </UploadedFilesField>
          )}
        />
        <Column
          dataIndex="id"
          key="delete"
          align="center"
          width={80}
          fixed="right"
          render={(id) => (
            <Button
              type="danger"
              icon="delete"
              onClick={() => deleteFile(id)}
            />
          )}
        />
      </Table>
      <DocumentViewerModal
        isOpen={documentViewerModal.isVisible}
        closeModal={closeDocumentViewerModal}
        data={documentViewerModal.data}
        mask
      />
    </>
  );
};
