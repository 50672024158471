import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  margin: 0px -16px;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-size: 20px;
`;

export const PermissionsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;