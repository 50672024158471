import { Icon, Popover, Tooltip, Avatar } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";

import { convertToBRL, limitCharacterLength } from "~/utils/formatters";
import { necessity } from "../data";
import { renderSourceIcon } from '../helpers/renderSourceIcon';
import { LeadCalendarIcon } from "../LeadCalendarIcon";
import { handleCopyLeadToClipBoard } from "./handlers";
import {
  CalendarIcon,
  CompanyTypeAndNecessityContainer,
  CompanyTypeTag,
  Content,
  CopyButton,
  CopyIcon,
  Date,
  DateContainer,
  LeadInfo,
  LeadName,
  LeftTopContent,
  MainContent,
  NecessityTag,
  PercentageContainer,
  ProgressStyled,
  SourceIconContainer,
  Container,
  Value,
} from "./styles";

export function LeadCard({
  index,
  item,
  showDrawer,
  columnId,
  showOnboarding,
}) {

  const usersById = useSelector((state) => state.usersById);

  const assignedToInitials = (assignedTo) => {
    if (!assignedTo) return '';
    if( typeof assignedTo !== 'string') return '';
    const [firstName, lastName] = assignedTo.split(' ');
    return `${firstName.charAt(0)}${lastName ? lastName.charAt(0) : ''}`;
  };

  return (
    <Draggable key={index} draggableId={item.id} index={index}>
      {(providedProps, snapshotProps) => {
        const { innerRef, draggableProps, dragHandleProps } = providedProps;
        const { isDragging, isDraggingOver } = snapshotProps;

        return (
          <Popover
            placement="left"
            content="Mova o lead para Positivo"
            visible={showOnboarding}
          >
            <Container
              ref={innerRef}
              {...draggableProps}
              {...dragHandleProps}
              isDragging={isDragging}
              isDraggingOver={isDraggingOver}
              isOpportunityColumn={columnId === 1}
              isConversationStarted={item.conversation_started}
              isRevised={item.is_revised}
              onClick={() => showDrawer(item)}
            >
              <LeftTopContent>
                {item.source && (
                  <SourceIconContainer>
                    {renderSourceIcon(item.source)}
                  </SourceIconContainer>
                )}
                <DateContainer hasSourceIcon={!!item.source}>
                  <CalendarIcon />
                  <Date>{moment(item.created_at).fromNow()}</Date>
                </DateContainer>
              </LeftTopContent>
              <CopyButton
                onClick={(event) => handleCopyLeadToClipBoard(event, item)}
              >
                <CopyIcon />
              </CopyButton>
              <Content>
                <MainContent>
                  {item.stage !== 1 && <LeadCalendarIcon item={item} />}
                  <LeadInfo>
                    <LeadName>{limitCharacterLength(item.name, 25)}</LeadName>
                    {(item.company_type || item.necessity) && (
                      <CompanyTypeAndNecessityContainer>
                        {item.company_type && (
                          <CompanyTypeTag color="blue">
                            <Icon type="tag" /> {item.company_type}
                          </CompanyTypeTag>
                        )}
                        {item.necessity && (
                          <NecessityTag color={necessity[item.necessity].color}>
                            <Icon type="question-circle" />{" "}
                            {necessity[item.necessity].name}
                          </NecessityTag>
                        )}
                      </CompanyTypeAndNecessityContainer>
                    )}
                  </LeadInfo>
                </MainContent>
                {!!item.honorary && (
                  <Value>{convertToBRL(item.honorary)}</Value>
                )}
                {item.assigned_to && usersById[item.assigned_to] && usersById[item.assigned_to].name && (
                <Tooltip title={usersById[item.assigned_to] && usersById[item.assigned_to].name}>
                  <Avatar size="small" style={{ color: 'rgb(113 46 208)', backgroundColor: 'rgb(239 227 255)', textTransform: 'uppercase' }}>
                    {assignedToInitials(usersById[item.assigned_to] && usersById[item.assigned_to].name)}
                  </Avatar>
                </Tooltip>
                )}
              </Content>
              <PercentageContainer>
                <Tooltip title="Percentual de cadastro">
                  <ProgressStyled percent={item.percentage} />
                </Tooltip>
              </PercentageContainer>
            </Container>
          </Popover>
        );
      }}
    </Draggable>
  );
}
