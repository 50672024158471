import React, { useState, useEffect } from "react";
import axios from "axios";

import BotForm from "./BotForm";

import { getSubdomain } from "~/helpers/getSubdomain";

export const FormsIndication = ({match}) => {
    const [data , setData] = useState(null);
    const [isCheckingIfFormIsValid, setIsCheckingIfFormIsValid] = useState(true);

    const {
        id,
        indicated_by_id,
    } = match.params;

    async function fetchData() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/indications/${id}`, {
                headers: {
                  "X-Tenant": getSubdomain(),
                },
            });
            setData(response.data);
        } catch (error) {
            console.log(error);
            setIsCheckingIfFormIsValid(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return <BotForm 
        data={data} 
        isCheckingIfFormIsValid={isCheckingIfFormIsValid}
        token={id}
        indicated_by_id={indicated_by_id}
    />
}

export default FormsIndication;