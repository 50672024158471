import { Button, Steps, Tooltip } from "antd";
import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Container,
  OnboardingContainer,
  OnboardingOverlay,
  OnboardingProgress,
  OnboardingTitle,
  StepsContainer,
  StepTitle,
} from "./styles";
import * as onboardingActions from "~/components/Onboarding/actions";
import { steps } from "./data";
import { getOnboardingSteps } from "~/utils/get-onboarding-steps";
import { onboardingPermissions } from "~/utils/permissions";
import { VideoThumb } from "./components/VideoThumb";
import { VideoModal } from "../VideoModal";

export const Onboarding = () => {
  const bottomRef = useRef(null);
  const topRef = useRef(null);
  const { onboarding, user } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { isActive, step: temporaryStep } = onboarding;
  const { onboarding_status: stepsStatus } = user;

  const { finishedStepsCount, allStepsCount } = getOnboardingSteps(user);
  const progressPercent = Math.round(
    (finishedStepsCount * 100) / allStepsCount
  );

  useEffect(() => {
    handleStepsScroll(temporaryStep);
  }, [temporaryStep, isActive]);

  function handleStepsScroll(step) {
    if (bottomRef.current && topRef.current) {
      if (step <= 6) {
        topRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (step >= 7) {
        bottomRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  function closeOnboarding() {
    dispatch(onboardingActions.closeOnboarding());
  }

  function selectStep(selectedStep) {
    dispatch(onboardingActions.selectStep(selectedStep + 1));
  }

  function openVideoModal(params) {
    dispatch(onboardingActions.openVideoModal(params));
  }

  if (!isActive || !stepsStatus) return null;

  return (
    <>
      <Container>
        <OnboardingContainer>
          <OnboardingTitle>Bem-vindo</OnboardingTitle>
          <h2>Inicie sua jornada com os passos abaixo</h2>
          <OnboardingProgress percent={progressPercent} />
          <StepsContainer>
            <div ref={topRef} />
            <Steps direction="vertical" size="small">
              {steps.map(({ step, title, dependsOf, video, plans }, _, items) => {
                const stepPermission = onboardingPermissions[step];

                if(plans && !plans.includes(user.tenant.plan)) {
                  return null;
                }

                if (!user.permissions.includes(stepPermission)) {
                  return null;
                }

                const currentStepStatus = stepsStatus[step];
                const dependentStepStatus = stepsStatus[dependsOf];
                const isDependentStepPendent =
                  dependentStepStatus && dependentStepStatus !== "finish";
                const dependentStepTitle = isDependentStepPendent
                  ? items.find(({ step }) => step === dependsOf).title
                  : "";

                return (
                  <Steps.Step
                    key={step}
                    status={
                      step === temporaryStep && currentStepStatus !== "finish"
                        ? "process"
                        : currentStepStatus
                    }
                    title={
                      <Tooltip
                        placement="left"
                        title={
                          isDependentStepPendent
                            ? `Para selecionar esta etapa é necessário concluir a etapa "${dependentStepTitle}"`
                            : ""
                        }
                      >
                        <StepTitle
                          onClick={() => {
                            if (isDependentStepPendent) {
                              return;
                            }
                            selectStep(step - 1);
                          }}
                          isActive={temporaryStep === step}
                          style={{
                            cursor: isDependentStepPendent
                              ? "not-allowed"
                              : "pointer",
                          }}
                        >
                          {title}
                        </StepTitle>
                        {video && step === temporaryStep && (
                          <VideoThumb
                            onClick={() => {
                              openVideoModal({
                                title,
                                url: video.url,
                              });
                            }}
                            imageUrl={video.thumbnailUrl}
                          />
                        )}
                      </Tooltip>
                    }
                  />
                );
              })}
            </Steps>
            <div ref={bottomRef} />
          </StepsContainer>
          <Button onClick={closeOnboarding}>Fechar</Button>
        </OnboardingContainer>
      </Container>
      <OnboardingOverlay />
      <VideoModal />
    </>
  );
};
