import React from 'react';
import { Table } from 'antd';
import moment from 'moment';

const Logs = ({
    form
}) => {

    const capitalizeFirstLetter = (string) => {
        if (!string) return string;
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const fields = form.fields || [];

    const userCollumn = fields.filter((field) => field.from === 'user');

    const extraCollumns = userCollumn.map((field, index) => {
        return {
            title: capitalizeFirstLetter(field.custom_field_name || field.name),
            key: field.id,
            dataIndex: field.key,
            width: 200,
            render: (value, record, index) => {
                return record['fields'][field.field_name] && record['fields'][field.field_name].value ? record['fields'][field.field_name].value : '-';
            }
        }
    });

    const width = 200 + (extraCollumns.length * 200);

    return (
        <div>
            <Table
                rowKey="id"
                dataSource={form.logs}
                bordered
                pagination={true}
                className="mb-20"
                scroll={{ x: width }}
                columns={
                    [
                        {
                            title: 'Data',
                            key: 'created_at',
                            dataIndex: 'created_at',
                            width: 200,
                            sortDirections: ['descend', 'ascend'],
                            sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
                            defaultSortOrder: 'descend',
                            render: (created_at, record, index) => {
                                return moment(created_at).format("DD/MM/YYYY HH:mm");
                            }
                        },
                        ...extraCollumns
                    ]
                }
            />
        </div>
    );
};

export default Logs;