import React from 'react';
import { Table, Tag } from 'antd';
import moment from 'moment';

import { getBackgroundItem, getTextItem } from '~/screens/Societario/helpers';

const ArchivedTasksTable = ({ data, getCustomer, showDrawer }) => {
  const columns = [
    {
      title: 'Cod',
      dataIndex: 'id',
      key: 'id',
      width: '15%',
      render: (text) => <span>#SOC{text}</span>,
    },
    {
      title: 'Empresa',
      dataIndex: 'customer_id',
      key: 'customer_id',
      ellipsis: true,
      width: '65%',
      render: (id, record) => getCustomer(record).name
    },
    {
      title: 'Tarefa',
      dataIndex: 'category',
      key: 'category',
      width: '20%',
      render: (text) => <Tag className="mt-sm" color={getBackgroundItem(text)}>
          {getTextItem(text)}
      </Tag>,
    },
    {
      title: 'Arquivado em',
      dataIndex: 'archived_at',
      key: 'archived_at',
      width: '20%',
      align: 'center',
      render: (text) => moment(text).format('DD/MM/YYYY'),
    },
  ];

  return (
    <div className={`table-clabs`}>
      <Table
        rowKey="id"
        bordered
        rowClassName="row-clabs clickable-row"
        dataSource={data}
        columns={columns}
        pagination={{ pageSize: 10 }}
        onRow={(record) => {
          return {
            onClick: () => showDrawer(
              parseInt(record.category),
              {
                  ...record,
                  customer: getCustomer(record),
              }
            ),
          };
        }}
      />
    </div>
  );
};

export default ArchivedTasksTable;
