import { Col, Form, Input, Row, Switch } from 'antd';
import React from 'react';

export const MalhaFiscalTab = ({ form, data }) => {
  const { getFieldDecorator } = form;

  const urlRule = {
    type: 'url',
    message: 'Por favor, informe um link válido',
  };

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Form.Item label="Federal">
          {getFieldDecorator('federal_link', {
            rules: [urlRule],
            initialValue: data.federal_link,
          })(<Input placeholder="Link do departamento federal" />)}
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Exibir no federal?">
          {getFieldDecorator('show_in_federal', {
            valuePropName: 'checked',
            initialValue: data.id ? data.show_in_federal : true,
          })(<Switch />)}
        </Form.Item>
      </Col>
      <Col span={16}>
        <Form.Item label="Estadual">
          {getFieldDecorator('state_link', {
            rules: [urlRule],
            initialValue: data.state_link,
          })(<Input placeholder="Link do departamento estadual" />)}
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Exibir no estadual?">
          {getFieldDecorator('show_in_state', {
            valuePropName: 'checked',
            initialValue: data.id ? data.show_in_state : true,
          })(<Switch />)}
        </Form.Item>
      </Col>
      <Col span={16}>
        <Form.Item label="Municipal">
          {getFieldDecorator('municipal_link', {
            rules: [urlRule],
            initialValue: data.municipal_link,
          })(<Input placeholder="Link do departamento municipal" />)}
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Exibir no municipal?" valuePropName="checked">
          {getFieldDecorator('show_in_municipal', {
            valuePropName: 'checked',
            initialValue: data.id ? data.show_in_municipal : true,
          })(<Switch />)}
        </Form.Item>
      </Col>
      <Col span={16}>
        <Form.Item label="FGTS">
          {getFieldDecorator('fgts_link', {
            rules: [urlRule],
            initialValue: data.fgts_link,
          })(<Input placeholder="Link do departamento FGTS" />)}
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Exibir no FGTS?" valuePropName="checked">
          {getFieldDecorator('show_in_fgts', {
            valuePropName: 'checked',
            initialValue: data.id ? data.show_in_fgts : true,
          })(<Switch />)}
        </Form.Item>
      </Col>
    </Row>
  );
};
