export const getFormattedEvent = (event) => {
  if (!event) return null;

  return {
    delivered: {
      text: 'Enviado',
      color: '#0071c0'
    },
    opened: {
      text: 'Aberto',
      color: '#03b14e'
    },
    clicked: {
      text: 'Clicado',
      color: '#ffec3d'
    },
    complained: {
      text: 'Marcado como spam',
      color: '#ffc069'
    },
    failed: {
      text: 'Falhou',
      color: '#ff4d4f'
    },
    unsubscribed: {
      text: 'Inscrição cancelada',
      color: '#141414'
    },
    pendent: {
      text: 'Envio solicitado',
      color: '#9254de'
    }
  }[event]
}