import React, { cloneElement } from 'react';

import { ExperimentOutlined, IdcardOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export const renderSourceIcon = (source) => {
  const sources = {
    form: {
      title: "Cadastro por formulário no site",
      icon: <IdcardOutlined />,
    },
    panel: {
      title: "Cadastrado manualmente pelo painel do Contlabs",
      icon: <ExperimentOutlined />,
    },
    extension: {
      title: "Cadastrado pela extensão do WhatsApp",
      icon: <WhatsAppOutlined />,
    },
  };

  if (!source) return null;

  return (
    <Tooltip title={sources[source].title}>
      {cloneElement(sources[source].icon, {
        style: { fontSize: 15, color: "white" },
      })}
    </Tooltip>
  );
};
