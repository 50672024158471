import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form, Icon, Input, Button, Upload, message } from 'antd';
import { request } from '../Instance';
import InputMask from '../UI/InputMask';
import { getSubdomain } from '~/helpers/getSubdomain';
import { AccountStorage } from '~/storages/account';

const FormItem = Form.Item;

class SettingsCompanyForm extends Component {
  state = {
    loading: false,
    imageUrl: this.props.company.logoUrl
  };
  
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { updateCompany } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        request('patch', '/company', values)
        .then(response => {
          updateCompany({ name: values.name });
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { company } = this.props;
    const { loading, imageUrl } = this.state;
    const context = this
    
    const uploadButton = (
      <div>
        {loading ? <Icon type="loading" /> : <Icon type="plus" />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    const dataFiles = {
      action: process.env.REACT_APP_API+'/auth/company/logo',
      multiple: false,
      listType: "picture-card",
      showUploadList: false,
      headers: {
        Authorization: `Bearer ${AccountStorage.get().token}`,
        'X-Tenant': getSubdomain()
      },
      onChange(info) {
        if (info.file.status === 'uploading') {
          context.setState({ loading: true, imageUrl: null });
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          context.getBase64(info.file.originFileObj, imageUrl =>
            context.setState({
              imageUrl,
              loading: false,
            }),
          );
        }
      },
      beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
      }
    };

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 4,
        },
      },
    };

    return (
      <Form layout="horizontal" onSubmit={this.handleSubmit}>
        <FormItem {...formItemLayout} label="Nome Fantasia">
          {getFieldDecorator('name', {
             initialValue: company.name,
            rules: [{ required: true, message: 'Por favor informe o nome fantasia!', whitespace: true }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Razão Social">
          {getFieldDecorator('legal_name', {
             initialValue: company.legal_name,
            rules: [{ required: true, message: 'Por favor informe a Razão Social!', whitespace: true }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Contador">
          {getFieldDecorator('accountant', {
             initialValue: company.accountant,
            rules: [{ required: false, message: 'Por favor informe o Contador!', whitespace: true }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="CRC">
          {getFieldDecorator('crc', {
             initialValue: company.crc,
            rules: [{ required: false, message: 'Por favor informe o nome do CRC!', whitespace: true }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Endereço">
          {getFieldDecorator('address', {
             initialValue: company.address,
            rules: [{ required: false, message: 'Por favor informe o Endereço!', whitespace: true }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="CEP">
          {getFieldDecorator('zipcode', {
             initialValue: company.zipcode,
            rules: [{ required: false, message: 'Por favor informe o CEP!', whitespace: true }],
          })(
            <InputMask 
                  mask="99999-999" 
                  formatChars={{"9": "[0-9]"}} 
                  maskChar={null}                  
              />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Município">
          {getFieldDecorator('city', {
             initialValue: company.city,
            rules: [{ required: false, message: 'Por favor informe o Município!', whitespace: true }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Estado">
          {getFieldDecorator('state', {
             initialValue: company.state,
            rules: [{ required: false, message: 'Por favor informe o Estado!', whitespace: true }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="CNPJ">
          {getFieldDecorator('national_register', {
             initialValue: company.national_register,
            rules: [{ required: false, message: 'Por favor informe o CNPJ!', whitespace: true }],
          })(
            <InputMask 
                  mask="99.999.999/9999-99" 
                  formatChars={{"9": "[0-9]"}} 
                  maskChar={null}
                  
              />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Site">
          {getFieldDecorator('website', {
             initialValue: company.national_register,
            rules: [{ required: false, message: 'Por favor informe o Site!', whitespace: true }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Email">
          {getFieldDecorator('email', {
             initialValue: company.email,
            rules: [{ required: false, message: 'Por favor informe o Email!', whitespace: true }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Telefone">
          {getFieldDecorator('phone', {
             initialValue: company.phone,
            rules: [{ required: false, message: 'Por favor informe o Telefone!', whitespace: true }],
          })(
            <InputMask 
                  mask="(99) 9999tt999?" 
                  formatChars={{"9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]"}} 
                  maskChar={null}
              />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Selecione a logo">
            <Upload               
              {...dataFiles}
              >
              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
        </FormItem>
            
        
        
        <FormItem {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">Salvar</Button>
        </FormItem>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return{
      company: state.user.tenant
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCompany: (data) => dispatch({type: 'UPDATE_COMPANY', payload: data})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(SettingsCompanyForm));