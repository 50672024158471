import styled from "styled-components";

export const Body = styled.div``;

export const Footer = styled.div`
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  text-align: left;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 30px;
`;
