/* eslint-disable no-useless-computed-key */
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import * as dashTasksHelpers from '~/screens/Societario/helpers';
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import * as drawerLeads from '~/containers/DrawerLeads/actions';


const defaultState = {
    not_started: [],
    in_process: [],
    done: [],
    archived: [],
    pending: [],
    isLoading: true,
}

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.dashTasks]: (state, action) => {
        return action.payload;
    },

    ['LOAD_DASHTASKS_SUCCESS']: (state, action) => {
        const dashTasks = action.payload.data.data;
        return {
            not_started: dashTasks.filter(item => dashTasksHelpers.checkIfIsNotStarted(item)),
            in_process: dashTasks.filter(item => dashTasksHelpers.checkIfInProcess(item)),
            done: dashTasks.filter(item => dashTasksHelpers.checkIfIsDone(item)),
            pending: dashTasks.filter(item => dashTasksHelpers.checkIfIsPending(item)),
            archived: dashTasks.filter(item => dashTasksHelpers.checkIfIsArchived(item)),
            isLoading: false
        }
    },

    ['UPDATE_DASHTASK_LIST']: (state, action) => {
        return {
            ...state,
            [action.payload.list]: action.payload.data
        };
    },

    ['@@SOCIETARIO/CREATE_SUCCESS']: (state, action) => {
        const leadId = action.payload.data.data.lead_id ? action.payload.data.data.lead_id : null;

        const newState = {
            ...state,
            not_started: [
                {...action.payload.data.data},
                ...state.not_started
            ]
        }

        if (leadId) {
            newState.pending = state.pending.filter(({ lead_id }) => parseInt(lead_id) !== parseInt(leadId))
        }

        return newState;
    },

    ['@@DRAWERSOCIETARIO/UPDATE_SUCCESS']: (state, action) => {
        const allTasks = [
            ...state.not_started,
            ...state.in_process,
            ...state.done,
            ...state.pending,
            ...state.archived
        ];
        
        const updatedTask = action.payload.data.data;
        const updatedTaskIndex = allTasks.findIndex(item => item.id === updatedTask.id);
        allTasks[updatedTaskIndex] = updatedTask;

        return {
            not_started: allTasks.filter(item => dashTasksHelpers.checkIfIsNotStarted(item)),
            in_process: allTasks.filter(item => dashTasksHelpers.checkIfInProcess(item)),
            done: allTasks.filter(item => dashTasksHelpers.checkIfIsDone(item)),
            pending: allTasks.filter(item => dashTasksHelpers.checkIfIsPending(item)),
            archived: allTasks.filter(item => dashTasksHelpers.checkIfIsArchived(item)),
        };
    },

    ['@@DRAWERSOCIETARIO/DELETE_SUCCESS']: (state, action) => {
        return {
            not_started: state.not_started.filter(item => item.id !== action.meta.previousAction.id),
            in_process: state.in_process.filter(item => item.id !== action.meta.previousAction.id),
            done: state.done.filter(item => item.id !== action.meta.previousAction.id),
            pending: state.pending.filter(item => item.id !== action.meta.previousAction.id),
            archived: state.archived.filter(item => item.id !== action.meta.previousAction.id),
        };
    },

    ['@@DRAWERSOCIETARIO/ARCHIVE_SUCCESS']: (state, action) => {
        const allTasks = [
            ...state.not_started,
            ...state.in_process,
            ...state.done,
            ...state.pending,
            ...state.archived,
        ];

        const updatedTask = action.payload.data.data;
        const updatedTaskIndex = allTasks.findIndex(item => item.id === updatedTask.id);
        allTasks[updatedTaskIndex] = updatedTask;

        return {
            not_started: allTasks.filter(item => dashTasksHelpers.checkIfIsNotStarted(item)),
            in_process: allTasks.filter(item => dashTasksHelpers.checkIfInProcess(item)),
            done: allTasks.filter(item => dashTasksHelpers.checkIfIsDone(item)),
            pending: allTasks.filter(item => dashTasksHelpers.checkIfIsPending(item)),
            archived: allTasks.filter(item => dashTasksHelpers.checkIfIsArchived(item)),
        };
    },

    ['@@DRAWERSOCIETARIO/UNARCHIVE_SUCCESS']: (state, action) => {
        const allTasks = [
            ...state.not_started,
            ...state.in_process,
            ...state.done,
            ...state.pending,
            ...state.archived
        ];

        const updatedTask = action.payload.data.data;
        const updatedTaskIndex = allTasks.findIndex(item => item.id === updatedTask.id);
        allTasks[updatedTaskIndex] = updatedTask;

        return {
            not_started: allTasks.filter(item => dashTasksHelpers.checkIfIsNotStarted(item)),
            in_process: allTasks.filter(item => dashTasksHelpers.checkIfInProcess(item)),
            done: allTasks.filter(item => dashTasksHelpers.checkIfIsDone(item)),
            pending: allTasks.filter(item => dashTasksHelpers.checkIfIsPending(item)),
            archived: allTasks.filter(item => dashTasksHelpers.checkIfIsArchived(item)),
        };
    },

    // if user save a lead, we need to update the lead property inside of the task
    [drawerLeads.SAVE.SUCCESS]: (state, action) => {
        const newState = {
            ...state,
            not_started: state.not_started.map(item => {
                if(item.lead_id === action.payload.data.data.id){
                    return {
                        ...item,
                        lead: action.payload.data.data
                    }
                }
                return item;
            }),
            pending: state.pending.map(item => {
                if(item.lead_id === action.payload.data.data.id){
                    return {
                        ...item,
                        lead: action.payload.data.data
                    }
                }
                return item;
            }),
            in_process: state.in_process.map(item => {
                if(item.lead_id === action.payload.data.data.id){
                    return {
                        ...item,
                        lead: action.payload.data.data
                    }
                }
                return item;
            }),
            done: state.done.map(item => {
                if(item.lead_id === action.payload.data.data.id){
                    return {
                        ...item,
                        lead: action.payload.data.data
                    }
                }
                return item;
            }),
        }

        return newState;
    },

    ['SAVE_CUSTOMER_SUCCESS']: (state, action) => {
        const newState = {
            ...state,
            not_started: state.not_started.map(item => {
                if(item.customer_id === action.payload.data.data.id){
                    return {
                        ...item,
                        customer: action.payload.data.data
                    }
                }
                return item;
            }),
            pending: state.pending.map(item => {
                if(item.customer_id === action.payload.data.data.id){
                    return {
                        ...item,
                        customer: action.payload.data.data
                    }
                }
                return item;
            }),
            in_process: state.in_process.map(item => {
                if(item.customer_id === action.payload.data.data.id){
                    return {
                        ...item,
                        customer: action.payload.data.data
                    }
                }
                return item;
            }),
            done: state.done.map(item => {
                if(item.customer_id === action.payload.data.data.id){
                    return {
                        ...item,
                        customer: action.payload.data.data
                    }
                }
                return item;
            }),
        }

        return newState;
    }
}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if(!handler) return state;

    const newState = handler(state, action);

    const updateCache = [
        'LOAD_DASHTASKS_SUCCESS',
        '@@SOCIETARIO/CREATE_SUCCESS',
        '@@DRAWERSOCIETARIO/UPDATE_SUCCESS',
        '@@DRAWERSOCIETARIO/DELETE_SUCCESS',
        '@@DRAWERSOCIETARIO/ARCHIVE_SUCCESS',
        '@@DRAWERSOCIETARIO/UNARCHIVE_SUCCESS',
    ].includes(action.type);

    if(updateCache){
        LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.dashTasks,
            data: newState
        });
    }

    return newState;
}

export default reducer;