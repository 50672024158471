import React, { Component } from 'react';
import { Form, Col, Row, Input } from 'antd';

import InputMask from '~/components/UI/InputMask';
import InputCurrency, { currency2Float } from '~components/UI/InputCurrency';


class LastAccounting extends Component {
    state = {
        default: {
            last_accounting_name: null,
            last_accounting_owner: null,
            last_accounting_email: null,
            last_accounting_phone: null,
            last_accounting_honorary: null,
        },
        activeForm: {}
    };

  render() {
    const { getFieldDecorator } = this.props.form;
    const data = {
        ...this.state.default,
        ...this.props.data,
        ...this.state.activeForm
    }

    return (
      <Row gutter={16}>
        <Col span={24}>
            <Form.Item label="Nome do Escritório">
            {getFieldDecorator('last_accounting_name', {
                initialValue: data.last_accounting_name,
                // rules: [{ required: true, message: 'Por favor informe o nome da empresa!' }],
            })(<Input placeholder="Insira o nome da empresa" />)}
            </Form.Item>
        </Col>
        <Col span={24}>
            <Form.Item label="Nome do Responsavél">
            {getFieldDecorator('last_accounting_owner', {
                initialValue: data.last_accounting_owner,
                // rules: [{ required: true, message: 'Por favor informe o nome do responsavél pelo escritório!' }],
            })(<Input placeholder="Insira o nome da empresa" />)}
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item label="Email">
                {getFieldDecorator('last_accounting_email', {initialValue: data.last_accounting_email})(
                    <Input placeholder="Email da empresa" />
                )}
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item label="Whatsapp">
                {getFieldDecorator('last_accounting_phone', {initialValue: data.last_accounting_phone})(
                    <InputMask 
                        mask="(99) 9999tt999?" 
                        // eslint-disable-next-line no-useless-escape
                        formatChars={{"9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]"}} 
                        maskChar={null} 
                        placeholder="Telefone do escritório" 
                    />
                )}
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item label="Honorário antigo">
            {getFieldDecorator('last_accounting_honorary', {
                initialValue: data.last_accounting_honorary,
                normalize: (value) => currency2Float(value)
            })(
                <InputCurrency/>
            )}
            </Form.Item>
        </Col>
      </Row>
    )
  }
}

export default LastAccounting;