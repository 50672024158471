export const taxationTypes = [
    'Doméstica',
    'Lucro Presumido',
    'Lucro Real',
    'MEI',
    'Profissional Liberal',
    'Simples Nacional'
];

export const deliveryMethod = [
    {text: 'Email', value: 0, color: 'blue'},
    {text: 'Protocolo', value: 1, color: 'orange'},
    {text: 'Painel Web', value: 2, color: 'purple'},
    {text: 'WhatsApp', value: 3, color: 'green'}
]

export const deliveryMethodNames = {
    0: 'Email',
    1: 'Protocolo',
    2: 'Painel Web',
    3: 'WhatsApp',
};

export const necessityOptions = {
    1: 'Troca de contabilidade',
    2: 'Abertura de Empresa',
    3: 'Encerramento de empresa',
    4: 'BPO Financeiro',
    5: 'Outros',
    6: 'MEI > ME'
};

export const necessityOptionsArray = Object.keys(necessityOptions).map(key => ({
    text: necessityOptions[key],
    value: parseInt(key)
}));