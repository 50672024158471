import React from 'react'
import { Table, Tag } from "antd";

const { Column } = Table;

export function DashTasksTable({ data }) {
  if (!data) return null;
  
  function toBrl(amount) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(amount);
  }
  
  const mappedData = data.map((item) => {
    if (item.property === 'Valores') {
      return {
        ...item,
        opening: toBrl(item.opening),
        changing: toBrl(item.changing),
        closing: toBrl(item.closing),
        account_change: toBrl(item.account_change),
      };
    }
    return item;
  });

  return (
    <Table
      className="overflow-x-auto"
      style={{ marginBottom: 20 }}
      rowKey="property"
      bordered={false}
      pagination={false}
      dataSource={mappedData}
      scroll={{ x: 'auto' }}
    >
      <Column
        key="property"
        dataIndex="property"
        fixed="left"
        width={120}
      />
      <Column
        title={<Tag color="#52c41a">Abertura</Tag>}
        key="opening"
        dataIndex="opening"
        align="center"
      />
      <Column
        title={<Tag color="#faad14">Alteração</Tag>}
        key="changing"
        dataIndex="changing"
        align="center"
      />
      <Column
        title={<Tag color="#595959">Encerramento</Tag>}
        key="closing"
        dataIndex="closing"
        align="center"
      />
      <Column
        title={<Tag color="#f5222d">Troca de contabilidade</Tag>}
        key="account_change"
        dataIndex="account_change"
        align="center"
      />
    </Table>
  )
}