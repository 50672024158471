import styled from "styled-components";

export const Container = styled.div`
  font-family: 'Roboto';
  margin-bottom: 16px;
`;

export const Label = styled.span`
  color: #7f00ff;
  font-size: 10px;
`;

export const PlansWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
  align-items: stretch;
  flex-direction: ${({ isMobileScreen }) => isMobileScreen ? 'column' : 'row'};
`;

export const Plan = styled.div`
  padding: 8px;
  padding-bottom: ${({ hasCoupon }) => hasCoupon ? '20px' : '8px'};
  flex: 1;
  box-shadow: ${({ isSelected }) => isSelected ? "inset 0 0 0 2px #7F00FF" : "inset 0 0 0 1px #A9ABAC"};
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: ${({ alignItemsCenter }) =>
    alignItemsCenter ? "center" : "flex-start"};
  cursor: pointer;
  transition: all 300ms;
  user-select: none;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: ${({ isSemester }) => isSemester ? '12px' : 'inherit'};
`;

export const ByMonthText = styled.span``;

export const PlanDurationText = styled.span`
  font-size: 12px;
  text-align: end;
`;

export const RenewTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RenewText = styled.span`
  font-size: 12px;
`;

export const PlanName = styled.span`
  font-weight: bold;
`;

export const PlanPriceWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const PlanPriceContaienr = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PlanPrice = styled.span`
  font-size: 20px;
  line-height: 1;
  text-decoration: ${({ hasCoupon }) => hasCoupon ? 'line-through' : 'none'};
  color: ${({ withDiscount }) => withDiscount ? 'green' : 'inherit'};
`;

export const PlanSplit = styled.span`
  margin-right: 4px;
`;

export const MoneySymbol = styled.span``;

export const DiscountWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 10px;
  background-color: green;
  color: white;
  font-weight: 600;
  padding: 2px 40px;
  border-radius: 4px 0 4px 0;
`;