import { Button, Col, Form, Modal, Table, Tabs } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as documentActions from "~/containers/Documents/actions";
import { SporadictDocumentTypeModal } from "./SporadictDocumentTypeModal";
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;

export const SporadicDocumentTypes = () => {
  const [types, setTypes] = useState([]);
  const [isSavingToDatabase, setIsSavingToDatabase] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(1);
  const [typeModal, setTypeModal] = useState({
    isVisible: false,
    data: null,
  });

  const { types: documentTypes } = useSelector((state) => state.documents);
  const dispatch = useDispatch();

  const modifiedTasks = types.filter(
    ({ is_new, is_modified, is_deleted }) => is_new || is_modified || is_deleted
  );

  useEffect(() => {
    setTypes(documentTypes);
  }, [documentTypes]);

  async function saveDocumentTypesRequest(types) {
    return dispatch(documentActions.saveDocumentTypes({ types }));
  }

  function handleDelete(id) {
    Modal.confirm({
      title: "Você realmente deseja remover este tipo de documento?",
      content: "Esta ação não poderá ser desfeita.",
      okText: "Sim",
      okType: "danger",
      cancelText: "Cancelar",
      onOk: () => {
        setTypes(
          types.map((item) => {
            if (item.id !== id) return item;

            return {
              ...item,
              is_deleted: true,
            };
          })
        );
      },
    });
  }

  function showTypeModal(data = null) {
    setTypeModal({
      isVisible: true,
      data,
    });
  }

  function hideTypeModal() {
    setTypeModal({
      isVisible: false,
      data: null,
    });
  }

  function handleCreate(newType) {
    setTypes([...types, newType]);
  }

  function handleUpdate(updatedType) {
    setTypes(
      types.map((type) => {
        if (type.id !== updatedType.id) {
          return type;
        }

        return updatedType;
      })
    );
  }

  function renderTypesByDepartment(department) {
    if (selectedDepartment !== department) return null;

    return (
      <Table
        rowKey="id"
        dataSource={types.filter(
          (type) => type.department === department && !type.is_deleted
        )}
        bordered
        pagination={getDefaultPaginationConfig({
          storageKey: STORAGE.defaultPageSize.sporadicDocumentTypesConfig,
          defaultPageSize: 20
        })}
      >
        <Column
          key="description"
          dataIndex="description"
          title="Nome"
          sorter={(a, b) => a.description.localeCompare(b.description)}
          defaultSortOrder="ascend"
        />
        <Column
          key="actions"
          width={80}
          align="center"
          render={(_, type) => (
            <div style={{ display: "flex", gap: 6 }}>
              <Button
                type="primary"
                icon="edit"
                onClick={() => showTypeModal(type)}
              />
              <Button
                type="danger"
                icon="delete"
                onClick={() => handleDelete(type.id)}
              />
            </div>
          )}
        />
      </Table>
    );
  }

  async function saveToDatabase() {
    try {
      setIsSavingToDatabase(true);
      await saveDocumentTypesRequest(modifiedTasks);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSavingToDatabase(false);
    }
  }

  return (
    <Form layout="horizontal">
      <Col offset={4}>
        <Tabs
          type="card"
          activeKey={String(selectedDepartment)}
          onChange={(key) => setSelectedDepartment(Number(key))}
        >
          <Tabs.TabPane tab="Legalização" key="1">
            {renderTypesByDepartment(1)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Administrativo" key="2">
            {renderTypesByDepartment(2)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Contábil" key="3">
            {renderTypesByDepartment(3)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Fiscal" key="4">
            {renderTypesByDepartment(4)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Pessoal" key="5">
            {renderTypesByDepartment(5)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Sucesso do Cliente (CS)" key="6">
            {renderTypesByDepartment(6)}
          </Tabs.TabPane>
        </Tabs>
      </Col>
      <Form.Item
        wrapperCol={{
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 4,
          },
        }}
      >
        <Button type="dashed" onClick={() => showTypeModal()}>
          Adicionar tipo
        </Button>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 4,
          },
        }}
      >
        <Button
          type="primary"
          onClick={saveToDatabase}
          loading={isSavingToDatabase}
          disabled={modifiedTasks.length === 0}
        >
          Salvar
        </Button>
      </Form.Item>
      <SporadictDocumentTypeModal
        isVisible={typeModal.isVisible}
        onClose={hideTypeModal}
        data={typeModal.data}
        selectedDepartment={selectedDepartment}
        onCreateType={handleCreate}
        onUpdateType={handleUpdate}
        types={types}
      />
    </Form>
  );
};
