import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Select } from 'antd';
import { useDispatch } from 'react-redux';

import { formOptions } from './formOptions';
import { availableAutomations } from './availableAutomations';
import { CertificateFormModal } from './components/CertificateFormModal';
import { AutomationConditionField } from './components/AutomationConditionField';
import * as pluginsActions from '~/containers/Plugins/actions';

const { Option } = Select;

export const AutomationModal = Form.create()(
  ({ isVisible, onClose, automationId, automations, setAutomations, form }) => {
    const dispatch = useDispatch();

    const {
      getFieldDecorator,
      validateFields,
      setFieldsValue,
      getFieldsValue,
      resetFields,
    } = form;

    const [isSubmiting, setIsSubmiting] = useState(false);
    const [selectedAutomation, setSelectedAutomation] = useState(null);
    const [conditions, setConditions] = useState([1]);
    const [certificateFormModal, setCertificateFormModal] = useState({
      isVisible: false,
    });

    useEffect(() => {
      if (automationId) {
        const selectedAutomation = automationId
          ? automations.find(({ id }) => id === automationId)
          : null;

        if (selectedAutomation) {
          const {
            trigger,
            action,
            conditions: currentConditions,
          } = selectedAutomation;

          const fields = {
            trigger,
            action,
          };

          setFieldsValue(fields);
          setSelectedAutomation(selectedAutomation);
          setConditions(
            currentConditions.map((_, index) => {
              if (index === 0) return 1;
              return index + 1;
            })
          );
        }
      }
    }, [automationId]);

    function closeModal() {
      resetFields();
      setSelectedAutomation(null);
      setConditions([1]);
      onClose();
    }

    function handleSubmit() {
      validateFields(async (error, values) => {
        if (error) {
          const hasErrorInCertificateFormModal =
            Object.keys(error).length === 1 &&
            error.hasOwnProperty('certificate');

          if (hasErrorInCertificateFormModal) {
            showCertificateFormModal();
          }
          return;
        }

        const { action, conditions, trigger, ...extraFields } = values;

        const formattedConditions = conditions
          .filter(({ is_deleted }) => !is_deleted)
          .map(({ is_deleted, ...otherFieds }) => otherFieds);

        const fields = {
          config: {
            action,
            conditions: formattedConditions,
            trigger,
          },
          extra_fields: {
            ...extraFields,
          },
        };

        setIsSubmiting(true);
        try {
          const { updateAutomation, createAutomation } = pluginsActions;

          if (selectedAutomation) {
            const {
              payload: {
                data: { automation: automationFromApi },
              },
            } = await dispatch(
              updateAutomation({
                data: fields,
                id: selectedAutomation.id,
              })
            );
            setAutomations((state) => {
              return state.map((automation) => {
                if (automation.id !== automationFromApi.id) {
                  return automation;
                }
                return automationFromApi;
              });
            });
          } else {
            const {
              payload: {
                data: { automation: automationFromApi },
              },
            } = await dispatch(createAutomation(fields));
            setAutomations((state) => [...state, automationFromApi]);
          }
          closeModal();
        } catch (error) {
          console.log(error);
        } finally {
          setIsSubmiting(false);
        }
      });
    }

    function hideCertificateFormModal() {
      setCertificateFormModal({
        isVisible: false,
      });
    }

    function showCertificateFormModal() {
      setCertificateFormModal({
        isVisible: true,
      });
    }

    const { trigger: activeTrigger, action: activeAction } = getFieldsValue();

    const { actions, conditionFields } = activeTrigger
      ? availableAutomations.find(
          ({ trigger }) => trigger.value === activeTrigger
        )
      : { actions: [], conditionFields: [] };

    const showEditCertificateButton =
      activeTrigger === 'when_creating_customer' &&
      activeAction === 'create_certificate';

    return (
      <Modal
        title={!!automationId ? 'Editar' : 'Nova automação'}
        visible={isVisible}
        onCancel={closeModal}
        okText={!!automationId ? 'Salvar' : 'Cadastrar'}
        cancelText="Voltar"
        onOk={handleSubmit}
        okButtonProps={{ loading: isSubmiting }}
        width={650}
      >
        <Form layout="vertical">
          <Form.Item label="Gatilho">
            {getFieldDecorator(
              'trigger',
              formOptions.trigger
            )(
              <Select placeholder="Selecione um Gatilho">
                {availableAutomations.map(
                  ({ trigger: { value, description } }) => (
                    <Option key={value} value={value}>
                      {description}
                    </Option>
                  )
                )}
              </Select>
            )}
          </Form.Item>
          <AutomationConditionField
            conditionFields={conditionFields}
            activeTrigger={activeTrigger}
            selectedAutomation={selectedAutomation}
            setConditions={setConditions}
            conditions={conditions}
            form={form}
          />
          <Form.Item label="Ação">
            {getFieldDecorator(
              'action',
              formOptions.action
            )(
              <Select
                placeholder="Selecione uma ação"
                disabled={!activeTrigger}
              >
                {actions.map(({ value, description }) => (
                  <Option key={value} value={value}>
                    {description}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Form>
        {showEditCertificateButton && (
          <Button block icon="edit" onClick={showCertificateFormModal}>
            Certificado digital
          </Button>
        )}
        <CertificateFormModal
          isVisible={certificateFormModal.isVisible}
          onClose={hideCertificateFormModal}
          form={form}
        />
      </Modal>
    );
  }
);
