import { Button, Drawer, Form, Input, Modal, Select, Switch } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import * as linksActions from '~/containers/Links/actions';
import { Footer } from './styles';

const DrawerLinks = ({
  closeDrawer,
  isDrawerOpen,
  createLink,
  updateLink,
  deleteLink,
  form,
  permissions,
  data,
  linkCategories,
}) => {
  const { getFieldDecorator } = form;
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (data) {
      const {
        title,
        address,
        description,
        link_category_id,
        permissions_groups_ids,
        show_in_menu,
      } = data;

      form.setFieldsValue({
        title,
        address,
        description,
        link_category_id,
        permissions_groups_ids: permissions_groups_ids || [],
        show_in_menu,
      });
    }
  }, [isDrawerOpen]);

  const isEditing = !!data;

  function handleSubmit() {
    form.validateFields(async (errors, values) => {
      if (errors) return;

      setIsLoading(true);

      try {
        if (isEditing) {
          await updateLink(data.id, values);
        } else {
          await createLink(values);
        }

        closeDrawer();
      } finally {
        setIsLoading(false);
      }
    });
  }

  function handleDelete() {
    Modal.confirm({
      title: 'Você realmente deseja remover este link?',
      content: 'Você não poderá reverter esta ação no futuro.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        setIsDeleting(true);

        try {
          await deleteLink(data.id);
        } finally {
          setIsDeleting(false);
          closeDrawer();
        }
      },
    });
  }

  return (
    <Drawer
      title={`${isEditing ? 'Editar' : 'Cadastrar'} link`}
      width={500}
      placement="right"
      onClose={closeDrawer}
      visible={isDrawerOpen}
      destroyOnClose={true}
    >
      <Form layout="vertical">
        <Form.Item label="Título">
          {getFieldDecorator('title', {
            rules: [
              {
                required: true,
                message: 'Por favor informe o título do link',
              },
            ],
          })(<Input placeholder="Título" />)}
        </Form.Item>
        <Form.Item label="Endereço">
          {getFieldDecorator('address', {
            rules: [
              {
                required: true,
                message: 'Por favor informe o endereço do link',
              },
              {
                type: 'url',
                message: 'Por favor, insira uma URL válida',
              },
            ],
          })(<Input placeholder="Endereço" />)}
        </Form.Item>
        <Form.Item label="Descrição">
          {getFieldDecorator('description', {
            rules: [
              {
                required: true,
                message: 'Por favor informe a descrição do link',
              },
            ],
          })(<Input.TextArea placeholder="Descrição" rows={3} />)}
        </Form.Item>
        <Form.Item label="Categoria">
          {getFieldDecorator('link_category_id', {
            rules: [
              {
                required: true,
                message: 'Por favor selecione uma categoria',
              },
            ],
          })(
            <Select placeholder="Selecine uma opção">
              {linkCategories.map((category) => (
                <Select.Option key={category.id} value={category.id}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Permissão">
          {getFieldDecorator('permissions_groups_ids')(
            <Select placeholder="Selecine uma opção" mode="multiple">
              {permissions.map((permission) => (
                <Select.Option key={permission.id} value={permission.id}>
                  {permission.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Exibir no menu principal?">
          {getFieldDecorator('show_in_menu', {
            valuePropName: 'checked',
            initialValue: false,
          })(<Switch />)}
        </Form.Item>
      </Form>
      <Footer>
        {isEditing && (
          <Button
            style={{ marginRight: 8 }}
            icon="delete"
            type="danger"
            onClick={handleDelete}
            loading={isDeleting}
          >
            Remover
          </Button>
        )}
        <Button
          style={{
            marginRight: 8,
          }}
          onClick={closeDrawer}
        >
          Cancelar
        </Button>
        <Button onClick={handleSubmit} type="primary" loading={isLoading}>
          {isEditing ? 'Salvar' : 'Cadastrar'}
        </Button>
      </Footer>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  isDrawerOpen: state.links.drawer.isOpen,
  data: state.links.drawer.data,
  permissions: state.permissions.list,
  linkCategories: state.links.categories,
});

const mapDispatchProps = (dispatch) => ({
  closeDrawer: () => dispatch(linksActions.closeDrawer()),
  createLink: (data) => dispatch(linksActions.createLink(data)),
  updateLink: (id, data) => dispatch(linksActions.updateLink(id, data)),
  deleteLink: (id) => dispatch(linksActions.deleteLink(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchProps
)(Form.create()(DrawerLinks));
