import { Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { formOptions } from '../../formOptions';
import {
  ConditionField,
  ConditionFieldContainer,
  ConditionText,
  Container,
  FieldSelect,
  LogicalOperatorField,
  NewConditionButton,
  OperatorField,
  OperatorSelect,
  ValueField,
  ValueFieldContainer,
  ValueSelect,
} from './styles';

const { Option } = Select;

export const AutomationConditionField = ({
  conditionFields,
  activeTrigger,
  selectedAutomation,
  conditions,
  setConditions,
  form,
}) => {
  const { getFieldValue, getFieldDecorator, setFieldsValue } = form;

  const [currentConditionsFields, setCurrentConditionsFields] = useState([]);

  useEffect(() => {
    setCurrentConditionsFields(
      conditionFields.map((condition) => {
        const alreadySelectedIndex = selectedAutomation
          ? selectedAutomation.conditions.findIndex(
              ({ field }) => field === condition.value
            )
          : -1;

        return {
          ...condition,
          already_selected_by:
            alreadySelectedIndex !== -1 ? alreadySelectedIndex + 1 : null,
        };
      })
    );
  }, [conditionFields]);

  useEffect(() => {
    if (selectedAutomation) {
      const { conditions, extra_fields } = selectedAutomation;
      const fields = {};
      conditions.forEach(
        ({ field, logical_operator, operator, value }, index) => {
          fields[`conditions[${index}][field]`] = field;
          fields[`conditions[${index}][logical_operator]`] = logical_operator;
          fields[`conditions[${index}][operator]`] = operator;
          fields[`conditions[${index}][value]`] = value;
        }
      );
      if (extra_fields.certificate) {
        fields['certificate[nature]'] = extra_fields.certificate.nature;
        fields['certificate[type]'] = extra_fields.certificate.type;
      }

      setFieldsValue(fields);
    }
  }, [selectedAutomation]);

  function createAdditionalCondition() {
    setConditions((state) => [...state, conditions.length + 1]);
  }

  function selectConditionField(selectedCondition, id, index) {
    setFieldsValue({
      [`conditions[${index}][value]`]: undefined,
    });

    setCurrentConditionsFields((state) => {
      return state.map((condition) => {
        if (condition.value !== selectedCondition) {
          if (condition.already_selected_by === id) {
            return {
              ...condition,
              already_selected_by: null,
            };
          }

          return condition;
        }

        return {
          ...condition,
          already_selected_by: id,
        };
      });
    });
  }

  function removeConditionField(id, index) {
    setCurrentConditionsFields((state) => {
      return state.map((conditionField) => {
        if (conditionField.already_selected_by !== id) {
          return conditionField;
        }
        return {
          ...conditionField,
          already_selected_by: null,
        };
      });
    });
    setFieldsValue({
      [`conditions[${index}][is_deleted]`]: true,
    });
  }

  const areAllConditionsFieldsSelected = currentConditionsFields.every(
    ({ already_selected_by }) => !!already_selected_by
  );

  const conditionsRows = conditions.map((id, index) => {
    const isFirstCondition = id === 1;

    const isDeleted = getFieldValue(`conditions[${index}][is_deleted]`);
    const activeConditionField = getFieldValue(`conditions[${index}][field]`);
    const activeConditionOperator = getFieldValue(
      `conditions[${index}][operator]`
    );
    const activeConditionLogicalOperator = !isFirstCondition
      ? getFieldValue(`conditions[${index}][logical_operator]`)
      : null;

    const { options: activeConditionFieldOptions } = activeConditionField
      ? currentConditionsFields.find(
          ({ value }) => value === activeConditionField
        )
      : { options: [] };

    return (
      <Container key={id} show={isDeleted ? 'false' : 'true'}>
        {getFieldDecorator(`conditions[${index}][is_deleted]`, {
          initialValue: false,
        })(<input hidden />)}
        {isFirstCondition ? (
          <ConditionField label="Condição">
            <ConditionFieldContainer>
              <ConditionText>Quando</ConditionText>
              {getFieldDecorator(
                `conditions[${index}][field]`,
                isDeleted ? undefined : formOptions.conditionField
              )(
                <FieldSelect
                  disabled={!activeTrigger}
                  onChange={(value) => selectConditionField(value, id, index)}
                >
                  {currentConditionsFields.map(
                    ({ value, description, already_selected_by }) => (
                      <Option
                        key={value}
                        value={value}
                        // disabled={!!already_selected_by}
                      >
                        {description}
                      </Option>
                    )
                  )}
                </FieldSelect>
              )}
            </ConditionFieldContainer>
          </ConditionField>
        ) : (
          <>
            <LogicalOperatorField>
              {getFieldDecorator(
                `conditions[${index}][logical_operator]`,
                isDeleted ? undefined : formOptions.conditionLogicalOperator
              )(
                <Select disabled={!activeTrigger}>
                  <Option value="and">E</Option>
                  <Option value="or">Ou</Option>
                </Select>
              )}
            </LogicalOperatorField>
            <ConditionField>
              {getFieldDecorator(
                `conditions[${index}][field]`,
                isDeleted ? undefined : formOptions.conditionField
              )(
                <FieldSelect
                  disabled={!activeConditionLogicalOperator}
                  onChange={(value) => selectConditionField(value, id, index)}
                >
                  {currentConditionsFields.map(
                    ({ value, description, already_selected_by }) => (
                      <Option
                        key={value}
                        value={value}
                        // disabled={!!already_selected_by}
                      >
                        {description}
                      </Option>
                    )
                  )}
                </FieldSelect>
              )}
            </ConditionField>
          </>
        )}
        <OperatorField>
          {getFieldDecorator(
            `conditions[${index}][operator]`,
            isDeleted ? undefined : formOptions.conditionOperator
          )(
            <OperatorSelect disabled={!activeConditionField}>
              <Option value="=">É</Option>
              <Option value="!=">Não é</Option>
            </OperatorSelect>
          )}
        </OperatorField>
        <ValueField>
          <ValueFieldContainer>
            {getFieldDecorator(
              `conditions[${index}][value]`,
              isDeleted ? undefined : formOptions.conditionValue
            )(
              <ValueSelect
                disabled={!activeConditionOperator}
                is_first={isFirstCondition ? 'true' : 'false'}
              >
                {activeConditionFieldOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </ValueSelect>
            )}
            {!isFirstCondition && (
              <Button
                type="danger"
                icon="delete"
                onClick={() => removeConditionField(id, index)}
              />
            )}
          </ValueFieldContainer>
        </ValueField>
      </Container>
    );
  });

  return (
    <>
      {conditionsRows}
      <NewConditionButton
        icon="plus"
        size="small"
        onClick={createAdditionalCondition}
        disabled={!activeTrigger || areAllConditionsFieldsSelected}
      >
        Nova condição
      </NewConditionButton>
    </>
  );
};
