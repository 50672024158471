import { createAsyncActions } from '~/utils/store';

export const LOAD_MALHA_FINA = createAsyncActions('@@MALHAFINA/LOAD_MALHA_FINA');
export const STATUS = createAsyncActions('@@MALHAFINA/STATUS');
export const DELETE_REGISTER = createAsyncActions('@@MALHA_FINA/DELETE_REGISTER');
export const DELETE_ALL_REGISTER_OF_MONTH = createAsyncActions('@@MALHA_FINA/DELETE_ALL_REGISTERS_OF_MONTH');
export const SEND_WHATSAPP = createAsyncActions('@@MALHA_FINA/SEND_WHATSAPP');
export const SEND_EMAIL = createAsyncActions('@@MALHA_FINA/SEND_EMAIL');
export const SEND_EMAILS_IN_BATCH = createAsyncActions('@@MALHA_FINA/SEND_EMAILS_IN_BATCH');
export const LOAD_EMAILS_LOGS = createAsyncActions('@@MALHA_FINA/LOAD_EMAILS_LOGS');

export const loadMalhaFina = ({ department, year, ...params }) => ({
    type: LOAD_MALHA_FINA.INIT,
    payload: {
        request: {
            method: 'GET',
            url: `/malha-fina/${department}/year/${year}`,
            ...params
        },
        department,
      }
});

export const changeStatus = (department, data) => ({
    type: STATUS.INIT,
    payload: {
        request:{
            method: 'put',
            data,
            url:`/malha-fina/${department}/year/${data.year}/status`
        },
        data,
        department
      }
});

export const deleteRegister = (department, data) => ({
    type: DELETE_REGISTER.INIT,
    payload: {
        request: {
            method: 'DELETE',
            url: `/malha-fina/${department}/year/${data.year}`,
            data,
        },
        department,
        data
    }
});

export const deleteAllRegisterOfMonth = (department, data) => ({
    type: DELETE_ALL_REGISTER_OF_MONTH.INIT,
    payload: {
        request: {
            method: 'DELETE',
            url: `/malha-fina/${department}/year/${data.year}/${data.month_base}`
        },
        department,
        data
    }
})

export const sendWhatsapp = (department, data) => ({
    type: SEND_WHATSAPP.INIT,
    payload: {
        request: {
            method: 'PATCH',
            url: `/malha-fina/${department}/year/${data.year}/whatsapp`,
            data,
        },
        department,
        data
    }
});

export const sendEmail = (department, data) => ({
    type: SEND_EMAIL.INIT,
    payload: {
        request: {
            method: 'PATCH',
            url: `/malha-fina/${department}/year/${data.year}/email`,
            data,
        },
        department,
        data
    }
});

export const sendEmailsInBatch = ({ department, year, month, ids }) => ({
    type: SEND_EMAILS_IN_BATCH.INIT,
    payload: {
        request: {
            method: 'PATCH',
            url: `/malha-fina/${department}/year/${year}/month/${month}/emails/batch`,
            data: {
                ids
            },
        },
        department,
        year,
        month,
        ids
    }
});

export const loadEmailsLogs = (
    department,
    { year, month_base, id }
) => ({
    type: LOAD_EMAILS_LOGS.INIT,
    payload: {
      request: {
        method: 'GET',
        url: `/malha-fina/${department}/year/${year}/month/${month_base}/customer/${id}/emails/logs`,
      },
    },
});