import moment from 'moment';

export const getNextTask = (tasks) => {
  let nextTask = null;

  tasks.forEach((task, index) => {
    if (index === 0) {
      nextTask = task;
    } else {
      if (moment(task.due_at).isAfter(moment(nextTask.due_at))) {
        nextTask = task;
      }
    }
  });

  return nextTask;
};
