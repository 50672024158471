//no-unused-vars
import React from 'react';
import axios from 'axios';
import { message } from 'antd';
import { getSubdomain } from '~/helpers/getSubdomain';
import { DEFAULT_MESSAGES } from '~/constants/default-messages.constants';
import { AccountStorage } from '~/storages/account';

const instance = (loading = true) => {

    const instance = axios.create({
        baseURL: `${process.env.REACT_APP_API}/auth`,
        timeout: 120000,
        headers: {
            Authorization: `Bearer ${AccountStorage.get().token}`,
            'X-Tenant': getSubdomain()
        },
    });
    
    instance.interceptors.request.use(function (config) {

        if(loading){
            message.loading('Aguarde....', 0);
        }

        return config;
      }, function (error) {
        return Promise.reject(error);
    });
    
    instance.interceptors.response.use(function (response) {

        message.destroy();
        if(response.status === 200 && response.data.message !== undefined){
            message.success(response.data.message);
        }

        return response;
      }, function (error) {

        message.destroy();

        if (error.response) {
            if(error.response.status === 422 && error.response.data.message !== undefined){
                message.error(error.response.data.message);
            }else if(error.response.status === 401){
                message.error('Necessário refazer o login!');
            }else if (error.response.status === 404 || error.response.status >= 500) {
                message.error(DEFAULT_MESSAGES['high-demand']);
            }
        } else {
            message.error('Não foi possível concluir sua solicitação!');
        }

        return Promise.reject(error);
    });

    return instance;
};

export const request = (method, route, data, loading = true, add = {}) => {
    return instance(loading).request({
        url: route,
        method: method,
        data,
        ...add
    });
}

export const client = axios.create({
    baseURL:`${process.env.REACT_APP_API}/auth`,
    timeout: 180000,
    responseType: 'json',
    headers: {
        'X-Tenant': getSubdomain()
    }
});  

message.config({
    maxCount: 1,
});

export const options = {
    returnRejectedPromiseOnError: true,
    interceptors: {
        request: [
            ({ getState, dispatch }, config) => {
                if(!config.hasOwnProperty('showLoading') || config.showLoading){
                    message.loading('Aguarde....', 0);
                }

                const { token } = AccountStorage.get();

                if (token !== null) {
                    config.headers['Authorization'] = `Bearer ${token}`
                }

              return config
            }
        ],
        response: [
        {
          success: ({ dispatch }, response) => {
            message.destroy();

            if(response.status === 200 && typeof response.data.message !== 'undefined'){
                message.success(response.data.message);
            }
            return response
          },
          error: ({ dispatch }, error) => {
            message.destroy();

            if (error.response) {
                if(error.response.status === 422 && error.response.data.message !== undefined){
                    message.error(error.response.data.message);
                }else if(error.response.status === 401){
                    message.error('Necessário refazer o login!');
                }else if (error.response.status === 404 || error.response.status >= 500) {
                    message.error(DEFAULT_MESSAGES['high-demand']);
                }
            } else {
                message.error('Não foi possível concluir sua solicitação!');
            }

            return Promise.reject(error)
          }
        }
      ]
    }
}  

export default instance;