import { Col, Form, Input, Modal, Row } from 'antd';
import React from 'react';
import uid from 'uid';
import InputMask from '~/components/UI/InputMask';

export const IndicationModal = Form.create()(({
    isVisible,
    form,
    closeModal,
    setIndications,
    indications
}) => {
  const { getFieldDecorator, validateFields, setFields } = form;

  function createIndication() {
    validateFields((errors, values) => {
      if (errors) return;
      const emailAlreadyExists = !!values.email && indications.find((indication) => indication.email === values.email)
        
      if (emailAlreadyExists) {
        setFields({
          email: {
            value: values.email,
            errors: [new Error('O e-mail informado já existe')],
          },
        });
        return;
      }

      setIndications((state) => [
        ...state,
        {
          ...values,
          id: uid(10),
          is_new: true,
        }
      ]);
      closeModal();
    })
  }

  return (
    <Modal
      visible={isVisible}
      title="Nova indicação"
      onOk={createIndication}
      onCancel={closeModal}
      okText="Salvar"
      destroyOnClose
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Nome">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Por favor, informe o nome",
                  },
                ],
              })(
                <Input placeholder="Nome do indicado" />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Whatsapp">
              {getFieldDecorator("phone", {
                rules: [
                  {
                    required: true,
                    message: "Por favor, informe o whatsaap",
                  },
                ],
              })(
                <InputMask
                  mask="(99) 9999tt999?" 
                  // eslint-disable-next-line no-useless-escape
                  formatChars={{"9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]"}} 
                  maskChar={null}
                  placeholder="Whatsapp do indicado"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="E-mail">
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "Por favor, informe um e-mail válido"
                  }
                ],
              })(
                <Input placeholder="E-mail do indicado" />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
})
