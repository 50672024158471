import React from 'react';
import moment from 'moment';
import * as XLSX from 'xlsx/dist/xlsx.full.min.js';

import { leadsTable } from './tableColumns';

export const downloadExcel = ({ selectedRows, columns }) => {

    const showDrawer = () => {};

    const tableColumns = leadsTable.getColumns({columns, showDrawer});

    const data = selectedRows.map((row) => {
        const newRow = {};
        tableColumns.map((column) => {
            if(column.render) {
                newRow[column.title] = column.render(row[column.dataIndex], row);
            }else{
                newRow[column.title] = row[column.dataIndex];
            }
        });
        return newRow;
    });

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Leads");
    XLSX.writeFile(wb, "leads.xlsx");
  }