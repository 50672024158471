import { Button } from "antd";
import styled from "styled-components";

export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const NewTypeButton = styled(Button).attrs({
  style: {
    marginBottom: 50
  }
})``