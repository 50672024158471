export const displaySocCategory = (category) => {
    switch(category){
        case 1: return 'Abertura de Empresa';
        case 2: return 'Alteração Contratual';
        case 3: return 'Encerramento de Empresa';
        case 4: return 'Tarefa esporádica';
        case 5: return 'Troca de Contabilidade';
        case 6: return 'Outros';
        default: return 'Não vinculado ao Societário';
    }
}