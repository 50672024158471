/* eslint-disable default-case */
import React from "react";
import { Row, Col, Tag, Tooltip, Progress, Icon } from "antd";
import Moment from "react-moment";
import styled from "styled-components";

import { UsersAssigned } from "~/components/AssignTo";
import { limitCharacterLength } from "~/utils/formatters";

export const CalendarIcon = styled(Icon).attrs({
  type: 'calendar',
  style: {
    color: 'black',
    marginRight: '5px',
  },
})``;

const ItemTask = styled.div`
    user-select: none;
    padding: 7px;
    margin: 0 0 16px 0;
    border-radius: 3px;
    background: ${props => props.item.payment ? getBackgroundCard(props.item.payment): '#bdecb685'};
    position: relative;

    ${props => props.isDragging && `
        box-shadow: 1.5px 1px 1px #ccc;
    `}

    &:hover {
        box-shadow: 1.5px 1px 1px #ccc;
    }
`

const ItemTaskTitle = styled.div`
    display: block;
    margin-bottom: 8px;
    font-size: 15px;
`

const ItemId = styled.div`
    text-align: right;
    font-size: 13px;
    font-weight: bold;
`

const ItemTaskAmountPaid = styled.span`
  ${props => areAllInstallmentsPaid(props.payment) && `
    color: #52c41a;
    font-weight: bold;
  `}
`

const ItemTaskDate = styled.div`
  font-size: 12px;
`

const ItemPendency = styled.div`
  font-size: 12px;
  line-height: 14px;
`
const PercentageContainer = styled.div`
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 15px;
`;

const ProgressStyled = styled(Progress).attrs({
    strokeColor: '#52c41a',
    showInfo: false,
    size: 'small',
  })``;

const getBackgroundItem = (category) => {
    switch (parseInt(category)) {
      case 1: return '#52c41a'; //Abertura
      case 2: return '#faad14'; //Alteração
      case 3: return '#595959'; //Encerramento
      case 4: return '#1890ff'; //Tarefa
      case 5: return '#388E3C'; //Troca de Contabilidade
      case 6: return '#722ed1'; //Outros
      case 7: return '#f5222d'; //Troca de Contabilidade
    }
  }
  const getTextItem = (category) => {
    switch (parseInt(category)) {
      case 1: return 'Abertura';
      case 2: return 'Alteração';
      case 3: return 'Encerramento';
      case 4: return 'Tarefa';
      case 5: return 'Troca de Contabilidade';
      case 6: return 'Outros';
      case 7: return 'Troca de Contabilidade';
    }
  }
  
  const getPendency = (pendency) => {
    switch (parseInt(pendency)) {
      case 0: return 'Escritório';
      case 1: return 'Orgão Público';
      case 2: return 'Cliente';
    }
  }

  const areAllInstallmentsPaid = (payment) => {
    return payment.installments.every((installment) => installment.status === 'Pago');
  }
  
  const isDefaulter = (payment) => {
    return payment.installments.some((installment) => installment.status === 'Inadimplente');
  }
  const isProcessed = (payment) => {
    return payment.installments.some((installment) => installment.status === 'Ajuizado');
  }
  
  const getBackgroundCard = (payment) => {
    if (areAllInstallmentsPaid(payment)) {
      return '#bdecb685';
    } else if (isDefaulter(payment)) {
      return '#ffa39e';
    } else if (isProcessed(payment)) {
      return '#ffd591';
    }
  
    return '#fff';
  }

  const getTooltipMessage = (category) => {
    switch (parseInt(category)) {
      case 1: return 'Abertura de Empresa';
      case 2: return 'Alteração Contratual';
      case 3: return 'Encerramento de Empresa';
      case 4: return 'Tarefa';
      case 5: return 'Troca de Contabilidade (entrada)';
      case 6: return 'Outros';
      case 7: return 'Troca de Contabilidade (saída)';
    }
  }

export const ItemCard = ({
    item,
    provided,
    snapshot,
    getCustomer,
    showDrawer,
    hasContract,
}) => {

    const progress = Array.isArray(item.tasks) ? (item.tasks.filter(task => task.done).length / item.tasks.length * 100) : 0;

    return (
        <ItemTask
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            isDraggingOver={snapshot.isDraggingOver}
            item={item}
            onClick={() =>
                showDrawer(
                  parseInt(item.category),
                  {
                      ...item,
                      customer: getCustomer(item),
                  }
                )
            }
            >
            <Row
                type="flex"
                justify="center"
                align="bottom"
            >
                <Col span={12}>
                  <ItemTaskDate>
                    <CalendarIcon />
                      <Moment fromNow>
                      {item.updated_at}
                      </Moment>
                  </ItemTaskDate>
                </Col>
                <Col span={12}>
                < ItemId>#SOC{item.id}</ItemId>
                </Col>
                <Col span={24}>
                  <ItemTaskTitle
                      style={
                      hasContract(item)
                          ? { color: '#52c41a' }
                          : {}
                      }
                  >
                      {getCustomer(item).cod
                      ? `${getCustomer(item).cod} - `
                      : ''}
                      {limitCharacterLength(getCustomer(item).name, 25)}
                  </ItemTaskTitle>
                </Col>
                <Col span={14}>
                  <Tooltip title={getTooltipMessage(item.category)}>
                    <Tag
                        className="mt-sm"
                        color={getBackgroundItem(
                        item.category
                        )}
                    >
                        {getTextItem(item.category)}
                    </Tag>
                  </Tooltip>
                  {item.payment || (item.custom_fields && item.custom_fields.amount && item.custom_fields.amount > 0) ? (
                      item.payment ? (
                      <Tooltip
                      title={
                          areAllInstallmentsPaid(
                          item.payment
                          )
                          ? 'Pago'
                          : 'Pendente'
                      }
                      >
                      <ItemTaskAmountPaid
                          payment={item.payment}
                      >
                          Cobrança
                      </ItemTaskAmountPaid>
                      </Tooltip>) :  (<span>
                          Cobrança
                      </span>)
                  ) : (
                      <Tooltip title="Este ticket esta marcado como cortesia porque não foi definido nenhum valor na aba Financeiro">
                        <span
                        style={{
                            fontWeight: 'bold',
                            color: '#52c41a',
                        }}
                        >
                        Cortesia
                        </span>
                      </Tooltip>
                  )}
                </Col>
                <Col span={6}>
                {item.pendency !== null && (
                    <ItemPendency>
                    Pendência: <br />
                    {getPendency(item.pendency)}
                    </ItemPendency>
                )}
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                  <UsersAssigned selectedUsers={item.custom_fields.assigned_to || []} />
                </Col>
            </Row>
            {progress > 0 && (
            <PercentageContainer>
                <Tooltip title="Percentual de tarefas concluídas">
                  <ProgressStyled percent={progress} />
                </Tooltip>
            </PercentageContainer>
            )}
        </ItemTask>
    );
}

export default ItemCard;