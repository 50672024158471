import React from 'react';
import { Button, Icon, Skeleton } from 'antd';
import moment from 'moment';
import { Tag } from 'antd';

export const columnsAPI ={
    getColumns: function({
        setSelectedRow,
    }) {
        return [
            {
                title: 'Data',
                dataIndex: 'created_at',
                key: 'created_at',
                className: 'text-center',
                sortDirections: ['descend', 'ascend'],
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
                render: (created_at) => moment(created_at).format('DD/MM/YYYY'),
                width: '10%',
                ellipsis: true,
            },
            {
                title: 'Solicitação',
                dataIndex: 'solicitation_type',
                key: 'solicitation_type',
                width: '30%',
                render: (_, row) => {
                    return (
                    <>
                        <span>{row.solicitation_type}</span><br />
                        <small>{row.description}</small>
                    </>
                    );
                },
                ellipsis: true,
            },
            {
                title: 'Empresa',
                dataIndex: 'customer_id',
                key: 'customer_id',
                width: '30%',
                ellipsis: true,
                render: (_, row) => {
                    if (!row.customer) {
                    return <Skeleton active rows={1}/>;
                    }

                    const appUser = row.customer.app_users.find(appUser => appUser.id === row.app_user_id);

                    return (
                    <>
                        <span>{row.customer.cod} - {row.customer.name}</span><br />
                        <small>{appUser.name}</small>
                    </>
                    );
                }
            },
            {
                title: 'Status',
                width: '10%',
                dataIndex: 'status',
                key: 'status',
                filters: [
                    { text: 'Pendente', value: 0 },
                    { text: 'Concluído', value: 1 },
                ],
                onFilter: (value, record) => record.status === value,
                render: (status) => {
                    const color = status === 0 ? 'volcano' : 'green';
                    const text = status === 0 ? 'Pendente' : 'Concluído';
                    return <Tag color={color}>{text}</Tag>;
                },
            }
        ];
    }
}