export const getDefaultPaginationConfig = ({
  storageKey,
  defaultPageSize = 10,
}) => {
  return {
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '40', '100', '500', '1000'],
    showTotal: (total) =>  `Total de ${total} registros`,
    defaultPageSize: Number(
      localStorage.getItem(storageKey) || defaultPageSize
    ),
    onShowSizeChange: (_, size) => {
      localStorage.setItem(storageKey, String(size));
    },
  };
};
