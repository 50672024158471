import { createAsyncActions } from "~/utils/store";

export const LOAD_KNOWLEDGES = createAsyncActions("@@KNOWLEDGES_BASE/LOAD");
export const CREATE_KNOWLEDGE = createAsyncActions("@@KNOWLEDGES_BASE/CREATE");
export const UPDATE_KNOWLEDGE = createAsyncActions("@@KNOWLEDGES_BASE/UPDATE");
export const DELETE_KNOWLEDGE = createAsyncActions("@@KNOWLEDGES_BASE/DELETE");

export const loadKnowledges = (params) => ({
  type: LOAD_KNOWLEDGES.INIT,
  payload: {
    request: {
      method: "GET",
      url: "/knowledge-base",
      ...params,
    },
  },
});

export const createKnowledge = (data) => ({
  type: CREATE_KNOWLEDGE.INIT,
  payload: {
    request: {
      method: "POST",
      url: "/knowledge-base",
      data,
    },
  },
});

export const updateKnowledge = (data, id) => ({
  type: UPDATE_KNOWLEDGE.INIT,
  payload: {
    request: {
      method: "PUT",
      url: `/knowledge-base/${id}`,
      data,
    },
  },
});

export const deleteKnowledge = (id) => ({
  type: DELETE_KNOWLEDGE.INIT,
  payload: {
    request: {
      method: "DELETE",
      url: `/knowledge-base/${id}`,
    },
    id,
  },
});
