/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Button, Switch, Table, Tooltip } from 'antd'

import { IndicationModal } from '../Modals/IndicationModal';
import { useState } from 'react';
import { phoneMask } from '~/helpers/masks';
import { WhatsappIcon } from '~/icons/WhatsappIcon';
import { WhatsappLink } from './styles';


const { Column } = Table;

export const IndicationsTab = ({
    setIndications,
    indications,
    leadChoseNotToIndicate,
    setLeadChoseNotToIndicate
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Button
        type="primary"
        className="mb-20 mr-20"
        onClick={() => setIsModalVisible(true)}
        disabled={leadChoseNotToIndicate}
      >
        Nova indicação
      </Button>

      <span>Cliente optou por não indicar</span>

      <Switch
        checked={leadChoseNotToIndicate}
        onChange={(isChecked) => setLeadChoseNotToIndicate(isChecked)}
        style={{ marginLeft: 10 }}
      />

        <Table
          rowKey="id"
          dataSource={leadChoseNotToIndicate ? [] : indications}
          bordered
          size="small"
          pagination={false}
        >
          <Column
            title="Nome"
            dataIndex="name"
            key="name"
          />
          <Column
            title="Whatsapp"
            dataIndex="phone"
            key="phone"
            render={(phone) => {
              const phoneWithOnlyNumber = phone.replace(/\D/g, '');
              return (
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <span style={{ lineHeight: 0 }}>{phoneMask(phone)}</span>
                  <Tooltip title="Ir para o Whatsapp">
                    <WhatsappLink href={`https://wa.me/55${phoneWithOnlyNumber}`} target="_blank">
                      <WhatsappIcon />
                    </WhatsappLink>
                  </Tooltip>
                </div>
              )
            }}
          />
          <Column
            title="E-mail"
            dataIndex="email"
            key="email"
          />
        </Table>

      <IndicationModal
        isVisible={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        setIndications={setIndications}
        indications={indications}
      />
    </>
  )
}