const ACCOUNT = "account";

export const AccountStorage = {
  get: () => {
    try {
      const { token = null, user = null, created_at = null } = JSON.parse(
        localStorage.getItem(ACCOUNT)
      );
      return {
        token,
        user,
        created_at,
      };
    } catch (error) {
      return {
        token: null,
        user: null,
        created_at: null,
      };
    }
  },
  set: ({ token, created_at = Date.now(), user = null}) => {
    localStorage.setItem(
      ACCOUNT,
      JSON.stringify({ token, user, created_at })
    );
  },
  remove: () => {
    localStorage.removeItem(ACCOUNT);
  },
};
