import update from 'immutability-helper';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import {
    CREATE_SOLICITATION,
    DELETE_SOLICITATION,
    LOAD_SOLICITATIONS,
    UPDATE_SOLICITATION,
} from './actions';

const DEFAULT_CACHE_KEY = CACHE_ACTIONS.solicitations;
const defaultState = {
    data: [],
    loading: false,
};

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.solicitations]: (state, action) => {
        return action.payload;
    },
    [CREATE_SOLICITATION.SUCCESS]: (state, action) => update(state, {
        data: { $push: [action.payload.data.data] },
        loading: { $set: false },
    }),
    [LOAD_SOLICITATIONS.SUCCESS]: (state, action) => update(state, {
        data: { $set: action.payload.data },
        loading: { $set: false },
    }),
    [UPDATE_SOLICITATION.SUCCESS]: (state, action) => {
        const index = state.data.findIndex(row => row.id === action.payload.data.data.id)

        return update(state, {
            data: { [index]: { $set: action.payload.data.data } },
            loading: { $set: false },
        });
    },
    [DELETE_SOLICITATION.SUCCESS]: (state, action) => {
        const index = state.data.findIndex(row => row.id === action.payload.data.data.id);
        return update(state, {
            solicitations: { $splice: [[index, 1]] },
            loading: { $set: false },
        });
    },
};

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if (!handler) return state;

    const newState = handler(state, action);

    const updateCache = [
        CREATE_SOLICITATION.SUCCESS,
        LOAD_SOLICITATIONS.SUCCESS,
        UPDATE_SOLICITATION.SUCCESS,
        DELETE_SOLICITATION.SUCCESS,
    ].includes(action.type);

    if (updateCache) {
        LocalCacheHandler.save({
            pathname: DEFAULT_CACHE_KEY,
            data: newState,
        });
    }

    return newState;
};

export default reducer;