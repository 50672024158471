import { createAsyncActions } from "~/utils/store";

export const LOAD_DOCUMENTS_DEADLINES = createAsyncActions(
  "@@DOCUMENTS_DEADLINES/LOAD"
);

export const loadDocumentsDeadlines = ({ date, ...params }) => ({
  type: LOAD_DOCUMENTS_DEADLINES.INIT,
  payload: {
    request: {
      method: "GET",
      url: `/deadlines/${date}`,
      ...params
    },
  },
});
