import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';

import { LoadingTopBar } from "~/components/UI/LoadingTopBar";
import Documents from "~/containers/Documents";
import { loadDocumentsByFilter } from "~/containers/Documents/actions";
import Layout from "../components/UI/Layout/Layout";
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

export const ScreensDocuments = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);
  
  async function loadData() {
    try {
      await LocalCacheHandler.load({
        actionName: CACHE_ACTIONS.documents,
        dispatch,
        pathname
      });
      const { payload } = await dispatch(
        loadDocumentsByFilter({
          filterBy: "created_at",
          from: moment()
            .subtract({ month: 1 })
            .endOf("month")
            .format("YYYY-MM-DD"),
          to: moment()
            .add({ month: 1 })
            .startOf("month")
            .format("YYYY-MM-DD"),
          showLoading: false,
        })
      );
      await LocalCacheHandler.save({
        pathname,
        data: payload.data.data
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <Documents />
      </Layout>
    </div>
  );
};
