import React from 'react';
import styled from 'styled-components';

export const TodoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const TodoItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s;
    gap: 8px;

    &:hover {
        background-color: #f0f0f0;
    }
`;