import { Button, Col, Form, Modal, Table, Tabs } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { department } from "~/components/RegisterTasks/Utils";
import { ChecklistTaskModal } from "./components/ChecklistTaskModal";
import * as checklistsActions from "~/containers/Checklist/actions";
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;

export const ChecklistTasks = () => {
  const dispatch = useDispatch();
  const { checklists } = useSelector((state) => state);
  const [selectedStatus, setSelectedStatus] = useState(2);
  const [tasks, setTasks] = useState([]);
  const [isSavingToDatabase, setIsSavingToDatabase] = useState(false);
  const [taskModal, setTaskModal] = useState({
    isVisible: false,
    data: null,
  });

  const modifiedTasks = tasks.filter(
    ({ is_new, is_modified, is_deleted }) => is_new || is_modified || is_deleted
  );

  useEffect(() => {
    setTasks(checklists.tasks);
  }, [checklists.tasks]);

  function showTaskModal(data = null) {
    setTaskModal({
      isVisible: true,
      data,
    });
  }

  function hideTaskModal() {
    setTaskModal({
      isVisible: false,
      data: null,
    });
  }

  function createTask(newTask) {
    setTasks([...tasks, newTask]);
  }

  function updateTask(updatedTask) {
    setTasks(
      tasks.map((task) => {
        if (task.id !== updatedTask.id) {
          return task;
        }

        return updatedTask;
      })
    );
  }

  function deleteTask(id) {
    Modal.confirm({
      title: "Você realmente deseja remover esta tarefa?",
      content: "Esta ação não poderá ser desfeita.",
      okText: "Sim",
      okType: "danger",
      cancelText: "Cancelar",
      onOk: () => {
        setTasks(
          tasks.map((item) => {
            if (item.id !== id) return item;

            return {
              ...item,
              is_deleted: true,
            };
          })
        );
      },
    });
  }

  async function saveChecklistTasksRequest() {
    return dispatch(checklistsActions.saveChecklistTasks(modifiedTasks));
  }

  async function saveToDatabase() {
    try {
      setIsSavingToDatabase(true);
      await saveChecklistTasksRequest();
    } catch (error) {
      console.log(error);
    } finally {
      setIsSavingToDatabase(false);
    }
  }

  function renderTasksByStatus(status) {
    if (selectedStatus !== status) return null;

    return (
      <Table
        rowKey="id"
        dataSource={tasks.filter(
          (task) => task.status === status && !task.is_deleted
        )}
        bordered
        pagination={getDefaultPaginationConfig({
          storageKey: STORAGE.defaultPageSize.checklistTasksConfig,
          defaultPageSize: 20
        })}
      >
        <Column
          key="name"
          dataIndex="name"
          title="Nome"
          sorter={(a, b) => a.name.localeCompare(b.name)}
          defaultSortOrder="ascend"
        />
        <Column
          key="department"
          dataIndex="department"
          title="Departamento"
          width={140}
          filters={[
            {
              text: department(1),
              value: "1",
            },
            {
              text: department(2),
              value: "2",
            },
            {
              text: department(3),
              value: "3",
            },
            {
              text: department(4),
              value: "4",
            },
            {
              text: department(5),
              value: "5",
            },
            {
              text: department(6),
              value: "6",
            },
          ]}
          onFilter={(value, record) => +record.department === +value}
          render={(currentDepartment) => department(currentDepartment)}
        />
        <Column
          key="actions"
          width={80}
          align="center"
          render={(_, task) => (
            <div style={{ display: "flex", gap: 6 }}>
              <Button
                type="primary"
                icon="edit"
                onClick={() => showTaskModal(task)}
              />
              <Button
                type="danger"
                icon="delete"
                onClick={() => deleteTask(task.id)}
              />
            </div>
          )}
        />
      </Table>
    );
  }

  return (
    <Form layout="horizontal">
      <Col offset={4}>
        <Tabs
          type="card"
          activeKey={String(selectedStatus)}
          onChange={(key) => setSelectedStatus(Number(key))}
        >
          <Tabs.TabPane tab="Entrada" key="2">
            {renderTasksByStatus(2)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Entrada (Troca de Contabilidade)" key="4">
            {renderTasksByStatus(4)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Saída (Encerramento)" key="1">
            {renderTasksByStatus(1)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Saída (Troca de Contabilidade)" key="3">
            {renderTasksByStatus(3)}
          </Tabs.TabPane>
        </Tabs>
      </Col>
      <Form.Item
        wrapperCol={{
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 4,
          },
        }}
      >
        <Button type="dashed" onClick={() => showTaskModal()} className="mt-20">
          Adicionar nova tarefa
        </Button>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 4,
          },
        }}
      >
        <Button
          type="primary"
          onClick={saveToDatabase}
          loading={isSavingToDatabase}
          disabled={modifiedTasks.length === 0}
        >
          Salvar
        </Button>
      </Form.Item>
      <ChecklistTaskModal
        isVisible={taskModal.isVisible}
        onClose={hideTaskModal}
        data={taskModal.data}
        selectedStatus={selectedStatus}
        onCreateTask={createTask}
        onUpdateTask={updateTask}
        tasks={tasks}
      />
    </Form>
  );
};
