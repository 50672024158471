import React, { useEffect, useState } from 'react';
import { Button, Drawer, Table } from 'antd';
import { useDispatch } from 'react-redux';

import * as documentsActions from '~/containers/Documents/actions';
import { DeliveryMethodsButtonsModal } from '../Modals/DeliveryMethodsButtonsModal';

const { Column } = Table;

export const PendingDocumentsDrawer = ({ isVisible, onClose }) => {
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deliveryMethodsModal, setDeliveryMethodsModal] = useState({
    isVisible: false,
    data: null,
  });

  useEffect(() => {
    if (isVisible) {
      loadDocuments();
    }
  }, [isVisible]);

  async function loadDocuments() {
    try {
      setIsLoading(true);
      const {
        payload: { data },
      } = await dispatch(documentsActions.loadPendingDocuments());
      setDocuments(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function openDeliveryMethodsModal(data = null) {
    setDeliveryMethodsModal({
      isVisible: true,
      data,
    });
  }

  function closeDeliveryMethodsModal({ isDocumentSent }) {
    if (isDocumentSent) {
      setDocuments((state) => {
        return state.filter(({ id }) => id !== deliveryMethodsModal.data.id);
      });
    }

    setDeliveryMethodsModal({
      isVisible: false,
      data: null,
    });
  }

  return (
    <Drawer
      width={700}
      title="Documentos pendentes"
      visible={isVisible}
      onClose={onClose}
    >
      <Table dataSource={documents} bordered rowKey="id" loading={isLoading}>
        <Column title="Descrição" key="description" dataIndex="description" />
        <Column title="Data de vencimento" key="due_at" dataIndex="due_at" />
        <Column
          key="delivery_methods"
          render={(_, document) => (
            <Button
              type="primary"
              onClick={() => openDeliveryMethodsModal(document)}
            >
              Métodos de entrega
            </Button>
          )}
        />
      </Table>
      <DeliveryMethodsButtonsModal
        isVisible={deliveryMethodsModal.isVisible}
        onClose={closeDeliveryMethodsModal}
        document={deliveryMethodsModal.data}
      />
    </Drawer>
  );
};
