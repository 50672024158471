import 'braft-editor/dist/index.css';

import React, { useEffect, useState } from 'react';
import { Button, Form, Icon, Input, Select, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import WhatsappTextEditor from '~/components/WhatsappTextEditor';

import {
  CategoryLabelWrapper,
  Footer,
  StyledDrawer,
} from './styles';
import { fieldsOptions } from './fieldsOptions';
import * as knowledgeBaseActions from '~/containers/KnowledgeBase/actions';

export const KnowledgeDrawer = Form.create()(
  ({ isVisible, onClose, data, form }) => {
    const {
      getFieldDecorator,
      validateFields,
      resetFields,
      setFieldsValue,
      isFieldsTouched,
      getFieldsValue,
      getFieldValue,
    } = form;

    const { knowledges } = useSelector((state) => ({
      knowledges: state.knowledgeBase.list,
    }));

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      if (isVisible && !!data) {
        const { title, content, categories } = data;
        setFieldsValue({
          title,
          content: content,
          categories,
        });
      }
    }, [isVisible]);

    function closeDrawer() {
      resetFields();
      onClose();
    }

    function handleSubmit() {
      validateFields(async (error, values) => {
        if (error) return;

        setIsLoading(true);

        const { updateKnowledge, createKnowledge } = knowledgeBaseActions;

        try {
          if (isEditing) {
            await dispatch(updateKnowledge(values, data.id));
          } else {
            await dispatch(createKnowledge(values));
          }

          closeDrawer();
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      });
    }

    const isEditing = !!data;
    const areAllFieldsFilled = !Object.values(getFieldsValue()).every(Boolean);
    const isSubmitButtonDisabled = areAllFieldsFilled;

    const uniqueExistingCategories = Array.from(
      new Set(
        knowledges
          .filter(({ id }) => (data ? id !== data.id : true))
          .flatMap(({ categories }) => categories)
      )
    );

    return (
      <StyledDrawer
        width={750}
        title={isEditing ? 'Editar conhecimento' : 'Novo conhecimento'}
        visible={isVisible}
        onClose={closeDrawer}
        destroyOnClose
      >
        <Form layout="vertical"> 
          <Form.Item label="Título">
            {getFieldDecorator(
              'title',
              fieldsOptions.title
            )(<Input placeholder="Informe o título" />)}
          </Form.Item>
          <Form.Item label="Conteúdo">
            {getFieldDecorator('content', {
              rules: [
                {
                  required: true,
                  message: 'Por favor, informe o conteúdo',
                },
              ],
            })(
              <WhatsappTextEditor 
                className="ant-input"
                name="content"
                onSetValue={(value) => setFieldsValue({ content: value })}
                getFieldValue={getFieldValue}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <CategoryLabelWrapper>
                <span>Categoria</span>
                <Tooltip
                  title="Selecione uma categoria
                existente ou crie uma nova categoria digitando no campo abaixo"
                >
                  <Icon type="exclamation-circle" />
                </Tooltip>
              </CategoryLabelWrapper>
            }
          >
            {getFieldDecorator(
              'categories',
              fieldsOptions.categories
            )(
              <Select mode="tags" placeholder="Informe uma ou mais categorias">
                {uniqueExistingCategories.map((category) => (
                  <Select.Option key={category} value={category}>
                    {category}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Form>
        <Footer>
          <Button onClick={onClose}>Voltar</Button>
          <Button
            onClick={handleSubmit}
            type="primary"
            disabled={isSubmitButtonDisabled}
            loading={isLoading}
          >
            Salvar
          </Button>
        </Footer>
      </StyledDrawer>
    );
  }
);
