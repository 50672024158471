import React from 'react';
import { Modal, Radio, Form, Select, Row, Col } from 'antd';

import { formOptions } from './formOptions';

const { Option } = Select;

export const CertificateFormModal = ({ isVisible, onClose, form }) => {
  const { getFieldDecorator } = form;

  return (
    <Modal
      title="Certificado digital"
      visible={isVisible}
      onCancel={onClose}
      width={570}
      okButtonProps={{ hidden: true }}
      cancelText="Voltar"
    >
      <Form layout="vertical">
        <Row>
          <Col span={12}>
            <Form.Item label="Natureza">
              {getFieldDecorator(
                'certificate[nature]',
                formOptions.nature
              )(
                <Radio.Group>
                  <Radio.Button value="PF">Pessoa Física</Radio.Button>
                  <Radio.Button value="PJ">Pessoa Jurídica</Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tipo de certificado">
              {getFieldDecorator(
                'certificate[type]',
                formOptions.type
              )(
                <Select
                  placeholder="Selecione uma opção"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="E-CPF A1">E-CPF A1</Option>
                  <Option value="E-CPF A3">E-CPF A3</Option>
                  <Option value="E-CNPJ A1">E-CNPJ A1</Option>
                  <Option value="E-CNPJ A3">E-CNPJ A3</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
