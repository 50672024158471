import React from 'react'
import { Button, Icon, message, Modal, Table } from "antd"
import moment from 'moment'
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config'
import { STORAGE } from '~/constants/storage.constants'

const { Column } = Table

export const HonorariesTable = ({ honoraries, setHonoraryToUpdate, deleteHonorary, honoraryOfCurrentYear, isCreating }) => {
  function requestForDeleteHonorary(uid, year) {
    Modal.confirm({
      title: 'Você realmente deseja remover este honorário?',
      content: 'Esta ação não poderá ser desfeita.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => deleteHonorary(uid, year)
    });
  }

  function editHonorary(uid, record) {
    if (!isCreating) {
      const isHonoraryOfCurrentYear = uid === honoraryOfCurrentYear.uid && !record.created_at;
      
      if (isHonoraryOfCurrentYear && !isCreating) {
        message.warning('Para editar o honorário é necessário iniciar o atendimento');
        return;
      }
    }

    setHonoraryToUpdate(honoraries.find((honorary) => honorary.uid === uid));
  }

  return (
    <Table
      rowKey="uid"
      size="small"
      dataSource={honoraries.sort((a, b) => Number(b.year) - Number(a.year))}
      pagination={getDefaultPaginationConfig({
        storageKey: STORAGE.defaultPageSize.irpfHonoraries
      })}
    >
      <Column
        title="Ano"
        dataIndex="year"
        key="year"
      />
      <Column
        title="Honorário"
        dataIndex="amount"
        key="amount"
        render={(amount) =>
          new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(amount)
        }
        
      />
      <Column
        title="Data de pagamento"
        dataIndex="paid_at"
        key="paid_at"
        render={(paid_at) => paid_at ? moment(paid_at).format('DD/MM/YYYY') : null}
      />
      <Column
        title="Quitado?"
        dataIndex="payment_status"
        key="payment_status"
        render={(payment_status) => payment_status === 'Pago' ? 'Sim' : 'Não'}
      />
      <Column
        title="Descrição"
        dataIndex="description"
        key="description"
      />
      <Column
        dataIndex="uid"
        align="center"
        key="edit"
        render={(uid, record) => (
          <Button type="primary" onClick={() => editHonorary(uid, record)}>
            <Icon type="edit" />
          </Button>
        )}
      />
      <Column
        dataIndex="uid"
        align="center"
        key="delete"
        render={(uid, record) => {
          if (isCreating || (honoraryOfCurrentYear && (uid !== honoraryOfCurrentYear.uid))) {
            return (
              <Button type="danger" onClick={() => requestForDeleteHonorary(uid, record.year)}>
                <Icon type="delete" />
              </Button>
            )
          }

          return null;
        }}
      />
    </Table>
  )
}