import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Tree, Radio, Select } from 'antd';
import { permissionsTree } from '../Permissions/permissions-tree';
import { finishCurrentOnboardingStepAction } from '../Onboarding/helpers';

const FormItem = Form.Item;
const { TreeNode } = Tree;

class UsersModalForm extends React.Component {
  state = {
    permissionsType: 'group',
    isSaving: false,
  };

  componentDidUpdate = (prevProps) => {
    const { visible, data } = this.props;

    if (prevProps.visible !== visible) {
      if (data) {
        this.setState({
          permissionsType: data.permissions_group_id ? 'group' : 'custom',
        });
      }
    }
  };

  checkIfStepIsActive = (step) => {
    const { isOnboardingActive, onboardingStep, isVideoModalVisible } = this.props;
    return !isVideoModalVisible && isOnboardingActive && onboardingStep === step;
}

  onSave = () => {
    const { form, finishCurrentOnboardingStep, user } = this.props;

    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const { data, registerUser, savePermissions, onboardingStep } = this.props;
      const { permissions } = values;

      if (permissions) {
        values.permissions = permissions.filter(
          (item) => !item.includes('_PARENT')
        );
      }

      this.setState({ isSaving: true });

      try {
        if (data) {
          const { email, ...permissionsData } = values;
          await savePermissions(data.id, permissionsData);
        } else {
          await registerUser(values);
        }

        form.resetFields();
        this.props.onCancel();
        finishCurrentOnboardingStep({
          isActive: this.checkIfStepIsActive(1),
          step: onboardingStep,
          user
        });
      } catch (error) {
        console.log(error)
      } finally {
        this.setState({ isSaving: false });
      }
    });
  };

  renderTreeNodes = (permissions) => {
    return permissions.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} />;
    });
  };

  render() {
    const { visible, onCancel, form, permissionsGroups, data } = this.props;
    const { getFieldDecorator } = form;
    const { permissionsType, isSaving } = this.state;

    const isPermissionsGroup = permissionsType === 'group';

    return (
      <Modal
        visible={visible}
        title={data ? 'Editar Permissões do Usuário' : 'Convidar novo usuário'}
        okText={data ? 'Salvar' : 'Convidar'}
        onCancel={onCancel}
        onOk={this.onSave}
        okButtonProps={{ loading: isSaving }}
        destroyOnClose
      >
        <Form layout="vertical">
          <FormItem label="Email">
            {getFieldDecorator('email', {
              initialValue: data ? data.email : undefined,
              rules: [
                { required: true, message: 'Por favor informe o email!' },
                { type: "email", message: 'Por favor, informe um e-mail válido!' },
              ],
            })(<Input disabled={!!data} />)}
          </FormItem>
          <FormItem
            label="Permissões"
            className="collection-create-form_last-form-item"
          >
            <Radio.Group
              value={permissionsType}
              buttonStyle="solid"
              size="small"
              onChange={(event) =>
                this.setState({ permissionsType: event.target.value })
              }
            >
              <Radio.Button value="group">Grupos</Radio.Button>
              <Radio.Button value="custom">Customizado</Radio.Button>
            </Radio.Group>
            {isPermissionsGroup
              ? getFieldDecorator('permissions_group_id', {
                  initialValue: data ? data.permissions_group_name : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Selecione um grupo de permissões!',
                    },
                  ],
                })(
                  <Select
                    style={{ marginTop: 14 }}
                    placeholder="Selecione um grupo de permissões"
                  >
                    {permissionsGroups.map((permission) => (
                      <Select.Option key={permission.id} value={permission.id}>
                        {permission.name}
                      </Select.Option>
                    ))}
                  </Select>
                )
              : getFieldDecorator('permissions', {
                  initialValue: data ? data.permissions : undefined,
                  rules: [
                    {
                      required: true,
                      message:
                        'O usuário precisa ter pelo menos uma permissão!',
                    },
                  ],
                  valuePropName: 'checkedKeys',
                  validateTrigger: 'onCheck',
                })(
                  <Tree style={{ marginTop: 7 }} checkable>
                    {this.renderTreeNodes(permissionsTree)}
                  </Tree>
                )}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissionsGroups: state.permissions.list,
    isOnboardingActive: state.onboarding.isActive,
    onboardingStep: state.onboarding.step,
    isVideoModalVisible: state.onboarding.videoModal.isVisible,
    user: state.user
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    registerUser: (data) =>
      dispatch({
        type: 'REGISTER_USER',
        payload: {
          request: {
            method: 'post',
            url: '/user',
            data,
          },
        },
      }),
    savePermissions: (id, data) =>
      dispatch({
        type: 'SAVE_PERMISSIONS_USER',
        payload: {
          request: {
            method: 'patch',
            url: `/user/${id}/permissions`,
            data,
          },
        },
        id: id,
      }),
    finishCurrentOnboardingStep: ({ isActive, step, user }) => {
      finishCurrentOnboardingStepAction({ isActive, dispatch, step, user })
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(Form.create()(UsersModalForm));
