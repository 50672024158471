import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Col, Row, Input, Select, Divider, Modal, DatePicker } from 'antd';
import moment from 'moment';
import get from 'lodash/get';

import { DatePicker as CustomDatePicker } from '~components/UI/DatePicker/Locale';
import InputMask from '~components/UI/InputMask';
import { Acquisitions } from '~/components/SelectWithAdd';
import Tip from '~/components/Tip';
import { CompanyTypes } from '~components/SelectWithAdd';

import { 
    taxationTypes,
    deliveryMethod
} from '~containers/Customer/data';
import { AppUserAccessModal } from '~/components/Customers/Modals/AppUserAccessModal';

const { Option } = Select;
const { MonthPicker } = DatePicker;

const Default = (props) => {
    const { settings } = useSelector((state) => state);

    const [email, setEmail] = useState({});
    const [activeForm, setActiveForm] = useState({});
    const [appUserAccessModal, setAppUserAccessModal] = useState({
        isVisible: false,
        customer: null
    });

    const openAppUserAccessModal = (data) => {
        Modal.confirm({
            title: "Você deseja enviar convite de acesso ao painel do cliente?",
            onOk: () => {
                setAppUserAccessModal({
                    isVisible: true,
                    customer: data
                });
            },
            okText: "Sim",
            cancelText: "Não"
        });
    };

    const closeAppUserAccessModal = () => {
        setAppUserAccessModal({
            isVisible: false,
            customer: null,
        });
    };

    useEffect(() => {
        const { error } = props;
        if (error && error.response && error.response.status === 422 && error.response.data.email !== null) {
            setEmail({
                validateStatus: 'error',
                help: error.response.data.email[0]
            });
        }
    }, [props.error]);

    const { getFieldDecorator, getFieldValue } = props.form;

    const data = {
        ...props.data,
        ...activeForm
    };

    const settingsAcquisition = settings["leads.acquisition"];
    const indication = settingsAcquisition.find((item) => item.value === 'Indicação');
    const outros = settingsAcquisition.find((item) => item.value === 'Outros');
    const isIndication = indication && getFieldValue('acquisition_id') === indication.id ? true : false;
    const isOutros = outros && getFieldValue('acquisition_id') === outros.id ? true : false;

    return (
        <Row gutter={16}>
            <Col span={4}>
                <Form.Item label="Código">
                    {getFieldDecorator('cod', {initialValue: data.cod})(
                        <Input placeholder="Código interno de identificação" />
                    )}
                </Form.Item>
            </Col>
            <Col span={20}>
                <Form.Item label="Nome da Empresa">
                    {getFieldDecorator('name', {
                        initialValue: data.name,
                        rules: [{ required: true, message: 'Por favor informe o nome da empresa!' }],
                    })(<Input placeholder="Insira o nome da empresa" />)}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="CNPJ">
                    {getFieldDecorator('identification_number', {initialValue: data.identification_number})(
                        <InputMask mask="99.999.999/9999-99" placeholder="CNPJ" />
                    )}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Regime Tributário">
                    {getFieldDecorator('type_taxation', {initialValue: data.type_taxation})(
                        <Select placeholder="Selecione um tipo de tributação">
                            {taxationTypes.map((item, index) => <Option key={index} value={item}>{item}</Option>)}
                        </Select>
                    )}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Email" {...email}>
                    {getFieldDecorator('email', {initialValue: data.email})(
                        <Input placeholder="Email da empresa" />
                    )}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Telefone">
                    {getFieldDecorator('phone', {initialValue: data.phone})(
                        <InputMask 
                            mask="(99) 9999tt999?" 
                            formatChars={{"9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]"}} 
                            maskChar={null} 
                            placeholder="Telefone da empresa" 
                        />
                    )}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label={`Data de Entrada`}>
                    {getFieldDecorator('registered_at', data.registered_at? {
                    initialValue: moment(data.registered_at)
                    }:{})(
                    <CustomDatePicker/>
                    )}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label={<span>Atividade <Tip>Dica: Se a atividade da empresa não estiver na lista, adicione digitando o nome da atividade e em seguida clique em cadastrar.</Tip></span>}>
                    {getFieldDecorator('company_type_id', {initialValue: data.company_type_id})(
                        <CompanyTypes />
                    )}
                </Form.Item>
            </Col>
            <Divider orientation="left">Responsabilidade do escritório:</Divider>
            <Col span={12}>
                <Form.Item label="Competência">
                    {getFieldDecorator('resp_compet_at', {
                        initialValue: data.resp_compet_at && data.resp_compet_at !== '0000-00-00' ? moment(data.resp_compet_at) : null
                    })(
                        <MonthPicker
                            placeholder="Selecione uma data"
                            format={'MM/YYYY'}
                            style={{ width: '100%' }}
                        />
                    )}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Vencimento">
                    {getFieldDecorator('resp_due_at', {
                        initialValue: data.resp_due_at && data.resp_due_at !== '0000-00-00' ? moment(data.resp_due_at): null
                    })(
                        <MonthPicker
                            placeholder="Selecione uma data"
                            format={'MM/YYYY'}
                            style={{ width: '100%' }}
                        />
                    )}
                </Form.Item>
            </Col>
            <Divider orientation="left">Outros</Divider>
            <Col span={12}>
                <Form.Item label={<span>Origem <Tip>A origem é o canal que direcionou o cliente até a sua empresa. Por exemplo: Seu site, blog, Instagram, entre outros. <br /><br/> Dica: Se a origem desejada não estiver na lista, adicione digitando no campo e em seguida clique em cadastrar.</Tip></span>}>
                    {getFieldDecorator("acquisition_id", { initialValue: data.acquisition_id })(
                        <Acquisitions />
                    )}
                </Form.Item>
            </Col>
            {(isIndication || isOutros) && (
            <Col span={12}>
                <Form.Item label={isIndication?'Quem?': 'Qual?'}>
                    {getFieldDecorator('indication', {initialValue: data.indication})(
                        <Input placeholder={isIndication?'Descreva quem indicou': 'Descreva qual tipo de aquisição'} />
                    )}
                </Form.Item>
            </Col>
            )}
            <Col span={12}>
                <Form.Item label="Sistema de entrega">
                    {getFieldDecorator('doc_delivery_method', {initialValue: get(data, 'doc_delivery_method', null)})(
                        <Select
                            placeholder="Selecione um tipo de entrega"
                            onChange={(value) => {
                                if (!!props.data.id && value === 2 && !data.generated_app_user) {
                                    openAppUserAccessModal(data);
                                }
                            }}
                        >
                            {deliveryMethod.map((item, index) => <Option value={parseInt(item.value)} key={index}>{item.text}</Option>)}
                        </Select>
                    )}
                </Form.Item>
            </Col>
            <Divider orientation="left">Acesso Gov.br</Divider>
            <Col span={12}>
                <Form.Item label="Login">
                    {getFieldDecorator('gov_login', {initialValue: data.gov_login})(
                        <Input placeholder="Login Gov.br" />
                    )}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Senha">
                    {getFieldDecorator('gov_password', {initialValue: data.gov_password})(
                        <Input placeholder="Senha Gov.br" />
                    )}
                </Form.Item>
            </Col>
            <AppUserAccessModal
                isOpen={appUserAccessModal.isVisible}
                onClose={closeAppUserAccessModal}
                customer={appUserAccessModal.customer}
            />
        </Row>
    );
};

export default Form.create()(Default);
