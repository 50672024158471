import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Layout } from '~/components/UI';
import { ModuleHeader } from '~/components/ModuleHeader';
import { Container, Header, HeaderLeftContentWrapper, Main } from './styles';
import { Input } from 'antd';
import { normalizeToFilter } from '~/utils/formatters';
import TableList from './components/table';
import DrawerSolicitation from './components/DrawerSolicitation';
import Tip from '~/components/Tip';

export const Page = () => {

    const [filter, setFilter] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);

    const data = useSelector(state => state.solicitations.data);

  return (
    <div>
        <Layout>
            <Container>
                <Header>
                    <HeaderLeftContentWrapper>
                    <ModuleHeader breadcrumbs={['Solicitações']} title={<span>Solicitações <Tip>Este módulo é responsável por gerenciar as solicitações enviadas pelos clientes através do painel do cliente.</Tip></span>} />
                    <Input
                        placeholder="Digite para filtrar"
                        onChange={({ target }) =>
                        setFilter(normalizeToFilter(target.value))
                        }
                    />
                    </HeaderLeftContentWrapper>
                </Header>
                <Main>
                    <DrawerSolicitation 
                        data={selectedRow}
                        onClose={() => setSelectedRow(null)}
                    />
                    <TableList 
                        data={data}
                        filter={filter}
                        setSelectedRow={setSelectedRow}
                    />
                </Main>
            </Container>
        </Layout>
    </div>
  );
};

export default Page;
