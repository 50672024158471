import Axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  InputAdornment,
  TextField,
} from "@material-ui/core";

import { validateEmail } from "~/utils/validators";
import { phoneMask } from "~/helpers/masks";
import { ButtonsWrapper, Container } from "./styles";
import { MaterialUIFormControl } from "~/components/UI/Material/FormControl";

export const RegisterStep = ({ formState, setFormState, setStep }) => {
  const [isPrefixVisible, setIsPrefixVisible] = useState();
  const [formErrorState, setFormErrorState] = useState({
    email: null,
    phone: null,
  });

  const hasFormError = Object.values(formErrorState).some((value) => !!value);

  const {
    fullname,
    email,
    company,
    password,
    id,
    phone,
    desired_modules,
  } = formState;

  const isContinueButtonDisabled =
    !fullname ||
    !email ||
    !company ||
    !password ||
    !id ||
    !phone ||
    hasFormError;

  function updateFormState(event) {
    event.persist();
    const { value, name } = event.target;

    if (name === "email") {
      validateCurrentEmail(value);
    }

    if (name === "id") {
      setFormState((state) => ({
        ...state,
        id: formatId(value),
      }));
      return;
    }

    if (name === "phone") {
      setFormState((state) => ({
        ...state,
        phone: phoneMask(value),
      }));
      return;
    }

    setFormState((state) => ({
      ...state,
      [name]: value,
    }));
  }

  function validateCurrentEmail(value) {
    setFormErrorState((state) => ({
      ...state,
      email: !validateEmail(value)
        ? "Por favor, informe um e-mail válido"
        : null,
    }));
  }

  function formatId(value) {
    return value
      .replaceAll(" ", "")
      .trim()
      .toLowerCase()
      .slice(0, 10);
  }

  function handleContinue() {
    setStep(1);
    Axios.post(`${process.env.REACT_APP_API}/auth/checkout`, {
      subdomain: id,
      fullname,
      email,
      company,
      phone,
      desired_modules,
    });
  }

  return (
    <Container>
      <MaterialUIFormControl>
        <TextField
          label="Nome completo"
          name="fullname"
          onChange={updateFormState}
          value={formState.fullname}
        />
      </MaterialUIFormControl>
      <MaterialUIFormControl>
        <TextField
          label="Email"
          name="email"
          onChange={updateFormState}
          value={formState.email}
          error={!!formErrorState.email}
          helperText={formErrorState.email}
        />
      </MaterialUIFormControl>
      <MaterialUIFormControl>
        <TextField
          label="Telefone (com DDD)"
          name="phone"
          onChange={updateFormState}
          value={formState.phone}
        />
      </MaterialUIFormControl>
      <MaterialUIFormControl>
        <TextField
          label="Nome da empresa"
          name="company"
          onChange={updateFormState}
          value={formState.company}
        />
      </MaterialUIFormControl>
      <MaterialUIFormControl>
        <TextField
          label="Escolha sua URL de acesso"
          name="id"
          onChange={updateFormState}
          value={formState.id}
          InputProps={{
            endAdornment: isPrefixVisible ? (
              <InputAdornment position="end">.contlabs.com.br</InputAdornment>
            ) : null,
          }}
          onBlur={() => {
            if (!formState.id) {
              setIsPrefixVisible(false);
            }
          }}
          onFocus={() => {
            setIsPrefixVisible(true);
            if (!formState.id) {
              setFormState((state) => ({
                ...state,
                id: formatId(formState.company),
              }));
            }
          }}
        />
      </MaterialUIFormControl>
      <MaterialUIFormControl>
        <TextField
          label="Escolha uma senha"
          name="password"
          onChange={updateFormState}
          value={formState.password}
          type="password"
        />
      </MaterialUIFormControl>
      {/* <MaterialUIFormControl>
        <InputLabel id="desired_modules_label">
          Módulos que tem interesse
        </InputLabel>
        <Select
          multiple
          name="desired_modules"
          onChange={updateFormState}
          value={formState.desired_modules}
          renderValue={(selected) => {
            return selected.join(", ");
          }}
          labelId="desired_modules_label"
        >
          {desiredModules.map(({ description, name }) => (
            <MenuItem value={name} key={name}>
              <Checkbox
                color="primary"
                checked={formState.desired_modules.includes(name)}
              />
              <ListItemText primary={`${name} (${description})`} />
            </MenuItem>
          ))}
        </Select>
      </MaterialUIFormControl> */}
      <ButtonsWrapper>
        <Button
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          disabled={isContinueButtonDisabled}
          onClick={handleContinue}
        >
          Continuar
        </Button>
        <Link className="no-account is-secondary" to="/login">
          Já possui uma conta?
        </Link>
      </ButtonsWrapper>
    </Container>
  );
};
