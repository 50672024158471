import { Modal, Table } from "antd";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  formatToBrDate,
  getDaysCountBetweenDates,
} from "~/utils/date-handlers";
import { convertToBRL } from "~/utils/formatters";
import { getDaysCountColor } from "../helpers";
import { Header, Title, FullscreenButton, FullscreenIcon } from "./styles";
import { deliveryMethodsSnakeNames } from "../data";

const { Column } = Table;

export const CustomersModal = ({ isVisible, data, hideCustomersModal }) => {
  const { users } = useSelector((state) => state);
  const [isFullscreen, setIsFullscreen] = useState(false);

  function onHideCustomersModal() {
    setIsFullscreen(false);
    hideCustomersModal();
  }

  const formattedData = data
    ? data.map((item) => ({
        ...item,
        amount: item.created_at ? convertToBRL(item.amount || 0) : null,
        due_at: formatToBrDate(item.due_at),
        days_count: getDaysCountBetweenDates(
          item.due_at,
          item.checked_as_without_movement_at ? item.checked_as_without_movement_at : item[deliveryMethodsSnakeNames[item.customer_delivery_method]]
        ),
      }))
    : [];

  return (
    <Modal
      title={
        <Header>
          <Title>Clientes</Title>
          <FullscreenButton onClick={() => setIsFullscreen(!isFullscreen)}>
            <FullscreenIcon
              type={isFullscreen ? "fullscreen-exit" : "fullscreen"}
            />
          </FullscreenButton>
        </Header>
      }
      visible={isVisible}
      footer={false}
      onCancel={onHideCustomersModal}
      width={isFullscreen ? "calc(100% - 40px)" : 950}
      style={{ top: isFullscreen ? 20 : 100 }}
    >
      <Table
        destroyOnClose
        rowKey="id"
        dataSource={formattedData}
        bordered
        pagination={false}
        // scroll={{ y: isFullscreen ? "calc(100vh - 230px)" : 860 }}
      >
        <Column 
          title="Código" 
          dataIndex="cod" 
          key="cod" 
          width={80} 
          ellipsis 
        />
        <Column 
          title="Nome" 
          dataIndex="name" 
          key="name" 
          ellipsis 
          width={250}
        />
        <Column
          title="Valor"
          dataIndex="amount"
          key="amount"
          align="center"
          width={140}
          render={(amount, record) => {
            if (record.is_without_movement) return 'Sem movimento';
            return amount;
          }}
        />
        <Column
          title="Data de entrega"
          dataIndex="created_by"
          key="created_by"
          align="center"
          width={180}
          ellipsis
          render={(createdBy, record) => {
            if (!createdBy) return null;
            const user = users.find((user) => user.id === createdBy);
            if (!user) return null;
            const createdAt = moment(record.created_at);
            const firstName = user ? user.name.split(" ")[0] : "";

            return `${firstName} ${createdAt.format(
              "DD/MM/YYYY"
            )} as ${createdAt.format("HH:MM")}`;
          }}
        />

        <Column
          title="Vencimento"
          dataIndex="due_at"
          key="due_at"
          align="center"
          width={120}
        />
        <Column
          title="Dias"
          dataIndex="days_count"
          key="days_count"
          align="center"
          width={90}
          sortOrder="ascend"
          sorter={(a, b) => {
            return (
              (a.days_count === null) - (b.days_count === null) ||
              +(a.days_count > b.days_count) ||
              -(a.days_count < b.days_count)
            );
          }}
          render={(daysCount) => {
            if (daysCount === null) return null;
            return (
              <span style={{ color: getDaysCountColor(daysCount) }}>
                {daysCount}
              </span>
            );
          }}
        />
      </Table>
    </Modal>
  );
};
