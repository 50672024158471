import { Upload } from 'antd';
import styled from 'styled-components';

export const Footer = styled.div`
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  text-align: left;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

export const DocumentUpload = styled(Upload)`
  .ant-upload {
    width: 100%;
  }
`;

export const UploadWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;