import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import 'moment/locale/pt-br';
import InputCurrency, { currency2Float } from '~/components/UI/InputCurrency';
import { compare } from 'natural-orderby';
import moment from 'moment';
import uid from 'uid';
import get from 'lodash/get';

import {
  Input,
  Button,
  Form,
  Row,
  Col,
  Drawer,
  Divider,
  Select,
  Switch,
  Radio,
  Modal
} from 'antd';

import {
  DatePicker
} from '~/components/UI';

import { pad } from '~/components/UI/Utils';

import * as drawerRefunds from './actions';
import * as refundsActions from '../Refunds/actions';
import NumericInput from '~/components/UI/InputNumber';
import { PERMISSIONS } from '~/constants/permissions.constants';

const { Option } = Select;

const DrawerRefunds = (props) => {

  const [payments, setPayments] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const items = get(props, 'drawer.data.refund.payments', []);
    let itemsWithId = [];
    items.map(item => {
      itemsWithId.push({...item, uid: uid(10)});
    })
    setPayments(itemsWithId);
  }, [get(props, 'drawer.data.refund.payments')]);

  const { 
    user, 
    form, 
    drawer, 
    filter,
    customers, 
    handleClose
  } = props;

  const { getFieldDecorator } = form;
  const { data } = drawer;

  const canEdit = (user.permissions.includes(PERMISSIONS.refunds.edit) && get(data, 'id')) 
    || get(data, 'id', null) === null;
  const canDelete = user.permissions.includes(PERMISSIONS.refunds.delete);

  const handleCreate = () => {

    form.validateFields((err, values) => {
      if (err) {
          return;
      }

      setLoading(true);
      props.handleCreate({...data, ...values})
      .then(() => {
        if(get(filter, 'filter_by')){
          props.loadRefunds(filter);
        }
        setLoading(false);
        form.resetFields();
        handleClose();
      });
      
    });

  }

  const handleSave = () => {

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      setLoading(true);
      props.handleSave(get(data, 'refund.id'), {deleted, ...data, ...values})
      .then(() => {
        if(get(filter, 'filter_by')){
          props.loadRefunds(filter);
        }
        setLoading(false);
        form.resetFields();
        handleClose();
      });
     
    });

  }

  const handleDelete = () => {
    Modal.confirm({
      title: 'Você realmente deseja excluir esta comissão?',
      content: 'Todas as parcelas serão removidas. Esta ação não poderá ser desfeita.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => props.handleDelete(get(data, 'refund.id'))
    });
  }

  const optionsPayment = () => {
    let o = [];
    for (let i = 1; i <= 12; i++) {
      o.push(<Option key={i} value={i}>{i}x</Option>);
    }
    return o;
  }

  const changePaymentSplit = (val) => {
    if(val > payments.length){

      let p = payments;
      const max = (val-payments.length);
      for(let i=1; i <= max; i++){
        p.push({
          due_date: payments.length ? moment(payments[payments.length-1].due_date).add(i, 'months').format('YYYY-MM-DD'):moment().add(i, 'months').format('YYYY-MM-DD'),
          amount: form.getFieldValue('total')/val,
          method: payments.length ? payments[payments.length-1].method : 1,
          uid: uid(10),
          cod: null,
          new: true
        });
      }
      setPayments(p);

    }else if(val < payments.length){

      let p = payments;
      let d = deleted;
      const max = (payments.length-val);
      for(let i=1; i <= max; i++){
        d.push(p.pop());
      }
      setPayments(p);
      setDeleted(d);

    }
  }

    return (
      <Drawer
        title={(data.id)? (canEdit? 'Editar comissão' : 'Comissão'): 'Cadastrar comissão'}
        width={800}
        placement="right"
        onClose={handleClose}
        maskClosable={false}
        visible={drawer.show}
        destroyOnClose={true}
        style={{
            height: '100%',
            overflow: 'auto',
            paddingBottom: 53,
        }}>
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={18}>
              <Form.Item label="Responsavél pela indicação">
              {getFieldDecorator('responsible', {
                  initialValue: get(data, 'refund.responsible'),
                  rules: [{ required: true, message: 'Por favor informe o nome do responsavél!' }],
              })(<Input placeholder="Nome do responsavél" disabled={!canEdit}/>)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Societário">
              {getFieldDecorator('dash_task_id', {
                  initialValue: get(data, 'refund.dash_task_id'),
              })(<NumericInput addonBefore={"#SOC"}/>)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Empresa do responsável pela indicação">
                {getFieldDecorator('customer_id', {
                    initialValue: get(data, 'refund.customer_id'),
                  })(
                      <Select
                        showSearch
                        placeholder="Selecione uma empresa"
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                      {customers!==null && customers.map(row => 
                          <Option value={row.id} key={row.id}>{`${row.cod} - ${row.name}`}</Option>
                      )}
                    </Select>
                  )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Empresa indicada">
                {getFieldDecorator('nominated_company', {
                    initialValue: get(data, 'refund.nominated_company'),
                    rules: [{ required: true, message: 'Por favor informe uma empresa!' }],
                })(
                  <Input placeholder="Nome da empresa indicada" />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Descrição">
                  {getFieldDecorator('description', {
                      initialValue: get(data, 'refund.description'),
                  })(
                      <Input.TextArea rows={4} placeholder="Descrição da comissão" />
                  )}
                  </Form.Item>
              </Col>
              <Col span={8}>
                  <Form.Item label="Apuração">
                  {getFieldDecorator('apuration_at', {
                      initialValue: data.id? moment(get(data, 'refund.apuration_at'), 'YYYY-MM-DD'): moment(),
                      rules: [{ required: true, message: 'Por favor escolha uma data!' }],
                  })(
                      <DatePicker disabled={!canEdit}/>
                  )}
                  </Form.Item>
              </Col>
              <Col span={8}>
                  <Form.Item label="Valor Total">
                  {getFieldDecorator('total', {
                      initialValue: get(data, 'refund.total'),
                      rules: [{ required: true, message: 'Por favor informe o valor total!' }],
                      normalize: (value) => currency2Float(value)
                  })(<InputCurrency disabled={!canEdit}/>)}
                  </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Parcelamento">
                  {getFieldDecorator('payment_split', { 
                      initialValue: get(data, 'refund.payment_split'),
                      rules: [{ required: true, message: 'Por favor selecione a quantidade de parcelas.' }],
                  })(<Select onChange={changePaymentSplit} disabled={!canEdit}>
                      {optionsPayment()}
                  </Select>)}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Tipo de comissão">
                {getFieldDecorator('type', {
                    initialValue: get(data, 'refund.type'),
                    rules: [{ required: true, message: 'Selecione quem é responsavél pela pendência' }],
                })(
                <Radio.Group>
                  <Radio.Button value={0} size="small">A pagar</Radio.Button>
                  <Radio.Button value={1} size="small">Crédito (Desconto)</Radio.Button>
                </Radio.Group>
                )}
                </Form.Item>
              </Col>
            </Row>
            <Divider>Pagamento</Divider>
            {payments && payments.map((row, index) => 
            <Row gutter={16} key={row.uid}>
              {row.id && (
              <Col span={2}>
                <Form.Item label="Pago">
                  <Switch 
                    defaultChecked={(row.paid_at!==null)}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
            )}
            {row.id && (
              <Col span={3}>
              <Form.Item label="ID">
                <Input disabled={true} value={`SE${pad(row.id, 4)}`}/>
              </Form.Item>
              </Col>
            )}
            <Col span={5} offset={(row.id || get(data, 'id') === null)? 0 : 5}>
              <Form.Item label={`Parcela ${(index+1)}`}>
                {getFieldDecorator(`payments[${row.uid}][due_date]`, {
                  initialValue: moment(row.due_date, 'YYYY-MM-DD'),
                  rules: [{ required: true, message: 'Por favor informe a data de vencimento!' }],
                })(<DatePicker 
                onChange={
                  (e) => setPayments([...payments.map((item) => {
                      if(item.id !== row.id){
                        return item;
                      }else{
                        return {
                          ...item,
                          value: e? e.format('YYYY-MM-DD'):null
                        }
                      }
                })])}
                disabled={!canEdit}
                />)}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Valor">
                {getFieldDecorator(`payments[${row.uid}][amount]`, {
                  initialValue: row.amount,
                  rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                  normalize: (value) => currency2Float(value)
                })(
                  <InputCurrency disabled={!canEdit}/>
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item label="Forma PGTO">
              {getFieldDecorator(`payments[${row.uid}][method]`, {
                initialValue: row.method,
                rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
              })(
                <Select disabled={!canEdit}>
                  <Option key={1} value={1}>Dinheiro</Option>
                  <Option key={2} value={2}>Boleto</Option>
                  <Option key={3} value={3}>Cartão</Option>
                  <Option key={4} value={4}>Transferência</Option>
                  <Option key={5} value={5}>Pix</Option>
                  <Option key={6} value={6}>Depósito</Option>
                  <Option key={7} value={7}>Chque</Option>
                </Select>
              )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Cód">
                {getFieldDecorator(`payments[${row.uid}][cod]`, {
                  initialValue: row.cod,
                })(
                  <Input disabled={!canEdit}/>
                )}
              </Form.Item>
            </Col>
            <Form.Item label="Id" style={{display: 'none'}}>
              {getFieldDecorator(`payments[${row.uid}][id]`, {
                initialValue: data.id?row.id:null,
              })(
                <Input disabled={!canEdit}/>
              )}
            </Form.Item>
          </Row>
        )}
      </Form>
      <div
          style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
          }}
      >
      {get(data, 'id') && canEdit && canDelete && (<Button onClick={handleDelete} type="danger" style={{marginRight: '8px'}}>Deletar</Button>)}
        <Button
        style={{
            marginRight: 8,
        }}
        onClick={handleClose}
        >
        Cancelar
        </Button>
        {(get(data, 'id') && canEdit) && (
        <Button onClick={handleSave} type="primary" loading={loading}>Salvar</Button>
        )}
        {!get(data, 'id') && (
        <Button onClick={handleCreate} type="primary" loading={loading}>Cadastrar</Button>
        )}
      </div>
    </Drawer>
    )
}

const mapStateToProps = state => ({
  user: state.user,
  customers: state.customers.list.filter(row => row.inactived_at === null ).sort((a,b) => compare()(a.cod, b.cod)), //needs to display all customers and not customers here
  drawer: state.drawerRefunds,
  filter: get(state, 'refunds.filter'),
});

const mapDispatchProps = dispatch => ({
  handleClose: () => dispatch(drawerRefunds.close()),
  handleCreate: (payload) => dispatch(drawerRefunds.create(payload)),
  handleSave: (id, payload) => dispatch(drawerRefunds.save(id, payload)),
  handleDelete: (payload) => dispatch(drawerRefunds.remove(payload)),
  loadRefunds: (filter) => dispatch(refundsActions.loadRefunds(filter))
});

export default connect(mapStateToProps, mapDispatchProps)(Form.create()(DrawerRefunds));