import update from "immutability-helper";
import { get } from "lodash";
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const DEFAULT_STATE = {
  list: [],
  tasks: [],
  isLoading: true,
};

const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CACHE_ACTIONS.checklists: {
      return update(state, {
        list: {
          $set: action.payload.checklists
        },
        isLoading: {
          $set: false
        }
      });
    }

    case "LOAD_CHECKLISTS_SUCCESS": {
      return update(state, {
        list: {
          $set: action.payload.data.data,
        },
        isLoading: {
          $set: false
        }
      });
    }

    case "CREATE_CHECKLIST_SUCCESS": {
      return update(state, {
        list: { $set: [...state.list, get(action, "payload.data.data")] },
      });
    }

    case "UPDATE_CHECKLIST_SUCCESS": {
      const updatedChecklist = get(action, "payload.data.data");
      return update(state, {
        list: {
          $set: [
            ...state.list.map(
              (item) =>
                item.id !== updatedChecklist.id ? item : updatedChecklist
            ),
          ],
        },
      });
    }

    case "DELETE_CHECKLIST_SUCCESS": {
      return update(state, {
        list: { $set: [...state.list.filter((item) => item.id !== action.meta.previousAction.id)] },
      });
    }

    case "LOAD_CHECKLIST_TASKS_SUCCESS": {
      return update(state, {
        tasks: {
          $set: action.payload.data.tasks
        }
      });
    }

    case "SAVE_CHECKLIST_TASKS_SUCCESS": {
      return update(state, {
        tasks: {
          $set: action.payload.data.tasks
        }
      });
    }

    default:
      return state;
  }
};

export default reducer;
