import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const defaultState = {
  isLoading: true,
  visualizationType: 'current_month',
  monthlyPaymentsGraph: null,
  customersCountGraph: null,
  extraPaymentsGraph: null,
  dashtasksSummary: null,
  calendarTasksGraph: null,
  // certificatesSummary: null,
  leadsGraph: null,
  customersByType: null,
  defaultersGraph: null,
  customGraphicsLastUpdatedAt: null,
  calendarTasksGraphLastUpdatedAt: null
};

const dashboard = (state = defaultState, action) => {
  switch (action.type) {
    case CACHE_ACTIONS.dashboard: {
      const {
        monthly_payments,
        customers_count,
        extra_payments,
        dash_tasks,
        calendar_tasks,
        leads,
        customers_by_type,
        defaulters
      } = action.payload;
      return {
        isLoading: false,
        visualizationType: "current_month",
        monthlyPaymentsGraph: monthly_payments,
        customersCountGraph: customers_count,
        extraPaymentsGraph: extra_payments,
        dashtasksSummary: dash_tasks,
        calendarTasksGraph: calendar_tasks,
        leadsGraph: leads,
        customersByType: customers_by_type,
        defaultersGraph: defaulters,
      }
    }

    case "LOAD_DASHBOARD_DATA_FOR_CURRENT_MONTH_SUCCESS": {
      const {
        monthly_payments,
        customers_count,
        extra_payments,
        dash_tasks,
        calendar_tasks,
        leads,
        customers_by_type,
        defaulters
      } = action.payload.data;
      return {
        isLoading: false,
        visualizationType: "current_month",
        monthlyPaymentsGraph: monthly_payments,
        customersCountGraph: customers_count,
        extraPaymentsGraph: extra_payments,
        dashtasksSummary: dash_tasks,
        calendarTasksGraph: calendar_tasks,
        leadsGraph: leads,
        customersByType: customers_by_type,
        defaultersGraph: defaulters,
      };
    }

    case 'LOAD_DASHBOARD_DATA_FOR_CUSTOM_MONTHS_SUCCESS':
      return {
        visualizationType: 'custom_months',
        monthlyPaymentsGraph: action.payload.data.monthly_payments,
        customersCountGraph: action.payload.data.customers_count,
        extraPaymentsGraph: action.payload.data.extra_payments,
        dashtasksSummary: action.payload.data.dash_tasks,
        calendarTasksGraph: action.payload.data.calendar_tasks,
        leadsGraph: action.payload.data.leads,
        customersByType: action.payload.data.customers_by_type,
        defaultersGraph: action.payload.data.defaulters,
        customGraphicsLastUpdatedAt: action.payload.data.last_updated_at,
        calendarTasksGraphLastUpdatedAt: action.payload.data.calendar_tasks_last_updated_at
      }

    case 'CHANGE_VISUALIZATION_TYPE_OF_DASHBOARD':
      return {
        ...state,
        visualizationType: action.payload
      };

    default:
      return state
  }
}

export default dashboard;