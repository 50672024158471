import update from 'immutability-helper';
import get from 'lodash/get';

import * as ACTIONS from './actions';

const defaultState = {
    data: {
        id: null,
    },
    show: false,
};

const ACTION_HANDLERS = {

    [ACTIONS.SHOW]: (state, action) => {
        return update(state, {
            data: { $set: get(action, 'payload.data') ? get(action, 'payload.data') : defaultState.data},
            show: { $set: true },
        })
    },

    [ACTIONS.CLOSE]: (state) => {
        return update(state, {
            data: { $set: defaultState.data} ,
            show: { $set: false },
        })
    },

    [ACTIONS.SAVE.SUCCESS]: (state) => {
        return update(state, {
            data: { $set: defaultState.data} ,
            show: { $set: false },
        })
    },

}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
  
    return handler ? handler(state, action) : state;
};

export default reducer;