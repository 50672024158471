export const loadCertificates = ({ ...params }) => ({
  type: "LOAD_CERTIFICATES",
  payload: {
    request: {
      method: "GET",
      url: "/certificates",
      ...params,
    },
  },
});
