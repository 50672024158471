import { PERMISSIONS } from '~/constants/permissions.constants';
import { ROUTES } from '~/constants/routes.constants';

export const checkForPermissions = (permissions) => {
  if (!permissions) return false;

  const { pathname } = window.location;

  if ([ROUTES['/usuarios'], ROUTES['/permissoes']].includes(pathname)) {
    return permissions.includes(PERMISSIONS.users.view);
  }

  if (pathname === ROUTES['/clientes']) {
    return permissions.includes(PERMISSIONS.customers.view);
  }
  if (pathname === ROUTES['/checklist']) {
    return permissions.includes(PERMISSIONS.checklist.view);
  }

  if (pathname === ROUTES['/certificados']) {
    return permissions.includes(PERMISSIONS.certificates.view);
  }

  if (pathname === ROUTES['/lembretes']) {
    return permissions.includes(PERMISSIONS.procurations.view);
  }

  if (pathname === ROUTES['/convencoes']) {
    return permissions.includes(PERMISSIONS.conventions.view);
  }

  if (pathname === ROUTES['/societario']) {
    return permissions.includes(PERMISSIONS.corporate.view);
  }

  if (pathname === ROUTES['/tarefas']) {
    return permissions.includes(PERMISSIONS.calendarTasks.create);
  }

  if (pathname === ROUTES['/documentos']) {
    return permissions.includes(PERMISSIONS.documents.view);
  }

  if (pathname.startsWith('/calendario')) {
    return permissions.includes(PERMISSIONS.calendarTasks.view);
  }

  if (pathname === ROUTES['/financeiro/extras']) {
    return permissions.includes(PERMISSIONS.extraServices.view);
  }

  if (pathname === ROUTES['/financeiro/mensalidades']) {
    return permissions.includes(PERMISSIONS.financial.monthlyPayments.view);
  }

  if (pathname === ROUTES['/financeiro/comissoes']) {
    return permissions.includes(PERMISSIONS.refunds.view);
  }

  if (pathname === ROUTES['/leads']) {
    return permissions.includes(PERMISSIONS.leads.view);
  }

  if (pathname === ROUTES['/links']) {
    return permissions.includes(PERMISSIONS.links.view);
  }

  if (pathname === ROUTES['/IRPF']) {
    return permissions.includes(PERMISSIONS.irpf.view);
  }

  if (pathname.startsWith('/malha-fina')) {
    return permissions.includes(PERMISSIONS.irpf.malhaFina.view);
  }

  if (pathname === ROUTES['/relatorios/prazos']) {
    return permissions.includes(PERMISSIONS.documentsDeadline.view);
  }

  if (pathname === ROUTES['/relatorios/honorarios']) {
    return permissions.includes(PERMISSIONS.honoraries.view);
  }

  if (pathname === ROUTES['/relatorios/emails']) {
    return permissions.includes(PERMISSIONS.sentEmails.view);
  }

  if (pathname === ROUTES['/relatorios/kpis']) {
    return permissions.includes(PERMISSIONS.feedbacks.view);
  }

  if (pathname === ROUTES['/plugins']) {
    return permissions.includes(PERMISSIONS.plugins.view);
  }

  if (pathname === ROUTES['/base-de-conhecimento']) {
    return permissions.includes(PERMISSIONS.knowledgeBase.view);
  }

  return true;
};