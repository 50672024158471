import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useLocation } from 'react-router-dom';

import Layout from "~/components/UI/Layout/Layout";
import List from "./components/List";
import DrawerIRPF from "~/containers/DrawerIRPF";
import { loadIRPFs } from "./actions";
import { LoadingTopBar } from "~/components/UI/LoadingTopBar";
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

export const ScreensIRPF = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await LocalCacheHandler.load({
        actionName: CACHE_ACTIONS.irpfs,
        dispatch,
        pathname
      });
      const { payload } = await dispatch(
        loadIRPFs({
          year: moment().year(),
          showLoading: false,
        })
      );
      await LocalCacheHandler.save({
        pathname,
        data: payload.data.customers 
      })
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <DrawerIRPF />
        <List />
      </Layout>
    </div>
  );
};
