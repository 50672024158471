import { Button, Form, Select } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

const ModuleMonthlyForm = ({ form, saveConfig, user }) => {
  const { getFieldDecorator } = form;

  function handleSubmit() {
    form.validateFields(async (errors, values) => {
      if (errors) return;

      await saveConfig(values);
    });
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{
        xs: { span: 24 },
        sm: { span: 4 },
      }}
      wrapperCol={{
        xs: { span: 24 },
        sm: { span: 18 },
      }}
    >
      <Form.Item label="Décimo terceiro">
        {getFieldDecorator('decimo_terceiro_split', {
          initialValue: user.tenant.decimo_terceiro_split,
          rules: [
            {
              required: true,
              message: 'Por favor selecione uma opção',
            },
          ],
        })(
          <Select placeholder="Selecione uma opção">
            <Select.Option value={1}>Cota única</Select.Option>
            <Select.Option value={2}>Parcelado em 2x</Select.Option>
            <Select.Option value={3}>Parcelado em 3x</Select.Option>
          </Select>
        )}
      </Form.Item>
      <Form.Item
        wrapperCol={{
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 4,
          },
        }}
      >
        <Button type="primary" onClick={handleSubmit}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveConfig: (data) =>
    dispatch({
      type: 'SAVE_CONFIG',
      payload: {
        request: {
          method: 'patch',
          url: '/tenant/config',
          data,
        },
      },
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ModuleMonthlyForm));
