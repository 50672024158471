/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Table, Icon, Button, Tag, Dropdown, Menu, Modal } from 'antd';
import { connect } from 'react-redux';

import { Container, Header, PermissionsList, Title } from './styles';
import * as permissionsActions from './actions';
import {
  getParentPermission,
  verifyIfAllPermissionsOfAParentAreChecked,
} from '~/components/Permissions/handlers';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;

class Permissions extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount = async () => {
    try {
      await this.props.loadPermissionsGroups();
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleDeletePermissionsGroup = (id) => {
    Modal.confirm({
      title: 'Você realmente deseja excluir este grupo de permissão?',
      content: 'Esta ação não poderá ser desfeita.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => this.props.removePermissionsGroup(id),
    });
  };

  renderPermissions = (currentPermissions, isPremiumPlan) => {
    let parentPermissions = currentPermissions
      .map((item) => getParentPermission(item))
      .filter((item) => !!item)
      .sort((a, b) => a.order - b.order);

    if(!isPremiumPlan){
      parentPermissions = parentPermissions.filter((item) => item.plans.includes('starter'));
    }

    const uniqueParentPermissions = [
      ...new Set(parentPermissions.map((item) => item.title)),
    ];

    return (
      <PermissionsList>
        {uniqueParentPermissions.map((currentParent) => {
          const allPermissionsIsChecked = verifyIfAllPermissionsOfAParentAreChecked(
            currentPermissions,
            currentParent
          );
          return (
            <Tag color="purple" key={currentParent} style={{ marginRight: 0 }}>
              {currentParent}{' '}
              <Icon type={allPermissionsIsChecked ? 'folder' : 'folder-open'} />
            </Tag>
          );
        })}
      </PermissionsList>
    );
  };

  render() {
    const { openModal, list, user } = this.props;
    const isPremiumPlan = user.tenant.plan === 'premium';
    const { isLoading } = this.state;

    return (
      <Container>
        <Header>
          <Title>Permissões</Title>
          <Button type="primary" onClick={() => openModal()}>
            <Icon type="folder-add" /> Novo grupo
          </Button>
        </Header>
        <div className="table-clabs">
          <Table
            loading={isLoading}
            rowKey="id"
            dataSource={list}
            bordered
            rowClassName="row-clabs"
            pagination={getDefaultPaginationConfig({
              storageKey: STORAGE.defaultPageSize.permissions
            })}
          >
            <Column title="Nome" dataIndex="name" key="name" width={400} />
            <Column
              title="Permissões"
              key="permissions"
              dataIndex="permissions"
              render={(permissions) =>
                this.renderPermissions(permissions, isPremiumPlan)
              }
            />
            <Column
              key="actions"
              align="center"
              width={100}
              render={(_, record) => (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="edit" onClick={() => openModal(record)}>
                        <Icon type="edit"></Icon> Editar
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item
                        key="delete"
                        onClick={() =>
                          this.handleDeletePermissionsGroup(record.id)
                        }
                      >
                        <Icon type="delete"></Icon> Excluir
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <a className="ant-dropdown-link" href="#">
                    Ações <Icon type="down" />
                  </a>
                </Dropdown>
              )}
            />
          </Table>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  list: state.permissions.list,
});

const mapDispatchProps = (dispatch) => ({
  openModal: (data) => dispatch(permissionsActions.openModal(data)),
  loadPermissionsGroups: () =>
    dispatch(permissionsActions.loadPermissionsGroups()),
  removePermissionsGroup: (id) => dispatch(permissionsActions.remove(id)),
});

export default connect(mapStateToProps, mapDispatchProps)(Permissions);
