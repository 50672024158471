import React, { Component } from 'react';
import { Modal, Input, Form } from 'antd';
import { request } from '../../Instance';

const { TextArea } = Input;

class Archive extends Component {

    handleOk = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {

                request('PUT', `/certificates/${this.props.data.id}/archive`, {
                    ...values
                })
                .then(response => {
                    this.props.saveCertificate(response);
                    this.props.onClose();
                })
            }
        })

    }

    render() {
        const { data } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                title={`Arquivar Certificado | ${data.name}`}
                okText="Arquivar"
                visible={true}
                onOk={this.handleOk}
                onCancel={this.props.onClose}
            >
            <Form>
                <Form.Item label="Observação">
                    {getFieldDecorator('archived_message', {
                    rules: [{ required: true, message: 'Por favor preencha este campo!' }],
                })(
                    <TextArea rows={4} />
                )}
                </Form.Item>
            </Form>
        </Modal>
        )
    }
}

export default Form.create()(Archive);