import React from 'react';
import styled from 'styled-components';

import WhatsappPng from './whatsapp.png';

export const WhatsappIcon = () => {
  return <img src={WhatsappPng} alt="Ícone do Whatsapp" />
}

export const onlyNumbers = (value) => {
  if (!value) {
    return value;
  }
  return value.replace(/\D/g, '');
}

export const phoneMask = (phone) => {
  if (!phone) {
    return '';
  }
  if (phone.length === 11) {
    return `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}-${phone.slice(7)}`;
  }
  return `(${phone.slice(0, 2)}) ${phone.slice(2, 6)}-${phone.slice(6)}`;
}

export const WhatsappLink = styled.a`
> img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
`;