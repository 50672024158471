// import react from 'react';
import styled from 'styled-components';

const TopBar = styled.div`
    margin: 0px -16px; 
    padding: 15px; 
    background: #fff; 
    margin-bottom: 10px;

    h1 {
        font-size: 20px;
    }
`
export default TopBar;