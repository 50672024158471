import React, { Component } from 'react'
import { Row, Col, Card } from 'antd';

import { Background, InvitedCard, Image } from '../components/Invited/Styled';
import InvitedForm from '../components/Invited/InvitedForm';

class ScreensInvited extends Component {
  render() {

    return (
      <Background>
        <Row type="flex" justify="center" className="pt-80">
            <Col span={14}>
                <InvitedCard>
                    <Row type="flex" justify="center">
                        <Image span={10} />
                        <Col span={14}>
                            <Card style={{paddingLeft: `20px`, paddingRight: `20px`}}>
                                <InvitedForm invitation={this.props.match.params.invitation}/>
                            </Card>
                        </Col>
                    </Row>
                </InvitedCard>
                <center><p>Contlabs.com.br</p></center>
            </Col>
        </Row>
      </Background>
    )
  }
}

export default ScreensInvited;