import React from 'react';
import { Icon, Button } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TodoWrapper, TodoItem, TaskCheckbox, TaskInput } from './styled';

export const TodoList = ({ tasks, setTasks }) => {
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const updatedTasks = Array.from(tasks);
    const [reorderedTask] = updatedTasks.splice(result.source.index, 1);
    updatedTasks.splice(result.destination.index, 0, reorderedTask);

    setTasks(updatedTasks);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="tasks">
        {(provided) => (
          <TodoWrapper {...provided.droppableProps} ref={provided.innerRef}>
            {tasks.map((task, index) => (
              <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                {(provided) => (
                  <TodoItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <>
                      <TaskCheckbox
                        checked={task.done}
                        onChange={() => setTasks(tasks.map(t => t.id === task.id ? { ...t, done: !t.done } : t))}
                      />
                      <TaskInput
                        value={task.title}
                        onChange={(e) => setTasks(tasks.map(t => t.id === task.id ? { ...t, title: e.target.value } : t))}
                        placeholder="Descreva a tarefa"
                      />
                      <Button type="link" onClick={() => setTasks(tasks.filter(t => t.id !== task.id))}>
                        <Icon type="delete" style={{ color: 'red' }} />
                      </Button>
                    </>
                  </TodoItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </TodoWrapper>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TodoList;
