export const STORAGE = {
  defaultPageSize: {
    certificates: 'certificates/default-page-size',
    billsToReceive: 'bills-to-receive/default-page-size',
    sporadicDocumentTypesConfig: 'sporadic-document-types-config/default-page-size',
    checklistTasksConfig: 'checklist-tasks-config/default-page-size',
    documents: 'documents/default-page-size',
    conventions: 'conventions/default-page-size',
    customers: 'customers/default-page-size',
    monthlyPayments: 'monthly-payments/default-page-size',
    procurations: 'procurations/default-page-size',
    registerTasks: 'register-tasks/default-page-size',
    users: 'users/default-page-size',
    checklists: 'checklists/default-page-size',
    emailsLogs: 'emails-logs/default-page-size',
    honoraries: 'honoraries/default-page-size',
    irpfs: 'irpfs/default-page-size',
    malhaFina: 'malha-fina/default-page-size',
    refunds: 'refunds/default-page-size',
    feedbacks: 'feedbacks/default-page-size',
    customersMonthly: 'customers-monthly/default-page-size',
    documentsEmailsLogs: 'documents-emails-logs/default-page-size',
    irpfHonoraries: 'irpf-honoraries/default-page-size',
    customersAppUsers: 'customers-app-users/default-page-size',
    partnersInstallments: 'partners-installments/default-page-size',
    knowledgeBase: 'knowledge-base/default-page-size',
    leadCalendarFollowUps: 'lead-calendar-follow-ups/default-page-size',
    leadCalendarMeetings: 'lead-calendar-meetings/default-page-size',
    permissions: 'permissions/default-page-size',
    automations: 'automations/default-page-size',
    referralProgram: 'referral-program/default-page-size',
    referralProgramIndications: 'referral-program-indications/default-page-size',
    referralProgramAudience: 'referral-program-audience/default-page-size',
    reports: 'reports/default-page-size',
    solicitations: 'solicitations/default-page-size',
  },
};
