import React, { useEffect, useState } from 'react';

import { EmailButton } from './buttons/EmailButton';
import { ProtocolButton } from './buttons/ProtocolButton';
import { PanelButton } from './buttons/PanelButton';
import { WhatsappButton } from './buttons/WhatsappButton';
import {
  ButtonsGroupContainer,
  DeliveryMethodButtonContainer,
} from '../styles';

export const DeliveryMethodButtons = ({
  filesInState,
  data,
  updateCustomers,
}) => {
  const [buttonsLoadingState, setButtonsLoadingState] = useState({
    panel: false,
    email: false,
    whatsapp: false,
    protocol: false,
  });

  const [buttonsState, setButtonsState] = useState({
    isPanelButtonClicked: false,
    isEmailButtonClicked: false,
    isWhatsappButtonClicked: false,
    isProtocolButtonClicked: false,
  });

  useEffect(() => {
    setButtonsState({
      isPanelButtonClicked: checkIfFilesHasDeliveryMethod(2),
      isEmailButtonClicked: checkIfFilesHasDeliveryMethod(0),
      isWhatsappButtonClicked: checkIfFilesHasDeliveryMethod(3),
      isProtocolButtonClicked: checkIfFilesHasDeliveryMethod(1),
    });
  }, [data]);

  function checkIfFilesHasDeliveryMethod(method) {
    return data.files.length > 0
      ? data.files.every(({ delivery_methods }) =>
          delivery_methods.includes(method)
        )
      : false;
  }

  function updateDeliveryMethods(method) {
    updateCustomers(
      {
        files: data.files.map((file) => ({
          ...file,
          delivery_methods: [...new Set([...file.delivery_methods, method])],
        })),
      },
      data.id
    );
  }

  function checkIfHasButtonLoading() {
    const { panel, email, whatsapp, protocol } = buttonsLoadingState;
    return panel || email || whatsapp || protocol;
  }

  function getButtonTootipTitle(method) {
    if (defaultCustomerDeliveryMethod === method) {
      return 'Método de envio escolhido pelo cliente';
    }

    if (hasNewFiles) {
      return 'É necessário salvar os documentos';
    }

    switch (method) {
      case 0: {
        if (
          checkIfIsDefaultDeliveryMethod(0) ||
          buttonsState.isEmailButtonClicked
        ) {
          return 'E-mail já enviado';
        }
        break;
      }
      case 1: {
        if (buttonsState.isProtocolButtonClicked) {
          return 'Protocolo já gerado';
        }
        break;
      }
      case 2: {
        if (
          checkIfIsDefaultDeliveryMethod(2) ||
          buttonsState.isPanelButtonClicked
        ) {
          return 'Documento já enviado';
        }
        break;
      }
      case 3: {
        if (buttonsState.isWhatsappButtonClicked) {
          return 'Mensagem já enviada';
        }
        break;
      }
      default: {
        break;
      }
    }
    return undefined;
  }

  function checkIfIsDefaultDeliveryMethod(method) {
    return defaultCustomerDeliveryMethod === method;
  }

  function renderButtons() {
    return Object.entries(buttons)
      .filter(([key]) => +key !== +defaultCustomerDeliveryMethod)
      .map(([_, value]) => value);
  }

  function renderDeliveryMethodButton() {
    return buttons[defaultCustomerDeliveryMethod];
  }

  const hasNewFiles = filesInState.length > 0;
  const defaultCustomerDeliveryMethod = data.customer.doc_delivery_method;
  const currentTaskRecordId = data.task_record_id;
  const currentCustomer = data.customer;

  const buttonsProps = {
    email: {
      tooltipTitle: getButtonTootipTitle(0),
      isDefaultMethod: checkIfIsDefaultDeliveryMethod(0),
    },
    protocol: {
      tooltipTitle: getButtonTootipTitle(1),
      isDefaultMethod: checkIfIsDefaultDeliveryMethod(1),
    },
    panel: {
      tooltipTitle: getButtonTootipTitle(2),
      isDefaultMethod: checkIfIsDefaultDeliveryMethod(2),
    },
    whatsapp: {
      tooltipTitle: getButtonTootipTitle(3),
      isDefaultMethod: checkIfIsDefaultDeliveryMethod(3),
    },
  };

  const defaultProps = {
    buttonsProps,
    buttonsState,
    buttonsLoadingState,
    hasNewFiles,
    currentTaskRecordId,
    checkIfHasButtonLoading,
    setButtonsLoadingState,
    updateDeliveryMethods,
    setButtonsState,
    defaultCustomerDeliveryMethod,
    currentCustomer,
  };

  const buttons = {
    0: <EmailButton key="1" props={defaultProps} />,
    1: <ProtocolButton key="2" props={defaultProps} />,
    2: <PanelButton key="3" props={defaultProps} />,
    3: <WhatsappButton key="4" props={defaultProps} />,
  };

  return (
    <>
      <DeliveryMethodButtonContainer>
        {renderDeliveryMethodButton()}
      </DeliveryMethodButtonContainer>
      <ButtonsGroupContainer>{renderButtons()}</ButtonsGroupContainer>
    </>
  );
};
