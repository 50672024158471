import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Layout } from '~/components/UI';
import { LoadingTopBar } from '~/components/UI/LoadingTopBar';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { Honoraries } from '~/containers/Honoraries';
import { loadHonoraries } from '~/containers/Honoraries/actions';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

export const ScreensHonoraries = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [yearFilter, setYearFilter] = useState(new Date().getFullYear());
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    loadData();
  }, [yearFilter]);

  async function loadData() {
    try {
      setIsLoading(true);

      if(currentYear === yearFilter) {
        await LocalCacheHandler.load({
          actionName: CACHE_ACTIONS.honoraries,
          dispatch,
          pathname,
        });
      }

      const { payload } = await dispatch(
        loadHonoraries({
          showLoading: false,
          year: yearFilter,
        })
      );

      if(currentYear === yearFilter) {
        await LocalCacheHandler.save({
          pathname,
          data: payload.data.customers,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <Honoraries 
          yearFilter={yearFilter} 
          setYearFilter={setYearFilter}
        />
      </Layout>
    </div>
  );
};
