import { Button } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: url("/images/bg.svg");
`;

export const FormContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin-top: 50px;
  height: max-content;
  padding: 30px;
  background-color: #fff;
  border-radius: 4px;

  @media only screen and (max-width: 480px) {
    margin-top: 0px;
    height: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  text-align: center;
  line-height: 35px;
`;

export const SubmitButton = styled(Button).attrs({
  size: "large",
  block: true,
  icon: "whatsapp",
  type: "primary",
})`
  margin-top: 10px;
`;

export const LogoLink = styled.a.attrs({
  href: "https://contlabs.com.br/",
  target: "_blank",
  rel: "noopener noreferrer",
})``;

export const LogoImage = styled.img.attrs({
  src: "/images/logo.svg",
  alt: "Logo",
})`
  margin: auto;
  display: block;
  width: 170px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorTitle = styled.h1`
  font-size: 20px;
  text-align: center;
  line-height: 25px;
`;
