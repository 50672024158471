import React, { Component } from 'react';
import { DatePicker as DatepickerAntd } from 'antd';
import CalendarLocale from 'rc-calendar/lib/locale/pt_BR';
import TimePickerLocale from '../TimePicker/Locale';

// Merge into a locale object
const locale = {
  lang: {
    placeholder: 'Selecionar data',
    rangePlaceholder: ['Data de início', 'Data de fim'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

export class DatePicker extends Component {
  render() {
    const { props } = this;
    return (
      <DatepickerAntd 
        placeholder="Selecione uma data"
        style={{ width: '100%' }}
        locale={locale}
        format={`DD/MM/YYYY ${props.showTime ? 'HH:mm' : ''}`}
        getPopupContainer={trigger => trigger.parentNode}
        {...props}
      />
    )
  }
}

export default locale;