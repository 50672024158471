export const groupBy = (originalArray, prop) => {
    var newArray = [];
    var lookupObject  = {};

    for(var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
}

export const multipleSort = (compFunctions) => {
    return function (a, b) {
        for (const func of compFunctions) {
            const result = func(a, b);
            if (result !== 0) {
                return result;
            }
        }
    }
}

export function pad(str, length) {
    const resto = length - String(str).length;
    return '0'.repeat(resto > 0 ? resto : '0') + str;
  }