import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Checklist from '~/containers/Checklist';
import { loadChecklists } from '~/containers/Checklist/actions';
import DrawerChecklist from '~/containers/DrawerChecklist';
import { loadPermissionsGroups } from '~/containers/Permissions/actions';
import { LoadingTopBar } from '~/components/UI/LoadingTopBar';
import Layout from '../components/UI/Layout/Layout';
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

export const ScreensChecklist = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await LocalCacheHandler.load({
        actionName: CACHE_ACTIONS.checklists,
        dispatch,
        pathname,
      });
      const [
        { payload: checklistsPayload },
        { payload: permissionsGroupsPayload },
      ] = await Promise.all([
        dispatch(loadChecklists({ showLoading: false })),
        dispatch(loadPermissionsGroups({ showLoading: false })),
      ]);
      await LocalCacheHandler.save({
        pathname,
        data: {
          checklists: checklistsPayload.data.data,
          permissionsGroups: permissionsGroupsPayload.data.data,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <Checklist />
        <DrawerChecklist />
      </Layout>
    </div>
  );
};
