import { createAsyncActions } from "~/utils/store";

export const BOARD_LOAD = createAsyncActions("@@BOARDS/LOAD");
export const BOARD_MOVE_ITEM = createAsyncActions('@@BOARDS/MOVE_ITEM');
export const BOARD_DUPLICATE_ITEM = createAsyncActions('@@BOARDS/DUPLICATE_ITEM');


export const load = () => ({
  type: BOARD_LOAD.INIT,
  payload: {
    request: {
      method: 'GET',
      url: '/boards',
      showLoading: false,
    }
  }
})

export const moveItem = (data) => ({
  type: BOARD_MOVE_ITEM.INIT,
  payload: {
    request: {
      method: 'put',
      url: `/boards/${data.draggableId}/move/${data.destination.droppableId}`,
      data: data,
      showLoading: false,
    }
  }
});

export const duplicateItem = (data) => ({
  type: BOARD_DUPLICATE_ITEM.INIT,
  payload: {
    request: {
      method: 'post',
      url: `/boards/item/${data.id}/duplicate`,
      showLoading: false,
    },
    data
  }
});