import React from 'react';
import styled from 'styled-components';
import { message, Icon } from 'antd';

const Code = styled.div`
    background-color: #f5f5f5;
    border-radius: 3px;
    color: #333;
    display: block;
    font-size: 13px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 2.3em 1.5em;
    word-wrap: break-word;
    white-space: pre-wrap;
    position: relative;

    &:hover{
        cursor: pointer;
    }
    `

const CopyToClipboardIcon = styled(Icon).attrs({
    type: "copy",
    style: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      fontSize: '18px'
    }
})``

const handleOnClikck = (content) => {
    navigator.clipboard.writeText(content.replace(/%0a/g, '\n'));
    message.success('Texto copiado.');
}
  

const ContentCopy = ({content}) => 
    <Code onClick={() => handleOnClikck(content)}>
        {content.replace(/%0a/g, '\n')} <CopyToClipboardIcon onClick={() => handleOnClikck(content)}/>
    </Code>

export default ContentCopy;