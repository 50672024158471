import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Row, Col, Icon, Tooltip, InputNumber } from 'antd';
import InputCurrency, { currency2Float } from '~/components/UI/InputCurrency';
import { DatePicker } from '~/components/UI/DatePicker/Locale';
import uid from 'uid';
import moment from 'moment';

const ModalMonthly = ({
    visible, 
    setVisible,
    monthlyHistory,
    addToMonthlyHistory,
    honoraryToUpdate,
    setHonoraryToUpdate,
    updateHonorary,
    form
}) => {
    const { getFieldDecorator } = form;

    const canUpdateAmountAndDate = honoraryToUpdate ? honoraryToUpdate.canUpdateAmountAndDate : true;

    const handleOk = () => {
        
        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            if (monthlyHistory.length > 0) {
                const lastDate = monthlyHistory[monthlyHistory.length - 1].start_at;
                const honoraryToUpdateId = honoraryToUpdate ? honoraryToUpdate.id : undefined;
                const currentDateIsNotTheOnlyOne = monthlyHistory[monthlyHistory.length - 1].id !== honoraryToUpdateId;

                if ((currentDateIsNotTheOnlyOne && canUpdateAmountAndDate) || !honoraryToUpdate) {
                    if(!moment(values.start_at).isAfter(lastDate)){
    
                        form.setFields({
                            start_at: {
                                value: values.start_at,
                                errors: [new Error(`A data não pode ser anterior ao último lançamento (${moment(lastDate).format('DD/MM/YYYY')}).`)]
                            }
                        });

                        setHonoraryToUpdate(undefined);
                        return;
                    }
                }
            }

            if (honoraryToUpdate) {
                updateHonorary({
                    ...honoraryToUpdate,
                    start_at: values.start_at.format('YYYY-MM-DD'),
                    amount: values.amount,
                    average_billing: values.average_billing,
                    n_staffs: values.n_staffs,
                    updated: true
                });
            } else {
                addToMonthlyHistory({
                    id: uid(10),
                    start_at: values.start_at.format('YYYY-MM-DD'),
                    amount: values.amount,
                    average_billing: values.average_billing,
                    n_staffs: values.n_staffs,
                    new: true
                });
            }

            form.resetFields();
            setVisible(false);

            // props.addMonthlyHonorary(props.data.id, values)
            // .then(response => {
            //     form.resetFields();
            // });
        });

    }

    return (
        <Modal
          title={honoraryToUpdate ? "Editar Honorário" : "Novo Honorário"}
          visible={visible}
          onOk={handleOk}
          destroyOnClose
          onCancel={() => {
            setVisible(false);
            if (honoraryToUpdate) setHonoraryToUpdate(undefined);
          }}
          okText="Salvar"
        >
            <Form name="monthly" layout="vertical">
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label={<span>Honorário</span>}>
                            {getFieldDecorator('amount', {
                                initialValue: honoraryToUpdate ? honoraryToUpdate.amount : null,
                                rules: [{ required: true, message: 'Por favor informe o valor do honorário!' }],
                                normalize: (value) => currency2Float(value)
                            })(
                                <InputCurrency disabled={!canUpdateAmountAndDate} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={<span>
                            Data <small>(a partir de)</small>{' '}
                            <Tooltip placement="top" title={'Inicie com a data mais antiga.'}>
                                <Icon type="info-circle"/>
                            </Tooltip>
                            </span>}>
                            {getFieldDecorator('start_at', {
                                initialValue: honoraryToUpdate ? moment(honoraryToUpdate.start_at) : null,
                                rules: [{ required: true, message: 'Por favor informe uma data!' }],
                                })(
                                <DatePicker style={{width: `100%`}} disabled={!canUpdateAmountAndDate} />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className="mb-20">
                    <Col span={12}>
                        <Form.Item label="Faturamento médio">
                            {getFieldDecorator('average_billing', {
                                initialValue: honoraryToUpdate ? honoraryToUpdate.average_billing : null,
                                rules: [{ required: true, message: 'Por favor informe o valor do faturamento médio!' }],
                                normalize: (value) => currency2Float(value)
                            })(
                                <InputCurrency/>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Funcionários">
                            {getFieldDecorator('n_staffs', {
                                initialValue: honoraryToUpdate ? honoraryToUpdate.n_staffs : null,
                                rules: [{ required: true, message: 'Por favor informe o número de funcionários!' }],
                                })(
                                <InputNumber min={0} style={{ width: `100%` }} />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

ModalMonthly.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    monthlyHistory: PropTypes.array
}
export default Form.create()(ModalMonthly);