import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

const defaultState = [];

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.users]: (state, action) => {
        return action.payload;
    },

    'LOAD_USERS_SUCCESS': (state, action) => {
        return action.payload.data;
    },

    'REGISTER_USER_SUCCESS': (state, action) => {
        return [
            ...state,
            {...action.payload.data.data}
        ];
    },

    'DISABLE_USER_SUCCESS': (state, action) => {
        return state.map(item => 
            item.id === action.meta.previousAction.id ? {...item, status: 'Desativado'} : item
        );
    },

    'ACTIVE_USER_SUCCESS': (state, action) => {
        return state.map(item => 
            item.id === action.meta.previousAction.id ? {...item, status: 'Ativo'} : item
        );
    },

    'DELETE_USER_SUCCESS': (state, action) => {
        return state.filter(item => item.id !== action.meta.previousAction.id);
    },

    'SAVE_PERMISSIONS_USER_SUCCESS': (state, action) => {
        return state.map(item => 
            item.id !== action.meta.previousAction.id ? item : action.payload.data.data
        );
    },
};

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if (!handler) return state;

    const newState = handler(state, action);

    const updateCache = [
        'LOAD_USERS_SUCCESS',
        'REGISTER_USER_SUCCESS',
        'DISABLE_USER_SUCCESS',
        'ACTIVE_USER_SUCCESS',
        'DELETE_USER_SUCCESS',
        'SAVE_PERMISSIONS_USER_SUCCESS'
    ].includes(action.type);

    if (updateCache) {
        LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.users,
            data: newState
        });
    }

    return newState;
};

export default reducer;
