import { createAsyncActions } from '~/utils/store';

export const SHOW = '@@DRAWER_IRPF/SHOW';
export const CLOSE = '@@DRAWER_IRPF/CLOSE';

export const CREATE = createAsyncActions('@@DRAWER_IRPF/CREATE');
export const SAVE = createAsyncActions('@@DRAWER_IRPF/SAVE');
export const DELETE = createAsyncActions('@@DRAWER_IRPF/DELETE');
export const STATUS = createAsyncActions('@@DRAWER_IRPF/STATUS');
export const START_SERVICE = createAsyncActions('@@DRAWER_IRPF/START_SERVICE');
export const ARCHIVE = createAsyncActions('@@DRAWER_IRPF/ARCHIVE');
export const ACTIVATE = createAsyncActions('@@DRAWER_IRPF/ACTIVATE');

export const show = (data = null) => ({
    type: SHOW,
    payload: {
        data
    }
});

export const close = () => ({
    type: CLOSE,
    payload: {}
});

export const create = (data) => ({
    type: CREATE.INIT,
    payload: {
        request:{
            method: 'post',
            url:'/pf/customer',
            data,
            showLoading: false
        }
      }
});

export const save = (id, data) => ({
    type: SAVE.INIT,
    payload: {
        request:{
            method: 'post',
            url:`/pf/customer/${id}`,
            data,
            showLoading: false
        }
    }
});

export const remove = (data) => ({
    type: DELETE.INIT,
    payload: {
        request:{
            method: 'delete',
            url:`/pf/customer/${data.id}`
        },
        data
    }
});

export const changeStatus = (data, status, year) => ({
    type: STATUS.INIT,
    payload: {
        request:{
            method: 'put',
            url:`/pf/customer/${data.id}?year=${year}`,
            data: {
                status
            },
            showLoading: false
        }
    }
});

export const startService = (irpf_id, current_honorary, year) => ({
    type: START_SERVICE.INIT,
    payload: {
        request: {
            method: 'post',
            url: `/pf/customer/${irpf_id}/start_service`,
            data: {
                current_honorary,
                year
            }
        }
    }
})

export const archive = (honorary_id, year) => ({
    type: ARCHIVE.INIT,
    payload: {
        request: {
            method: 'put',
            url: `/pf/customer/${honorary_id}/archive?year=${year}`
        }
    }
})

export const activate = (honorary_id, year) => ({
    type: ACTIVATE.INIT,
    payload: {
        request: {
            method: 'put',
            url: `/pf/customer/${honorary_id}/activate?year=${year}`
        }
    }
})
