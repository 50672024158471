import styled from 'styled-components';

export const HeaderContainer = styled.div`
  margin: 0px -16px;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderLeftContent = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 600px;
  align-items: center;
  gap: 8px;
`;

export const NewIrpfContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-calendar-picker {
    width: 160px !important;
  }
`;

export const WhatsappLink = styled.a`
  > img {
    width: 16px;
    height: 16px;
  }
`;