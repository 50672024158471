import React from "react";
import { Table } from "antd";
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';
import { columnsAPI } from './columns';
import { useSelector } from "react-redux";

const TableList = (props) => {

    let {
        isLoading = false,
        data = [],
        filter,
    } = props;

    const tableColumns = columnsAPI.getColumns({...props});

    const customersById = useSelector(state => state.customersById);

    data = data.map((item) => {
        return {
            ...item,
            customer: customersById[item.customer_id]
        }
    });

    data = data.filter(item => {
        if (filter) {
            return (
                item.customer.cod.toLowerCase().includes(filter.toLowerCase()) ||
                item.customer.name.toLowerCase().includes(filter.toLowerCase()) ||
                item.solicitation_type.toLowerCase().includes(filter.toLowerCase()) ||
                item.description.toLowerCase().includes(filter.toLowerCase()) ||
                item.customer.app_users.find(appUser => appUser.id === item.app_user_id).name.toLowerCase().includes(filter.toLowerCase())
            );
        }

        return true;
    });

    return (
        <div>
            <div className={`table-clabs`}>
                <Table 
                    loading={isLoading}
                    tableLayout="fixed"
                    pagination={getDefaultPaginationConfig({
                        storageKey: STORAGE.defaultPageSize.solicitations
                    })}
                    bordered={true}
                    rowClassName={(record) => record.status === 1 ? 'row-clabs row-green' : 'row-clabs'}
                    columns={tableColumns}
                    dataSource={data}
                    rowKey="id"
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                props.setSelectedRow(record);
                            },
                            onMouseEnter: () => {
                                document.body.style.cursor = 'pointer';
                            },
                            onMouseLeave: () => {
                                document.body.style.cursor = 'default';
                            }
                        };
                    }}
                />
            </div>
        </div>
    );
}

export default TableList;