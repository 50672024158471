import { Radio } from "antd";
import styled from "styled-components";

export const RevisedRadioButton = styled(Radio.Button).attrs({
  value: "revised",
})`
  border-color: ${({ isActive }) => (isActive ? "#a0d911 !important" : "inherit")};
  color: ${({ isActive }) => (isActive ? "#a0d911 !important" : "inherit")};
`;

export const NotRevisedRadioButton = styled(Radio.Button).attrs({
  value: "not_revised",
})`
  border-color: ${({ isActive }) => (isActive ? "#f5222d !important" : "inherit")};
  color: ${({ isActive }) => (isActive ? "#f5222d !important" : "inherit")};
  box-shadow: ${({ isActive }) =>
    isActive ? "-1px 0 0 0 #f5222d !important" : "inherit"};
`;

export const DropdownButton = styled.button`
  background-color: transparent;
  width: 100%;
  border: none;
  text-align: start;
  padding: 0;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`;

export const HeaderButtonsWrapper = styled.div`
  float: right;
  display: flex;
  gap: 6px;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
`;

export const HeaderLeftContent = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
  width: 100%;
`;