const methods = [
  { name: 'Dinheiro', value: 1},
  { name: 'Boleto', value: 2},
  { name: 'Cartão', value: 3},
  { name: 'Transferência', value: 4},
  { name: 'Pix', value: 5},
  { name: 'Depósito', value: 6},
  { name: 'Cheque', value: 7},
]

const getLabel = (value) => {
  return methods.find((i) => i.value == value)
}

export {
  methods,
  getLabel
} 