import { Avatar } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const ThumbnailAvatar = styled(Avatar)`
  border: 2px solid;
  border-color: ${({ viewed }) => viewed === "true" ? "#d9d9d9" : "#52c41a"};
  padding: 2px !important;
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;
  transition: border-color 500ms;

  > img {
    border-radius: 50% !important;
  }
`;
