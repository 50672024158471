import React, { useState } from "react";
import { Form, Input, Modal } from "antd";
import uid from "uid";
import { useDispatch } from "react-redux";

import * as customersActions from "~containers/Customer/actions";

export const AppUserAccessModal = Form.create()(
  ({ isOpen, onClose, customer, form }) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [wantToGenerateAccess, setWantToGenerateAccess] = useState(false);

    function closeDrawer() {
      form.resetFields();
      if (wantToGenerateAccess) {
        setWantToGenerateAccess(false);
      }
      onClose();
    }

    async function generateAccessRequest(data) {
      await dispatch(customersActions.generateAccess(data));
    }

    async function checkAccessRequest(data) {
      return dispatch(customersActions.checkAccess(data));
    }

    function handleGenerateAccess() {
      form.validateFields(async (error, values) => {
        if (error) return;

        try {
          setIsLoading(true);

          if (wantToGenerateAccess) {
            await generateAccessRequest({
              customerId: customer.id,
              name: values.name,
              email: values.email,
            });
            return closeDrawer();
          }

          const { payload } = await checkAccessRequest({
            customerId: customer.id,
            email: values.email,
          });

          const { customers, app_user } = payload.data;

          if (customers.length > 0) {
            form.setFieldsValue({
              name: app_user.name,
            });

            Modal.confirm({
              title: `Você tem certeza de que deseja criar um acesso com o e-mail "${values.email}" para o cliente "${customer.name}"?`,
              width: 550,
              content: (
                <div>
                  <span>Este e-mail já possui acesso aos clientes:</span>
                  <ul>
                    {customers.map((item) => (
                      <li key={uid()}>- {item}</li>
                    ))}
                  </ul>
                </div>
              ),
              okText: "Sim",
              cancelText: "Não",
              onOk: () => setWantToGenerateAccess(true),
            });
          } else {
            await generateAccessRequest({
              customerId: customer.id,
              name: values.name,
              email: values.email,
            });
            closeDrawer();
          }
        } finally {
          setIsLoading(false);
        }
      });
    }

    return (
      <Modal
        visible={isOpen}
        onCancel={closeDrawer}
        onOk={handleGenerateAccess}
        title="Acesso"
        okText="Gerar"
        cancelText="Voltar"
        okButtonProps={{ loading: isLoading }}
      >
        <Form layout="vertical">
          <Form.Item label="Nome" required>
            {form.getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Por favor, insira o nome do usuário",
                },
              ],
            })(<Input placeholder="Nome do usuário" />)}
          </Form.Item>
          <Form.Item label="E-mail" required>
            {form.getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  message: "Por favor, insira o e-mail do usuário",
                },
                {
                  type: "email",
                  message: "Por favor, insira um e-mail válido",
                },
              ],
              initialValue: customer ? customer.email : null,
            })(<Input placeholder="E-mail do usuário" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);
