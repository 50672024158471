import React, { Component } from "react";
import Permissions from "~/containers/Permissions";
import PermissionsFormModal from "~/containers/Permissions/Modals/PermissionsFormModal";

import Layout from "../components/UI/Layout/Layout";

class ScreensPermissions extends Component {
  render() {
    return (
      <div>
        <Layout>
          <Permissions />
          <PermissionsFormModal />
        </Layout>
      </div>
    );
  }
}

export default ScreensPermissions;
