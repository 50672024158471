import styled from "styled-components";

export const Container = styled.div``;

export const Header = styled.header`
  margin: 0 -16px;
  padding: 15px;
  background-color: white;
  display: flex;
  align-items: center;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const Cards = styled.div`
  padding-top: 16px;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 8px;
  overflow-y: auto;

  @media only screen and (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: 1920px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: 2048px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media only screen and (min-width: 2500px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const Card = styled.button`
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: white;
  padding: 12px;
  display: flex;
  gap: 12px;
  transition: filter 300ms;
  cursor: pointer;
  position: relative;

  &:hover {
    filter: brightness(96%);
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: baseline;
`;

export const CardTitle = styled.span`
  color: #722ed1;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 20px;
  z-index: 999;
  text-align: initial;
`;

export const CardDescription = styled.span`
  margin-bottom: 6px;
  color: #8c8c8c;
  font-size: 14px;
  text-align: start;
`;
