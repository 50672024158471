/* eslint-disable default-case */
import { get } from 'lodash';

export const getNameDrawer = (category) => {
    switch (parseInt(category)) {
      case 1: return 'Abertura de Empresa';
      case 2: return 'Alteração Contratual';
      case 3: return 'Encerramento de Empresa';
      case 4: return 'Tarefa esporádica';
      case 5: return 'Troca de Contabilidade (entrada)';
      case 6: return 'Outros';
      case 7: return 'Troca de Contabilidade (saída)';
    }
  }
  
export const getCurrentStep = (data) => {
    if (get(data, 'finalized_at')) {
      return 2;
    } else if (get(data, 'processed_at')) {
      return 1;
    } else {
      return 0;
    }
}