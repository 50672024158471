import { Button, Form, Select } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: ${({ show }) => (show === 'true' ? 'flex' : 'none')};
  align-items: flex-end;
  gap: 8px;
`;

export const LogicalOperatorField = styled(Form.Item)`
  width: 80px;
`;

export const ConditionField = styled(Form.Item)``;

export const OperatorField = styled(Form.Item)`
  width: 80px;
`;

export const ValueField = styled(Form.Item)`
  flex: 1;
`;

export const ConditionFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ConditionText = styled.div`
  width: 80px;
`;

export const FieldSelect = styled(Select)`
  width: 180px !important;
`;

export const OperatorSelect = styled(Select)``;

export const ValueSelect = styled(Select)`
  max-width: ${({ is_first }) => {
    return is_first === 'true' ? 'none' : '218.5px !important';
  }};
`;

export const ValueFieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const NewConditionButton = styled(Button)`
  margin-bottom: 20px;
`;
