import * as onboardingActions from "~/components/Onboarding/actions";
import { getOnboardingSteps } from "~/utils/get-onboarding-steps";

export const finishCurrentOnboardingStepAction = ({
  isActive,
  dispatch,
  step,
  user,
}) => {
  if (!isActive) return;

  const { finishedStepsCount, allStepsCount } = getOnboardingSteps(user);

  if (finishedStepsCount === allStepsCount) {
    dispatch(onboardingActions.selectStep(step + 1));
  } else {
    const newStatus = {
      ...user.onboarding_status,
      [step]: "finish",
    };

    dispatch(onboardingActions.finishStep(newStatus));
  }
};
