import Chart from "react-apexcharts";
import React from "react";

import "./styles.css";
import moment from "moment";

export const CustomersByType = ({ data }) => {
  if (!data) return null;

  const companyTaxations = Object.values(data).map((item) => item.company_taxations);

  return (
    <Chart
      options={{
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        tooltip: {
          custom: function(props) {
            const isDomesticSerie = props.seriesIndex === 1;
            const headerTitle = props.w.globals.labels[props.dataPointIndex];
            const domesticValue = props.series[1][props.dataPointIndex];
            const companyData = companyTaxations[props.dataPointIndex];

            const {
              presumed_profit_count,
              real_profit_count,
              mei_count,
              liberal_professional_count,
              simple_national_count,
            } = companyData;

            return `
              <div class="custom_tooltip">
                <div class="header">${headerTitle}</div>
                <div class="content">
                  ${
                    isDomesticSerie
                      ? `<span>Domesticas: <strong>${domesticValue}</strong></span>`
                      : "<span>" +
                        `Lucro Presumido: <strong>${presumed_profit_count}</strong><br />` +
                        `Lucro Real: <strong>${real_profit_count}</strong><br />` +
                        `MEI: <strong>${mei_count}</strong><br />` +
                        `Profissional Liberal: <strong>${liberal_professional_count}</strong><br />` +
                        `Simples Nacional: <strong>${simple_national_count}</strong><br />` +
                        "</span>"
                  }
                </div>
              </div>
            `;
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: Object.keys(data).map((item) => moment(`${item}-01`).format('MMM/YY')),
        },
        fill: {
          opacity: 1,
        },
      }}
      series={[
        {
          name: "Empresas",
          data: Object.values(data).map((item) => item.company_count),
        },
        {
          name: "Domésticas",
          data: Object.values(data).map((item) => item.domestic_count),
        },
      ]}
      type="bar"
      height={350}
    />
  );
};
