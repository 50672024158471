import { Modal, Table, Tag } from "antd";
import moment from "moment";
import React from "react";
import { getFormattedEvent } from "~/containers/Documents/helpers";

const { Column } = Table;

export const EmailsLogsModal = ({ isOpen, onClose, logs }) => {
  const formattedLogs = logs.map((log) => ({
    ...log,
    email_status: getFormattedEvent(log.event),
    sent_at: moment(log.sent_at).format("DD/MM/YYYY HH:mm:ss"),
  }));

  return (
    <Modal visible={isOpen} onCancel={onClose} title="Status" footer={false}>
      <Table bordered dataSource={formattedLogs} pagination={false}>
        <Column title="Data" key="sent_at" align="center" dataIndex="sent_at" />
        <Column
          title="Status"
          key="email_status"
          dataIndex="email_status"
          align="center"
          render={(status) => {
            if (!status) return null;
            return <Tag color={status.color}>{status.text}</Tag>;
          }}
        />
      </Table>
    </Modal>
  );
};
