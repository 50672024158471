import { createAsyncActions } from "~/utils/store";

export const LOAD_EMAILS_LOGS = createAsyncActions(
  "@@EMAILS_LOGS/LOAD"
);

export const loadEmailsLogs = (params) => ({
  type: LOAD_EMAILS_LOGS.INIT,
  payload: {
    request: {
      method: "GET",
      url: '/emails-logs',
      ...params,
      showLoading: false
    },
  },
});
