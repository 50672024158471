import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Layout from '../components/UI/Layout/Layout';
import ConventionsList from '../components/Conventions/ConventionsList';
import ConventionsForm from '../components/Conventions/ConventionsForm';
import { loadConventions } from '~/components/Conventions/actions';
import { LoadingTopBar } from '~/components/UI/LoadingTopBar';
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

export const ScreensConventions = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [drawer, setDrawer] = useState({
    isVisible: false,
    data: null,
  });

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await LocalCacheHandler.load({
        actionName: CACHE_ACTIONS.conventions,
        dispatch,
        pathname
      });
      const { payload } = await dispatch(
        loadConventions({ showLoading: false })
      );
      await LocalCacheHandler.save({
        pathname,
        data: payload.data,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function hideDrawer() {
    setDrawer({
      isVisible: false,
      data: null,
    });
  }

  function showDrawer(data = null) {
    setDrawer({
      isVisible: true,
      data,
    });
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <ConventionsList showEdit={showDrawer} showModal={() => showDrawer()} />
        <ConventionsForm
          visible={drawer.isVisible}
          onClose={hideDrawer}
          data={drawer.data}
        />
      </Layout>
    </div>
  );
};
