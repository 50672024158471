import { DatePicker, Empty, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadDocumentsDeadlines } from "./actions";

import {
  Container,
  // DeadlineItem,
  // DeadLineName,
  // Deadlines,
  DeadlineSkeletonItem,
  // DeadlineSummary,
  // DeadlineSummaryItem,
  // Footer,
  Header,
  Main,
} from "./styles";

import "./custom-skeleton.css";
import { CustomersModal } from "./CustomersModal";
import { ModuleHeader } from '~/components/ModuleHeader';
import { columns } from "./Columns";

const { MonthPicker } = DatePicker;

export const DocumentsDeadlines = () => {
  const dispatch = useDispatch();
  const {
    documentsDeadlines: { list: documentsDeadlines, isLoading },
  } = useSelector((state) => state);

  const [customersModal, setCustomersModal] = useState({
    isVisible: false,
    data: null,
  });

  function showCustomersModal(data) {
    setCustomersModal({
      isVisible: true,
      data,
    });
  }

  function hideCustomersModal() {
    setCustomersModal({
      isVisible: false,
      data: null,
    });
  }

  return (
    <Container>
      <Header>
        <ModuleHeader 
          breadcrumbs={['Relatórios', 'Prazos']} 
          title="Prazos (impostos)" 
          actions={
            <MonthPicker
              allowClear={false}
              format="MM/YYYY"
              defaultValue={moment()}
              onChange={(date) => {
                dispatch(
                  loadDocumentsDeadlines({
                    date: date.endOf("month").format("YYYY-MM-DD"),
                  })
                );
                }}
              />
              }
            />
            </Header>
            <Main>
            {isLoading ? (
              <>
              {Array(6)
                .fill(1)
                .map((_, index) => (
                <DeadlineSkeletonItem key={index} />
                ))}
              </>
            ) : !isLoading && documentsDeadlines.length === 0 ? (
              <Empty
              description="Nenhuma tarefa encontrada."
              style={{ marginTop: 200 }}
              />
            ) : (
                <div className="table-clabs">
                  <Table
                    bordered
                    dataSource={documentsDeadlines}
                    columns={columns()}
                    rowKey="id"
                    onRow={(record) => ({
                      onClick: () => showCustomersModal(record.registers),
                      style: { cursor: 'pointer' },
                    })}
                  />
                </div>
              )}
            </Main>
      <CustomersModal
        isVisible={customersModal.isVisible}
        data={customersModal.data}
        hideCustomersModal={hideCustomersModal}
      />
    </Container>
  );
};
