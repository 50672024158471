export const showDrawer = (data = {}) => ({
  type: 'SHOW_DRAWER_CHECKLIST',
  payload: {
    data
  }
});

export const closeDrawer = () => ({
  type: 'CLOSE_DRAWER_CHECKLIST',
  payload: null
});