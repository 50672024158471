import update from "immutability-helper";
import { get } from "lodash";

const DEFAULT_STATE = {
  data: {},
  show: false,
};

const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "SHOW_DRAWER_CHECKLIST": {
      return update(state, {
        data: { $set: get(action, "payload.data") },
        show: { $set: true },
      });
    }

    case "CLOSE_DRAWER_CHECKLIST": {
      return update(state, {
        show: { $set: false },
      });
    }

    default:
      return state;
  }
};

export default reducer;
