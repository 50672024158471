import moment from 'moment';
import update from 'immutability-helper';
import get from 'lodash/get';

import * as IRPF from './actions';
import * as DRAWER_IRPF from '../DrawerIRPF/actions';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const defaultState = {
    customers: [],
    fetched: false,
    filter: {},
    current_year: moment(),
    isLoading: true
};

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.irpfs]: (state, action) => {
        return update(state, {
            customers: {
                $set: action.payload
            },
            isLoading: {
                $set: false
            }
        });
    },

    [IRPF.LOAD_IRPFS.SUCCESS]: (state, action) => {
        return update(state, {
            customers: {
                $set: action.payload.data.customers
            },
            fetched: { $set: true },
            isLoading: {
                $set: false
            }
        });
    },

    [IRPF.CHANGE_YEAR]: (state, action) => {
        return update(state, {
            current_year: { $set: get(action, 'payload.year') }
        })
    },

    [DRAWER_IRPF.CREATE.SUCCESS]: (state, action) => {
        return update(state, {
            customers: { $push: [get(action, 'payload.data.data')] },
        })
    },

    [DRAWER_IRPF.SAVE.SUCCESS]: (state, action) => {
        return update(state, {
            customers: (old) => old.map(c => c.id !== get(action, 'payload.data.data.id') ? c : get(action, 'payload.data.data')),
        })
    },

    [DRAWER_IRPF.DELETE.SUCCESS]: (state, action) => {
        return update(state, {
            customers: (old) => old.filter(c => c.id !== get(action, 'meta.previousAction.payload.data.id')),
        })
    },

    [DRAWER_IRPF.STATUS.SUCCESS]: (state, action) => {
        return update(state, {
            customers: (old) => old.map(c => c.id !== get(action, 'payload.data.data.id') ? c : get(action, 'payload.data.data')),
        })
    },

    [DRAWER_IRPF.ARCHIVE.SUCCESS]: (state, action) => {
        return update(state, {
            customers: (old) => old.map(c => c.id !== get(action, 'payload.data.data.id') ? c : get(action, 'payload.data.data')),
        })
    },

    [DRAWER_IRPF.ACTIVATE.SUCCESS]: (state, action) => {
        return update(state, {
            customers: (old) => old.map(c => c.id !== get(action, 'payload.data.data.id') ? c : get(action, 'payload.data.data')),
        })
    },
}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};

export default reducer;