import { Button, Icon, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import moment from 'moment';
import React, { Component } from 'react';
import { department } from '~/components/RegisterTasks/Utils';
import { phoneMask } from '~/helpers/masks';

export class DocumentsTable extends Component {
  getStatusTag = (file) => {
    let selectedStatus;
    let icon;

    if (moment(file.due_at).isBefore(moment(), 'dates') && !file.viewed_at) {
      selectedStatus = { name: 'VENCIDO', color: '#ff0a02' };
    } else if (file.viewed_at) {
      selectedStatus = { name: 'BAIXADO', color: '#03b14e' };
    } else {
      selectedStatus = { name: 'NÃO BAIXADO', color: '#0071c0' };
    }

    if (file.status === 'Pago') {
      icon = <Icon type="check" />;
    } else if (file.viewed_at) {
      icon = <Icon type="download" />;
    }

    return (
      <div
        style={{
          ...styles.statusTag,
          backgroundColor: selectedStatus.color,
        }}
      >
        {icon || null}
        {selectedStatus.name}
      </div>
    );
  };

  render() {
    const { selectedTaskRecord, files, openDocumentDrawer } = this.props;

    return (
      <Table
        rowKey="id"
        bordered
        style={styles.table}
        dataSource={files}
        scroll={{ x: true }}
        pagination={false}
      >
        <Column
          title="Arquivo"
          dataIndex="file_url"
          align="center"
          key="file_url"
          fixed="left"
          width={90}
          render={(file_url, record) => {
            return (
              <a
                href={file_url || URL.createObjectURL(record.file)}
                style={styles.fileLink}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon type="file" />
              </a>
            );
          }}
        />
        <Column
          title="Tipo"
          key="type"
          dataIndex="type"
          width={150}
          align="center"
        />
        <Column
          title="Colaborador"
          key="created_by_user"
          dataIndex="created_by_user"
        />
        <Column
          title="Departamento"
          key="department"
          dataIndex="department"
          align="center"
          render={(departmentId) => department(departmentId)}
        />
        <Column
          title="Competência"
          key="competency_at"
          dataIndex="competency_at"
          align="center"
          render={(competency_at) => moment(competency_at).format('MM/YYYY')}
        />
        <Column
          title="Vencimento"
          key="due_at"
          dataIndex="due_at"
          align="center"
          render={(due_at) => moment(due_at).format('DD/MM/YYYY')}
        />
        <Column
          title="Valor"
          key="amount"
          dataIndex="amount"
          align="center"
          render={(amount) =>
            new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(amount)
          }
        />
        <Column
          title="Administração"
          key="viewed_at"
          dataIndex="viewed_at"
          align="center"
          render={(viewed_at, file) => {
            const appUser = file.viewed_by_app_user;

            if (!viewed_at || !appUser) return null;

            const firstAppUserName = appUser.name.split(" ")[0];
            const formattedViewedAt = moment(viewed_at).format('DD/MM/YYYY [as] LTS');

            return `${firstAppUserName}... ${formattedViewedAt}`;
          }}
        />
        <Column
          title="Whatsapp"
          key="whatsapp"
          align="center"
          render={() => phoneMask(selectedTaskRecord.customer.phone)}
        />
        <Column
          align="center"
          title="Status"
          key="status"
          render={(_, file) => this.getStatusTag(file)}
        />
        <Column
          align="center"
          dataIndex="id"
          key="edit"
          width={50}
          fixed="right"
          render={(id, record) => !Number.isInteger(id) && <Button type="primary" icon="edit" onClick={() => openDocumentDrawer(record)} />}
        />
      </Table>
    );
  }
}

const styles = {
  statusTag: {
    color: 'white',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    fontSize: '13px',
    padding: '5px 10px',
    borderRadius: '3px',
  },
  table: {
    marginTop: 20,
  },
  fileLink: {
    color: 'black',
  },
};
