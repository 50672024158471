import React from 'react';
import { Button, Popover, Radio } from 'antd';

const RadioGroup = Radio.Group;

export default class FilterPopOver extends React.Component{
    state ={
        visible: false,
        filter: null
    }

    hide = () => {
        this.setState({
          visible: false,
        });
    }
    
    handleVisibleChange = (visible) => {
        this.setState({ visible });
    }

    render(){
        const { options } = this.props;

        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };

        return (
        <Popover 
            visible={this.state.visible}
            onVisibleChange={this.handleVisibleChange}
            content={
            <RadioGroup 
                value={this.state.filter}
                onChange={(e) => {
                    this.setState({filter: e.target.value});
                    this.props.onChange(e.target.value);
                    this.hide();
                }}>
                    {options.map(item => 
                    <Radio key={item} style={radioStyle} value={item.value}>{item.label}</Radio>
                    )}
                    <Button size="small" onClick={() => {
                            this.setState({filter: null});
                            this.props.onChange(null);
                            this.hide();
                    }}>Resetar</Button>
                    </RadioGroup>
                } trigger="click">
            <Button type="default" icon={'filter'}/>
        </Popover>
        );
    }
}