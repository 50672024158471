import { Button } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  margin: 0 -16px;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const RegistrationButton = styled(Button)`
  padding: 0 30px;
`;

export const ExtensionButton = styled(Button)`
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;

  > img {
    height: 16px;
    width: 16px;
  }
`;