import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';

import Layout from "~/components/UI/Layout/Layout";
import List from "./List";
import { loadMalhaFina } from "./actions";
import { LoadingTopBar } from "~/components/UI/LoadingTopBar";
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

export const ScreensMalhaFina = ({ match }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [year, setYear] = useState(moment().format("YYYY"));
  const [isLoading, setIsLoading] = useState(true);

  const { department } = match.params;

  useEffect(() => {
    loadData();
  }, [department, year]);

  async function loadData() {
    try {
      await LocalCacheHandler.load({
        actionName: CACHE_ACTIONS.malhaFina,
        dispatch,
        pathname
      });
      const { payload } = await dispatch(
        loadMalhaFina({
          department,
          year,
          showLoading: false,
        })
      );
      await LocalCacheHandler.save({
        pathname,
        data: {
          department,
          data: payload.data[department]
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <List year={year} setYear={setYear} />
      </Layout>
    </div>
  );
};
