import update from 'immutability-helper';
import get from 'lodash/get';

import * as MALHAFINA from './actions';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const defaultState = {
    data: {
        federal: [],
        estadual: [],
        municipal: [],
        INSS: [],
        FGTS: [],
        IRPF: []
    },
    fetched: false,
    filter: {},
    isLoading: true
};

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.malhaFina]: (state, action) => {
        const { department, data } = action.payload;
        return update(state, {
            data: {
                [department]: {
                    $set: data
                }
            },
            isLoading: {
                $set: false
            }
        });
    },

  [MALHAFINA.LOAD_MALHA_FINA.SUCCESS]: (state, action) => {
    const { department } = action.meta.previousAction.payload;
    return update(state, {
      data: {
        [department]: {
          $set: action.payload.data[department],
        },
      },
      fetched: { $set: true },
      isLoading: {
        $set: false
      }
    });
  },

    [MALHAFINA.STATUS.SUCCESS]: (state, action) => {
        return update(state, {
            data: { [action.meta.previousAction.payload.department]: (old) => old.map(c => c.id !== get(action, 'payload.data.row.id') ? c : get(action, 'payload.data.row'))}
        })
    },

    [MALHAFINA.DELETE_REGISTER.SUCCESS]: (state, action) => {
        const department = action.meta.previousAction.payload.department;
        const { id, month_base } = action.meta.previousAction.payload.data;

        const IRPFIndexes = {
            '04': 0,
            '07': 1,
            '10': 2,
            '02': 3
        }

        const currentMonthIndex = department !== 'IRPF' ?
            Number(month_base) - 1 :
            IRPFIndexes[month_base] ;

        return update(state, {
            data: {
                [department]: {
                    $set: state.data[department].map((item) => {
                        if (item.id !== id) {
                            return item;
                        }
                        return {
                            ...item,
                            [`date${currentMonthIndex}`]: null,
                            [`date${currentMonthIndex}_user`]: null,
                            [`date${currentMonthIndex}_performed_at`]: null,
                        }
                    })
                }
            }
        });
    },

    [MALHAFINA.DELETE_ALL_REGISTER_OF_MONTH.SUCCESS]: (state, action) => {
        const department = action.meta.previousAction.payload.department;
        const { month_base } = action.meta.previousAction.payload.data;

        const IRPFIndexes = {
            '04': 0,
            '07': 1,
            '10': 2,
            '02': 3
        }

        const currentMonthIndex = department !== 'IRPF' ?
            Number(month_base) - 1 :
            IRPFIndexes[month_base] ;

        return update(state, {
            data: {
                [department]: {
                    $set: state.data[department].map((item) => {
                        if (!Object.keys(item).includes(`date${currentMonthIndex}`)) {
                            return item;
                        }

                        return {
                            ...item,
                            [`date${currentMonthIndex}`]: null,
                            [`date${currentMonthIndex}_user`]: null,
                            [`date${currentMonthIndex}_performed_at`]: null,
                        }
                    })
                }
            }
        })
    },

    [MALHAFINA.SEND_WHATSAPP.SUCCESS]: (state, action) => {
        const { department, data: { id, month_base } } = action.meta.previousAction.payload;

        const IRPFIndexes = {
            '04': 0,
            '07': 1,
            '10': 2,
            '02': 3
        }

        const currentMonthIndex = department !== 'IRPF'
            ? Number(month_base) - 1
            : IRPFIndexes[month_base];

        return update(state, {
            data: {
                [department]: {
                    $set: state.data[department].map((item) => {
                        if (item.id !== id) {
                            return item;
                        }
                        return {
                            ...item,
                            [`is_whatsapp_sent${currentMonthIndex}`]: 1,
                        }
                    })
                }
            }
        });
    },

    [MALHAFINA.SEND_EMAIL.SUCCESS]: (state, action) => {
        const { department, data: { id, month_base } } = action.meta.previousAction.payload;

        const IRPFIndexes = {
            '04': 0,
            '07': 1,
            '10': 2,
            '02': 3
        }

        const currentMonthIndex = department !== 'IRPF'
            ? Number(month_base) - 1
            : IRPFIndexes[month_base];

        return update(state, {
            data: {
                [department]: {
                    $set: state.data[department].map((item) => {
                        if (item.id !== id) {
                            return item;
                        }
                        return {
                            ...item,
                            [`is_email_sent${currentMonthIndex}`]: 1,
                        }
                    })
                }
            }
        });
    },

    [MALHAFINA.SEND_EMAILS_IN_BATCH.SUCCESS]: (state, action) => {
        const { department, month, ids } = action.meta.previousAction.payload;

        const IRPFIndexes = {
            '04': 0,
            '07': 1,
            '10': 2,
            '02': 3
        }

        const currentMonthIndex = department !== 'IRPF'
            ? Number(month) - 1
            : IRPFIndexes[month];

        return update(state, {
            data: {
                [department]: {
                    $set: state.data[department].map((item) => {
                        if (!ids.includes(item.id)) {
                            return item;
                        }
                        return {
                            ...item,
                            [`is_email_sent${currentMonthIndex}`]: 1,
                        }
                    })
                }
            }
        });
    }

}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
  
    return handler ? handler(state, action) : state;
};

export default reducer;