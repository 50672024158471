import moment from 'moment';
import React from 'react'
import Chart from 'react-apexcharts'

export const MonthlyPaymentChart = ({ data }) => {
  if (!data) return null;

  const options = {
    chart: {
      type: 'line',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (value, { seriesIndex }) {
        if (seriesIndex === 2) {
          return `${value}%`;
        }
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(value)
      }
    },
    stroke: {
      width: [1, 1, 4]
    },
    xaxis: {
      categories: Object.keys(data).map((item) => moment(`${item}-01`).format('MMM/YY')),
    },
    yaxis: [
      {
        show: true,
      },
      {
        show: false,
        seriesName: 'Faturamento'
      },
      {
        opposite: true,
        show: true,
        max: 100,
      },
    ],
    tooltip: {
      enabled: true
    },
  };

  const series = [{
    name: 'Faturamento',
    type: 'column',
    data: Object.values(data).map((item) => item.total)
  }, {
    name: 'Recebido',
    type: 'column',
    data: Object.values(data).map((item) => item.paid)
  }, {
    name: 'Percentual de inadimplência',
    type: 'line',
    data: Object.values(data).map((item) => item.defaulters_percentage)
  }];

  return (
    <Chart
      height={350}
      options={options}
      series={series}
    />
  )
}