import { createAsyncActions } from '~/utils/store';

export const OPEN_DRAWER = createAsyncActions('@@LINKS/OPEN_DRAWER');
export const CLOSE_DRAWER = createAsyncActions('@@LINKS/CLOSE_DRAWER');
export const OPEN_CATEGORIES_MODAL = createAsyncActions(
  '@@LINKS/OPEN_CATEGORIES_MODAL'
);
export const CLOSE_CATEGORIES_MODAL = createAsyncActions(
  '@@LINKS/CLOSE_CATEGORIES_MODAL'
);
export const LOAD_LINKS = createAsyncActions('@@LINKS/LOAD');
export const CREATE_LINK = createAsyncActions('@@LINKS/CREATE');
export const UPDATE_LINK = createAsyncActions('@@LINKS/UPDATE');
export const DELETE_LINK = createAsyncActions('@@LINKS/DELETE');
export const LOAD_CATEGORIES = createAsyncActions('@@LINKS/LOAD_CATEGORIES');
export const SAVE_CATEGORIES = createAsyncActions('@@LINKS/SAVE_CATEGORIES');

export const loadLinks = ({ ...params }) => ({
  type: LOAD_LINKS.INIT,
  payload: {
    request: {
      method: "GET",
      url: "/links",
      ...params,
    },
  },
});

export const createLink = (data) => ({
  type: CREATE_LINK.INIT,
  payload: {
    request: {
      method: 'post',
      url: '/links',
      data,
    },
  },
});

export const updateLink = (id, data) => ({
  type: UPDATE_LINK.INIT,
  payload: {
    request: {
      method: 'put',
      url: `/links/${id}`,
      data,
    },
  },
});

export const deleteLink = (id) => ({
  type: DELETE_LINK.INIT,
  payload: {
    request: {
      method: 'delete',
      url: `/links/${id}`,
    },
  },
  id,
});

export const loadLinkCategories = ({ ...params }) => ({
  type: LOAD_CATEGORIES.INIT,
  payload: {
    request: {
      method: "GET",
      url: "/links/categories",
      ...params
    },
  },
});

export const saveLinkCategories = (data, updatedCategories) => {
  return {
  type: SAVE_CATEGORIES.INIT,
  payload: {
    request: {
      method: 'post',
      url: '/links/categories',
      data,
    },
  },
  updatedCategories
}};

export const openDrawer = (data = null) => ({
  type: OPEN_DRAWER.INIT,
  payload: {
    data,
  },
});

export const closeDrawer = () => ({
  type: CLOSE_DRAWER.INIT,
});

export const openCategoriesModal = () => ({
  type: OPEN_CATEGORIES_MODAL.INIT,
});

export const closeCategoriesModal = () => ({
  type: CLOSE_CATEGORIES_MODAL.INIT,
});
