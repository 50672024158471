export const loadDocumentsByFilter = ({
  filterBy,
  from,
  to,
  min = 0,
  max = 0,
  ...params
}) => ({
  type: "LOAD_DOCUMENTS",
  payload: {
    request: {
      method: "get",
      url: `/documents?filter_by=${filterBy}&from=${from}&to=${to}&min=${min}&max=${max}`,
      ...params,
    },
  },
});
export const impersonateAccess = (id, data) => ({
  type: "IMPERSONATE_ACCESS",
  payload: {
    request: {
      method: "post",
      url: `/documents/${id}/impersonateaccess/generate`,
      data
    },
  },
});

export const changeRevised = (id, data) => ({
  type: "CHANGE_DOCUMENT_REVISED",
  payload: {
    request: {
      method: "patch",
      url: `/documents/${id}/change-revised`,
      data
    },
    id
  }
});

export const generateAccessToFile = (id) => ({
  type: "GENERATE_ACCESS_TO_FILE",
  payload: {
    request: {
      method: "get",
      url: `/documents/${id}/generate-jwt`
    }
  }
});

export const archiveDocument = (id, data) => ({
  type: "ARCHIVE_DOCUMENT",
  payload: {
    request: {
      method: 'patch',
      url: `/documents/${id}/archive`,
      data
    }
  }
})

export const createDocument = (data) => ({
  type: "CREATE_DOCUMENT",
  payload: {
    request: {
      method: 'post',
      url: '/documents',
      data
    }
  }
})

export const createDocumentInBatch = (data) => ({
  type: "CREATE_DOCUMENT_IN_BATCH",
  payload: {
    request: {
      method: "POST",
      url: `/documents/batch`,
      data
    }
  }
})


export const loadDocumentTypes = (showLoading = true) => ({
  type: 'LOAD_DOCUMENT_TYPES',
  payload: {
    request: {
      method: 'GET',
      url: '/documents/types',
      showLoading
    }
  }
})

export const saveDocumentTypes = (data) => ({
  type: 'SAVE_DOCUMENT_TYPES',
  payload: {
    request: {
      method: 'PUT',
      url: '/documents/types',
      data,
    },
  },
});

export const loadDocumentLogs = (id) => ({
  type: 'LOAD_DOCUMENT_LOGS',
  payload: {
    request: {
      method: 'GET',
      url: `/documents/${id}/emails/logs`,
    }
  }
})

export const resendEmail = ({ documentId, email, copyEmails }) => ({
  type: 'RESEND_DOCUMENT_EMAIL',
  payload: {
    request: {
      method: 'POST',
      url: `/documents/${documentId}/resend-email`,
      data: {
        email,
        copyEmails
      }
    }
  }
});

export const sendEmails = (id) => ({
  type: 'SEND_DOCUMENT_EMAILS',
  payload: {
    request: {
      method: 'POST',
      url: `/documents/${id}/send-emails`,
    },
    id
  }
});

export const loadPendingDocuments = () => ({
  type: 'LOAD_PENDING_DOCUMENTS',
  payload: {
    request: {
      method: 'GET',
      url: '/documents/pending'
    }
  }
});

export const sendToWhatsapp = ({ customerFileId }) => ({
  type: "GENERATE_ACCESS_TO_FILES",
  payload: {
    request: {
      method: "GET",
      url: `/documents/${customerFileId}/send-to-whatsapp`,
    },
  },
});

export const sendEmailWithLink = ({ customerFileId }) => ({
  type: "SEND_EMAIL_WITH_LINK",
  payload: {
    request: {
      method: "POST",
      url: `/documents/${customerFileId}/send-email-with-link`,
    },
  },
});

export const sendEmailWithAttachment = ({ customerFileId }) => ({
  type: "SEND_EMAIL_WITH_ATTACHMENT",
  payload: {
    request: {
      method: "POST",
      url: `/documents/${customerFileId}/send-email-with-attachment`,
    },
  },
});