import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Layout } from '~/components/UI';
import { LoadingTopBar } from '~/components/UI/LoadingTopBar';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import Leads from '~/containers/Leads';
import { fetchQtddByMonth, loadLeads } from '~/containers/Leads/actions';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

export const ScreensLeads = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await LocalCacheHandler.load({
        actionName: CACHE_ACTIONS.leads,
        dispatch,
        pathname,
      });
      const [
        { payload: leadsPayload },
        { payload: metaInfoPayload },
      ] = await Promise.all([
        dispatch(loadLeads({ showLoading: false })),
        dispatch(
          fetchQtddByMonth({
            date: moment().format('MM-YYYY'),
            showLoading: false,
          })
        ),
      ]);
      await LocalCacheHandler.save({
        pathname,
        data: {
          columns: leadsPayload.data.leads,
          hideLeads: leadsPayload.data.hideLeads,
          metaInfo: metaInfoPayload.data.data,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <Leads />
      </Layout>
    </div>
  );
};
