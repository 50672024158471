import React from "react";
import { Drawer, Empty, Icon, Tooltip } from "antd";
import moment from "moment";

import { convertToBRL, limitCharacterLength } from "~/utils/formatters";
import { necessity } from "../data";
import { renderSourceIcon } from "../helpers/renderSourceIcon";
import { handleCopyLeadToClipBoard } from "../LeadCard/handlers";

import {
  Container,
  CalendarIcon,
  CompanyTypeAndNecessityContainer,
  CompanyTypeTag,
  CopyButton,
  CopyIcon,
  Date,
  DateContainer,
  LeadInfo,
  LeadName,
  LeftTopContent,
  MainContent,
  NecessityTag,
  PercentageContainer,
  ProgressStyled,
  SourceIconContainer,
  Value,
  Content,
} from "./styles";

export const LeadsDrawer = ({ isVisible, onClose, list, showDrawer }) => {
  return (
    <Drawer
      title="Leads ocultos"
      visible={isVisible}
      onClose={onClose}
      width={400}
    >
      {list.length === 0 && <Empty description="Nenhum lead encontrado." />}
      {list.map((lead) => (
        <Container key={lead.id} onClick={() => showDrawer(lead)}>
          <LeftTopContent>
            {lead.source && (
              <SourceIconContainer>
                {renderSourceIcon(lead.source)}
              </SourceIconContainer>
            )}
            <DateContainer hasSourceIcon={!!lead.source}>
              <CalendarIcon />
              <Date>{moment(lead.created_at).fromNow()}</Date>
            </DateContainer>
          </LeftTopContent>
          <CopyButton
            onClick={(event) => handleCopyLeadToClipBoard(event, lead)}
          >
            <CopyIcon />
          </CopyButton>
          <Content>
            <MainContent>
              <LeadInfo>
                <LeadName>{limitCharacterLength(lead.name, 33)}</LeadName>
                {(lead.company_type || lead.necessity) && (
                  <CompanyTypeAndNecessityContainer>
                    {lead.company_type && (
                      <CompanyTypeTag color="blue">
                        <Icon type="tag" /> {lead.company_type}
                      </CompanyTypeTag>
                    )}
                    {lead.necessity && (
                      <NecessityTag color={necessity[lead.necessity].color}>
                        <Icon type="question-circle" />{" "}
                        {necessity[lead.necessity].name}
                      </NecessityTag>
                    )}
                  </CompanyTypeAndNecessityContainer>
                )}
              </LeadInfo>
            </MainContent>
            {!!lead.honorary && <Value>{convertToBRL(lead.honorary)}</Value>}
          </Content>
          <PercentageContainer>
            <Tooltip title="Percentual de cadastro">
              <ProgressStyled percent={lead.percentage} />
            </Tooltip>
          </PercentageContainer>
        </Container>
      ))}
    </Drawer>
  );
};
