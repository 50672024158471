import React, { Component } from 'react';
import CurrencyInput from 'react-currency-input';

class InputCurrency extends Component {
    render() {
        const { props } = this;
        return (
            <CurrencyInput 
                {...props} 
                className={`ant-input ${props.disabled ? 'ant-input-disabled':''}`}
                decimalSeparator="," 
                thousandSeparator="."
                prefix="R$ "
            />
        )
    }
}

export const currency2Float = (value) => {
    if (typeof value !== 'string') return value;
    if (value === '') return 0;

    let isNegative = value[0] === '-';
    let tmp = value.replace(/\D+/g, "");
    
    if (tmp.length < 2) {
        return isNegative ? -parseFloat("0." + tmp) : parseFloat("0." + tmp);
    }

    let result = parseFloat(tmp.substr(0, tmp.length - 2) + "." + tmp.substr(-2));
    return isNegative ? -result : result;
}

export const toBRL = (value) => {
    if(typeof value !== 'number') return value;
    return value.toLocaleString('pt-BR', {minimumFractionDigits: 2});
}

export default InputCurrency;