import React from "react";
import { department } from "~/components/RegisterTasks/Utils";
import { Table, Checkbox, Button, Icon } from "antd";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";

import "./styles.css";

const { Column } = Table;

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grabbing", color: "#999" }} />
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const defaultDepartmentFilters = [
  {
    text: department(1),
    value: "1",
  },
  {
    text: department(2),
    value: "2",
  },
  {
    text: department(3),
    value: "3",
  },
  {
    text: department(4),
    value: "4",
  },
  {
    text: department(5),
    value: "5",
  },
  {
    text: department(6),
    value: "6",
  },
]

const TasksTable = ({
  tasks,
  onCheckTask,
  onEditTask,
  onRemoveTask,
  onSetState,
  defaultFilteredDepartments
}) => {
  function onSortEnd({ oldIndex, newIndex }) {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(tasks),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      const upadatedData = newData.map((item, index) => ({
        ...item,
        index,
      }));
      onSetState({ tasks: upadatedData });
    }
  }

  function DraggableContainer(props) {
    return (
      <SortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  }

  function DraggableBodyRow({ className, style, ...restProps }) {
    const index = tasks.findIndex(
      (task) => task.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  }

  const sortedTasks = tasks.sort((a, b) => a.index - b.index);

  const departmentFilters = defaultFilteredDepartments.length > 0 ?
    defaultFilteredDepartments.map((item) => ({
      text: department(item),
      value: item
    })) :
    defaultDepartmentFilters;

  console.log('defaultFilteredDepartments', defaultFilteredDepartments);
  console.log('departmentFilters', departmentFilters);


  return (
    <Table
      dataSource={sortedTasks}
      rowKey="index"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
      style={{ marginTop: 20, marginBottom: 20 }}
      bordered
      scroll={{ y: 300 }}
      pagination={false}
    >
      <Column
        key="sort"
        render={() => <DragHandle />}
        width={60}
        className="drag-visible"
        align="center"
      />
      <Column
        key="done"
        dataIndex="done"
        className="drag-visible"
        render={(done, record) => (
          <Checkbox onChange={() => onCheckTask(record.uid)} checked={!!done} />
        )}
        width={50}
        align="center"
      />
      <Column title="Nome" key="name" dataIndex="name" />
      <Column
        title="Departamento"
        dataIndex="department"
        key="department"
        filters={departmentFilters}
        defaultFilteredValue={defaultFilteredDepartments}
        onFilter={(value, record) => +record.department === +value}
        render={(key) => department(key)}
        width={150}
        align="center"
      />

      <Column
        render={(_, record) => (
          <Button type="primary" onClick={() => onEditTask(record.uid)}>
            <Icon type="edit" />
          </Button>
        )}
        width={80}
        align="center"
      />
      <Column
        key="actions"
        render={(_, record) => (
          <Button type="danger" onClick={() => onRemoveTask(record.uid)}>
            <Icon type="delete" />
          </Button>
        )}
        width={80}
        align="center"
      />
    </Table>
  );
};

export default TasksTable;
