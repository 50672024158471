import { Skeleton } from 'antd';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const LoadingSkeletonContainer = styled.div`
  height: 90px;
  margin-bottom: 8px;
`;

export const LoadingSkeleton = styled(Skeleton).attrs({
  active: true,
  title: false,
  paragraph: {
    width: '100%',
    rows: 1,
  },
})`
  .ant-skeleton-content .ant-skeleton-paragraph {
    border: 1px solid #e6e6e6;
  }

  .ant-skeleton-content .ant-skeleton-paragraph li:first-child {
    height: 90px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;