import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Layout from '../components/UI/Layout/Layout';
import ProcurationsList from '~/components/Procurations/ProcurationsList';
import ProcurationsForm from '~/components/Procurations/ProcurationsForm';
import { loadProcurations } from '~/components/Procurations/actions';
import { LoadingTopBar } from '~/components/UI/LoadingTopBar';
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { loadCustomers } from '~/containers/Customer/actions';

export const ScreensProcurations = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [drawer, setDrawer] = useState({
    isVisible: false,
    data: null,
  });

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await LocalCacheHandler.load({
        actionName: CACHE_ACTIONS.procurations,
        dispatch,
        pathname,
      });
      const [
        { payload: procurationsPayload },
        { payload: customersPayload },
      ] = await Promise.all([
        dispatch(loadProcurations({ showLoading: false })),
        dispatch(loadCustomers({ showLoading: false })),
      ]);
      await LocalCacheHandler.save({
        pathname,
        data: {
          procurations: procurationsPayload.data,
          customers: customersPayload.data,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function showDrawer(data = null) {
    setDrawer({
      isVisible: true,
      data,
    });
  }

  function hideDrawer() {
    setDrawer({
      isVisible: false,
      data: null,
    });
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <ProcurationsList showEdit={showDrawer} showModal={showDrawer} />
        <ProcurationsForm
          visible={drawer.isVisible || drawer.data}
          onClose={hideDrawer}
          data={drawer.data}
        />
      </Layout>
    </div>
  );
};
