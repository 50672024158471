import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

const defaultState = {
    list: [],
    isLoading: true
}

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.registerTasks]: (state, action) => {
        return action.payload;
    },

    'LOAD_TASKS_SUCCESS': (state, action) => {
        return {
            ...state,
            isLoading: false,
            list: action.payload.data,
        };
    },

    'CREATE_TASK_SUCCESS': (state, action) => {
        return {
            ...state,
            list: [
                ...state.list,
                {...action.payload.data.data}
            ]
        };
    },

    'SAVE_TASK_SUCCESS': (state, action) => {
        return {
            ...state,
            list: state.list.map(item => item.id !== action.payload.data.data.id? item : action.payload.data.data)
        };
    },

    'DELETE_TASK_SUCCESS': (state, action) => {
        return {
            ...state,
            list: state.list.filter(item => item.id !== action.meta.previousAction.id)
        };
    },
}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if(!handler) return state;

    const newState = handler(state, action);
  
    const updateCache = [
        'LOAD_TASKS_SUCCESS',
        'CREATE_TASK_SUCCESS',
        'SAVE_TASK_SUCCESS',
        'DELETE_TASK_SUCCESS',
    ].includes(action.type);

    if(updateCache){
        LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.registerTasks,
            data: newState
        });
    }

    return newState;
}

export default reducer;