import { Popover } from "antd";
import moment from "moment";
import React from "react";
import { Draggable } from "react-beautiful-dnd";

import { convertToBRL, limitCharacterLength } from "~/utils/formatters";
import { LeadCalendarIcon } from "../LeadCalendarIcon";
import { Icon } from "antd";
import {
  CalendarIcon,
  Content,
  Date,
  DateContainer,
  LeadInfo,
  LeadName,
  LeftTopContent,
  RightTopContent,
  MainContent,
  RTName,
  Container,
  Value,
} from "./styles";

export function LeadCard({
  index,
  item,
  showDrawer,
  columnId,
  showOnboarding,
}) {
  return (
    <Draggable key={index} draggableId={item.id} index={index}>
      {(providedProps, snapshotProps) => {
        const { innerRef, draggableProps, dragHandleProps } = providedProps;
        const { isDragging, isDraggingOver } = snapshotProps;

        return (
          <Popover
            placement="left"
            content="Mova o lead para Positivo"
            visible={showOnboarding}
          >
            <Container
              ref={innerRef}
              {...draggableProps}
              {...dragHandleProps}
              isDragging={isDragging}
              isDraggingOver={isDraggingOver}
              onClick={() => showDrawer(item)}
            >
              <LeftTopContent>
                <DateContainer>
                  <CalendarIcon />
                  <Date>{moment(item.created_at).fromNow()}</Date>
                </DateContainer>
              </LeftTopContent>
              <RightTopContent>
              {!!item.honorary && (
                  <Value>{convertToBRL(item.honorary)}</Value>
                )}
              </RightTopContent>
              <Content>
                <MainContent>
                  <LeadCalendarIcon item={item} />
                  <LeadInfo>
                    <LeadName><Icon type="user"/> {limitCharacterLength(item.name, 33)}</LeadName>
                    {item.name_rt && <RTName><Icon type="reconciliation"/> {limitCharacterLength(item.name_rt, 33)}</RTName>}
                  </LeadInfo>
                </MainContent>
              </Content>
            </Container>
          </Popover>
        );
      }}
    </Draggable>
  );
}
