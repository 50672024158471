import { Form, Input, message, Modal } from "antd";
import React from "react";
import { useEffect } from "react";
import uid from "uid";
import { department } from "~/components/RegisterTasks/Utils";

export const SporadictDocumentTypeModal = Form.create()(
  ({
    isVisible,
    onClose,
    data,
    selectedDepartment,
    onCreateType,
    onUpdateType,
    form,
    types,
  }) => {
    const {
      getFieldDecorator,
      validateFields,
      resetFields,
      setFieldsValue,
      getFieldValue,
    } = form;
    const isEditing = !!data;

    const isOkButtonEnabled = isEditing
      ? getFieldValue("description") !== data.description
      : true;

    useEffect(() => {
      if (isVisible && isEditing) {
        const { description } = data;
        setFieldsValue({
          description,
        });
      }
    }, [isVisible]);

    function closeModal() {
      onClose();
      resetFields();
    }

    function handleSubmit() {
      validateFields((error, values) => {
        if (error) return;

        const { description } = values;

        const existingType = types.find((type) => {
          return (
            type.department === selectedDepartment &&
            type.description === values.description
          );
        });

        if (existingType) {
          return message.warning(
            `Este tipo já existe em "${department(selectedDepartment)}"`
          );
        }

        if (isEditing) {
          onUpdateType({
            ...data,
            description,
            is_modified: true,
          });
        } else {
          const newType = {
            id: uid(),
            description,
            department: selectedDepartment,
            is_new: true,
          };

          onCreateType(newType);
        }
        closeModal();
      });
    }

    return (
      <Modal
        title={isEditing ? "Editar tipo" : "Nova tipo"}
        visible={isVisible}
        onCancel={closeModal}
        okText={isEditing ? "Salvar" : "Adicionar"}
        cancelText="Voltar"
        onOk={handleSubmit}
        okButtonProps={{ disabled: !isOkButtonEnabled }}
      >
        <Form>
          <Form.Item label="Nome">
            {getFieldDecorator("description", {
              rules: [
                {
                  required: true,
                  message: "Por favor, informe o nome do tipo",
                },
              ],
            })(<Input.TextArea placeholder="Nome do tipo" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);
