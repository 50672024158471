import React from 'react';
import { Button, Drawer, Table, Tabs, Tooltip } from 'antd';
import { phoneMask } from '~/helpers/masks';
import { useState } from 'react';
import moment from 'moment';
import { useEffect } from 'react';
import { WhatsappIcon } from '~/icons/WhatsappIcon';
import { WhatsappLink } from './styles';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;

export const LeadCalendarDrawer = ({
  isVisible,
  onClose,
  followUps,
  meetings,
  onOpenDrawerLeads,
}) => {
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    if (isVisible) {
      if (followUps.length === 0 && meetings.length > 0) {
        setActiveTab('2');
      }
    }
  }, [isVisible]);

  function handleCloseModal() {
    onClose();
    setActiveTab('1');
  }

  return (
    <Drawer
      width={800}
      visible={isVisible}
      onClose={handleCloseModal}
      title="Follow Ups e Reuniões"
    >
      <Tabs
        type="card"
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key)}
      >
        <Tabs.TabPane tab={`Follow Ups (${followUps.length})`} key="1">
          <Table
            dataSource={followUps}
            rowKey="id"
            bordered
            pagination={getDefaultPaginationConfig({
              storageKey: STORAGE.defaultPageSize.leadCalendarFollowUps
            })}
          >
            <Column title="Nome" key="name" dataIndex="name" />
            <Column
              title="Telefone"
              key="phone"
              dataIndex="phone"
              render={(phone) => {
                if (!phone) return null;
                return (
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                  >
                    <span style={{ lineHeight: '12px' }}>
                      {phoneMask(phone)}
                    </span>
                    <Tooltip title="Ir para o Whatsapp">
                      <WhatsappLink
                        href={`https://wa.me/55${phone}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <WhatsappIcon />
                      </WhatsappLink>
                    </Tooltip>
                  </div>
                );
              }}
            />
            <Column
              key="action"
              width={100}
              render={(_, lead) => (
                <Button onClick={() => onOpenDrawerLeads(lead)}>
                  #CRM{lead.id}
                </Button>
              )}
            />
          </Table>
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Reuniões (${meetings.length})`} key="2">
          <Table
            dataSource={meetings}
            rowKey="id"
            bordered
            pagination={getDefaultPaginationConfig({
              storageKey: STORAGE.defaultPageSize.leadCalendarMeetings
            })}
          >
            <Column
              title="Descrição"
              key="name"
              dataIndex="name"
            />
            <Column
              title="Horário"
              align="center"
              width={80}
              key="time"
              dataIndex="due_at"
              render={(value) => moment(value).format('HH:mm')}
            />
            <Column title="Lead" key="lead_name" dataIndex="lead.name" />
            <Column
              title="Telefone"
              key="lead_phone"
              dataIndex="lead.phone"
              render={(phone) => {
                if (!phone) return null;
                return (
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                  >
                    <span style={{ lineHeight: '12px' }}>
                      {phoneMask(phone)}
                    </span>
                    <Tooltip title="Ir para o Whatsapp">
                      <WhatsappLink
                        href={`https://wa.me/55${phone}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <WhatsappIcon />
                      </WhatsappLink>
                    </Tooltip>
                  </div>
                );
              }}
            />
          </Table>
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  );
};
