import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Switch, Table, Icon, Tooltip, Button, Badge, Tag, message, Popover } from 'antd';
import moment from 'moment';
import FileDownload from 'js-file-download';
import { compare } from 'natural-orderby';
import { deliveryMethod } from '~containers/Customer/data';
import {  get } from 'lodash';
import { multipleSort } from '../../UI/Utils';
import 'moment/locale/pt-br';

import { request } from '../../Instance';

import CustomFields from './CustomFields';
import { DocumentsList } from './Drawers/DocumentsList';
import { Title, TitleWrapper } from './styles';
import { BatchTasksDrawer } from './Drawers/BatchTasksDrawer';
import { finishCurrentOnboardingStepAction } from '~/components/Onboarding/helpers';
import * as calendarTasksActions from '~/components/CalendarTask/actions'
import { customerColumns } from '~/components/RegisterTasks/Utils';
import { formatAnyField } from '~/utils/formatters';

const { Column } = Table;

const getBadge = (status) => {
    switch(status){
        case 'Executada': return 'success';
        case 'Revisada': return 'success';
        case 'Vencida': return 'error';
        default: return 'warning';
    }
}

class CalendarTaskList extends React.Component {

    state = {
        recordSelected: null,
        isLoading: true,
        customers: [],
        isDocumentsListDrawerVisible: false,
        isBatchTasksDrawerVisible: false,
        isDrawerFullyVisible: false
    }

    loadTaskList = (taskId, date) => {
        if (!this.state.isLoading) {
            this.setState({
                isLoading: true
            });
        }
        request('get', `/calendar/task/${taskId}/list/${date}`, {}, false)
        .then(response => {
            const { recordSelected } = this.state;

            const updatedRecordSelected = !!recordSelected
                ? response.data.find(({ id }) => id === recordSelected.id)
                : null;

            this.setState({
                isLoading: false, 
                customers: response.data,
                recordSelected: updatedRecordSelected
            })
        });
    }

    exportExcel = () => {

        request('get', `/calendar/task/${this.props.task.id}/export/excel/${moment(this.props.date).format('MM-YYYY')}`, {}, true, {
            responseType: 'blob'
        })
        .then(response => {
            const now = moment().format('YYYY-MM-DD_hh-mm-ss');
            FileDownload(response.data, `${this.props.task.name}_REF_${moment(this.props.date).format('MM-YYYY')}__${now}.xlsx`);
        });
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.id !== prevProps.id) {
            const task = this.props.task;
            if (task) {
                this.setState({ isLoading: true, customers: [] });
                this.loadTaskList(task.id, this.props.date.format('YYYY-MM-DD'));
            }
        }
    }

    getExecutedTitle = (record) => {
        const { executed_at, executed_by, is_filled } = record;
        const { getUser, task } = this.props;

        if (executed_at && executed_by) {
            return `Executado por ${getUser(executed_by).name} - ${moment(executed_at).fromNow()}`;
        }

        if (executed_at) {
            return `Executado ${moment(executed_at).fromNow()}`;
        }

        if (executed_by) {
            return `Executado por ${getUser(executed_by).name}`;
        }

        const { custom_fields, file_upload } = task;

        if (!is_filled) {
            if (custom_fields) {
                return 'É necessário preencher os campos adicionais para executar esta tarefa';
            }
    
            if (!!file_upload) {
                return 'É necessário fazer o upload dos arquivos para executar esta tarefa'
            }
        }

        return 'Pendente';
    }

    closeDocumentsListDrawer = () => {
        this.setState({ isDocumentsListDrawerVisible: false, recordSelected: null });
    }

    openDocumentsListDrawer = () => {
        this.setState( { isDocumentsListDrawerVisible: true });
    }

    openBatchTasksDrawer = () => {
        this.setState({
            isBatchTasksDrawerVisible: true
        })
    }

    closeBatchTasksDrawer = (tasksHaveBeenSent) => {
        this.setState({
            isBatchTasksDrawerVisible: false
        })
        
        if (tasksHaveBeenSent) {
            this.loadTaskListOfToday();
        }
    }

    checkIfAllFilesAreUploaded = (item) => {
        const { task } = this.props;

        if (!!task.file_upload) {
            if (item.files.length > 0 || item.has_not_document) {
                return true;
            }
        }

        return false;
    }

    checkIfAllTasksAreFilled = (item, customFieldsOfTask) => {
        const { task } = this.props;

        if (!task.custom_fields && !task.file_upload) {
            return true;
        }

        const customFieldsOfItem = item.custom_fields ?
            Object.keys(item.custom_fields) :
            [];
        
        return !!task.custom_fields && (customFieldsOfItem.length === customFieldsOfTask.length);
    }

    toggleExecuted = async (status, {
        customer_id,
        task_record_id
    }) => {
        const { task, finishCurrentOnboardingStep } = this.props;

        try {
            let updatedCustomer = null;

            if (!task_record_id) {
                const { payload } = await this.props.createTaskRecord({
                    task_id: task.id,
                    customer_id,
                    due_date: task.due_date,
                    status: status ? 'executed' : 'uncheck_executed'
                });

                updatedCustomer = payload.data.data;
            } else {
                const { payload } = await this.props.updateStatus(
                    task_record_id,
                    status ? 'executed' : 'uncheck_executed'
                );
                
                updatedCustomer = payload.data.data;
            }

            const updatedCustomers = this.state.customers.map((customer) => {
                return customer.customer.id !== updatedCustomer.customer_id ? customer : {
                    ...customer,
                    executed_at: updatedCustomer.executed_at,
                    executed_by: updatedCustomer.executed_by,
                }
            });

            this.setState({
                customers: updatedCustomers
            });

            finishCurrentOnboardingStep({
                isActive: this.checkIfStepIsActive(5),
                step: this.props.onboardingStep,
                user: this.props.user
            });
        } catch (error) {
            message.error('Não foi possível alterar o status da tarefa!')
        }
    }

    loadTaskListOfToday = () => {
        const { task } = this.props;
        this.loadTaskList(task.id, this.props.date.format('YYYY-MM-DD'));
    }

    checkIfStepIsActive = (step) => {
        const { isOnboardingActive, onboardingStep, isVideoModalVisible } = this.props;
        return !isVideoModalVisible && isOnboardingActive && onboardingStep === step;
    }

    updateCustomers = (updatedFields, id) => {
        const updatedCustomers = this.state.customers.map((customer) => {
            if (customer.id !== id) return customer;
            return {
                ...customer,
                ...updatedFields
            }
        });
        const updatedRecordSelected = {
            ...this.state.recordSelected,
            ...updatedFields
        }
        this.setState({
            customers: updatedCustomers,
            recordSelected: updatedRecordSelected
        });
    }

  render() {
    const { task } = this.props;
    const { recordSelected, isLoading, customers } = this.state;

    const customFieldsOfTask = task && task.custom_fields ?
        Object.keys(task.custom_fields) :
        [];

    const formattedCustomers = task ? customers.map((item) => {
        const isFilledObject = {
            ...item,
            is_filled: true
        }

        if (item.is_without_movement) {
            return isFilledObject;
        }

        const areAllFilesUploaded = this.checkIfAllFilesAreUploaded(item);

        if (areAllFilesUploaded) {
            return isFilledObject;
        }

        const areAllTasksFilled = this.checkIfAllTasksAreFilled(item, customFieldsOfTask);

        if (areAllTasksFilled) {
            return isFilledObject;
        }

        return {
            ...item,
            is_filled: false
        }
    }) : [];

    return (
      <div>
          {task && (
            <Drawer
                title={
                    <TitleWrapper>
                        <Title>{task.name}</Title>
                        <Tooltip
                            onClick={this.exportExcel}
                            placement="bottom"
                            title={'Exportar para arquivo CSV (excel)'}
                        >
                            <Button
                                className="btn-success"
                                size="small"
                            >
                                <Icon type="file-excel" /> Excel
                            </Button>
                        </Tooltip>
                        {!!task.file_upload && (
                            <Tooltip title="Preencher tarefas em lote">
                                <Button
                                    onClick={this.openBatchTasksDrawer}
                                    icon="upload"
                                    size="small"
                                    type="primary"
                                    placement="bottom"
                                >
                                    Em lote
                                </Button>
                            </Tooltip>
                        )}
                    </TitleWrapper>
                }
                width={1400}
                destroyOnClose
                placement="right"
                onClose={() => {
                    const isCurrentDateEqualsSelectedDate = task.due_date.slice(0, 7) === moment().format('YYYY-MM');
                    if (isCurrentDateEqualsSelectedDate) {
                        this.props.updateContextTasksCardsFromList(customers, task.id);
                    } else {
                        this.props.updateTasksCardsFromList(customers, task.id);
                    }

                    this.props.onClose();
                }}
                maskClosable={true}
                visible={this.props.visible}
                className="drwawer-padding-0"
                style={{
                    height: '100%',
                    overflow: 'auto',
                    padding: '0px',
                }}
                afterVisibleChange={(isVisible) => this.setState({ isDrawerFullyVisible: isVisible })}
            >
            <Table 
                rowKey="id"
                dataSource={formattedCustomers} 
                pagination={false}
                rowClassName="row-clabs"
                loading={isLoading}
                size="middle" 
                locale={{
                    filterConfirm: 'Ok',
                    filterReset: 'Resetar' ,
                    emptyText: 'Nenhum registro corresponde ao filtro'
                }}
            >
                <Column
                    title=""
                    key="status"
                    dataIndex="status"
                    align="center"
                    width={30}
                    filters={[{
                        text: 'Pendente',
                        value: 'Pendente',
                      }, {
                        text: 'Executada',
                        value: 'Executada',
                      },
                      {
                        text: 'Revisão Pendente',
                        value: 'Revisão Pendente',
                      },
                      {
                        text: 'Revisada',
                        value: 'Revisada',
                      }
                    ]}
                    filterMultiple={false}
                    onFilter={(value, record) => {
                        if (value === 'Pendente') {
                            return record.status.indexOf(value) === 0 || record.status.indexOf('Vencida') === 0;
                        }

                        if (value === 'Revisão Pendente') {
                            return !!record.executed_at && !record.revised_at;
                        }

                        return record.status.indexOf(value) === 0;
                    }}
                    render={(text, record) => (
                        <Tooltip placement="bottom" title={record.status}>
                            <Badge status={getBadge(record.status)}/>
                        </Tooltip>
                    )}
                />
                <Column
                    width={30}
                    title=""
                    dataIndex="priority"
                    key="priority"
                    render={(text, record) => (
                        record.priority === 1 && (
                        <Tooltip placement="bottom" title="Prioridade">
                            <Icon type="star" theme="filled" style={{color: `#fadb14`}}></Icon>
                        </Tooltip>
                        )
                    )}
                />
                <Column
                    width={100}
                    title="Executado"
                    dataIndex="executed"
                    key="executed"
                    render={(_, record, index) => (
                        <Tooltip placement="bottom" title={this.getExecutedTitle(record)}>
                            <Popover
                                placement="left"
                                content="Clique aqui para executar esta tarefa"
                                visible={
                                    index === 0 &&
                                    this.checkIfStepIsActive(5) &&
                                    this.state.isDrawerFullyVisible &&
                                    !this.state.recordSelected
                                }
                            >
                                <Switch 
                                    defaultChecked={record.executed_at !== null}
                                    disabled={!record.is_filled}
                                    onChange={(status) => this.toggleExecuted(status, {
                                        customer_id: record.customer.id,
                                        task_record_id: record.task_record_id
                                    })}
                                    
                                />
                            </Popover>
                        </Tooltip>
                    )}
                />
                <Column
                    width={100}
                    title="Revisado"
                    dataIndex="revised"
                    key="revised"
                    render={(text, record) => (
                        <Tooltip placement="bottom" title={record.revised_at !==null? `Revisado por ${this.props.getUser(record.revised_by).name} - ${moment(record.revised_at).fromNow()}`: 'Pendente'}>
                            <Switch 
                                defaultChecked={record.revised_at !== null}
                                onChange={(status) => this.props.updateStatus(record.task_record_id, status?'revised':'uncheck_revised')}
                                disabled={record.executed_at === null}
                            />
                        </Tooltip>
                    )}
                />
                <Column
                    width={150}
                    title="S. de entrega"
                    dataIndex="customer.doc_delivery_method"
                    key="customer.doc_delivery_method"
                    sorter={(a, b) => {
                        const aDeliveryMethodText = get(deliveryMethod, `[${a.customer.doc_delivery_method}].text`);
                        const bDeliveryMethodText = get(deliveryMethod, `[${b.customer.doc_delivery_method}].text`);
                        if (!aDeliveryMethodText) return -1;
                        return aDeliveryMethodText.localeCompare(bDeliveryMethodText);
                    }}
                    filters={deliveryMethod}
                    onFilter={(value, record) => record.customer.doc_delivery_method === value}
                    render={value => value !== null && <Tag color={get(deliveryMethod, `[${value}].color`)}>{get(deliveryMethod, `[${value}].text`)}</Tag>}
                />
                <Column
                    width={100}
                    title="Cod"
                    dataIndex="customer_id"
                    key="cod"
                    render={(text, record) => (<span>{record.customer.cod}</span>)}
                    sorter={multipleSort([
                        (c,d) => compare({ order: 'desc' })(c.priority, d.priority),
                        (c,d) => compare()(c.customer.cod, d.customer.cod)
                    ])}
                    sortDirections={['descend', 'ascend']}
                    defaultSortOrder={'ascend'}
                />
                <Column
                    width={350}
                    title="Cliente"
                    dataIndex="customer_id"
                    key="customer_id"
                    render={(text, record) => (<span>{record.customer.name}</span>)}
                    sorter={(a, b) => a.customer.name.localeCompare(b.customer.name)}
                    ellipsis={true}
                />
                <Column
                    width={150}
                    title="Tributação"
                    dataIndex="customer.type_taxation"
                    key="type_taxation"
                    render={(text, record) => (<span>{record.customer.type_taxation}</span>)}
                    ellipsis={true}
                />
                {task.customer_columns && Array.isArray(task.customer_columns) && task.customer_columns.map((column, index) => (
                    <Column
                        width={150}
                        title={customerColumns[column]}
                        dataIndex={`customer.${column}`}
                        key={column}
                        render={(text, record) => (<span>{formatAnyField(column, record.customer[column])}</span>)}
                        ellipsis={true}
                    />
                ))}
                {customFieldsOfTask.length > 0 && (
                    <Column
                        width={150}
                        key="extra_columns"
                        align="center"
                        dataIndex="is_filled"
                        ellipsis={true}
                        render={(isFilled, record) => {
                            if (isFilled) {
                                return (
                                    <Button
                                        type="primary"
                                        icon="check"
                                        size="small"
                                        style={{ borderColor: '#52c41a', backgroundColor: '#52c41a'}}
                                        onClick={() => this.setState({ recordSelected: record })}
                                    >
                                        Preenchido
                                    </Button>
                                )
                            }

                            return (
                                <Button
                                    type="dashed"
                                    size="small"
                                    onClick={() => this.setState({ recordSelected: record })}
                                >
                                    Preencher
                                </Button>
                            )
                        }
                            
                        }
                    />
                )}
                {!task.custom_fields && task.file_upload && (
                     <Column
                        width={150}
                        align="center"
                        key="document"
                        render={(_, record) => {
                            const hasFiles = record.files.length > 0;
                            const buttonType = hasFiles ? 'primary' : 'dashed';
                            const buttonIcon = hasFiles ? 'check' : undefined;
                            const buttonStyle = {
                                style: {
                                    borderColor: '#52c41a',
                                    backgroundColor: '#52c41a'
                                }
                            };
                            const buttonText = hasFiles ? 'Preenchido' : 'Preencher'

                            return (
                                <Button
                                    type={buttonType}
                                    icon={buttonIcon}
                                    {...(hasFiles && buttonStyle)}
                                    onClick={() => {
                                        this.openDocumentsListDrawer();
                                        this.setState({ recordSelected: record })
                                    }}
                                    size="small"
                                >
                                    {buttonText}
                                </Button>
                            )
                        }}
                    />
                )};
            
            </Table>
            
            {!!task.custom_fields && (
                <CustomFields
                    onClose={() => this.setState({recordSelected: null})}
                    data={recordSelected}
                    task={task}
                    updateCustomers={this.updateCustomers}
                    loadTaskList={this.loadTaskListOfToday}
                />
            )}
            
        </Drawer>
        )}
        {task && (
            <DocumentsList
                visible={this.state.isDocumentsListDrawerVisible}
                onClose={this.closeDocumentsListDrawer}
                task={task}
                selectedTaskRecord={recordSelected}
                loadTaskList={this.loadTaskListOfToday}
                updateCustomers={this.updateCustomers}
            />
        )}
        <BatchTasksDrawer
            isVisible={this.state.isBatchTasksDrawerVisible}
            closeDrawer={this.closeBatchTasksDrawer}
            departmentId={this.props.departmentId}
            parentTask={task}
            customersOfParentTask={customers}
        />
      </div>
    );
  }
}

const mapDispatchProps = dispatch => {
  return{
    updateStatus: (record_task_id, status) => dispatch({
      type: 'CALENDAR_TASK_RECORD_STATUS',
      payload: {
        request:{
          method: 'patch',
          url: `/calendar/task_record/${record_task_id}/status/${status}`,
        }
      }
    }),
    createTaskRecord: (data) => dispatch({
      type: 'CALENDAR_TASK_RECORD_CREATE',
      payload: {
        request:{
          method: 'post',
          url: `/calendar/task_record/create`,
          data,
        }
      }
    }),
    updateTasksCardsFromList: (customers, taskId) => {
        dispatch(calendarTasksActions.updateTasksCardsFromList(customers, taskId));
    },
    updateContextTasksCardsFromList: (customers, taskId) => {
        dispatch(calendarTasksActions.updateContextTasksCardsFromList(customers, taskId));
    },
    finishCurrentOnboardingStep: ({ isActive, step, user }) => {
        finishCurrentOnboardingStepAction({ isActive, dispatch, step, user })
    }
  }
}

const mapStateToProps = (state, ownProps) => {
    let task = null;

    const isCurrentDateEqualsSelectedDate = ownProps.date.format('YYYY-MM') === moment().format('YYYY-MM');

    if (isCurrentDateEqualsSelectedDate) {
        task = state.allDepartmentsCalendarTasks.list.find((task) => task.id === ownProps.id);
    } else {
        task = state.calendarTasks.find((task) => task.id === ownProps.id);
    }

    return{
        getCustomer: (id) => state.customers.list.find(item => item.id===id),
        getUser: (id) => state.users.find(item => item.id===id),
        task,
        onboardingStep: state.onboarding.step,
        isOnboardingActive: state.onboarding.isActive,
        isVideoModalVisible: state.onboarding.videoModal.isVisible,
        user: state.user
    }
}

export default connect(mapStateToProps, mapDispatchProps)(CalendarTaskList);