import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Select, Avatar, Tooltip, Icon } from 'antd';
import Tip from '~/components/Tip';

const { Option } = Select;

export const AssignTo = (props) => {
  const { getFieldDecorator } = props.form;
  const users = useSelector(state => state.users);
  const loggedUser = useSelector(state => state.user);

  // remove user with email suporte@contlabs.com.br
  const filteredUsers = users.filter(user => user.email !== 'suporte@contlabs.com.br');

  const integerArray = props.initialValue ? props.initialValue.map(num => parseInt(num, 10)) : null;

  return (
    <Form.Item label={<span>Responsável <Tip>Responsável pela execução da tarefa</Tip></span>}>
      {getFieldDecorator('assigned_to', {
        initialValue: integerArray || [loggedUser.id],
        rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
      })(
        <Select mode="multiple" placeholder="Responsável">
          {filteredUsers.map(user => (
            <Option key={user.id} value={user.id}>{user.name}</Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

export default AssignTo;

export const UsersAssigned = ({
  selectedUsers,
}) => {

  if (!selectedUsers || selectedUsers.length === 0) return <></>

  const usersById = useSelector(state => state.usersById);

  const usersName = selectedUsers ? selectedUsers.map(id => usersById[parseInt(id)] && usersById[id].name).join(', ') : '';

  const assignedToInitials = (assignedTo) => {
    if (!assignedTo) return '';
    if( typeof assignedTo !== 'string') return '';
    const [firstName, lastName] = assignedTo.split(' ');
    return `${firstName.charAt(0)}${lastName ? lastName.charAt(0) : ''}`;
  };

  if(selectedUsers.length === 1){
    const user = usersById[selectedUsers[0]];
    if(!user) return <></>;

    return (
      <Tooltip title={usersName}>
        <Avatar size="small" style={{ color: 'rgb(113 46 208)', backgroundColor: 'rgb(239 227 255)', textTransform: 'uppercase' }}>
          {assignedToInitials(usersById[selectedUsers[0]].name ? usersById[selectedUsers[0]].name : '')}
        </Avatar>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={usersName}>
      <Avatar size="small" style={{ color: 'rgb(113 46 208)', backgroundColor: 'rgb(239 227 255)', textTransform: 'uppercase' }}>
        <Icon type="user" /> {selectedUsers.length}
      </Avatar>
    </Tooltip>
  );
}