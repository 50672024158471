export const loadExtras = ({ filter_by, from, to, status, ...params }) => ({
  type: "LOAD_EXTRAS_PAYMENTS",
  payload: {
    request: {
      method: "get",
      url: `/extras/payments?filter_by=${filter_by}&from=${from}&to=${to}&status=${status}`,
      ...params,
    },
    filter: {
      filter_by,
      from,
      to,
      status,
    },
  },
});
