import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Layout } from '~/components/UI';
import { LoadingTopBar } from '~/components/UI/LoadingTopBar';
import LeadsRT from '~/containers/LeadsRT';
import { loadLeads } from '~/containers/LeadsRT/actions';

export const ScreensRT = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await Promise.all([
        dispatch(loadLeads({ showLoading: false })),
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <LeadsRT />
      </Layout>
    </div>
  );
};
