import update from "immutability-helper";

import { LOAD_FEEDBACKS } from './actions';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const DEFAULT_STATE = {
  list: [],
  isLoading: true,
};

const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CACHE_ACTIONS.feedbacks: {
      return update(state, {
        $set: {
          list: action.payload,
          isLoading: false
        }
      });
    }

    case LOAD_FEEDBACKS.SUCCESS: {
      return update(state, {
        $set: {
          list: action.payload.data.feedbacks,
          isLoading: false,
        },
      });
    }

    default:
      return state;
  }
};

export default reducer;
