import { createAsyncActions } from '~/utils/store';

export const CHANGE_YEAR = '@@DRAWER_IRPF/CHANGE_YEAR';

export const LOAD_IRPFS = createAsyncActions('@@IRPF/LOAD_IRPFS');

export const loadIRPFs = ({ year, ...params }) => ({
    type: LOAD_IRPFS.INIT,
    payload: {
        request: {
            method: 'get',
            url: `/pf/customers?year=${year}`,
            ...params
        }
    }
});

export const changeYear = (year) => ({
    type: CHANGE_YEAR,
    payload: {
        year
    }
})

