import { Button, Result, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { VscStarEmpty } from 'react-icons/vsc';
import { IoReturnUpBackOutline } from 'react-icons/io5';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GoLinkExternal } from 'react-icons/go';
import { AiOutlinePrinter } from 'react-icons/ai';
import { Avatar } from '@material-ui/core';
import moment from 'moment';

import { request } from '~/components/Instance';
import { getSubdomain } from '~/helpers/getSubdomain';
import { AccountStorage } from '~/storages/account';
import {
  AvatarWrapper,
  Container,
  Content,
  ContentWrapper,
  Date,
  EmailFrom,
  EmailTo,
  InfoWrapper,
  LeftInfoWrapper,
  Name,
  NameAndEmailFromWrapper,
  RightInfoWrapper,
  StyledModal,
  Subject,
  SubjectIconsWrapper,
  SubjectWrapper,
} from './styles';

export const EmailBodyPreviewModal = ({
  isOpen,
  onClose,
  initialLogId,
  data,
}) => {
  const [iframeSource, setIframeSource] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getSrc();
    }
  }, [isOpen]);

  async function getSrc() {
    try {
      setIsLoading(true);
      const { data: blob } = await request(
        'GET',
        `/emails-logs/${initialLogId}/preview`,
        null,
        false,
        {
          headers: {
            Authorization: `Bearer ${AccountStorage.get().token}`,
            'X-Tenant': getSubdomain(),
          },
          responseType: 'blob',
        }
      );
      const urlObject = URL.createObjectURL(blob);
      setIframeSource(urlObject);
      if (hasError) {
        setHasError(false);
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }

  function closeModal() {
    setIframeSource(null);
    setHasError(false);
    onClose();
  }

  return (
    <StyledModal
      visible={isOpen}
      onCancel={closeModal}
      title="Prévia"
      footer={false}
      width="80%"
    >
      {isLoading && <Spin size="large" />}
      {hasError && !isLoading && (
        <Result
          status="error"
          title="Não foi possível carregar a prévia."
          extra={
            <Button type="primary" onClick={getSrc}>
              Tentar novamente
            </Button>
          }
        />
      )}
      {iframeSource && (
        <Container>
          <AvatarWrapper>
            <Avatar size="large" icon="user" />
          </AvatarWrapper>

          <ContentWrapper>
            <SubjectWrapper>
              <Subject>{data.title}</Subject>
              <SubjectIconsWrapper>
                <AiOutlinePrinter />
                <GoLinkExternal />
              </SubjectIconsWrapper>
            </SubjectWrapper>

            <InfoWrapper>
              <LeftInfoWrapper>
                <NameAndEmailFromWrapper>
                  <Name>{data.from_name.split(' ')[0]}</Name>
                  <EmailFrom>{`<${data.email_from}>`}</EmailFrom>
                </NameAndEmailFromWrapper>
                <EmailTo>Para: {data.email}</EmailTo>
              </LeftInfoWrapper>
              <RightInfoWrapper>
                <Date>
                  {moment(data.sent_at).format(
                    'DD [de] MMM [de] YYYY[,] HH:mm'
                  )}
                </Date>
                <VscStarEmpty />
                <IoReturnUpBackOutline />
                <BsThreeDotsVertical />
              </RightInfoWrapper>
            </InfoWrapper>

            <Content>
              <iframe
                width={752}
                height={680}
                src={iframeSource}
                title="Prévia do e-mail"
              />
            </Content>
          </ContentWrapper>
        </Container>
      )}
    </StyledModal>
  );
};
