import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';
const FormItem = Form.Item;

class ChangePasswordForm extends Component {
  state = {
    confirmDirty: false,
    current_password: {}
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const context = this;
    context.setState({ current_password: {} });
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.changePassword(values)
        .then(response => {
          context.props.form.resetFields();
        })
        .catch(response => {
          context.setState({
            current_password: {
              validateStatus: 'error',
              help: response.error.response.data.errors.current_password[0]
            }
          });
        });
      }
    });
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('As senhas não conferem!');
    } else {
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['password_confirm'], { force: true });
    }
    callback();
  }

  render() {
    const { current_password } = this.state;
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 4,
        },
      },
    };

    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem {...formItemLayout} {...current_password} 
          label="Senha Atual">
          {getFieldDecorator('current_password', {
            rules: [{
              required: true, message: 'Por favor informe sua senha atual!',
            }],
          })(
            <Input type="password" />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Nova Senha">
          {getFieldDecorator('password', {
            rules: [{
              required: true, message: 'Por favor informe uma senha!',
            }, {
              validator: this.validateToNextPassword,
            }],
          })(
            <Input type="password" />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Confirmar Senha">
          {getFieldDecorator('password_confirm', {
            rules: [{
              required: true, message: 'Por favor repita a senha!',
            }, {
              validator: this.compareToFirstPassword,
            }],
          })(
            <Input type="password" onBlur={this.handleConfirmBlur} />
          )}
        </FormItem>
        <FormItem {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">Salvar</Button>
        </FormItem>
      </Form>
    );
  }
}

const mapDispatchProps = dispatch => {
  return{
    changePassword: (data) => dispatch({
      type: 'CHANGE_PASSWORD',
      payload: {
        request:{
          method: 'patch',
          url:'/user/password',
          data: data
        }
      }
    })
  }
}

export default connect(null, mapDispatchProps)(Form.create()(ChangePasswordForm));