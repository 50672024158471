import { createAsyncActions } from '~/utils/store';

export const SHOW = '@@DRAWER_REFUNDS/SHOW';
export const CLOSE = '@@DRAWER_REFUNDS/CLOSE';

export const CREATE = createAsyncActions('@@DRAWER_REFUNDS/CREATE');
export const SAVE = createAsyncActions('@@DRAWER_REFUNDS/SAVE');
export const DELETE = createAsyncActions('@@DRAWER_REFUNDS/DELETE');

export const show = (data) => ({
    type: SHOW,
    payload: {
        data
    }
});

export const close = () => ({
    type: CLOSE,
    payload: {}
});

export const create = (data) => ({
    type: CREATE.INIT,
    payload: {
        request:{
            method: 'post',
            url:'/refunds',
            data,
            showLoading: false
        }
      }
});

export const save = (id, data) => ({
    type: SAVE.INIT,
    payload: {
        request:{
            method: 'post',
            url:`/refunds/${id}`,
            data,
            showLoading: false
        }
    }
});

export const remove = (id) => ({
    type: DELETE.INIT,
    payload: {
        request:{
            method: 'delete',
            url:`/refunds/${id}`
        },
        id
    }
});