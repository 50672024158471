import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useLocation } from 'react-router-dom';

import Layout from "../components/UI/Layout/Layout";
import BillsToReceive from "../components/BillsToReceive/BillsToReceiveList";
import BillsForm from "../components/BillsToReceive/BillsForm";
import * as billsToReceiveActions from "~/components/BillsToReceive/actions";
import { LoadingTopBar } from "~/components/UI/LoadingTopBar";
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

export const ScreensBillsToReceive = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await LocalCacheHandler.load({
        actionName: CACHE_ACTIONS.customerExtras,
        dispatch,
        pathname
      });
      const { payload } = await dispatch(
        billsToReceiveActions.loadExtras({
          filter_by: "due_date",
          from: moment().format("YYYY-MM-01"),
          to: moment()
            .endOf("month")
            .format("YYYY-MM-DD"),
          status: "all",
          showLoading: false,
        })
      );
      await LocalCacheHandler.save({
        pathname,
        data: payload.data.data
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }
  
  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <BillsToReceive />
        <BillsForm />
      </Layout>
    </div>
  );
};
