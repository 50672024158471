const calendarTasks = (state = [], action) => {
    switch (action.type) {
        case 'LOAD_CALENDAR_TASKS_MONTH_SUCCESS':
            return action.payload.data;

        case 'CALENDAR_CARDS_TASKS_UPDATE_FROM_LIST': {
            const { customers } = action.payload;

            if (!customers) return state;

            const executedListTasks = customers.filter((customer) => customer.executed_at).length;

            return state.map((currentTask) => currentTask.id !== action.payload.taskId ? currentTask : {
                ...currentTask,
                executed_tasks_count: executedListTasks,
                pendent_tasks_count: currentTask.tasks_count - executedListTasks
            });
        }

        case 'CALENDAR_CARDS_TASKS_UPDATE_FROM_GRID':
            const { customers, currentMonth, taskId } = action.payload;

            const executedGridTasks = customers
                .map((customer) => customer.months[currentMonth])
                .filter((customer) => customer.executed_at)
                .length;

            const priorityTasksCount = customers
                .map(customer => customer.months[currentMonth])
                .filter((customer) => customer.priority)
                .length;

            return state.map((currentTask) => currentTask.id !== taskId ? currentTask : {
                ...currentTask,
                executed_tasks_count: executedGridTasks,
                pendent_tasks_count: currentTask.tasks_count - executedGridTasks,
                priority_tasks_count: priorityTasksCount
            });

        default:
        return state
    }
}

export default calendarTasks;