import { Button, Modal, Tooltip } from 'antd';
import React from 'react';
import { MdComputer } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import { HelperText } from '../../styles';
import * as calendarTasksActions from '~/components/CalendarTask/actions';
import { deliveryMethodNames } from '~/containers/Customer/data';

export const PanelButton = ({
  props: {
    buttonsProps,
    buttonsState,
    buttonsLoadingState,
    hasNewFiles,
    currentTaskRecordId,
    checkIfHasButtonLoading,
    setButtonsLoadingState,
    updateDeliveryMethods,
    setButtonsState,
    defaultCustomerDeliveryMethod,
  },
}) => {
  const dispatch = useDispatch();

  async function sendEmailWithLink() {
    try {
      setButtonsLoadingState((state) => ({
        ...state,
        panel: true,
      }));
      await dispatch(
        calendarTasksActions.sendEmailWithLink({
          taskRecordId: currentTaskRecordId,
        })
      );
      updateDeliveryMethods(2);
      setButtonsState((state) => ({
        ...state,
        isPanelButtonClicked: true,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setButtonsLoadingState((state) => ({
        ...state,
        panel: false,
      }));
    }
  }

  function handleSendEmailWithLink() {
    if (checkIfHasButtonLoading()) return;

    if (!buttonsProps.panel.isDefaultMethod) {
      return Modal.confirm({
        title: `
          O método padrão de envio de documentos escolhido por este cliente é ${deliveryMethodNames[defaultCustomerDeliveryMethod]}. 
          Tem certeza que deseja continuar com o envio via ${deliveryMethodNames[2]}?
        `,
        content: 'Esta ação não poderá ser desfeita.',
        okText: 'Sim',
        okType: 'primary',
        cancelText: 'Cancelar',
        onOk: sendEmailWithLink,
      });
    }

    sendEmailWithLink();
  }

  return (
    <Tooltip title={buttonsProps.panel.tooltipTitle}>
      <Button
        onClick={handleSendEmailWithLink}
        loading={buttonsLoadingState.panel}
        disabled={hasNewFiles}
        type={buttonsProps.panel.isDefaultMethod ? 'primary' : 'default'}
      >
        <MdComputer />
        Painel
      </Button>
      {(buttonsProps.panel.isDefaultMethod ||
        buttonsState.isPanelButtonClicked) && (
        <HelperText>Documento já enviado</HelperText>
      )}
    </Tooltip>
  );
};
