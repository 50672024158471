import React from "react";
import { ClicksCard } from "./ClicksCard";
import { ProposalCard } from "./ProposalCard";
import { OpportunityCard } from "./OpportunityCard";
import { NegativeCard } from "./NegativeCard";
import { PositiveCard } from "./PositiveCard";
import { Container } from "./styles";

export const LeadSummary = ({ data }) => {
  const { clicks, opportunity, proposal, negative, positive } = data.stages;
  const {
    opportunity_percentage,
    proposal_percentage,
    positive_percentage,
    clicks_percentage,
  } = data.moved_stages;

  return (
    <Container>
      <ClicksCard data={clicks} movedPercentage={clicks_percentage} />
      <OpportunityCard
        data={opportunity}
        movedPercentage={opportunity_percentage}
      />
      <ProposalCard data={proposal} movedPercentage={proposal_percentage} />
      <PositiveCard data={positive} movedPercentage={positive_percentage} />
      <NegativeCard data={negative} />
    </Container>
  );
};
