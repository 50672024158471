import { createAsyncActions } from "~/utils/store";

export const LOAD_REFUNDS = createAsyncActions("@@REFUNDS/LOAD_REFUNDS");
export const SET_PAID = createAsyncActions("@@REFUNDS/SET_PAID");

export const loadRefunds = ({ filter_by, from, to, status, ...params }) => {
  return {
    type: LOAD_REFUNDS.INIT,
    payload: {
      request: {
        method: "GET",
        url: `/refunds/payments?filter_by=${filter_by}&from=${from}&to=${to}&status=${status}`,
        ...params,
      },
      filter: {
        filter_by,
        from,
        to,
        status,
      },
    },
  };
};

export const setPaid = (id) => ({
    type: SET_PAID.INIT,
    payload: {
      request:{
        method: 'put',
        url:'/refunds/payments/'+id,
      }
    },
});