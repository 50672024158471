export const loadForCurrentMonth = ({ ...params }) => ({
  type: "LOAD_DASHBOARD_DATA_FOR_CURRENT_MONTH",
  payload: {
    request: {
      method: "GET",
      url: "/dashboard/current-month",
      ...params,
      showLoading: false,
    },
  },
});

export const loadForCustomMonths = (months) => ({
  type: 'LOAD_DASHBOARD_DATA_FOR_CUSTOM_MONTHS',
  payload: {
    request: {
      method: 'get',
      url: `/dashboard?months=${months}`,
      showLoading: false,
    }
  }
})

export const changeVisualizationType = (type) => ({
  type: 'CHANGE_VISUALIZATION_TYPE_OF_DASHBOARD',
  payload: type
});