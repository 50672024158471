import * as IndexedDB from 'idb-keyval';
import { loadFromCacheAction } from '~/store/actions/load-from-cache';

export const LocalCacheHandler = {
  load: async ({ dispatch, pathname, actionName }) => {
    const cachedData = await IndexedDB.get(pathname);
    if (cachedData) {
      dispatch(
        loadFromCacheAction({
          actionName,
          cachedData,
        })
      );
    }
  },
  save: async ({ pathname, data }) => {
    await IndexedDB.update(pathname, () => data);
  },
  clear: async () => {
    await IndexedDB.clear();
  }
};
