import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Board from "./Board";
import Drawer from "~/containers/DrawerBoardItem";
import Layout from "~/components/UI/Layout/Layout";
import { LoadingTopBar } from "~/components/UI/LoadingTopBar";
import { LocalCacheHandler } from "~/utils/local-cache-handler";
import { CACHE_ACTIONS } from "~/constants/cache-actions.constants";

import * as boardActions from "./actions";

export const ScreensBoards = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadCache();
    loadData();
  }, []);

  async function loadData() {
    try {
      await Promise.all([
        dispatch(boardActions.load()),
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function loadCache() {
    await LocalCacheHandler.load({
      actionName: CACHE_ACTIONS.boards,
      dispatch,
      pathname: CACHE_ACTIONS.boards,
    });

    setIsLoading(false);
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <Board 
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
        <Drawer />
      </Layout>
    </div>
  );
};
