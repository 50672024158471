export const handlePartnersSets = (partnersSets, formData) => {
  const newPartnersSets = partnersSets.filter(({ is_new }) => !!is_new);

  if (newPartnersSets.length === 0) return;

  newPartnersSets.forEach((partnersSet, index) => {
    const currentPartnersSetKey = `new_partners_sets[${index}]`;

    const { change_date, partners } = partnersSet;

    formData.append(`${currentPartnersSetKey}[change_date]`, change_date);

    partners.forEach((partner, index) => {
      const currentPartnerKey = `${currentPartnersSetKey}[partners][${index}]`;

      const {
        id,
        name,
        identification_number,
        birthdate,
        phone,
        percentage,
      } = partner;

      formData.append(`${currentPartnerKey}[id]`, id);
      formData.append(`${currentPartnerKey}[name]`, name);

      if (identification_number) {
        formData.append(
          `${currentPartnerKey}[identification_number]`,
          identification_number
        );
      }

      if (birthdate) {
        formData.append(`${currentPartnerKey}[birthdate]`, birthdate);
      }

      if (phone) {
        formData.append(`${currentPartnerKey}[phone]`, phone);
      }

      formData.append(`${currentPartnerKey}[percentage]`, percentage);
    });
  });
};
