export const getIRPFMonths = (year) => {
  const shortYear = year.toString().substring(2, 4);

  return [
    {
      text: `30/Abr/${shortYear}`,
      fullText: 'Abril',
      base: '04',
      year
    },
    {
      text: `31/Jul/${shortYear}`,
      fullText: 'Julho',
      base: '07',
      year
    },
    {
      text: `31/Out/${shortYear}`,
      fullText: 'Outubro',
      base: '10',
      year
    },
    {
      text: `28/Fev/${parseInt(shortYear)+1}`,
      fullText: 'Fevereiro',
      base: '02',
      year: Number(year) + 1
    }
  ]
}