import moment from "moment";
import React from "react";
import Chart from "react-apexcharts";

export const ExtraPaymentChart = ({ data }) => {
  if (!data) return null;

  const dashTasks = Object.values(data).map((item) => item.dash_tasks);

  const series = [
    {
      name: "Faturamento",
      type: "column",
      data: Object.values(data).map((item) => item.billing),
    },
    {
      name: "Recebido",
      type: "column",
      data: Object.values(data).map((item) => item.received),
    },
    {
      name: "Quantidade total",
      type: "line",
      data: Object.values(data).map((item) => item.count),
    },
  ];

  return (
    <Chart
      height={350}
      options={{
        chart: {
          type: "line",
          stacked: false,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function(value, { seriesIndex }) {
            if (seriesIndex === 2) {
              return value;
            }
            return new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(value);
          },
        },
        stroke: {
          width: [1, 1, 4],
        },
        xaxis: {
          categories: Object.keys(data).map((item) =>
            moment(`${item}-01`).format("MMM/YY")
          ),
        },
        yaxis: [
          {
            show: true,
          },
          {
            show: false,
            seriesName: "Faturamento",
          },
          {
            opposite: true,
            show: true,
            decimalsInFloat: 0,
          },
        ],
        tooltip: {
          custom: function(props) {
            const headerTitle =
              props.w.globals.categoryLabels[props.dataPointIndex];
            const currentDashTasks = dashTasks[props.dataPointIndex];

            const {
              opening_count,
              accounting_change_count,
              contractual_alteration_count,
              company_closure_count,
              tasks_count,
            } = currentDashTasks;

            return `
            <div class="custom_tooltip">
              <div class="header">${headerTitle}</div>
              <div class="content">
                ${"<span>" +
                  `Abertura: <strong>${opening_count}</strong><br />` +
                  `Troca de Contabilidade: <strong>${accounting_change_count}</strong><br />` +
                  `Alteração Contratual: <strong>${contractual_alteration_count}</strong><br />` +
                  `Encerramento de Empresa: <strong>${company_closure_count}</strong><br />` +
                  `Tarefas: <strong>${tasks_count}</strong><br />` +
                  "</span>"}
              </div>
            </div>
          `;
          },
        },
      }}
      series={series}
    />
  );
};
