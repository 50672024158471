import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Axios from 'axios';
import { TextField, Button, ThemeProvider } from '@material-ui/core';

import iziToast from 'izitoast';
import logo from './logo.png';
import { getSubdomain } from '~/helpers/getSubdomain';
import { DEFAULT_MESSAGES } from '~/constants/default-messages.constants';
import { MaterialUIFormControl } from '../UI/Material/FormControl';
import { materialUITheme } from '~/themes/material-ui.theme';

const defaultiziToast = {
    titleColor: '#fff',
    messageColor: '#fff',
    iconColor: "#fff",
    position: 'topCenter',
    transitionIn: 'fadeInDown',
    close: false,
    zindex: 99999,
    timeout: 15000,
};

class LoginRecover extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            email: '',
            isOk: false,
            newPassword: undefined,
            confirmNewPassword: undefined
        }
    }

    notify = (message) => iziToast.show({
        ...defaultiziToast,
        class: 'danger-toast',
        icon: 'icon-close',
        title: 'Ops,',
        message: message,
        backgroundColor: '#FF7273',
        progressBarColor: '#444F60'
    });

    notifySuccess = (message) => iziToast.show({
        ...defaultiziToast,
        class: 'success-toast',
        icon: 'icon-check',
        // title: 'Ops,',
        message: message,
        backgroundColor: '#00b289',
        progressBarColor: '#00b289'
    });

    loading = (status) => this.setState({loading : status});

    handleSubmit = (e) => {
        e.preventDefault();
        const _this = this;
        const { email } = this.state;

        _this.loading(true);

        Axios.post(`${process.env.REACT_APP_API}/auth/recover`,{
            email: email
        },
        {
            headers: {
                'X-Tenant': getSubdomain()
            }
        }
        )
        .then(response => {
             _this.setState({isOk: true});
             _this.loading(false);
        })
        .catch(function (error) {
            const { response } = error;
            if (response) {
                if(response.status === 422){
                    _this.notify(response.data.email[0]);
                }
                if(response.data.hasOwnProperty('message')){
                    _this.notify(response.data.message);
                }

                if (response.status === 404 || response.status >= 500) {
                    _this.notify(DEFAULT_MESSAGES['high-demand']);
                }
            } else {
                _this.notify('Não foi possível concluir sua solicitação!');
            }

            _this.loading(false);
        });

    }

    handleSubmitNewPassword = (e) => {
        e.preventDefault();
        const _this = this;
    
        const { newPassword, confirmNewPassword } = this.state;
        const { token } = this.props.match.params;

        if(newPassword !== confirmNewPassword){
            _this.notify('As senhas não conferem!');
            return false;
        }

        _this.loading(true);

        Axios.post(`${process.env.REACT_APP_API}/auth/newpassword`,{
            token,
            newPassword
        },
        {
            headers: {
                'X-Tenant': getSubdomain()
            }
        })
        .then(response => {
             _this.loading(false);
             _this.notifySuccess(response.data.message);
             _this.props.history.push('/login');
        })
        .catch(function (error) {
            if(error.response.status === 422){
                _this.notify(error.response.data.email[0]);
            }
            if(error.response.data.hasOwnProperty('message')){
                _this.notify(error.response.data.message);
            }
            _this.loading(false);
        });

    }

    render() {
        const { email, loading, isOk, newPassword, confirmNewPassword } = this.state;
        const { token } = this.props.match.params;

        return (
            <ThemeProvider theme={materialUITheme}>
                <section className="hero is-fullheight is-light-grey is-bold">
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column is-4 is-offset-4">
                                    <div className="animated preFadeInLeft fadeInLeft">
                                        <div className="flex-card auth-card is-light-raised light-bordered card-overflow">
                                            <div className="auth-card-header header-secondary">
                                                <a href="/">
                                                    <img src={logo} alt="ContLabs" />
                                                </a>
                                            </div>
                                            {!token && (
                                            <form onSubmit={this.handleSubmit}>
                                                <MaterialUIFormControl>
                                                    <TextField
                                                        label="E-mail"
                                                        name="email"
                                                        onChange={(e) => this.setState({email: e.target.value})}
                                                        type="email"
                                                    />
                                                </MaterialUIFormControl>
                                                {/* <p className="has-text-left mt-30">
                                                    <a className="no-account is-secondary" href="#">Need Support?</a>
                                                </p> */}
                                                {isOk && (
                                                <article className="message is-success">
                                                    <div className="message-body">
                                                        Solicitação de recuperação de senha recebida com sucesso! Em breve você receberá um e-mail com as intruções de recuperação.
                                                    </div>
                                                </article>
                                                )}
                                                <div className="mt-20">
                                                    <Button
                                                        type="submit"
                                                        disabled={!email || loading}
                                                        variant="contained"
                                                        size="large"
                                                        color="primary"
                                                        fullWidth
                                                    >
                                                        {loading ? "Enviando..." : "Recuperar senha"}
                                                    </Button>
                                                </div>
                                            </form>
                                            )}
                                            {token && (
                                            <form onSubmit={this.handleSubmitNewPassword}>
                                                <MaterialUIFormControl>
                                                    <TextField
                                                        label="Nova senha"
                                                        name="password"
                                                        onChange={(e) => this.setState({newPassword: e.target.value})}
                                                        type="password"
                                                    />
                                                </MaterialUIFormControl>
                                                <MaterialUIFormControl>
                                                    <TextField
                                                        label="Confirme a nova senha"
                                                        name="confirmPassword"
                                                        onChange={(e) => this.setState({confirmNewPassword: e.target.value})}
                                                        type="password"
                                                    />
                                                </MaterialUIFormControl>
                                                <div className="mt-20">
                                                    <Button
                                                        type="submit"
                                                        disabled={(!newPassword || !confirmNewPassword) || loading}
                                                        variant="contained"
                                                        size="large"
                                                        color="primary"
                                                        fullWidth
                                                    >
                                                        {loading ? "Enviando..." : "Recuperar senha"}
                                                    </Button>
                                                </div>
                                            </form>
                                            )}
                                        </div>
                                        <p className="has-text-centered">
                                            <Link className="return return-material is-secondary" to="/login">Voltar para o login</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ThemeProvider>
        );
    }
}

export default withRouter(LoginRecover);