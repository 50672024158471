import React from 'react';
import uid from 'uid';
import update from 'immutability-helper';

import './styles.css';

import {
  Button,
  Checkbox,
  Input,
  Table,
  Form,
  Row,
  Col,
  Icon,
  message
} from 'antd';

const Checklist = (props) => {

  const {
    title,
    checklist,
    setChecklist,
    form,
    currentHonoraryYear,
    honoraryOfCurrentYear,
    isCreating
  } = props
  const { getFieldDecorator } = form;

  const handleDelete = (uid) => {
    if (checklist.newList.includes(uid)) {
      setChecklist({
        ...checklist,
        list: checklist.list
          .filter((item) => item.uid !== uid),
        newList: checklist.newList
          .filter((checklistUid) => checklistUid !== uid)
      });
    } else {
      setChecklist({
        ...checklist,
        list: checklist.list
          .filter((item) => item.uid !== uid),
        deletedList: update(checklist.deletedList, { $push: Array(uid) })
      });
    }
  }

  const handleAdd = () => {
    let tmpUid = uid(10);
    setChecklist({
      ...checklist,
      list: [...checklist.list, { checked: false, name: '', uid: tmpUid, year: Number(currentHonoraryYear) }],
      newList: update(checklist.newList, { $push: [tmpUid] })
    });
  }

  const handleChange = (event, uid) => {
    setChecklist({
      ...checklist,
      list: checklist.list
        .map((currentChecklist) => currentChecklist.uid !== uid ? currentChecklist : {
          ...currentChecklist,
          name: event.target.value
        }),
    });
  }

  const handleChangeCheckbox = (event, uid) => {
    setChecklist({
      ...checklist,
      list: checklist.list
        .map((currentChecklist) => currentChecklist.uid !== uid ? currentChecklist : {
          ...currentChecklist,
          checked: event.target.checked
        })
    });
  }

  const fullList = [...checklist.list, ...checklist.newList];
  const currentList = fullList.filter((item) => Number(item.year) === Number(currentHonoraryYear));

  const handleCopyToClipBoard = () => {
    let finalString = '';
    const items = currentList.map((item) => item.name);
    items.forEach((item) => {
      finalString += `${item.toUpperCase()}\n`
    });

    navigator.clipboard.writeText(finalString);
    message.success('Itens copiados para a área de transferência!');
  }


  const columns = [
    {
      title: '',
      dataIndex: '',
      key: 'uid',
      width: 30,
      render: (value, item, index) => <Checkbox key={index} defaultChecked={item.checked} onChange={(event) => handleChangeCheckbox(event, item.uid)} />,
    },
    {
      title: () => (
        <>
          <span>{title}</span>
          <Icon type="copy" onClick={handleCopyToClipBoard} />
        </>
      ),
      className: 'column-with-copy',
      dataIndex: 'name',
      key: 'name',
      render: (value, item) => getFieldDecorator(`[${item.uid}][name]`, {
        initialValue: value,
        required: true
      })(
        <Input placeholder="Nome do item" style={{ textTransform: 'uppercase' }} onChange={(event) => handleChange(event, item.uid)} />
      ),
    },
    {
      title: '',
      key: '',
      width: 40,
      render: (text, record) => (
        <Button type="danger" icon="delete" onClick={() => handleDelete(record.uid)}></Button>
      ),
    },
  ];

  if ((honoraryOfCurrentYear && !honoraryOfCurrentYear.created_at) && !isCreating) {
    return (
      <h2>Para adicionar um item é necessário iniciar o atendimento.</h2>
    )
  }

  if (isCreating && !honoraryOfCurrentYear) {
    return (
      <h2>Para adicionar um item é necessário cadastrar um honorário de {currentHonoraryYear}.</h2>
    )
  }

  return (
    <div>
      <Row>
        <Col span={18}>
          <Button
            className="mb-10"
            type="primary"
            onClick={handleAdd}>Novo Item
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={currentList}
        rowKey={record => record.uid}
        pagination={false}
        className="mb-40"
      />
    </div>
  );
}

export default Form.create()(Checklist);