export const necessity = {
    1:{name: 'Troca de contabilidade', color: 'orange'}, 
    2:{name: 'Abertura de Empresa', color: 'blue'}, 
    3:{name: 'Encerramento de empresa', color: 'red'},
    4:{name: 'BPO Financeiro', color: 'green' },
    6:{name: 'MEI > ME', color: 'purple' },
    5:{name: 'Outros', color: 'gray'},
};

export const fields = [
    {title: 'Name', name: 'name'},
    {title: 'E-mail', name: 'email'},
    {title: 'Telefone', name: 'phone'},
    {title: 'Data de criação', name: 'registered_at'},
    {title: 'Data de conclusão', name: 'last_column_updated_at'},
    {title: 'Estado', name: 'state'},
    {title: 'Cidade', name: 'city'},
    {title: 'Atividade', name: 'company_type_id'},
    {title: 'Necessidade', name: 'necessity'},
    {title: 'CNPJ', name: 'identification_number'},
    {title: 'Faturamento (mensal)', name: 'billing'},
    {title: 'NFs Entrada (mensal)', name: 'nfs_input'},
    {title: 'NFs Saída (mensal)', name: 'nfs_output'},
    {title: 'Qtd de Boletos', name: 'number_bills'},
    {title: 'Sistema fiscal', name: 'tax_software'},
    {title: 'Funcionários', name: 'n_staffs'},
    {title: 'Qtd de sócios', name: 'n_partners'},
    {title: 'Honorário de abertura', name: 'first_honorary'},
    {title: 'Mensalidade', name: 'honorary'},
    {title: 'Descrição', name: 'description'},
    {title: 'Regime', name: 'type_taxation'},
    {title: 'Motivo de perda', name: 'lost_reason'},
    {title: 'Classificação', name: 'rate'},
  ];

  export const tax_software = {
      0: 'SAT',
      1: 'ECF'
  };