import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Layout from "../components/UI/Layout/Layout";
import CustomersList from "../components/Customers/CustomersList";
import CustomerInactivate from "../components/Customers/CustomerInactivate";
import CustomerForm from "../containers/Customer/index";
import RefundsForm from "~/containers/DrawerRefunds";
import { loadCustomers } from "~/containers/Customer/actions";
import { LoadingTopBar } from "~/components/UI/LoadingTopBar";

export const ScreensCustomers = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await Promise.all([
        dispatch(loadCustomers({ showLoading: false })),
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <CustomersList />
        <CustomerForm />
        <CustomerInactivate />
        <RefundsForm />
      </Layout>
    </div>
  );
};
