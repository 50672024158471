import React from "react";
import Chart from "react-apexcharts";

export const RatingChart = ({ feedbacks }) => {
  const feedbacksCount = feedbacks.length;

  if (feedbacksCount === 0) return null;

  const positiveRatingCount = feedbacks.filter(({ rating }) => rating === 1).length;
  const positiveRatingPercentage = Math.round((positiveRatingCount / feedbacksCount) * 100);
  const negativeRatingPercentage = Math.round(100 - positiveRatingPercentage);

  return (
    <Chart
      options={{
        colors: ['#52c41ad9', '#f5222dd9'],
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Satisfatória", "Insatisfatória"],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -30,
            }
          },
        },
        legend: {
          position: 'bottom'
        }
      }}
      series={[positiveRatingPercentage, negativeRatingPercentage]}
      type="pie"
      width={380}
    />
  );
};
