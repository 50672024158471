import styled from 'styled-components';

export const DrawerFooter = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    left: 0;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    z-index: 10000;
`

export const DrawerBody = styled.div`
    padding: 5px 5px 35px 5px;
    overflow: auto;
    height: 100%;
`

export default DrawerFooter;