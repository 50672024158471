/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Upload, Icon, Table, message, Modal } from 'antd';
import styled from 'styled-components';
import { getSubdomain } from '~/helpers/getSubdomain';
import { AccountStorage } from '~/storages/account';
import { request } from '~/components/Instance';
import { get } from 'lodash';

export const DraggerStyled = styled(Upload.Dragger)`
  width: 100% !important;
  padding: 10px !important;
  height: 150px !important;
  margin-bottom: 20px !important;
`

const Attachments = ({
    initialFiles = [],
    source = 'attachments',
    entity = null,
    canUpload = true,
    canDelete = true
}) => {
    let [files, setFiles] = useState(initialFiles);
    const [fileErros, setFileErros] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getFiles();
    }, []);

    const getFiles = async () => {
        request('get', 'files/'+source+'/'+entity, null, false)
            .then(response => {
                if(response.data.success){
                    setFiles(response.data.data);
                    setLoading(false);
                }
            }
        ).catch(error => {
            message.error('Erro ao buscar os arquivos.');
            console.log(error);
        });
    }

    const props = {
        accept: 'image/png,image/jpg,image/jpeg,.pdf',
        action: process.env.REACT_APP_API+'/auth/files/'+source+'/'+entity,
        multiple: true,
        showUploadList: false,
        fileList: [],
        headers: {
          Authorization: `Bearer ${AccountStorage.get().token}`,
          'X-Tenant': getSubdomain()
        },
        beforeUpload(file) {
          const limit = file.size / 1024 / 1024 < 15;
          if (!limit) {
            message.error('O arquivo deve ser menor que 15MB!');
          }
          return limit;
        },
        onStart: (file) => {
            setFiles([{
                id: file.uid,
                original_name: file.name,
                loading: true
            }, ...files]);
        },
        onSuccess: (response, file) => {
            getFiles();
        },
        onProgress: (progress, file) => {
            // console.log(progress, file);
        },
        onError: (error, response, file) => {
            message.error('Erro ao fazer o upload do arquivo.');
            setFiles(files.filter(file => file.id !== file.uid));
            setFileErros([...fileErros, {
                id: file.uid,
                original_name: file.name,
                loading: false,
                error: true
            }]);
        }
    };

    const deleteFile = (file) => {
        Modal.confirm({
            title: 'Deseja realmente excluir o arquivo?',
            content: 'Essa ação não poderá ser desfeita.',
            onOk: () => {
                setFiles(files.filter(f => f.id !== file.id));
                request('delete', 'files/'+file.id, null, false)
                    .then(response => {
                        if(response.data.success){
                            message.success('Arquivo excluído com sucesso.');
                        }
                    }
                ).catch(error => {
                    message.error('Erro ao excluir o arquivo.');
                    console.log(error);
                });
            },
            okText: 'Sim',
            cancelText: 'Não',
        });
    }

    const actionColumn = canDelete ? [{
        title: '',
        key: 'actions',
        align: 'right',
        width: 100,
        render: (text, record) => !get(record, 'loading') && !get(record, 'error') ? (
            <a href="#" onClick={() => deleteFile(record)}><Icon type="delete" /></a>
        ) : get(record, 'error') ? (<a href="#" onClick={() => {
                setFileErros(fileErros.filter(file => file.id !== record.id));
                setFiles([record, ...files]);
            }
        }><Icon type="delete" /></a>) : ''
    }] : [{}];

    return (
        <div>
            {canUpload && (
            <DraggerStyled {...props}>
                <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">
                    Clique aqui ou arraste os arquivos para fazer o upload.
                </p>
            </DraggerStyled>
            )}
            <Table
                showHeader={false}
                loading={loading}
                dataSource={[...fileErros, ...files]}
                pagination={false}
                rowKey="id"
                locale={{
                    emptyText: 'Nenhum arquivo anexado.'
                }}
                columns={[
                    {
                        title: 'Arquivo',
                        dataIndex: 'original_name',
                        key: 'original_name',
                        render: (text, record) => !get(record, 'loading') ? (!get(record, 'error') ? (
                                <a href={record ? record.file_url : ''} target="_blank" rel="noopener noreferrer">
                                    <Icon type="file" /> {record.original_name}
                                </a>) : (
                                <span style={{ color: 'red' }}>
                                    <Icon type="close" /> {record.original_name}
                                </span>
                            )
                        ) : (
                            <span>
                                <Icon type="loading" /> {record.original_name}
                            </span>
                        )
                    },
                    ...actionColumn,
                ]}
            />
        </div>
    );
};

export default Attachments;