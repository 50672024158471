import React, { useState, useEffect } from "react";
import { Button, Drawer, Form } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";

import { Body, Footer } from "./styles";
import { FilesDragger } from "../../FilesDragger";
import { UploadedFilesTable } from "./UploadedFilesTable";
import * as documentsActions from "~/containers/Documents/actions";

export const SporadicDocumentUploadInBatch = Form.create()(
  ({ isVisible, onClose, selectedDepartmentId, form }) => {
    const dispatch = useDispatch();

    const [files, setFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isCreatingFiles, setIsCreatingFiles] = useState(false);

    useEffect(() => {
      if (uploadedFiles.length === 0) return;
      const newFiles = [];

      uploadedFiles.forEach((file) => {
        const newFile = {
          id: file.uid,
          file,
        };
        newFiles.push(newFile);
      });

      setFiles([...files, ...newFiles]);
    }, [uploadedFiles]);

    function handleAfterVisibleChange(isVisible) {
      if (!isVisible) {
        setFiles([]);
      }
    }

    async function createDocumentInBatchRequest(data) {
      return dispatch(documentsActions.createDocumentInBatch(data));
    }

    function createFiles() {
      form.validateFields(async (error, values) => {
        if (error) {
          return;
        }

        setIsCreatingFiles(true);
        try {
          const formData = new FormData();

          Object.entries(values).forEach(([key, value], index) => {
            const currentFile = files.find(({ id }) => id === key);

            if (currentFile) {
              formData.append(
                `files[${index}][customer_id]`,
                value.customer_id
              );
              formData.append(
                `files[${index}][description]`,
                value.description
              );
              formData.append(`files[${index}][type]`, value.type);
              formData.append(
                `files[${index}][department]`,
                value.department
              );
              formData.append(`files[${index}][amount]`, value.amount || 0);
              formData.append(
                `files[${index}][competency_at]`,
                moment(value.competency_at).format("YYYY-MM-DD")
              );
              formData.append(
                `files[${index}][due_at]`,
                moment(value.due_at).format("YYYY-MM-DD")
              );
              formData.append(
                `files[${index}][file]`,
                currentFile.file.originFileObj
              );
            }
          });

          await createDocumentInBatchRequest(formData);
          setFiles([]);
        } catch (error) {
          console.log(error);
        } finally {
          setIsCreatingFiles(false);
        }
      });
    }

    return (
      <Drawer
        afterVisibleChange={handleAfterVisibleChange}
        title="Em lote"
        visible={isVisible}
        onClose={onClose}
        width="80%"
        maskClosable={false}
      >
        <Body>
          <FilesDragger
            setCurrentFiles={setUploadedFiles}
            disabled={isCreatingFiles}
          />
          <UploadedFilesTable
            files={files}
            setFiles={setFiles}
            selectedDepartmentId={selectedDepartmentId}
            form={form}
          />
        </Body>
        <Footer>
          <Button onClick={onClose} disabled={isCreatingFiles}>
            Voltar
          </Button>
          <Button
            onClick={createFiles}
            type="primary"
            loading={isCreatingFiles}
            disabled={files.length === 0}
          >
            {isCreatingFiles ? "Enviando..." : "Enviar"}
          </Button>
        </Footer>
      </Drawer>
    );
  }
);
