import { Form, Input, message, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import uid from "uid";

import * as customersActions from "~/containers/Customer/actions";

export const UserModal = Form.create()(
  ({ isVisible, data, hideModal, setUsers, customer, users, form }) => {
    const {
      getFieldDecorator,
      getFieldsValue,
      validateFields,
      setFieldsValue,
      resetFields,
    } = form;

    const { name, email } = getFieldsValue();

    const dispatch = useDispatch();

    const isFormUnmodified = data
      ? name === data.name && email === data.email
      : true;

    useEffect(() => {
      if (isVisible) {
        setFieldsValue({
          name: data.name,
          email: data.email,
        });
      }
    }, [isVisible]);

    function onHideModal() {
      resetFields();
      hideModal();
    }

    async function updateUser(params) {
      const { payload } = await updateUserRequest(params);

      const { app_user: updatedAppUser } = payload.data;

      setUsers((usersInState) => {
        return usersInState.map((user) => {
          if (user.id !== params.appUserId) {
            return user;
          }

          return {
            id: updatedAppUser.id,
            name: updatedAppUser.name,
            email: updatedAppUser.email,
            last_access_at: updatedAppUser.last_access_at,
            pivot: {
              app_user_id: updatedAppUser.id,
              customer_id: customer.id,
            },
          };
        });
      });
      onHideModal();
    }

    function handleSave() {
      validateFields(async (error, values) => {
        if (error) {
          return;
        }

        try {
          const requestData = {
            appUserId: data.id,
            customerId: customer.id,
            name: values.name,
            email: values.email,
          };

          if (
            requestData.email !== data.email &&
            users.some((user) => user.email === requestData.email)
          ) {
            message.warning(
              "Já existe um usuário relacionado a este cliente que usa este e-mail"
            );
            return;
          }

          const { payload } = await checkAccessRequest({
            customerId: requestData.customerId,
            email: requestData.email,
          });

          const { customers } = payload.data;

          if (customers.length > 0 && requestData.email !== data.email) {
            return Modal.confirm({
              title: `Você tem certeza de que deseja atribuir o e-mail "${requestData.email}" para o usuário "${requestData.name}"?`,
              width: 550,
              content: (
                <div>
                  <span>Este e-mail já possui acesso aos clientes:</span>
                  <ul>
                    {customers.map((item) => (
                      <li key={uid()}>- {item}</li>
                    ))}
                  </ul>
                </div>
              ),
              okText: "Sim",
              cancelText: "Não",
              onOk: async () => await updateUser(requestData),
            });
          }
          await updateUser(requestData);
        } catch (error) {
          console.log(error);
        }
      });
    }

    async function checkAccessRequest(params) {
      return dispatch(customersActions.checkAccess(params));
    }

    async function updateUserRequest(params) {
      return dispatch(customersActions.updateAppUser(params));
    }

    return (
      <Modal
        title="Editar usuário"
        visible={isVisible}
        onCancel={onHideModal}
        cancelText="Voltar"
        onOk={handleSave}
        okText="Salvar"
        okButtonProps={{ disabled: isFormUnmodified }}
      >
        <Form>
          <Form.Item label="Nome">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Por favor, informe o nome",
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="E-mail">
            {getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  message: "Por favor, informe o e-mail",
                },
                {
                  type: "email",
                  message: "Por favor, informe um e-mail válido",
                },
              ],
            })(<Input disabled={true}/>)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);
