import { Button, Upload } from "antd";
import styled from "styled-components";

export const UploadStyled = styled(Upload)`
  .ant-upload {
    width: 100% !important;
  }
`;

export const DocumentViewerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  padding-top: 20px;
`;

export const AddDocumentButton = styled(Button)`
  &.has-error {
    border-color: #f5222d !important;
  }
`;

export const RequiredDocumentErrorMessage = styled.div`
  opacity: 0;
  color: #f5222d;
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-top: 2px;

  &.has-error {
    opacity: 1;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const TitleAndMonthPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MonthPickerWrapper = styled.div`
  font-size: 14px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;