import {
  // companyTypes,
  deliveryMethod,
  taxationTypes,
} from '~/containers/Customer/data';

export const availableAutomations = [
  {
    trigger: {
      value: 'when_creating_customer',
      description: 'Um novo cliente for cadastrado',
    },
    conditionFields: [
      {
        value: 'type_taxation',
        description: 'Regime Tributário',
        options: taxationTypes,
      },
      // {
      //   value: 'company_type',
      //   description: 'Tipo de empresa',
      //   options: companyTypes,
      // },
      {
        value: 'doc_delivery_method',
        description: 'Sistema de entrega',
        options: deliveryMethod.map(({ text }) => text),
      },
      // {
      //   value: 'acquisition',
      //   description: 'Origem da Aquisição',
      //   options: acquisitionOptions,
      // },
    ],
    actions: [
      {
        value: 'create_certificate',
        description: 'Criar um novo certificado digital para este cliente',
      },
    ],
  },
];