import { createAsyncActions } from '~/utils/store';

export const CREATE_SOLICITATION = createAsyncActions('@@SOLICITATIONS/CREATE_SOLICITATION');
export const LOAD_SOLICITATIONS = createAsyncActions('@@SOLICITATIONS/GET_SOLICITATIONS');
export const UPDATE_SOLICITATION = createAsyncActions('@@SOLICITATIONS/UPDATE_SOLICITATION');
export const DELETE_SOLICITATION = createAsyncActions('@@SOLICITATIONS/DELETE_SOLICITATION');

export const createSolicitation = (data) => ({
    type: CREATE_SOLICITATION.INIT,
    payload: {
        request: {
            method: "POST",
            url: "/solicitations",
            data,
        },
    },
});

export const loadSolicitations = ({ ...params }) => ({
    type: LOAD_SOLICITATIONS.INIT,
    payload: {
        request: {
            method: "GET",
            url: "/solicitations",
            ...params,
        },
    },
});

export const updateSolicitation = (id, data) => ({
    type: UPDATE_SOLICITATION.INIT,
    payload: {
        request: {
            method: "PUT",
            url: `/solicitations/${id}`,
            data,
        },
    },
});

export const deleteSolicitation = (id) => ({
    type: DELETE_SOLICITATION.INIT,
    payload: {
        request: {
            method: "DELETE",
            url: `/solicitations/${id}`,
        },
    },
});