import React from 'react';
import { Form, Input, Row, Col, Select } from 'antd';

import Tip from '~/components/Tip';

const { TextArea } = Input;
const { Option } = Select;

const CampaignForm = ({
    form: { getFieldDecorator },
    data = {}
}) => {
    return (
        <Form>
            <Row gutter={16}>
                <Col span={16}>
                    <Form.Item label={<span>Nome da campanha <Tip>Este nome aparecerá no formulário público.</Tip></span>}>
                        {getFieldDecorator('name', {
                            initialValue: data.name,
                            rules: [{ required: true, message: 'Campo obrigatório' }],
                        })(
                            <Input placeholder="Nome da campanha" />
                        )}
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Status">
                        {getFieldDecorator('status', {
                            initialValue: data.id ? data.status : 1,
                            rules: [{ required: true, message: 'Campo obrigatório' }],
                        })(
                            <Select>
                                <Option value={1}>Ativa</Option>
                                <Option value={0}>Arquivada <Tip>Campanhas arquivadas não podem mais receber indicações.</Tip></Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={<span>Descrição <Tip>Esta descrição aparecerá no formulário público.</Tip></span>}>
                        {getFieldDecorator('description', {
                            initialValue: data.description,
                            rules: [{ required: true, message: 'Campo obrigatório' }],
                        })(
                            <TextArea placeholder="Descrição" rows={6} />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default CampaignForm;