import React from 'react';
import { useDispatch } from 'react-redux';
import { Table, Tooltip, Tag } from 'antd';
import Moment from 'react-moment';
import styled from 'styled-components';

import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

import * as referralIndicationActions from '~/containers/DrawerReferralIndication/actions';

import { WhatsappIcon } from '~/icons/WhatsappIcon';
import { phoneMask } from '~/helpers/masks';

const getStatusText = (status) => {
    switch (status) {
        case 0:
            return <Tag color="blue">Pendente</Tag>;
        case 1:
            return <Tag color="green">Positivo</Tag>;
        case 2:
            return <Tag color="red">Negativo</Tag>;
        default:
            return '';
    }
};

export const WhatsappLink = styled.a`
  > img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`;

const Indications = ({
    data = {}
}) => {
    const dispatch = useDispatch();
    const columns = [
        {
            title: 'Data',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 50,
            ellipsis: true,
            render: (text) => <Tooltip title={
                <Moment format="DD/MM/YYYY HH:mm">{text}</Moment>
            }><Moment fromNow>{text}</Moment></Tooltip>,
        },
        {
            title: 'Empresa',
            dataIndex: 'company_name',
            key: 'company_name',
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Whatsapp',
            dataIndex: 'company_contact_phone',
            key: 'company_contact_phone',
            width: 100,
            ellipsis: true,
            render: (company_contact_phone) => {
                if (!company_contact_phone) {
                    return '';
                }
                const phoneWithOnlyNumber = company_contact_phone.replace(/\D/g, '');
                return (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <span style={{ lineHeight: 0 }}>{phoneMask(company_contact_phone)}</span>
                    <Tooltip title="Ir para o Whatsapp">
                      <WhatsappLink href={`https://wa.me/55${phoneWithOnlyNumber}`} target="_blank">
                        <WhatsappIcon />
                      </WhatsappLink>
                    </Tooltip>
                  </div>
                )
            }
        },
        {
            title: 'Indicado por',
            dataIndex: 'indicated_by',
            key: 'indicated_by',
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            ellipsis: true,
            render: (status) => {
                return getStatusText(status);
            }
        }
    ];

    return (
        <div className={`table-clabs`}>
            <Table
                columns={columns}
                dataSource={data.logs}
                rowKey="id"
                bordered
                rowClassName="row-clabs clickable-row"
                size="middle" 
                pagination={getDefaultPaginationConfig({
                    storageKey: STORAGE.defaultPageSize.referralProgramIndications
                })}
                onRow={(record, rowIndex) => {
                    return {
                    onClick: event => {
                        dispatch(referralIndicationActions.show(record));
                    }
                    };
                }}
            />
        </div>
    );
};

export default Indications;