import React, { Component } from 'react';
import FileDownload from 'js-file-download';
import { request } from '../Instance';
import PropTypes from 'prop-types';

class DownloadBatch extends Component {
    state = {}
    
    static propTypes = {
        url: PropTypes.string.isRequired,
        filename: PropTypes.string.isRequired
    }

    print = () => {
        console.log(this.props.url);
        request('get', this.props.url, {}, true, {
            responseType: 'blob'
        })
        .then(response => {
            FileDownload(response.data, this.props.filename);
        });
    }

    render() {
        let props = this.props;    
        
        return React.cloneElement(props.children, {            
              onClick: this.print              
            });
    }
}

export default DownloadBatch;