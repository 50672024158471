export const VIDEOS = {
  'bem-vindo': {
    url: "https://www.youtube.com/embed/5SdunIvBd2U",
    thumbnailUrl: "https://img.youtube.com/vi/5SdunIvBd2U/hqdefault.jpg",
    title: "Bem vindo",
  },
  'apresentacao-geral-do-sistema': {
    url: "https://www.youtube.com/embed/e1qPq1De-3w",
    thumbnailUrl: "https://img.youtube.com/vi/e1qPq1De-3w/hqdefault.jpg",
    title: "Apresentação geral do sistema",
  },
  'minha-conta': {
    url: "https://www.youtube.com/embed/MxSKPUtPQTk",
    thumbnailUrl: "https://img.youtube.com/vi/MxSKPUtPQTk/hqdefault.jpg",
    title: "Minha conta",
  },
  'gerenciar-usuario': {
    url: "https://www.youtube.com/embed/Xt6CvAb274E",
    thumbnailUrl: "https://img.youtube.com/vi/Xt6CvAb274E/hqdefault.jpg",
    title: "Gerenciar usuário",
  },
  'dashboard': {
    url: "https://www.youtube.com/embed/5yuGvkLVPms",
    thumbnailUrl: "https://img.youtube.com/vi/5yuGvkLVPms/hqdefault.jpg",
    title: "Dashboard",
  },
  'clientes': {
    url: "https://www.youtube.com/embed/8oDEa_uhY-0",
    thumbnailUrl: "https://img.youtube.com/vi/8oDEa_uhY-0/hqdefault.jpg",
    title: "Clientes",
  },
  'cadastro-de-tarefas': {
    url: "https://www.youtube.com/embed/ytx953z3qpE",
    thumbnailUrl: "https://img.youtube.com/vi/ytx953z3qpE/hqdefault.jpg",
    title: "Cadastro de tarefas",
  },
  'checklist': {
    url: "https://www.youtube.com/embed/klR7AdjqJI4",
    thumbnailUrl: "https://img.youtube.com/vi/klR7AdjqJI4/hqdefault.jpg",
    title: "Checklist",
  },
  'tarefas-do-checklist': {
    url: "https://www.youtube.com/embed/C4vpfuCyGpA",
    thumbnailUrl: "https://img.youtube.com/vi/C4vpfuCyGpA/hqdefault.jpg",
    title: "Tarefas do checklist",
  },
  'convencoes-coletivas': {
    url: "https://www.youtube.com/embed/a8WepG_iBHw",
    thumbnailUrl: "https://img.youtube.com/vi/a8WepG_iBHw/hqdefault.jpg",
    title: "Convenções coletivas",
  },
  'tarefas': {
    url: "https://www.youtube.com/embed/HLAxNi9ptMw",
    thumbnailUrl: "https://img.youtube.com/vi/HLAxNi9ptMw/hqdefault.jpg",
    title: "Tarefas",
  },
  'mensalidades': {
    url: "https://www.youtube.com/embed/qAAPS-jPr9s",
    thumbnailUrl: "https://img.youtube.com/vi/qAAPS-jPr9s/hqdefault.jpg",
    title: "Mensalidades",
  },
  'servicos-extras': {
    url: "https://www.youtube.com/embed/SICj7E1HLUw",
    thumbnailUrl: "https://img.youtube.com/vi/SICj7E1HLUw/hqdefault.jpg",
    title: "Serviços extras",
  },
  'comissoes': {
    url: "https://www.youtube.com/embed/EK6oCo3LSAY",
    thumbnailUrl: "https://img.youtube.com/vi/EK6oCo3LSAY/hqdefault.jpg",
    title: "Comissões",
  },
  'departamento-societario-painel': {
    url: "https://www.youtube.com/embed/uMIsMo2z5qU",
    thumbnailUrl: "https://img.youtube.com/vi/uMIsMo2z5qU/hqdefault.jpg",
    title: "Departamento societário - painel",
  },
  'lembretes': {
    url: "https://www.youtube.com/embed/KmROkIxWHbo",
    thumbnailUrl: "https://img.youtube.com/vi/KmROkIxWHbo/hqdefault.jpg",
    title: "Lembretes",
  },
  'malha-fiscal': {
    url: "https://www.youtube.com/embed/oWWijnx5Kt4",
    thumbnailUrl: "https://img.youtube.com/vi/oWWijnx5Kt4/hqdefault.jpg",
    title: "Malha fiscal",
  },
  'documentos-enviados': {
    url: "https://www.youtube.com/embed/VC5j9zyYBSU",
    thumbnailUrl: "https://img.youtube.com/vi/VC5j9zyYBSU/hqdefault.jpg",
    title: "Documentos enviados",
  },
  'relatorios-prazos': {
    url: "https://www.youtube.com/embed/U2VDEV9oSU4",
    thumbnailUrl: "https://img.youtube.com/vi/U2VDEV9oSU4/hqdefault.jpg",
    title: "Relatórios prazos",
  },
  'emails-enviados': {
    url: "https://www.youtube.com/embed/mYkyBLpF45E",
    thumbnailUrl: "https://img.youtube.com/vi/mYkyBLpF45E/hqdefault.jpg",
    title: "E-mails enviados",
  },
  'plugins': {
    url: "https://www.youtube.com/embed/WYDmdwMOKDY",
    thumbnailUrl: "https://img.youtube.com/vi/WYDmdwMOKDY/hqdefault.jpg",
    title: "Plugins",
  },
  'leads': {
    url: "https://drive.google.com/file/d/12ZzbsIHQ-LlqFudr09n7TINVM26l1PdJ/preview",
    thumbnailUrl: "/images/video-thumbnails/20_leads.png",
    title: "Leads"
  },
  'certificados': {
    url: "https://drive.google.com/file/d/1hRlYpsPArIY1qCelY9MfauFAy_ZYHPBB/preview",
    thumbnailUrl: "/images/video-thumbnails/16_certificado_digital.png",
    title: "Certificados"
  }
}