import { Icon } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  margin: 4px 0 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ThumbImage = styled.img``;

export const PlayIcon = styled(Icon).attrs({
  style: {
    fontSize: 26,
    position: "absolute",
    color: "#e8e8e8",
  },
  type: "play-circle",
})``;
