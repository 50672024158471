import moment from 'moment';
import React from 'react';
import Chart from 'react-apexcharts';
import { convertToBRL } from '~/utils/formatters';

export const CustomersCountChart = ({ data }) => {
  if (!data) return null;

  const inAmounts = Object.values(data).map((item) => item.in_amount);
  const outAmounts = Object.values(data).map((item) => item.out_amount);

  return (
    <Chart
      height={350}
      options={{
        chart: {
          type: 'line',
          stacked: false,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        tooltip: {
          enabled: true,
          custom: function(props) {
            const currentInAmount = inAmounts[props.dataPointIndex];
            const currentOutAmount = outAmounts[props.dataPointIndex];
            const title = props.w.globals.categoryLabels[props.dataPointIndex];
            const content = props.w.config.series.map(({ name, color, data: [value] }, index) => {
              return `
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: ${index + 1}; display: flex;">
                  <span class="apexcharts-tooltip-marker" style="background-color: ${color}"></span>
                  <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                    <div class="apexcharts-tooltip-y-group">
                      <span class="apexcharts-tooltip-text-y-label">${name}:</span>
                      <span class="apexcharts-tooltip-text-y-value">${value}</span>
                      ${index === 0 ? `<span class="apexcharts-tooltip-text-y-value" style="color: #52c41a">+ ${convertToBRL(currentInAmount)}</span>` : ''}
                      ${index === 1 ? `<span class="apexcharts-tooltip-text-y-value" style="color: #cf1322">- ${convertToBRL(currentOutAmount)}</span>` : ''}
                    </div>
                  </div>
                </div>
              `;
            }).join('');
            return `
              <div
                class="apexcharts-tooltip-title"
                style="font-size: 12px; font-family: Helvetica, Arial, sans-serif"
              >
                ${title}
              </div>
              ${content}
            `;
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function(value, { seriesIndex }) {
            if (seriesIndex === 3) {
              return `${value}%`;
            }
            return value;
          },
        },
        stroke: {
          width: [1, 1, 4, 4],
        },
        xaxis: {
          categories: Object.keys(data).map((item) =>
            moment(`${item}-01`).format('MMM/YY')
          ),
        },
        yaxis: [
          {
            show: true,
            labels: {
              formatter: (value) => {
                if (!Number.isInteger(value)) return 0;
                return value;
              },
            },
          },
          {
            show: false,
            seriesName: 'Entrada de clientes',
            decimalsInFloat: 0,
          },
          {
            opposite: true,
            show: true,
            decimalsInFloat: 0,
          },
          {
            show: false,
            max: 100,
          },
        ],
      }}
      series={[
        {
          name: 'Entrada de clientes',
          color: '#52c41a',
          type: 'column',
          data: Object.values(data).map((item) => item.in),
        },
        {
          name: 'Saída de clientes',
          color: '#cf1322',
          type: 'column',
          data: Object.values(data).map((item) => item.out),
        },
        {
          name: 'Quantidade de clientes',
          color: '#e8b627',
          type: 'line',
          data: Object.values(data).map((item) => item.total),
        },
        {
          name: 'Churn',
          color: '#000',
          type: 'line',
          data: Object.values(data).map((item) => item.churn),
        },
      ]}
    />
  );
};
