export const loadProcurations = ({ ...params }) => ({
  type: "LOAD_PROCURATIONS",
  payload: {
    request: {
      method: "get",
      url: "/procurations",
      ...params
    },
  },
});
