import React from 'react';
import { Col, Form, Modal, Row, Switch, Input } from "antd"
import moment from 'moment';
import uid from 'uid';

import { YearPicker } from '~/components/UI/YearPicker';
import InputCurrency, { currency2Float } from '~/components/UI/InputCurrency';
import { DatePicker } from '~/components/UI';

const { TextArea } = Input;

const HonoraryModal = ({
    isVisible,
    onClose,
    form,
    honoraries,
    setHonoraries,
    honoraryToUpdate,
    checklist,
    setChecklist,
    currentIRPFYear,
    isCreating
  }) => {
  const { getFieldDecorator } = form;

  function setErrorHonoraryAlreadyExists(year) {
    form.setFields({
      year: {
        value: moment().set({ year }),
        errors: [new Error(`O honorário de ${year} já existe.`)]
      }
    });
  }

  function createOrUpdateHonorary() {
    form.validateFields((errors, values) => {
      if (errors) return;

      const honoraryYear = values.year.format('YYYY');
      const paidAt = values.paid_at ? values.paid_at.format('YYYY-MM-DD HH:mm:ss') : undefined;
      const currentHonorary = honoraries.find((honorary) => Number(honorary.year) === Number(honoraryYear) && !honorary.is_deleted);
      
      if (honoraryToUpdate) {
        if (currentHonorary && honoraryToUpdate.uid !== currentHonorary.uid) {
          return setErrorHonoraryAlreadyExists(honoraryYear);
        }

        const updatedHonorary = {
          ...honoraryToUpdate,
          ...values,
          year: honoraryYear,
          paid_at: paidAt,
          is_updated: true,
        }

        setHonoraries((currentHonoraries) => {
          return currentHonoraries.map((honorary) => {
            return honorary.uid !== honoraryToUpdate.uid ? honorary : updatedHonorary
          })
        });
      } else {
        if (currentHonorary) {
          return setErrorHonoraryAlreadyExists(honoraryYear);
        }

        const newHonorary = {
          ...values,
          uid: uid(10),
          year: Number(honoraryYear),
          paid_at: paidAt,
          is_new: true,
        }

        setHonoraries((currentHonoraries) => [...currentHonoraries, newHonorary]);

        const fullChecklist = [...checklist.list, ...checklist.newList];

        const lastYearChecklist = fullChecklist.filter((checklist) => Number(checklist.year) === (Number(honoraryYear) - 1));
        
        if (lastYearChecklist.length > 0) {
          const newChecklist = lastYearChecklist.map((checklist) => ({
            uid: uid(10),
            name: checklist.name,
            checked: false,
            year: Number(honoraryYear)
          }));

          setChecklist((currentChecklist) => ({
            ...currentChecklist,
            list: [
              ...currentChecklist.list,
              ...newChecklist
            ],
            newList: [
              ...currentChecklist.newList,
              ...newChecklist.map((checklist) => checklist.uid)
            ]
          }));
        }
      }

      onClose();
    })
  }

  
  return (
    <Modal
      title={honoraryToUpdate ? "Editar honorário" : "Novo Honorário"}
      visible={isVisible}
      onCancel={onClose}
      onOk={createOrUpdateHonorary}
      destroyOnClose
      okText="Salvar"
    >
      <Form name="honorary" layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Ano">
              {getFieldDecorator('year', {
                initialValue: honoraryToUpdate ? moment().set({ year: honoraryToUpdate.year }) : undefined,
                rules: [{ required: true, message: 'Por favor, informe o ano do honorário' }],
              })(
                <YearPicker disabled={!isCreating ? (honoraryToUpdate ? Number(honoraryToUpdate.year) === currentIRPFYear : false) : false} />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Honorário">
              {getFieldDecorator('amount', {
                initialValue: honoraryToUpdate ? honoraryToUpdate.amount : undefined,
                rules: [{ required: true, message: 'Por favor, informe o honorário' }],
                normalize: (value) => currency2Float(value)
              })(
                <InputCurrency />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Data de pagamento">
              {getFieldDecorator('paid_at', {
                initialValue: honoraryToUpdate ? (honoraryToUpdate.paid_at ? moment(honoraryToUpdate.paid_at) : undefined) : undefined,
              })(
                <DatePicker />
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Quitado?">
              {getFieldDecorator('payment_status', {
                initialValue: honoraryToUpdate ? !!honoraryToUpdate.paid_at : false,
                valuePropName: 'checked'
              })(
                <Switch />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Descrição">
              {getFieldDecorator('description', {
                initialValue: honoraryToUpdate ? honoraryToUpdate.description : undefined,
              })(
                <TextArea rows={4} />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default Form.create()(HonoraryModal)