import React, { Component } from 'react';
import Axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import { Button, TextField, ThemeProvider } from '@material-ui/core';

import logo from './logo.png';

import iziToast from 'izitoast';
import { getSubdomain } from '~/helpers/getSubdomain';
import { getAllGraphPermissions } from '~/utils/permissions';
import { PERMISSIONS } from '~/constants/permissions.constants';
import { DEFAULT_MESSAGES } from '~/constants/default-messages.constants';
import { materialUITheme } from '~/themes/material-ui.theme';
import { MaterialUIFormControl } from '../UI/Material/FormControl';
import { AccountStorage } from '~/storages/account';

class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            email: '',
            password: ''
        }
    }

    notify = (message) => iziToast.error({
        title: 'Ops,',
        message: message,
        titleColor: '#fff',
        messageColor: '#fff',
        iconColor: "#fff",
        backgroundColor: '#FF7273',
        progressBarColor: '#444F60',
        position: 'topCenter',
        transitionIn: 'fadeInDown',
        close: false,
        zindex: 99999,
        timeout: 15000,
    });

    loading = (status) => this.setState({loading : status});

    handleSubmit = (e) => {
        e.preventDefault();
        const _this = this;
        const { email, password } = this.state;

        _this.loading(true);

        Axios.post(`${process.env.REACT_APP_API}/auth/login`,{
            email: email,
            password: password
        },
        {
            headers: {
                'X-Tenant': getSubdomain()
            }
        }
        )
        .then(response => {
            AccountStorage.set({ token: response.data.api_token, user: response.data.user });

            document.cookie = `Contlabs:ApiToken=${response.data.api_token}`;
            document.cookie = `Contlabs:Domain=${getSubdomain()}`;

            const userPermissions = response.data.user.permissions;

            if(!userPermissions.some(permission => getAllGraphPermissions().includes(permission))) {
                if(userPermissions.includes(PERMISSIONS.customers.view)){
                    _this.props.history.push('/clientes');
                }else if(userPermissions.includes(PERMISSIONS.calendarTasks.create)){
                    _this.props.history.push('/tarefas');
                }else if(userPermissions.includes(PERMISSIONS.calendarTasks.view)){
                    _this.props.history.push('/calendario/1/abertura-e-encerramento');
                }else if(userPermissions.includes(PERMISSIONS.corporate.view)){
                    _this.props.history.push('/societario');
                }else if(userPermissions.includes(PERMISSIONS.certificates.view)){
                    _this.props.history.push('/certificados');
                }else if(userPermissions.includes(PERMISSIONS.conventions.view)){
                    _this.props.history.push('/convencoes');
                }
            } else {
                _this.props.history.push('/');
            }
        })
        .catch(function (error) {
            const { response } = error;
            if (response) {
                if (response.data.hasOwnProperty('message')) {
                    _this.notify(response.data.message);
                } else if (response.status === 401) {
                    _this.notify('Login ou senha incorreta!')
                } else if (response.status === 404 || response.status >= 500) {
                    _this.notify(DEFAULT_MESSAGES['high-demand']);
                }
            } else {
                _this.notify('Não foi possível concluir sua solicitação!');
            }

            _this.loading(false);
        });

    }


    render() {
        const { email, password, loading } = this.state;
        return (
            <ThemeProvider theme={materialUITheme}>
                <section className="hero is-fullheight is-light-grey is-bold">
                    <div className="hero-body">
                        <div className="container">

                            <div className="columns is-vcentered">
                                <div className="column is-4 is-offset-4">

                                    <div className="login-form-wrapper">

                                        <div className="animated preFadeInLeft fadeInLeft">
                                            <form onSubmit={this.handleSubmit}>
                                                <div className="flex-card auth-card is-light-raised light-bordered card-overflow">
                                                    <div className="auth-card-header header-secondary">
                                                        <a href="/">
                                                            <img src={logo} alt="ContLabs" />
                                                        </a>
                                                    </div>
                                                    <MaterialUIFormControl>
                                                        <TextField
                                                            label="E-mail"
                                                            name="email"
                                                            type="email"
                                                            onChange={(e) => this.setState({email: e.target.value})}
                                                            value={this.state.email}
                                                        />
                                                    </MaterialUIFormControl>
                                                    <MaterialUIFormControl>
                                                        <TextField
                                                            label="Senha"
                                                            name="password"
                                                            type="password"
                                                            onChange={(e) => this.setState({password: e.target.value})}
                                                            value={this.state.password}
                                                        />
                                                    </MaterialUIFormControl>
                                                    {/* <p className="has-text-left mt-30">
                                                        <Link className="no-account is-secondary" to="/cadastro">Ainda não tem uma conta?</Link>
                                                    </p> */}
                                                    <div className="mt-10">
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            size="large"
                                                            color="primary"
                                                            fullWidth
                                                            disabled={!email || !password || loading}
                                                        >
                                                            {loading ? "Entrando..." : "Login"}
                                                        </Button>
                                                    </div>
                                                </div>
                                                <p className="has-text-centered">
                                                    <Link className="forgot forgot-material is-secondary" to="/esqueci-a-senha">Esqueceu sua senha?</Link>
                                                </p>
                                            </form>
                                        </div>
                                    
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </section>

            </ThemeProvider>
        );
    }
}

export default withRouter(Login);