import { createAsyncActions } from '~/utils/store';

export const FETCHQTDD = createAsyncActions('@@LEADS_RT/FETCHQTDD');
export const MOVE = createAsyncActions('@@LEADS_RT/MOVE');
export const LOAD_LEADS = createAsyncActions('@@LEADS_RT/LOAD');
export const LOAD_MORE_LEADS = createAsyncActions('@@LEADS_RT/LOAD_MORE_LEADS');

export const loadLeads = ({ ...params }) => ({
    type: LOAD_LEADS.INIT,
    payload: {
        request: {
            method: "GET",
            url: "/leads-rt",
            ...params
        }
    }
});

export const loadMoreLeads = ({ columnId, currentCount, moreCount }) => ({
    type: LOAD_MORE_LEADS.INIT,
    payload: {
        request: {
            method: "GET",
            url: `/leads-rt/more?stage=${columnId}&current_count=${currentCount}&more_count=${moreCount}`,
            columnId
        },
    },
})

export const fetchQtddByMonth = ({ date }) => ({
    type: FETCHQTDD.INIT,
    payload: {
        request:{
            method: 'get',
            url:`/leads-rt/qtdd/${date}`
        }
      }
});

export const move = (props) => ({
    type: MOVE.INIT,
    payload: {
        request:{
            method: 'put',
            url:`/leads-rt/${props[4]}/move`,
            data: {to: props[3].droppableId}
        },
    },
    props: props
});