import update from 'immutability-helper';

import {
  CLOSE_CATEGORIES_MODAL,
  CLOSE_DRAWER,
  CREATE_LINK,
  DELETE_LINK,
  LOAD_CATEGORIES,
  LOAD_LINKS,
  OPEN_CATEGORIES_MODAL,
  OPEN_DRAWER,
  SAVE_CATEGORIES,
  UPDATE_LINK,
} from './actions';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const DEFAULT_STATE = {
  list: [],
  drawer: {
    isOpen: false,
    data: null,
  },
  categoriesModal: {
    isOpen: false,
  },
  categories: [],
  isLoading: true,
};

const ACTION_HANDLERS = {
  [CACHE_ACTIONS.links]: (state, action) => {
    return update(state, {
      list: {
        $set: action.payload.links
      },
      categories: {
        $set: action.payload.linkCategories
      },
      isLoading: {
        $set: false
      }
    });
  },
  [LOAD_LINKS.SUCCESS]: (state, action) => {
    return update(state, {
      list: {
        $set: action.payload.data.links,
      },
      isLoading: {
        $set: false
      }
    });
  },
  [CREATE_LINK.SUCCESS]: (state, action) => {
    const createdLink = action.payload.data.link;
    return update(state, {
      list: {
        $set: [...state.list, createdLink],
      },
    });
  },
  [UPDATE_LINK.SUCCESS]: (state, action) => {
    const updatedLink = action.payload.data.link;
    return update(state, {
      list: {
        $set: state.list.map((item) => {
          if (item.id !== updatedLink.id) {
            return item;
          }
          return updatedLink;
        }),
      },
    });
  },
  [DELETE_LINK.SUCCESS]: (state, action) => {
    const deletedLinkId = action.meta.previousAction.id;
    return update(state, {
      list: {
        $set: state.list.filter((item) => item.id !== deletedLinkId),
      },
    });
  },
  [LOAD_CATEGORIES.SUCCESS]: (state, action) => {
    return update(state, {
      categories: {
        $set: action.payload.data.categories,
      },
    });
  },
  [SAVE_CATEGORIES.SUCCESS]: (state, action) => {
    const updatedCategories = action.meta.previousAction.updatedCategories;

    return update(state, {
      categories: {
        $set: action.payload.data.categories,
      },
      list: {
        $set: state.list.map((item) => {
          const updatedCategory = updatedCategories.find(
            (category) => category.id === item.link_category_id
          );

          if (!updatedCategory) return item;

          return {
            ...item,
            category: updatedCategory,
          };
        }),
      },
    });
  },
  [OPEN_DRAWER.INIT]: (state, action) => {
    return update(state, {
      drawer: {
        $set: {
          isOpen: true,
          data: action.payload.data,
        },
      },
    });
  },
  [CLOSE_DRAWER.INIT]: (state) => {
    return update(state, {
      drawer: {
        isOpen: {
          $set: false,
        },
      },
    });
  },
  [OPEN_CATEGORIES_MODAL.INIT]: (state) => {
    return update(state, {
      categoriesModal: {
        isOpen: {
          $set: true,
        },
      },
    });
  },
  [CLOSE_CATEGORIES_MODAL.INIT]: (state) => {
    return update(state, {
      categoriesModal: {
        isOpen: {
          $set: false,
        },
      },
    });
  },
};

const reducer = (state = DEFAULT_STATE, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
