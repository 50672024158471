import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

import DrawerLeadsRT from "~/containers/DrawerLeadsRT";
import * as drawerLeadsActions from "~/containers/DrawerLeadsRT/actions";
import * as leadsActions from "~/containers/LeadsRT/actions";
import { Header } from "./Header";
import { LeadColumns } from "./LeadColumns";

moment.updateLocale("pt-br", {
  weekdaysMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
});

const LeadsRT = ({
  columns,
  showDrawer,
  fetchQtddByMonth,
  loadMoreLeads,
  moveLead,
  isLoading,
  isLeadsDrawerVisible,
  user,
}) => {
  const [displayMode, setDisplayMode] = useState("funnel");
  const [date, setDate] = useState(moment().format("MM-YYYY"));

  return (
    <div>
      <Header
        date={date}
        setDate={setDate}
        displayMode={displayMode}
        setDisplayMode={setDisplayMode}
        showDrawer={showDrawer}
        // fetchQtddByMonth={fetchQtddByMonth}
        isLeadsDrawerVisible={isLeadsDrawerVisible}
        user={user}
      />
        <LeadColumns
          columns={columns}
          moveLead={moveLead}
          date={date}
          isLoading={isLoading}
          showDrawer={showDrawer}
          // fetchQtddByMonth={fetchQtddByMonth}
          loadMoreLeads={loadMoreLeads}
          user={user}
        />
      <DrawerLeadsRT />
    </div>
  );
};

const mapStateToProps = (state) => ({
  columns: state.leadsRT.columns,
  isLoading: state.leadsRT.isLoading,
  isLeadsDrawerVisible: state.drawerLeadsRT.show,
  user: state.user
});

const mapDispatchProps = (dispatch) => ({
  showDrawer: (data = null) => dispatch(drawerLeadsActions.show(data)),
  loadMoreLeads: ({ columnId, currentCount, moreCount }) => {
    dispatch(leadsActions.loadMoreLeads({ columnId, currentCount, moreCount }))
  },
  // fetchQtddByMonth: (date) => dispatch(leadsActions.fetchQtddByMonth({ date })),
  moveLead: (...props) => dispatch(leadsActions.move(props)),
});

export default connect(mapStateToProps, mapDispatchProps)(LeadsRT);
