import { FormControl } from "@material-ui/core";
import styled from "styled-components";

export const StyledFormControl = styled(FormControl).attrs({
  style: {
    marginBottom: 20,
  },
})`
  input,
  select,
  option {
    font-size: 16px;
  }
`;
