import update from 'immutability-helper';
import { get } from 'lodash';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const DEFAULT_STATE = {
  list: [],
  modal: {
    isVisible: false,
    data: null,
  },
};

const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CACHE_ACTIONS.checklists: {
      return update(state, {
        list: {
          $set: action.payload.permissionsGroups
        }
      });
    }

    case "LOAD_PERMISSIONS_GROUPS_SUCCESS": {
      return update(state, {
        list: {
          $set: action.payload.data.data,
        },
      });
    }

    case 'CREATE_PERMISSIONS_GROUP_SUCCESS': {
      const createdPermissionsGroup = get(action, 'payload.data.data');

      return update(state, {
        list: {
          $set: [...state.list, createdPermissionsGroup],
        },
      });
    }

    case 'UPDATE_PERMISSIONS_GROUP_SUCCESS': {
      const updatedPermissionsGroup = get(action, 'payload.data.data');

      return update(state, {
        list: {
          $set: state.list.map((item) => {
            if (item.id !== updatedPermissionsGroup.id) {
              return item;
            }

            return updatedPermissionsGroup;
          }),
        },
      });
    }

    case 'DELETE_PERMISSIONS_GROUP_SUCCESS': {
      const deletedPermissionsGroupId = get(
        action,
        'meta.previousAction.payload.request.id'
      );

      return update(state, {
        list: {
          $set: state.list.filter(
            (item) => item.id !== deletedPermissionsGroupId
          ),
        },
      });
    }

    case 'OPEN_PERMISSIONS_MODAL': {
      return update(state, {
        modal: {
          $set: { isVisible: true, data: get(action, 'payload.data') },
        },
      });
    }

    case 'CLOSE_PERMISSIONS_MODAL': {
      return update(state, {
        modal: {
          $set: { isVisible: false, data: null },
        },
      });
    }

    default:
      return state;
  }
};

export default reducer;
