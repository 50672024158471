import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Layout } from '~/components/UI';
import { LoadingTopBar } from '~/components/UI/LoadingTopBar';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { DocumentsDeadlines } from '~/_domains/reports/deadlines';
import { loadDocumentsDeadlines } from '~/_domains/reports/deadlines/actions';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

export const ScreensDocumentsDeadlines = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await LocalCacheHandler.load({
        actionName: CACHE_ACTIONS.documentsDeadlines,
        dispatch,
        pathname,
      });
      const { payload } = await dispatch(
        loadDocumentsDeadlines({
          date: moment()
            .endOf('month')
            .format('YYYY-MM-DD'),
          showLoading: false,
        })
      );
      await LocalCacheHandler.save({
        pathname,
        data: payload.data.tasks,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <DocumentsDeadlines />
      </Layout>
    </div>
  );
};
