import React, { useState } from 'react';
import { Modal, Table, Tooltip } from 'antd';
import moment from 'moment';

import { RatingIcon, SearchInput } from './styles';
import { normalizeToFilter } from '~/containers/KnowledgeBase/utils';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;

export const FeedbacksModal = ({ isVisible, onClose, feedbacks }) => {
  const [filter, setFilter] = useState('');

  const formattedFeedbacks = feedbacks.map((feedback) => ({
    ...feedback,
    formatted_created_at: moment(feedback.created_at).format('DD/MM/YYYY LTS'),
  }));

  const filteredFeedbacks = formattedFeedbacks.filter((feedback) => {
    const appUserNameMatches = normalizeToFilter(
      feedback.app_user_name
    ).includes(filter);

    const customerNameMatches = normalizeToFilter(
      feedback.customers_name[0]
    ).includes(filter);

    return appUserNameMatches || customerNameMatches;
  });

  return (
    <Modal
      title="Avaliações"
      visible={isVisible}
      onCancel={onClose}
      footer={false}
      width="70%"
    >
      <SearchInput
        placeholder="Pesquisar pelo nome do usuário ou empresa"
        onChange={({ target }) => setFilter(normalizeToFilter(target.value))}
      />
      <Table
        rowKey="id"
        bordered
        dataSource={filteredFeedbacks}
        pagination={getDefaultPaginationConfig({
          storageKey: STORAGE.defaultPageSize.feedbacks
        })}
      >
        <Column title="Nome" key="app_user_name" dataIndex="app_user_name" />
        <Column
          title="Empresa"
          key="customers_name"
          dataIndex="customers_name"
          render={(customersName) => {
            if (customersName.length === 0) {
              return null;
            }
            if (customersName.length === 1) {
              return customersName;
            }
            return (
              <Tooltip title={customersName.join(', ')}>
                {customersName[0]}...
              </Tooltip>
            );
          }}
        />
        <Column title="Comentário" key="description" dataIndex="description" />
        <Column
          title="Avaliação"
          width={120}
          key="rating"
          align="center"
          dataIndex="rating"
          filters={[
            {
              text: 'Positivo',
              value: 1,
            },
            {
              text: 'Negativo',
              value: 0
            }
          ]}
          onFilter={(value, { rating }) => value === rating}
          render={(rating) => <RatingIcon rating={rating} />}
        />
        <Column
          title="Criado em"
          width={120}
          key="created_at"
          dataIndex="formatted_created_at"
          defaultSortOrder="ascend"
          sorter={(a, b) => b.created_at.localeCompare(a.created_at)}
        />
      </Table>
    </Modal>
  );
};
