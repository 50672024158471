import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import 'moment/locale/pt-br';
import {
    Form,
    Drawer,
    Tabs,
    Button,
    Modal,
} from 'antd';

import DrawerFooter from '~/components/UI/Drawer';

import * as actions from './actions'
import CampaignForm from './CampaignForm';
import Audience from './Audience';
import Indications from './Indications';

import * as drawerActions from './actions';

const { TabPane } = Tabs;

const DrawerReferralProgram = ({
  form
}) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.drawerReferralProgram);
    const data = state && state.data ? state.data : {};

    const [customersChecked, setCustomersChecked] = useState([]);

    // set customersChecked when data changes
    useEffect(() => {
        setCustomersChecked(data && data.customers ? data.customers.map(id => id): []);
    }, [data]);

    const handleCreate = () => {
      form.validateFields((err, values) => {
          if (!err) {
            dispatch(drawerActions.create({
                ...values,
                customers: customersChecked,
            }));
          }
      });
    }

    const handleSave = () => {
      form.validateFields((err, values) => {
          if (!err) {
            dispatch(drawerActions.save(data.id, {
                ...values,
                customers: customersChecked,
            }));
          }
      });
    }

    const handleDelete = () => {
      Modal.confirm({
          title: 'Deletar Campanha',
          content: 'Tem certeza que deseja deletar esta campanha?',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Não',
          onOk: () => {
              dispatch(drawerActions.archive(data.id));
          }
      });
    }


  if(!state.show){
      return null;
  }

  return (
    <Drawer
      title={data.name || 'Nova Campanha'}
      width={1200}
      placement="right"
      onClose={() => dispatch(actions.close())}
      maskClosable={false}
      visible={state.show}
      destroyOnClose={true}
      style={{
        height: '100%',
        overflow: 'auto',
      }}
      bodyStyle={{ paddingBottom: '100px' }}
      >
        <Tabs defaultActiveKey="1" type="card">
            <TabPane tab="Campanha" key="1">
                <CampaignForm
                  form={form}
                  data={data}
                  handleCreate={handleCreate}
                />
            </TabPane>
            <TabPane tab="Público" key="2">
              <Audience
                data={data}
                customersChecked={customersChecked}
                setCustomersChecked={setCustomersChecked}
              />
            </TabPane>
            {data.id && (
            <TabPane tab="Indicações" key="3">
              <Indications 
                data={data} 
              />
            </TabPane>
            )}
        </Tabs>
        <DrawerFooter>
        <Button 
          type="danger"
          onClick={handleDelete}>
            Deletar
        </Button>{' '}
        <Button 
          onClick={() => dispatch(actions.close())}>
            Fechar
        </Button>
        {' '}
        {data.id ? (
          <Button 
            type="primary"
            loading={state.isSaving}
            onClick={handleSave}>
              Salvar
          </Button>
        ) : (
          <Button 
            type="primary" 
            loading={state.isSaving}
            onClick={handleCreate}>
              Criar
          </Button>
        )}
        </DrawerFooter>
    </Drawer>
  )
}

export default Form.create({ name: 'drawer_referral_program' })(DrawerReferralProgram);