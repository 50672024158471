import { Button } from 'antd';
import styled from 'styled-components';

export const NewDocumentButton = styled(Button)`
  margin-top: 20px;
`;

export const Footer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background-color: #fff;
  text-align: right;
`;

export const BackButton = styled(Button)`
  margin-right: 8px;
`;

export const BottonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
`;