import { Icon, Tag } from "antd";
import styled from "styled-components";

export const CouponTag = styled(Tag).attrs({
  style: {
    marginLeft: 8,
  },
})``;

export const CouponIcon = styled(Icon).attrs({
  style: {
    marginRight: 4,
  },
  type: "tag",
})``;

export const CouponLink = styled.span`
  cursor: pointer;
`;

export const Container = styled.div`
  input {
    font-size: 16px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
`;
