import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 20px;
`;

export const Header = styled.div`
  margin: 0 -16px;
  margin-bottom: 10px;
  padding: 15px;
  background-color: white;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const HeaderLeftContentWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
  width: 100%;

  > input {
    max-width: 400px;
  }
`;

export const Main = styled.div``;
