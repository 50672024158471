import React from 'react';
import { Table, Tag } from "antd"
import uid from 'uid';
import moment from 'moment';
import { toBRL } from '~/components/UI/InputCurrency';
import { methods as paymentMethods } from '~/helpers/paymentMethods';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;

export const InstallmentsTable = ({
  payment,
  billsData,
}) => {
  return (
    <Table
      size="small"
      dataSource={payment ? payment.installments : Object.values(billsData.payments).map((data, index, array) => ({
        id: uid(10),
        quota: `${index + 1}/${array.length}`,
        due_date: data.due_date,
        amount: data.amount,
        method: data.method,
        status: 'Pendente'
      }))}
      rowKey="id"
      bodyStyle={{margin: 0}}
      bordered
      pagination={getDefaultPaginationConfig({
        storageKey: STORAGE.defaultPageSize.partnersInstallments
      })}
    >
      <Column title="Parcela" dataIndex="quota" key="quota" align="center" />
      <Column title="Vencimento" dataIndex="due_date" key="due_date" render={date => moment(date).format('DD/MM/YYYY')} align="center" />
      <Column title="Valor" dataIndex="amount" key="amount" render={amount => `R$ ${toBRL(amount)}`} align="center" />
      <Column title="Forma PGTO" dataIndex="method" key="method" render={method => paymentMethods.find(data => data.value === method).name} align="center" />
      <Column title="Status" dataIndex="status" key="status" render={status => <Tag style={{margin: 0}} color={status === 'Pago' ? '#a0d911' : '#f5222d'}>{status}</Tag>} align="center" />
    </Table>
  )
}