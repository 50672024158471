import React, { Component } from "react"
import { DatePicker, message } from "antd";

export class YearPicker extends Component {
  state = {
    isOpen: false,
  }

  render() {
    const { isOpen } = this.state;
    const { onChange, value, allowedYear = null } = this.props;

    return (
      <DatePicker
        open={isOpen}
        mode="year"
        format="YYYY"
        style={{ width: '100%' }}
        placeholder="Selecione um ano"
        onOpenChange={(status) => this.setState({ isOpen: status })}
        onPanelChange={(year) => {
          if (allowedYear) {
            if (year.year() > allowedYear) {
              message.warning('Por favor, selecione o ano atual ou anterior', 5);
              return;
            }
          }
          this.setState({ isOpen: false });
          onChange(year);
        }}
        value={value}
        {...this.props}
      />
    )
  }
}