import { createAsyncActions } from '~/utils/store';

export const SHOW = '@@DRAWER_REFERRAL_PROGRAM/SHOW';
export const CLOSE = '@@DRAWER_REFERRAL_PROGRAM/CLOSE';

export const CREATE = createAsyncActions('@@DRAWER_REFERRAL_PROGRAM/CREATE');
export const SAVE = createAsyncActions('@@DRAWER_REFERRAL_PROGRAM/SAVE');
export const DELETE = createAsyncActions('@@DRAWER_REFERRAL_PROGRAM/DELETE');

export const show = (data = null) => ({
    type: SHOW,
    payload: {
        data
    }
});

export const close = () => ({
    type: CLOSE,
    payload: {}
});

export const create = (data) => ({
    type: CREATE.INIT,
    payload: {
        request:{
            method: 'post',
            url:'/referral-programs',
            data,
            showLoading: false
        }
    }
});

export const save = (id, data) => ({
    type: SAVE.INIT,
    payload: {
        request:{
            method: 'put',
            url:`/referral-programs/${id}`,
            data,
            showLoading: false
        }
    }
});

export const archive = (id) => ({
    type: DELETE.INIT,
    payload: {
        request:{
            method: 'delete',
            url:`/referral-programs/${id}`,
            showLoading: false
        },
        id
    }
});