import { createAsyncActions } from "~/utils/store";

export const FINISH_STEP = createAsyncActions(
  "@@ONBOARDING/FINISH_STEP"
);
export const SELECT_STEP = createAsyncActions("@@ONBOARDING/SELECT_STEP");
export const OPEN_ONBOARDING = createAsyncActions(
  "@@ONBOARDING/OPEN_ONBOARDING"
);
export const CLOSE_ONBOARDING = createAsyncActions(
  "@@ONBOARDING/CLOSE_ONBOARDING"
);
export const OPEN_VIDEO_MODAL = createAsyncActions(
  "@@ONBOARDING/OPEN_VIDEO_MODAL"
);
export const CLOSE_VIDEO_MODAL = createAsyncActions(
  "@@ONBOARDING/CLOSE_VIDEO_MODAL"
);

export const finishStep = (status) => ({
  type: FINISH_STEP.INIT,
  payload: {
    request: {
      method: "PATCH",
      url: "/user/onboarding/finish-step",
      showLoading: false,
      data: {
        status
      }
    },
  },
});

export const openOnboarding = () => ({
  type: OPEN_ONBOARDING.INIT,
});

export const closeOnboarding = () => ({
  type: CLOSE_ONBOARDING.INIT,
});

export const selectStep = (step) => ({
  type: SELECT_STEP.INIT,
  payload: {
    step,
  },
});

export const openVideoModal = ({ title, url }) => ({
  type: OPEN_VIDEO_MODAL.INIT,
  payload: {
    title,
    url
  }
});

export const closeVideoModal = () => ({
  type: CLOSE_VIDEO_MODAL.INIT,
})