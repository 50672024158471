import { Badge } from 'antd';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderLeftContent = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
  width: 100%;
`;

export const PendentBadge = styled(Badge).attrs({
  overflowCount: 999,
  offset: [10, -8],
  showZero: true
})`
  sup {
    background-color: #fa8c16;
    z-index: 2;
  }
`;

export const RegisteredBadge = styled(Badge).attrs({
  overflowCount: 999,
  offset: [10, -8],
  showZero: true
})`
  sup {
    background-color: #1890ff;
    z-index: 2;
  }
`;