import { Modal, Result } from 'antd';
import React from 'react';
import { memo } from 'react';

import { DocumentViewerContainer } from '../../styles';

const DocumentViewerModalComponent = ({
  isOpen,
  closeModal,
  data,
  ...props
}) => {
  const isPdf = data ? data.type === 'application/pdf' : false;

  const isImage = data
    ? ['image/png', 'image/jpeg', 'image/jpg'].includes(data.type)
    : false;

  return (
    <Modal
      visible={isOpen}
      onCancel={closeModal}
      footer={false}
      width={900}
      centered
      mask={false}
      {...props}
    >
      {data && (
        <DocumentViewerContainer>
          {isImage && (
            <img
              alt={data.name}
              src={URL.createObjectURL(data)}
            />
          )}
          {isPdf && (
            <object
              width="100%"
              style={{ height: 600 }}
              data={URL.createObjectURL(data)}
              type="application/pdf"
              aria-label={data.name}
            />
          )}
          {!isImage && !isPdf && (
            <Result
              status="error"
              title="Não foi possível pré-visualizar o documento."
            />
          )}
        </DocumentViewerContainer>
      )}
    </Modal>
  );
};

export const DocumentViewerModal = memo(
  DocumentViewerModalComponent,
  (prevProps, nextProps) => {
    return prevProps.isOpen === nextProps.isOpen;
  }
);
