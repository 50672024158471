export function getDiferenceAndPercentage(currentMonthCount, latestMonthCount) {
  const diferenceCount = currentMonthCount - latestMonthCount;
  const percentage =
    latestMonthCount > 0
      ? (diferenceCount / latestMonthCount) * 100
      : diferenceCount * 100;

  return {
    diferenceCount,
    percentage: Math.round(percentage),
  };
}
