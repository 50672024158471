import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import 'moment/locale/pt-br';
import InputCurrency, { currency2Float } from '~components/UI/InputCurrency';
import get from 'lodash/get';
import Moment from 'react-moment';
import { orderBy } from 'natural-orderby';

import {
  Input,
  Button,
  Form,
  Row,
  Col,
  Drawer,
  Divider,
  Modal,
  Timeline,
  Tooltip,
} from 'antd';



import { Cod } from '~/components/UI';
import InputMask from '~components/UI/InputMask';
import * as drawerLeads from './actions';

import { removeCountryCodeFromPhone } from '~/utils/formatters';
import TaskModal from './Modals/TaskModal';
import { WhatsappIcon } from '~/icons/WhatsappIcon';
import { WhatsappLink } from './styles';

const DrawerRT = (props) => {

  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [status, setStatus] = useState();
  const [taskModal, setTaskModal] = useState({
    isOpen: false,
    data: null
  });

  const {
    form,
    drawer,
    handleClose,
  } = props;

  const { getFieldDecorator, getFieldValue } = form;
  const { data } = drawer;

  useEffect(() => {
    setTasks(get(data, 'tasks', []));
  }, [data]);

  const handleCreate = () => {
    const { data } = drawer;
    setStatus(data.status);
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      setLoading(true);
      props.handleCreate({
        ...data,
        ...values,
        tasks,
        status,
        source: 'panel'
      })
        .then(() => {
          closeDrawerLeads();
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }

  const getTaskColor = (status) => {
    return {
      'Vencido': 'red',
      'A vencer': 'yellow',
      'Hoje': 'green',
    }[status]
  }

  const handleSave = () => {

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      setLoading(true);
      props.handleSave(get(data, 'id'), {
        ...values,
        tasks,
        status
      })
        .then(() => {
          closeDrawerLeads();
        })
        .finally(() => {
          setLoading(false);
        });
    });

  }

  const handleDelete = () => {
    Modal.confirm({
      title: 'Você realmente deseja excluir este lead?',
      content: 'Esta ação não poderá ser revertida.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => props.handleDelete(data)
    });
  }

  const openTaskModal = (data = null) => {
    setTaskModal({
      isOpen: true,
      data
    })
  }

  const closeTaskModal = () => {
    setTaskModal({
      isOpen: false,
      data: null
    });
  }

  const closeDrawerLeads = () => {
    form.resetFields();
    handleClose();
  }

  return (
    <Drawer
      title={(data.id) ? <span>Editar Lead <Cod float="right">#RT{data.id}</Cod></span> : 'Cadastrar Lead RT'}
      width={800}
      placement="right"
      onClose={closeDrawerLeads}
      maskClosable={false}
      visible={drawer.show}
      destroyOnClose={true}
      style={{
        height: '100%',
        overflow: 'auto',
        paddingBottom: 53,
      }}>
      <TaskModal
        visible={taskModal.isOpen}
        closeModal={closeTaskModal}
        data={taskModal.data}
        setTasks={setTasks}
      />
      <Form layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Nome do Cliente">
                {getFieldDecorator('name', {
                  initialValue: get(data, 'name'),
                  rules: [{ required: true, message: 'Por favor informe o nome do cliente!' }],
                })(<Input placeholder="Nome do cliente" />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Nome da Empresa">
                {getFieldDecorator('company', {
                  initialValue: get(data, 'company'),
                })(<Input placeholder="Nome da empresa" />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Nome da Recuperação Tributária">
                {getFieldDecorator('name_rt', {
                  initialValue: get(data, 'name_rt'),
                })(<Input placeholder="Nome da Recuperação Tributária" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="WhatsApp">
                {getFieldDecorator('phone', { initialValue: removeCountryCodeFromPhone(data.phone) })(
                  <InputMask
                    mask="(99) 9999tt999?"
                    // eslint-disable-next-line no-useless-escape
                    formatChars={{ "9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]" }}
                    maskChar={null}
                    placeholder="WhatsApp da empresa"
                    suffix={getFieldValue('phone') ?
                      <Tooltip title="Ir para o Whatsapp" placement="topRight">
                        <WhatsappLink href={`https://wa.me/55${getFieldValue('phone')}`} target="_blank">
                          <WhatsappIcon />
                        </WhatsappLink>
                      </Tooltip> : <span />
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={<span>Crédito Apurado</span>}>
                {getFieldDecorator('credit', {
                  initialValue: get(data, 'credit'),
                  normalize: (value) => currency2Float(value)
                })(
                  <InputCurrency />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<span>Honorário</span>}>
                {getFieldDecorator('honorary', {
                  initialValue: get(data, 'honorary'),
                  normalize: (value) => currency2Float(value)
                })(
                  <InputCurrency />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Observação">
                {getFieldDecorator('observation', {
                  initialValue: get(data, 'observation'),
                })(<Input.TextArea rows={6} placeholder="Observação" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Button type="primary" icon="plus"  onClick={() => openTaskModal()}>Follow Up ou Reunião</Button>
            <Divider orientation="left">Follow Up e Reuniões</Divider>
            <Timeline>
              {orderBy(tasks, [t => t.due_at, t => t.id], ['desc', 'desc']).filter((task) => !task.is_deleted).map(task =>
                <Timeline.Item key={task.id} dot={<div className="ant-timeline-item-head" style={{ 
                  backgroundColor: getTaskColor(task.status),
                  left: '0',
                  top: '0'
                  }} />}>
                  <p style={{
                    marginBottom: '0px',
                    fontWeight: 600,
                    fontSize: '16px'
                  }}>{task.name} <Button  type="link" icon="edit" onClick={() => openTaskModal(task)} ></Button></p>                    
                  <Moment format={`DD/MM ddd ${task.type === 2 ? '[às] HH:mm [hrs]' : ''}`}>
                    {task.due_at}
                  </Moment>
                </Timeline.Item>
              )}
            </Timeline>
          </Row>
      </Form>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }}
      >
        {get(data, 'id') && (<Button onClick={handleDelete} type="danger" style={{ marginRight: '8px' }}>Deletar</Button>)}
        <Button
          style={{
            marginRight: 8,
          }}
          onClick={closeDrawerLeads}
        >
          Cancelar
        </Button>
        {(get(data, 'id')) && (
          <Button onClick={handleSave} type="primary" loading={loading}>Salvar</Button>
        )}
        {!get(data, 'id') && (
          <Button onClick={handleCreate} type="primary" loading={loading}>Cadastrar</Button>
        )}
      </div>
    </Drawer>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  drawer: state.drawerLeadsRT,
});

const mapDispatchProps = dispatch => ({
  handleClose: () => dispatch(drawerLeads.close()),
  handleCreate: (payload) => dispatch(drawerLeads.create(payload)),
  handleSave: (id, payload) => dispatch(drawerLeads.save(id, payload)),
  handleDelete: (payload) => dispatch(drawerLeads.remove(payload)),
});

export default connect(mapStateToProps, mapDispatchProps)(Form.create()(DrawerRT));