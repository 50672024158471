import { createAsyncActions } from "~/utils/store";

export const LOAD_HONORARIES = createAsyncActions("@@HONORARIES/LOAD");
export const CREATE_HONORARY_FLAG = createAsyncActions("@@HONORARIES/CREATE_FLAG");
export const REMOVE_HONORARY_FLAG = createAsyncActions("@@HONORARIES/REMOVE_FLAG");

export const loadHonoraries = (params) => ({
  type: LOAD_HONORARIES.INIT,
  payload: {
    request: {
      method: "GET",
      url: `/honoraries/year/${params.year}`,
      ...params,
    },
  },
});

export const createFlag = (data) => ({
  type: CREATE_HONORARY_FLAG.INIT,
  payload: {
    request: {
      method: 'POST',
      url: `/honoraries/flag`,
      data
    },
    customer_id: data.customer_id
  }
});

export const removeFlag = (customerId) => ({
  type: REMOVE_HONORARY_FLAG.INIT,
  payload: {
    request: {
      method: 'DELETE',
      url: `/honoraries/flag/${customerId}`
    },
    customer_id: customerId
  }
});