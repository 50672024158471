import { Form, Input, message } from "antd";
import React, { useState, useRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import InputMask from "~/components/UI/InputMask";
import { getStateByPhone } from "~/utils/getStateByPhone";
import {
  Container,
  FormContainer,
  LogoImage,
  LogoLink,
  SubmitButton,
  Title,
} from "../styles";
import { useQuery } from "~/hooks/useQuery";
import { getSubdomain } from "~/helpers/getSubdomain";
import { DEFAULT_MESSAGES } from '~/constants/default-messages.constants';

export const ScreensFormOne = Form.create()(({ form, formProps }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);

  const query = useQuery();
  const recaptchaRef = useRef();

  const { getFieldDecorator } = form;
  const hasWhatsappParams = !!query.get("phone") && !!query.get("text");

  async function incrementAccess() {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API}/leads/increment-access`,
        {
          type: "form1",
          source: formProps.acquisition_id ? formProps.acquisition_id : null,
        },
        {
          headers: {
            "X-Tenant": getSubdomain(),
          },
        }
      );
    } catch (error) {
      const { response } = error;
      if (response) {
        if (response.status === 404 || response.status >= 500) {
          message.error(DEFAULT_MESSAGES['high-demand']);
        }
      }
    } finally {
    }
  }

  function handleSubmit() {
    form.validateFields(async (errors, values) => {
      if (errors) {
        return;
      }

      setIsLoading(true);
      try {
        const recaptchaToken = await recaptchaRef.current.executeAsync();

        if (!recaptchaToken) {
          message.error(
            "Erro ao recuperar o recaptcha!. Tente novamente mais tarde."
          );
          setIsLoading(false);
          return;
        }

        await axios.post(
          `${process.env.REACT_APP_API}/recaptcha/leads`,
          {
            name: values.name,
            email: values.email,
            phone: `55${values.phone}`,
            state: getStateByPhone(values.phone),
            source: "form",
            acquisition_id: formProps.acquisition_id ? formProps.acquisition_id : null,
          },
          {
            headers: {
              "X-Tenant": getSubdomain(),
              recaptcha: recaptchaToken,
            },
          }
        );

        if (formProps.redirect_to_whatsapp){
          window.open(
            `https://api.whatsapp.com/send?phone=55${formProps.phone}&text=${formProps.message}`,
            "_self"
          );
        } else {
          setIsFormSent(true);
        }
      } catch (error) {
        const { response } = error;
        if (response) {
          if (response.status === 404 || response.status >= 500) {
            message.error(DEFAULT_MESSAGES['high-demand']);
          } else if (response.data && response.data.message) {
            message.error(response.data.message);
          }
        } else {
          message.error(
            "Não foi possível enviar o formulário! Tente novamente mais tarde."
          );
        }
      } finally {
        setIsLoading(false);
      }
    });
  }

  useEffect(() => {
    incrementAccess();
  }, []);

  if (isFormSent) {
    return (
      <Container>
        <FormContainer>
          <Title>Muito bem!</Title>
          <Title>
            Agora é só aguardar o contato de um de nossos especialistas.
          </Title>
        </FormContainer>
      </Container>
    );
  }

  return (
    <Container>
      <FormContainer>
        <Title>
          Vamos precisar das <br /> informaçōes abaixo
        </Title>
        <Form>
          <Form.Item label="Nome">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Por favor, informe o nome",
                },
              ],
            })(<Input placeholder="Informe o nome" size="large" />)}
          </Form.Item>
          <Form.Item label="E-mail">
            {getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  message: "Por favor, informe o e-mail",
                },
                {
                  type: "email",
                  message: "Por favor, informe um e-mail válido",
                },
              ],
            })(<Input placeholder="Informe o e-mail" size="large" />)}
          </Form.Item>
          <Form.Item label="Whatsapp">
            {getFieldDecorator("phone", {
              rules: [
                {
                  required: true,
                  message: "Por favor, informe o telefone",
                },
              ],
            })(
              <InputMask
                mask="(99) 999tt999?"
                formatChars={{ "9": "[0-9]", t: "[0-9-]", "?": "[0-9 ]" }}
                maskChar={null}
                placeholder="Informe o Whatsapp"
                size="large"
              />
            )}
          </Form.Item>
          <Form.Item>
            <SubmitButton onClick={handleSubmit} loading={isLoading}>
              {hasWhatsappParams ? "Entrar em contato" : "Enviar"}
            </SubmitButton>
          </Form.Item>
        </Form>
        <LogoLink>
          <LogoImage />
        </LogoLink>
      </FormContainer>
      {process.env.REACT_APP_RECAPTCHA && (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA}
          size="invisible"
        />
      )}
    </Container>
  );
});

export default ScreensFormOne;