import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  height: 3px;
  background-color: #7f00ff;
  z-index: 991;
  transition: ${({ percentage }) =>
    percentage < 100 ? "width 5s" : "width 2s, opacity 300ms"};
  width: ${({ percentage }) => `${percentage}%`};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;
