import React from 'react';
import { Tag, Tooltip } from 'antd';
import moment from 'moment';

const statusTag = (status) => {
    switch (status) {
        case 1:
            return <Tag color="green">Ativa</Tag>;
        case 0:
            return <Tag color="blue">Arquivada</Tag>;
        default:
            return <Tag color="red">Sem status</Tag>;
    }
}

export const columns = [
    {
        width: 400,
        title: 'Campanha',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
    },
    {
        width: 80,
        ellipsis: true,
        title: 'Público',
        dataIndex: 'customers',
        key: 'customers',
        render: (customers) => customers.length
    },
    {
        width: 80,
        ellipsis: true,
        title: 'Indicações',
        dataIndex: 'logs',
        key: 'logs',
        render: (logs) => logs.length
    },
    {
        width: 100,
        ellipsis: true,
        title: 'Data',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (date) => moment(date).format('DD/MM/YYYY')
    },
    {
        width: 100,
        ellipsis: true,
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => statusTag(status)
    }
];