import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Icon, Menu, Dropdown, Tooltip, Skeleton, Popover } from 'antd';
import moment from 'moment';

import './tasks.css';
import { useEffect } from 'react';
import { useState } from 'react';
import { frequency } from '../RegisterTasks/Utils';
import { TasksInList } from './TasksInList';
import { BatchTasksDrawer } from './List/Drawers/BatchTasksDrawer';
import { request } from '../Instance';
import * as calendarTasksActions from '~/components/CalendarTask/actions';
import { CustomProgress, CustomProgressWrapper } from './TasksInList/styles';

const gridStyle1 = {
    width: '70%',
    padding: '8px',
    textAlign: 'left',
  };
const gridStyle2 = {
    width: '30%',
    textAlign: 'center',
    padding: '8px',
};
const strong = {
    fontWeight: `700`
}

const Task = ({
    data,
    openGrid,
    openList,
    isFourthOnboardingStepActive,
    openTasksInBatchDrawer
}) => {
    const background = () => {
        const isPendent = data.pendent_tasks_count === 0;
        const from = moment(data.due_date);
        const to = moment();

        if (isPendent) {
            return "#52c41a";
        } else if (to.diff(from, "days") > 0) {
            return "#f5222d";
        } else if (to.diff(from, "days") <= 0) {
            return "#fadb14";
        }
    }

    const executedTasksPercentage = data.tasks_count > 0
        ? Math.round((100 * data.executed_tasks_count) / data.tasks_count, 2)
        : 0;
    
    const isExpired = moment().diff(data.due_date, "days") > 0;

    return(
    <Col span={6}>
        <Card
            style={{ marginTop: 16, zIndex: isFourthOnboardingStepActive ? 999 : 'auto' }}
            type="inner"
            title={<Dropdown
                overlay={
                <Menu>
                    <Menu.Item
                        onClick={openList}
                    >
                        <Icon type="bars"/> Visualizar tarefas em modo lista
                    </Menu.Item>
                    <Menu.Item
                        onClick={openGrid}
                        disabled={isFourthOnboardingStepActive}
                    >
                    <Icon type="table"/> Visualizar tarefas em modo tabela
                  </Menu.Item>
                  {data.file_upload && (
                    <Menu.Item
                            onClick={() => openTasksInBatchDrawer({ parentTask: data })}
                            disabled={isFourthOnboardingStepActive}
                        >
                        <Icon type="upload"/> Executar tarefas em lote
                    </Menu.Item>
                  )}
                </Menu>
              } trigger={['click']}>
                <button style={{color: `#fff`, border: 'none', backgroundColor: 'transparent', cursor: 'pointer'}}>
                    {data.name} <Icon type="down" />
                </button>
            </Dropdown>}
            headStyle={{backgroundColor: background()}}
            extra={data.priority_tasks_count > 0 && (
                <Tooltip placement="bottom" title={`${data.priority_tasks_count} prioridades`}>
                    <Icon type="star" theme="filled"/>
                </Tooltip>
            )}
        >
            <Card.Grid style={{ width: '100%', padding: 8 }}>
                <CustomProgressWrapper>
                    <Tooltip title="Concluídos">
                        <span>{data.executed_tasks_count}</span>
                    </Tooltip>
                    <CustomProgress
                        percent={executedTasksPercentage}
                        expired={isExpired ? "true" : "false"}
                    />
                    <Tooltip title="Pendentes">
                        <span>{data.pendent_tasks_count}</span>
                    </Tooltip>
                </CustomProgressWrapper>
            </Card.Grid>
            <Card.Grid style={{...gridStyle1, ...strong}}>Competência</Card.Grid>
            <Card.Grid style={{...gridStyle2, ...strong}}>{data.competence_date}</Card.Grid>
            <Card.Grid style={gridStyle1}>Clientes</Card.Grid>
            <Card.Grid style={gridStyle2}>{data.tasks_count}</Card.Grid>
            <Card.Grid style={gridStyle1}>Tarefas Pendentes</Card.Grid>
            <Card.Grid style={gridStyle2}>{data.pendent_tasks_count}</Card.Grid>
            <Card.Grid style={gridStyle1}>Tarefas Concluidas</Card.Grid>
            <Card.Grid style={gridStyle2}>{data.executed_tasks_count}</Card.Grid>
            <Card.Grid style={gridStyle1}>Periodicionade</Card.Grid>
            <Card.Grid style={gridStyle2}>{frequency(data.frequency)}</Card.Grid>
            <Card.Grid style={gridStyle1}>Vencimentos</Card.Grid>
            <Card.Grid style={gridStyle2}>{data.due_date.substring(8)}</Card.Grid>
        </Card>
    </Col>
    );
};

const Tasks = ({
    calendarTasks,
    tasksFromContext,
    loadCalendarTasks,
    filter,
    date,
    match,
    openList,
    openGrid,
    isTasksContextLoading,
    isListOpen,
    onboardingStep,
    isOnboardingActive,
    tasksFromState,
    visualizationMode,
    updateContextTasksCardsFromList,
    updateTasksCardsFromList,
    isVideoModalVisible
}) => {
    const [tasks, setTasks] = useState([]);
    const [isCalendarTasksLoading, setIsCalendarTasksLoading] = useState(false);
    const [tasksInBatchDrawer, setTasksInBatchDrawer] = useState({
        isVisible: false,
        parentTask: null,
        customers: [],
        isLoading: false
    });
    const [alreadyLoadedFromContext, setAlreadyLoadedFromContext] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false
    });

    const selectedDateIsEqualsCurrentDate = date.format('YYYY-MM') === moment().format('YYYY-MM');
    const hasTaskFromContext = tasksFromContext.length > 0;
    const selectedDepartmentId = Number(match.params.id);

    useEffect(() => {
        setTasks(calendarTasks);
    }, [calendarTasks])

    useEffect(() => {
        if ((alreadyLoadedFromContext[selectedDepartmentId]) && selectedDateIsEqualsCurrentDate) {
            if (hasTaskFromContext) {
                setTasks(tasksFromContext);
            } else {
                setTasks([])
            }
        }
    }, [tasksFromContext, alreadyLoadedFromContext]);

    useEffect(() => {
        if (alreadyLoadedFromContext[selectedDepartmentId]) {
            setIsCalendarTasksLoading(true);
            loadCalendarTasks(selectedDepartmentId, date).finally(() => setIsCalendarTasksLoading(false));
        }
    }, [date]);

    useEffect(() => {
        if (!alreadyLoadedFromContext[selectedDepartmentId]) {
            setAlreadyLoadedFromContext({ ...alreadyLoadedFromContext, [selectedDepartmentId]: true });
        }

        if (!selectedDateIsEqualsCurrentDate) {
            setIsCalendarTasksLoading(true);
            loadCalendarTasks(selectedDepartmentId, date).finally(() => setIsCalendarTasksLoading(false));
        }
    }, [selectedDepartmentId]);

    const checkIfStepIsActive = (step) => {
        return !isVideoModalVisible && isOnboardingActive && onboardingStep === step;
    }

    const closeTasksInBatchDrawer = async (tasksHaveBeenSent) => {
        const { parentTask } = tasksInBatchDrawer;

        setTasksInBatchDrawer({
            isVisible: false,
            parentTask: null,
            customers: [],
            isLoading: false
        });

        if (tasksHaveBeenSent) {
            const updatedCustomers = await loadTaskList(parentTask.id);
    
            const isCurrentDateEqualsSelectedDate = parentTask.due_date.slice(0, 7) === moment().format('YYYY-MM');
            if (isCurrentDateEqualsSelectedDate) {
                updateContextTasksCardsFromList(updatedCustomers, parentTask.id);
            } else {
                updateTasksCardsFromList(updatedCustomers, parentTask.id);
            }
        }
    }

    const openTasksInBatchDrawer = async ({ parentTask }) => {
        setTasksInBatchDrawer({
            isVisible: true,
            parentTask,
            customers: [],
            isLoading: true
        });
        const customers = await loadTaskList(parentTask.id);
        setTasksInBatchDrawer({
            isVisible: true,
            parentTask,
            customers,
            isLoading: false
        });
    }

    const loadTaskList = async (taskId) => {
        try {
            const response = await request(
                'GET',
                `/calendar/task/${taskId}/list/${date.format('YYYY-MM-DD')}`,
                null,
                false
            );
            return response.data;
        } catch (error) {
            console.log(error)
        }
    }

    if (isTasksContextLoading || isCalendarTasksLoading) {
        return (
            <Row gutter={[16, 16]} style={{ marginTop: 16, height: 270 }}>
                {Array(10).fill(1).map((_, index) => (
                    <Col span={6} key={index}>
                        <div style={{ height: 270 }}>
                            <Skeleton
                                className='custom-skeleton'
                                active
                                title={false}
                                paragraph={{ width: '100%', rows: 5 }}
                            />
                        </div>
                    </Col>
                ))}
            </Row>
        )
    }

    const isFifthOnboardingStepActive = checkIfStepIsActive(5);

    const lastCreatedTask = isFifthOnboardingStepActive
        ? [...tasksFromState]
            .sort((a, b) => moment(b.created_at).diff(a.created_at))
            .at(0)
        : null;

    const filteredTasks = tasks.filter((task) => {
        const isPendent = task.pendent_tasks_count === 0;

        const from = moment(task.due_date);
        const to = moment();

        if(!filter.includes(2) && isPendent){
            return false;
        }else if(!filter.includes(0) && !isPendent && to.diff(from, 'days') > 0){
            return false;
        }else if(!filter.includes(1) && !isPendent && to.diff(from, 'days') <= 0){
            return false;
        }

        return true;
    });

    if (filteredTasks.length === 0) {
        return (
            <div style={{ paddingBottom: 20 }}>
                <div className="mt-10">
                    <center>
                        <Icon type="folder-open" style={{fontSize: `42px`}}/>
                        <h1 className="h1">Nenhuma tarefa encontrada.</h1>
                    </center>
                </div>
            </div>
        )
    }

    return (
        <div style={{ paddingBottom: 20 }}>
            {visualizationMode === 'box' ? (
                <Row gutter={16}>
                    {filteredTasks.map((task) => 
                        <Task 
                            openList={() => openList(task.id)} 
                            openGrid={() => openGrid(task)} 
                            data={task} 
                            key={task.id}
                            isFourthOnboardingStepActive={
                                isFifthOnboardingStepActive &&
                                lastCreatedTask &&
                                task.id === lastCreatedTask.id
                            }
                            openTasksInBatchDrawer={openTasksInBatchDrawer}
                        /> 
                    )}
                </Row>
            ) : (
                <TasksInList
                    openList={openList}
                    openGrid={openGrid}
                    filteredTasks={filteredTasks}
                    openTasksInBatchDrawer={openTasksInBatchDrawer}
                    isFourthOnboardingStepActive={isFifthOnboardingStepActive}
                    lastCreatedTask={lastCreatedTask}
                    isListOpen={isListOpen}
                />
            )}
            <BatchTasksDrawer
                isVisible={tasksInBatchDrawer.isVisible}
                closeDrawer={closeTasksInBatchDrawer}
                departmentId={selectedDepartmentId}
                parentTask={tasksInBatchDrawer.parentTask}
                customersOfParentTask={tasksInBatchDrawer.customers}
                isLoading={tasksInBatchDrawer.isLoading}
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        calendarTasks: state.calendarTasks,
        tasksFromContext: state.allDepartmentsCalendarTasks.list
            .filter((task) => task.department === ownProps.match.params.id)
            .sort((a, b) => new Date(a.due_date) - new Date(b.due_date)),
        isTasksContextLoading: state.allDepartmentsCalendarTasks.isLoading,
        onboardingStep: state.onboarding.step,
        isOnboardingActive: state.onboarding.isActive,
        isVideoModalVisible: state.onboarding.videoModal.isVisible,
        tasksFromState: state.tasks.list
    }
}
  
const mapDispatchProps = dispatch => {
    return {
        loadCalendarTasks: (id, date) => {
            return dispatch(calendarTasksActions.loadCalendarTasks(id, date));
        },
        updateTasksCardsFromList: (customers, taskId) => {
            dispatch(calendarTasksActions.updateTasksCardsFromList(customers, taskId));
        },
        updateContextTasksCardsFromList: (customers, taskId) => {
            dispatch(calendarTasksActions.updateContextTasksCardsFromList(customers, taskId));
        },
    }
}

export default connect(mapStateToProps, mapDispatchProps)(Tasks);