import { Icon, message, Modal, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getSubdomain } from "~/helpers/getSubdomain";
import { generateRandomPassword } from "~/utils/generate-random-password";
import {
  Container,
  InputStyled,
  LabelStyled,
  LockIconStyled,
  TagStyled,
  WhatsappLink,
} from "./styles";
import * as customersActions from "~/containers/Customer/actions";
import { WhatsappIcon } from '~/icons/WhatsappIcon';

export function CustomerPasswordModal({ isOpen, onClose, appUser, customer }) {
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const randomPassword = generateRandomPassword(8);
      setPassword(randomPassword);
    }

    return () => {
      setIsPasswordChanged(false);
      setIsLoading(false);
    };
  }, [isOpen]);

  async function changeAppUserPasswordRequest(data) {
    dispatch(customersActions.changeAppUserPassword(data));
  }

  async function changePassword() {
    setIsLoading(true);
    try {
      await changeAppUserPasswordRequest({ id: appUser.id, password });
      setIsPasswordChanged(true);
    } catch (error) {
      message.error("Não foi possível atualizar a senha do usuário");
    } finally {
      setIsLoading(false);
    }
  }

  function handleCopyToClipBoard() {
    navigator.clipboard.writeText(password);
    message.success("Senha copiada para a área de transferência!");
  }

  function getWhatsappMessage() {
    if (!appUser) return '';

    const accessUrl = `https://${getSubdomain()}.${
      process.env.REACT_APP_CUSTOMER_DOMAIN
    }/cliente/login`;

    return `Olá ${appUser.name},%0acriamos uma nova senha para que você possa acessar o painel do cliente:%0aUrl de acesso: ${accessUrl}%0aSenha: ${password}`;
  }

  return (
    <Modal
      title="Gerar nova senha"
      destroyOnClose
      visible={isOpen}
      onCancel={onClose}
      centered
      cancelText="Voltar"
      okText="Salvar"
      onOk={changePassword}
      okButtonProps={{
        disabled: !password || isPasswordChanged,
        loading: isLoading,
      }}
    >
      <Container>
        {isPasswordChanged ? (
          <>
            <LabelStyled>Senha gerada: </LabelStyled>
            <TagStyled>{password}</TagStyled>
            <Icon type="copy" onClick={handleCopyToClipBoard} />
            {customer.phone && (
              <Tooltip title="Enviar para o Whatsapp do cliente">
                <WhatsappLink
                  href={`https://api.whatsapp.com/send?phone=55${
                    customer.phone
                  }&text=${getWhatsappMessage()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsappIcon />
                </WhatsappLink>
              </Tooltip>
            )}
          </>
        ) : (
          <>
            <LabelStyled>Defina a nova senha</LabelStyled>
            <InputStyled
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              prefix={<LockIconStyled />}
            />
          </>
        )}
      </Container>
    </Modal>
  );
}
