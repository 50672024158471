import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Layout } from '~/components/UI';
import { LoadingTopBar } from '~/components/UI/LoadingTopBar';
import { KnowledgeBase } from '~/containers/KnowledgeBase';
import { loadKnowledges } from '~/containers/KnowledgeBase/actions';

export const ScreensKnowledgeBase = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await dispatch(
        loadKnowledges({ showLoading: false })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <KnowledgeBase />
      </Layout>
    </div>
  );
};
