import React from 'react';
import { Progress, Button, Icon, Col, Row } from 'antd';
import TodoList from '~/components/TodoList';
import uid from "uid";

export const Checklist = ({
    data,
    tasks,
    setTasks,
}) => {
    const progress = tasks.length > 0 ? Math.round((tasks.filter(t => t.done).length / tasks.length) * 100) : 0;

    const addNew = () => {
        setTasks([
            ...tasks,
            {
                id: uid(),
                title: '',
                done: false,
            }
        ]);
    }

    return (
        <Row style={{ margin: '-15px' }}>
            <Col span={24}>
                {tasks.length > 0 && (
                    <>
                        <Progress 
                            percent={progress}
                            className="mb-10"
                        />
                        <TodoList
                            tasks={tasks}
                            setTasks={setTasks}
                        />
                    </>
                )}
            </Col>
            <Col span={24}>
                <Button onClick={addNew} className="mt-10">
                    <Icon type="folder-add" /> Adicionar Tarefa
                </Button>
            </Col>
        </Row>
    );
}

export default Checklist;
