/* eslint-disable no-useless-computed-key */
import update from 'immutability-helper';
import get from 'lodash/get';
// import set from 'lodash/set';
import * as drawerLeads from '~/containers/DrawerLeads/actions';

const ACTION_HANDLERS = {

    ['@@DRAWERSOCIETARIO/SHOW']: (state, action) => {
        return update(state, {
            drawer: {
                data: { $set: get(action, 'payload.data') },
                category: { $set: get(action, 'payload.category') },
                show: { $set: true },
            }
        })
    },

    ['@@DRAWERSOCIETARIO/CLOSE']: (state, action) => {
        return update(state, {
            drawer: {
                data: { $set: action.payload} ,
                show: { $set: false },
                category: { $set: null },
            }
        })
    },

    // if lead is saved, update the lead data in the drawer
    [drawerLeads.SAVE.SUCCESS]: (state, action) => {
        return {
            drawer: {
                ...state.drawer,
                data: {
                    ...state.drawer.data,
                    lead: action.payload.data.data
                }
            }
        }
    },

    ['SAVE_CUSTOMER_SUCCESS']: (state, action) => {
        return {
            drawer: {
                ...state.drawer,
                data: {
                    ...state.drawer.data,
                    customer: action.payload.data.data
                }
            }
        }
    }

}

const defaultState = {
    drawer: {
        data: {},
        show: false,
        category: null
    }
};

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
  
    return handler ? handler(state, action) : state;
};

export default reducer;