import React from 'react';
import { Col, Row, Table, Button, Icon, Modal, Form, Popover } from 'antd';
import Moment from 'react-moment';
import { toBRL } from '~/components/UI/InputCurrency';
import InputCurrency, { currency2Float } from '~components/UI/InputCurrency';
import { connect } from 'react-redux';
import moment from 'moment';
import { PERMISSIONS } from '~/constants/permissions.constants';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;

const Monthly = (props) => {
    const { 
        monthlyHistory,
        data,
        user,
        modalMonthlyVisibe,
        tabActive,
        checkIfStepIsActive
    } = props;

    const { getFieldDecorator } = props.form;

    const removeHonorary = (id) => {
        Modal.confirm({
            title: 'Você realmente deseja remover este honorário?',
            content: 'Esta ação não poderá ser desfeita.',
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk: () => props.removeToMonthlyHistory(id)
        });
    }

    const updateHonorary = ({ id, canUpdateAmountAndDate = true }) => {
        const currentHonorary = monthlyHistory.find((item) => item.id === id);
        if (!currentHonorary) return;

        props.setHonoraryToUpdate({ ...currentHonorary, canUpdateAmountAndDate });

        props.setModalMonthlyVisible(true)
    }

    return (
        <div>
            <Row gutter={16} type="flex" justify="space-between" align="middle" className="mb-20">
                <Col span={8}>
                    <Popover
                        placement="top"
                        content="Clique aqui para criar um novo honorário"
                        visible={!modalMonthlyVisibe && tabActive === '4' && checkIfStepIsActive(10) && monthlyHistory.length === 0}
                    >
                        <Button
                            htmlType="submit"
                            type="primary"
                            style={{marginLeft: '10px'}}
                            onClick={() => props.setModalMonthlyVisible(true)}
                        >
                            Novo Honorário
                        </Button>
                    </Popover>
                </Col>
                <Col span={8}>
                    <Form.Item label="Honorário firmado">
                    {getFieldDecorator('honorary_agreed', {
                        initialValue: data.honorary_agreed,
                        normalize: (value) => currency2Float(value)
                    })(
                        <InputCurrency />
                    )}
                    </Form.Item>
                </Col>
                <Col span={24} className="mt-10">
                <Table 
                    rowKey="id"
                    dataSource={monthlyHistory.sort((a,b) => moment(a.start_at).diff(b.start_at))} 
                    bordered={false}
                    scroll={{x: true}}
                    size="small"
                    pagination={getDefaultPaginationConfig({
                        storageKey: STORAGE.defaultPageSize.customersMonthly
                    })}
                    >
                    <Column
                        title="Honorário"
                        dataIndex="amount"
                        key="amount"
                        width={60}
                        render={value => toBRL(value)}
                    />
                    <Column
                        title="Data do Vencimento"
                        dataIndex="start_at"
                        key="start_at"
                        width={120}
                        defaultSortOrder={'descend'}
                        sorter={(a, b) => a.start_at && a.start_at.localeCompare(b.start_at)}
                        render={key => key && (
                          <Moment format="DD/MM/YYYY">
                            {key}
                          </Moment>
                      )}
                    />
                    <Column
                        title="Faturamento médio"
                        dataIndex="average_billing"
                        key="average_billing"
                        width={100}
                        render={value => toBRL(value)}
                    />
                    <Column
                        title="Funcionários"
                        dataIndex="n_staffs"
                        key="n_staffs"
                        width={70}
                    />
                    <Column
                        key="edit"
                        dataIndex="id"
                        align="center"
                        width={20}
                        render={(id) => {
                            const lastHonorary = monthlyHistory[monthlyHistory.length - 1];
                            const isLastHonorary = id === lastHonorary.id;
                            const userHasPermissionToEdit = user.permissions.includes(PERMISSIONS.financial.monthlyPayments.edit);

                            if (userHasPermissionToEdit) {
                                return (
                                    <Button type="primary" onClick={() => updateHonorary({ id, canUpdateAmountAndDate: isLastHonorary })}>
                                        <Icon type="edit" />
                                    </Button>
                                )
                            }

                            return isLastHonorary && (
                                <Button type="primary" onClick={() => updateHonorary({ id })}>
                                    <Icon type="edit" />
                                </Button>
                            )
                        }}
                    />
                    <Column
                        title=""
                        dataIndex="id"
                        align='center'
                        key="id"
                        width={20}
                        render={(id, record) => id === monthlyHistory[monthlyHistory.length-1].id && (
                          <center><Button type="danger" onClick={() => removeHonorary(id)}><Icon type="delete"/></Button></center>
                      )}
                    />
                    </Table>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(Monthly);