import { Button, Form, Icon, Input } from 'antd';
import React from 'react';
import uid from 'uid';

import { FieldWrapper, NewTypeButton } from './styles';

export const DocumentTypesTab = ({ types, setState }) => {
  function handleChangeType(event, id) {
    const { value } = event.target;

    const updatedTypes = types.map((type) => {
      if (type.id !== id) return type;

      return {
        ...type,
        description: value,
      };
    });

    setState({
      documentTypes: updatedTypes,
    });
  }

  function handleDeleteType(id) {
    const updatedTypes = types.filter((type) => {
      return type.id !== id;
    });

    setState({
      documentTypes: updatedTypes,
    });
  }

  function handleCreateType() {
    const createdType = {
      id: uid(),
      description: '',
    };

    setState({
      documentTypes: [...types, createdType],
    });
  }

  return (
    <div>
      {types
        .map((type, index) => (
          <Form.Item label={`Tipo ${index + 1}`} key={type.id}>
            <FieldWrapper>
              <Input
                placeholder="Descrição"
                value={type.description}
                onChange={(event) => handleChangeType(event, type.id)}
              />
              <Button
                type="danger"
                icon="delete"
                onClick={() => handleDeleteType(type.id)}
              />
            </FieldWrapper>
          </Form.Item>
        ))}
      <NewTypeButton onClick={handleCreateType} style={{ marginBottom: 50 }}>
        <Icon type="plus" /> Adicionar novo tipo
      </NewTypeButton>
    </div>
  );
};
