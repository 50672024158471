import { createAsyncActions } from "~/utils/store";

export const LOAD_FEEDBACKS = createAsyncActions(
  "@@FEEDBACKS/LOAD"
);

export const loadFeedbacks = ({ initialDate, finalDate, ...params }) => ({
  type: LOAD_FEEDBACKS.INIT,
  payload: {
    request: {
      method: "GET",
      url: `/feedbacks?from=${initialDate}&to=${finalDate}`,
      ...params
    },
  },
});
