import { Progress } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  right: 0;
  height: 100vh;
  width: 17%;
  float: right;
  z-index: 999;
  background-color: white;
  padding: 20px;
  box-shadow: -6px 0 16px -8px #00000014, -9px 0 28px #0000000d,
    -12px 0 48px 16px #00000008;
  border-radius: 4px;
`;

export const OnboardingContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const OnboardingTitle = styled.h1`
  font-size: 18px;
`;

export const OnboardingProgress = styled(Progress).attrs({
  style: {
    marginBottom: 20,
  },
})``;

export const StepsContainer = styled.div`
  overflow: auto;
  text-align: left;
  align-self: flex-start;
  height: 60vh;
  margin-bottom: 20px;
`;

export const OnboardingOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const StepTitle = styled.div`
  color: ${({ isActive }) => (isActive ? "#1890ff" : "inherit")};
  border-bottom: ${({ isActive }) => (isActive ? "2px solid #1890ff" : "inherit")};
  margin-bottom: 4px;
`;