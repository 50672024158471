import { Form, Input, message, Modal, Select } from "antd";
import React from "react";
import { useEffect } from "react";
import uid from "uid";
import { getChecklistStatusName } from "~/containers/Checklist/helpers";

const { Option } = Select;

export const ChecklistTaskModal = Form.create()(
  ({
    isVisible,
    onClose,
    data,
    selectedStatus,
    onCreateTask,
    onUpdateTask,
    form,
    tasks,
  }) => {
    const {
      getFieldDecorator,
      validateFields,
      resetFields,
      setFieldsValue,
      getFieldValue,
    } = form;
    const isEditing = !!data;

    const isOkButtonEnabled = isEditing
      ? getFieldValue("name") !== data.name ||
        getFieldValue("department") !== data.department
      : true;

    useEffect(() => {
      if (isVisible && isEditing) {
        const { name, department } = data;
        setFieldsValue({
          name,
          department,
        });
      }
    }, [isVisible]);

    function closeModal() {
      onClose();
      resetFields();
    }

    function handleSubmit() {
      validateFields((error, values) => {
        if (error) return;

        const { name, department } = values;

        const existingTask = tasks.find((task) => {
          return (
            task.status === selectedStatus &&
            task.department === values.department &&
            task.name === values.name
          );
        });

        if (existingTask) {
          return message.warning(
            `Esta tarefa já existe em "${getChecklistStatusName(
              selectedStatus
            )}"`
          );
        }

        if (isEditing) {
          onUpdateTask({
            ...data,
            name,
            department,
            is_modified: true,
          });
        } else {
          const newTask = {
            id: uid(),
            name,
            department,
            status: selectedStatus,
            is_new: true,
          };

          onCreateTask(newTask);
        }
        closeModal();
      });
    }

    return (
      <Modal
        title={isEditing ? "Editar tarefa" : "Nova tarefa"}
        visible={isVisible}
        onCancel={closeModal}
        okText={isEditing ? "Salvar" : "Adicionar"}
        cancelText="Voltar"
        onOk={handleSubmit}
        okButtonProps={{ disabled: !isOkButtonEnabled }}
      >
        <Form>
          <Form.Item label="Nome">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Por favor, informe o nome da tarefa",
                },
              ],
            })(<Input.TextArea placeholder="Nome da tarefa" />)}
          </Form.Item>
          <Form.Item label="Departamento">
            {getFieldDecorator("department", {
              rules: [
                {
                  required: true,
                  message: "Por favor, selecione um departamento",
                },
              ],
            })(
              <Select placeholder="Selecione um departamento">
                <Option value={1}>Legalização</Option>
                <Option value={2}>Administrativo</Option>
                <Option value={3}>Contábil</Option>
                <Option value={4}>Fiscal</Option>
                <Option value={5}>Pessoal</Option>
                <Option value={6}>Sucesso do Cliente (CS)</Option>
              </Select>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);
