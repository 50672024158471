import update from 'immutability-helper';
import { get } from 'lodash';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const DEFAULT_STATE = {
  list: [],
  types: [],
  isLoading: true
};

const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CACHE_ACTIONS.documents: {
      return update(state, {
        list: {
          $set: action.payload
        },
        isLoading: {
          $set: false
        }
      });
    }

    case "LOAD_DOCUMENTS_SUCCESS": {
      return update(state, {
        list: {
          $set: action.payload.data.data,
        },
        isLoading: {
          $set: false
        }
      });
    }

    case 'IMPERSONATE_ACCESS_SUCCESS': {
      return state;
    }

    case 'CHANGE_DOCUMENT_REVISED_SUCCESS': {
      const fileId = action.meta.previousAction.payload.id;
      return update(state, {
        list: {
          $set: state.list.map((file) => {
            if (file.id !== fileId) return file;

            return {
              ...file,
              revised_at: action.payload.data.data.revised_at,
              revised_by: action.payload.data.data.revised_by,
            };
          }),
        },
      });
    }

    case 'ARCHIVE_DOCUMENT_SUCCESS': {
      const archivedFile = action.payload.data.document;
      return update(state, {
        list: {
          $set: state.list.map((file) => {
            if (file.id !== archivedFile.id) return file;

            return {
              ...file,
              ...archivedFile
            }
          })
        }
      });
    }

    case 'LOAD_DOCUMENT_TYPES_SUCCESS': {
      return update(state, {
        types: { $set: get(action, 'payload.data.types') },
      });
    }

    case 'SAVE_DOCUMENT_TYPES_SUCCESS': {
      return update(state, {
        types: { $set: get(action, 'payload.data.types') },
      });
    }

    case 'SEND_DOCUMENT_EMAILS_SUCCESS': {
      const updatedId = action.meta.previousAction.payload.id;
      return update(state, {
        list: {
          $set: state.list.map((item) => item.id !== updatedId ? item : {
            ...item,
            email_status: 'pendent'
          })
        }
      });
    }

    default:
      return state;
  }
};

export default reducer;
