import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Icon, Table, Menu, Dropdown } from 'antd';
import { compare } from 'natural-orderby';
import get from 'lodash/get';

import { request } from '../Instance';

import './grid.css';
import moment from 'moment';

const { Column } = Table;

const months = ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'];

function getBackgroundColorCell(record, index){
    if(record.months[index]){
      if(record.months[index].revised_at !== null){
        return '#52c41a';
      }else if(record.months[index].executed_at !== null){
        return '#b7eb8f';
      }else if(record.months[index].status === 'Vencida'){
        return '#ff4d4f';
      }else{
        return '#ffffb8';
      }
    }else{
      return '#fafafa';
    }
}

class DrawerForm extends React.Component {

  state = {
    customers: [],
    loading: true
  }

  loadTaskGrid = (task_id) => {
    const date = moment(this.props.date).format('YYYY-MM-DD');
    request('get', `/calendar/task/${task_id}/grid/${date}`, {}, false)
    .then(response => this.setState({
      loading: false, 
      customers: response.data
    }));
  }

  changeStatus = (record_task_id, status) => {
    request('patch', `/calendar/task_record/${record_task_id}/status/${status}`)
    .then(response => this.setState({
      customers: [
        ...this.state.customers.map(row => {
          if(row.customer_id !== response.data.data.customer_id){
            return row;
          }else{
  
            let obj = {};
            // eslint-disable-next-line array-callback-return
            Object.keys(row.months).map(index => {
              if(row.months[index] && row.months[index].id !== record_task_id){
                obj[index] = row.months[index];
              }else if(row.months[index] && row.months[index].id === record_task_id){
                obj[index] = response.data.data;
              }
            });

            return {
              ...row,
              months: obj
            }
          }
        })
      ]
    }));
  }

  createTaskRecordAndUpdateStatus = (data) => {
    request('post', '/calendar/task_record/create', data).then((response) => {
      const updatedCustomers = this.state.customers.map((currentCustomer) => {
        if (currentCustomer.customer_id !== response.data.data.customer_id) {
          return currentCustomer;
        } else {
          const months = {};
          const customerMonths = currentCustomer.months;

          Object.keys(customerMonths).forEach((month) => {
            const currentMonth = customerMonths[month];

            if (currentMonth) {
              if (currentCustomer.customer_id === data.customer_id && currentMonth.due_date === data.due_date.slice(0, 7)) {
                months[month] = response.data.data;
              } else {
                months[month] = currentMonth;
              }
            }
          })

          return {
            ...currentCustomer,
            months
          }
        }
      });

      this.setState({ customers: updatedCustomers });
    });
  }

  changeStatusAll = (status, month) => {
    const d = moment(this.props.date).format('MM-YYYY').split("-");
    const year = d[1];

    request('patch', `/calendar/task/${this.props.data.id}/status`,{
      status,
      month,
      year
    })
    .then(response => {
      this.setState({loading: true});
      this.loadTaskGrid(this.props.data.id);
    });
  }

  componentWillReceiveProps = (nextProps) => {
    if(nextProps.data && nextProps.data !== this.props.data){
      this.loadTaskGrid(nextProps.data.id);
    }
  }

  render() {
    const { data, date } = this.props;
    const { loading, customers } = this.state;

    return (
      <div>
          {data && (
            <Drawer
              title={data.name}
              width={1200}
              placement="right"
              onClose={() => {
                const isCurrentDateEqualsSelectedDate = data.due_date.slice(0, 7) === moment().format('YYYY-MM');
                if (isCurrentDateEqualsSelectedDate) {
                  this.props.updateContextTasksCardsFromGrid(customers, date.month() + 1, data.id)
                } else {
                  this.props.updateTasksCardsFromGrid(customers, date.month() + 1, data.id);
                }

                this.setState({loading: true, customers: []});
                this.props.onClose();
              }}
              maskClosable={false}
              visible={this.props.visible}
              style={{
                  height: '100%',
                  overflow: 'auto',
                  paddingBottom: 53,
              }}
            >
              <div className="table-clabs">
                <Table 
                  rowKey="customer_id"
                  loading={loading}
                  dataSource={customers} 
                  pagination={false}
                  bordered={true}
                  rowClassName="row-clabs-grid"
                  size="small"
                  scroll={{ x: `1350px`, y: `${window.innerHeight-175}px`}}
                >
                    <Column
                      title="Cod"
                      dataIndex="customer.cod"
                      key="cod"
                      fixed="left"
                      width={90}
                      sorter={(a,b) => compare()(a.customer.cod, b.customer.cod)}
                      sortDirections={['descend', 'ascend']}
                      defaultSortOrder={'ascend'}
                    />
                    <Column
                      title="Cliente"
                      dataIndex="customer.name"
                      key="customer"
                      width={300}
                      fixed="left"
                    />
                    {months.map((month, monthIndex) => {

                      monthIndex = monthIndex+1;

                      return (
                      <Column
                          title={
                            <Dropdown overlay={
                              <Menu onClick={(e) => this.changeStatusAll(e.key, monthIndex)}>
                                <Menu.Item key="priority">
                                  <Icon type="star" theme="twoTone" twoToneColor="#fadb14"/> Marcar todos como prioridade
                                </Menu.Item>
                                <Menu.Item key="uncheck_priority">
                                  <Icon type="star"/> Remover prioridade de todos
                                </Menu.Item>
                              </Menu>
                            } trigger={['click']}>
                            <a className="ant-dropdown-link" href="#">
                              {month} <Icon type="down" />
                            </a>
                          </Dropdown>
                          }
                          dataIndex={monthIndex}
                          key={monthIndex}
                          width={80}
                          className="grid-month"
                          render={(text, record) => {
                            const record_task = record.months[monthIndex];
                            if(record_task === undefined || record_task === null){
                              return false;
                            }
                            return(
                            <Dropdown overlay={
                              <Menu onClick={(e) => {
                                const recordDueDate = record.months[monthIndex].due_date;
                                const taskDueDay = data.due_date.split('-')[2];
                                
                                if (!record.months[monthIndex].id) {
                                  this.createTaskRecordAndUpdateStatus({
                                    task_id: data.id,
                                    customer_id: record.customer_id,
                                    due_date: `${recordDueDate}-${taskDueDay}`,
                                    status: e.key,
                                  });
                                } else {
                                  this.changeStatus(record.months[monthIndex].id, e.key);
                                }
                              }}>
                                {get(record_task, 'executed_at', null) === null && (<Menu.Item key="executed"><Icon type="check"/> Marcar como executado</Menu.Item>)}
                                {record_task.executed_at !=null && !record_task.revised_at && (<Menu.Item key="uncheck_executed"><Icon type="close"/> Desmarcar como executado</Menu.Item>)}
                                {!record_task.revised_at && (<Menu.Item key="revised"><Icon type="check" />Marcar como revisado</Menu.Item>)}
                                {record_task.revised_at !=null && (<Menu.Item key="uncheck_revised"><Icon type="close"/> Desmarcar como revisado</Menu.Item>)}
                                <Menu.Divider />
                                {record_task.priority === 0 && (<Menu.Item key="priority"><Icon type="star" theme="twoTone" twoToneColor="#fadb14"/> Marcar como prioridade</Menu.Item>)}
                                {record_task.priority === 1 && (<Menu.Item key="uncheck_priority"><Icon type="star"/> Desmarcar como prioridade</Menu.Item>)}
                              </Menu>
                            } trigger={['contextMenu','click']}>
                              <div style={{ 
                                userSelect: 'none',
                                backgroundColor: getBackgroundColorCell(record, monthIndex),
                                height: `100%`,
                                width: `100%`,
                                position: `absolute`,
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                              }}>
                              {record_task.priority === 1 && (<Icon type="star" theme="twoTone" twoToneColor="#fadb14"/>)}
                              </div>
                            </Dropdown>
                        )}
                        }
                      />
                      )}
                    )}
                </Table>
              </div>
            </Drawer>
        )}
      </div>
    );
  }
}

const mapDispatchProps = dispatch => {
  return{
    updateTasksCardsFromGrid: (customers, currentMonth, taskId) => dispatch({
      type: 'CALENDAR_CARDS_TASKS_UPDATE_FROM_GRID',
      payload: {
          customers,
          currentMonth,
          taskId
      }
    }),
    updateContextTasksCardsFromGrid: (customers, currentMonth, taskId) => dispatch({
      type: 'CONTEXT_CALENDAR_CARDS_TASKS_UPDATE_FROM_GRID',
      payload: {
        customers,
        currentMonth,
        taskId
      }
    })
  }
}

export default connect(null, mapDispatchProps)(DrawerForm);