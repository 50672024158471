import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import { BOARD_LOAD, BOARD_MOVE_ITEM } from './actions';
import * as drawerAcions from '~/containers/DrawerBoardItem/actions';
import * as boardActions from './actions';

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.boards]: (state, action) => {
        return action.payload;
    },

    [BOARD_LOAD.SUCCESS]: (state, action) => {
        const data = action.payload.data.data;
        const defaultList = defaultState.data[1].lists; //use defaultState to get the initial state

        const updatedLists = Object.keys(defaultList).reduce((acc, key) => {
            const list = defaultList[key];
            acc[list.id] = {
                ...list,
                cards: data.filter(card => card.column === list.id)
            };
            return acc;
        }, {});

        return {
            ...state,
            data: {
                1: {
                    ...state.data[1],
                    lists: updatedLists
                },
            }
        }
    },

    [BOARD_MOVE_ITEM.INIT]: (state, action) => {
        const defaultList = state.data[1].lists;
        const data = action.payload.request.data;
        const card = defaultList[parseInt(data.source.droppableId)].cards[data.source.index];

        const updatedLists = Object.keys(defaultList).reduce((acc, key) => {
            if(defaultList[key].uid === data.source.droppableId) {
                acc[defaultList[key].id] = {
                    ...defaultList[key],
                    cards: defaultList[key].cards.filter(card => card.uid !== data.draggableId)
                }
            }else if(defaultList[key].uid === data.destination.droppableId) {
                acc[defaultList[key].id] = {
                    ...defaultList[key],
                    cards: [
                        {
                            ...card,
                            column: parseInt(data.destination.droppableId)
                        },
                        ...defaultList[key].cards
                    ]
                }
            }else{
                acc[defaultList[key].id] = defaultList[key];
            }
            return acc;
        },{});

        return {
            ...state,
            data: {
                1: {
                    ...state.data[1],
                    lists: updatedLists
                },
            }
        }
    },

    [BOARD_MOVE_ITEM.SUCCESS]: (state, action) => {
        return state;
    },

    [drawerAcions.CREATE.SUCCESS]: (state, action) => {
        const defaultList = state.data[1].lists;

        const updatedLists = Object.keys(defaultList).reduce((acc, key) => {
            const list = defaultList[key];
            if(list.id === 1){
                acc[list.id] = {
                    ...list,
                    cards: [
                        action.payload.data.data,
                        ...state.data[1].lists[list.id].cards
                    ]
                }
            }else{
                acc[list.id] = list;
            }
            return acc;
        }, {});

        return {
            ...state,
            data: {
                1: {
                    ...state.data[1],
                    lists: updatedLists
                }
            }
        }
    },

    [drawerAcions.SAVE.SUCCESS]: (state, action) => {
        const payloadData = action.payload.data.data;
        const defaultList = state.data[1].lists;

        const updatedLists = Object.keys(defaultList).reduce((acc, key) => {
            const list = defaultList[key];
            acc[list.id] = {
                ...list,
                cards: state.data[1].lists[list.id].cards.map(card => {
                    if (card.id === payloadData.id) {
                        return payloadData;
                    }
                    return card;
                })
            }
            return acc;
        }, {});

        return {
            ...state,
            data: {
                1: {
                    ...state.data[1],
                    lists: updatedLists
                }
            }
        }
    },

    [drawerAcions.DELETE.SUCCESS]: (state, action) => {
        const defaultList = state.data[1].lists;
        const requestData = action.meta.previousAction.payload.data;

        const updatedLists = Object.keys(defaultList).reduce((acc, key) => {
            const list = defaultList[key];
            acc[list.id] = {
                ...list,
                cards: state.data[1].lists[list.id].cards.filter(card => card.id !== requestData.id)
            }
            return acc;
        }, {});

        return {
            ...state,
            data: {
                1: {
                    ...state.data[1],
                    lists: updatedLists
                }
            }
        }
    },

    [boardActions.BOARD_DUPLICATE_ITEM.INIT]: (state, action) => {
        const item = {
            ...action.payload.data,
            id: '###',
            title: 'duplicando...',
            uid: `uid-${Date.now()}`,
            isCreating: true,
        }
        const itemColumn = `${action.payload.data.column}`;
        return {
            ...state,
            data: {
                1: {
                    ...state.data[1],
                    lists: {
                        ...state.data[1].lists,
                        [itemColumn]: {
                            ...state.data[1].lists[itemColumn],
                            cards: [
                                item,
                                ...state.data[1].lists[itemColumn].cards
                            ]
                        }
                    }
                }
            }
        }
    },

    [boardActions.BOARD_DUPLICATE_ITEM.SUCCESS]: (state, action) => {
        const item = action.payload.data.data;
        const itemColumn = `${action.payload.data.data.column}`;

        return {
            ...state,
            data: {
                1: {
                    ...state.data[1],
                    lists: {
                        ...state.data[1].lists,
                        [itemColumn]: {
                            ...state.data[1].lists[itemColumn],
                            cards: state.data[1].lists[itemColumn].cards.map(card => {
                                if (card.isCreating) {
                                    return item;
                                }
                                return card;
                            })
                        }
                    }
                }
            }
        }
    },

    // remove item from the list in case of error
    [boardActions.BOARD_DUPLICATE_ITEM.FAIL]: (state, action) => {
        const item = action.payload.data;
        const itemColumn = `${action.payload.data.column}`;

        return {
            ...state,
            data: {
                1: {
                    ...state.data[1],
                    lists: {
                        ...state.data[1].lists,
                        [itemColumn]: {
                            ...state.data[1].lists[itemColumn],
                            cards: state.data[1].lists[itemColumn].cards.filter(card => card.uid !== item.uid)
                        }
                    }
                }
            }
        }
    },
}

const defaultState = {
    data: {
        1: {
            id: 1,
            name: 'Marketing',
            uid: 'uid-1',
            lists: {
                1: {
                    id: 1,
                    uid: '1',
                    name: 'Pendente',
                    style: {
                        background: '#f5f5f5',
                        color: '#000'
                    },
                    cards: []
                },
                2: {
                    id: 2,
                    uid: '2',
                    name: 'Em progresso',
                    style: {
                        background: '#f5f5f5',
                        color: '#faad14'
                    },
                    cards: []
                },
                3: {
                    id: 3,
                    uid: '3',
                    name: 'Concluído',
                    style: {
                        background: '#f5f5f5',
                        color: '#52c41a'
                    },
                    cards: []
                }
            },
        },
    }
};

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    if (!handler) return state;

    const newState = handler(state, action);

    const updateCache = [
        BOARD_LOAD.SUCCESS,
        drawerAcions.CREATE.SUCCESS,
        drawerAcions.SAVE.SUCCESS,
        drawerAcions.DELETE.SUCCESS,
        BOARD_MOVE_ITEM.SUCCESS,
        boardActions.BOARD_DUPLICATE_ITEM.INIT,
        boardActions.BOARD_DUPLICATE_ITEM.FAIL,
    ].includes(action.type);

    if (updateCache) {
            LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.boards,
            data: newState
        });
    }

  
    return handler ? handler(state, action) : state;
};

export default reducer;