import { Skeleton } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 20px;
`;

export const Header = styled.div`
  margin: 0 -16px;
  margin-bottom: 10px;
  padding: 15px;
  background-color: white;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 0;
`;

export const Main = styled.div``;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
`;

export const Deadlines = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const DeadlineItem = styled.div`
  background-color: white;
  padding: 10px 15px;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 500ms;
  border-radius: 6px;

  &:hover {
    background-color: #e6f7ff;
  }
`;

export const DeadLineName = styled.span``;

export const DeadlineSummary = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-between;
`;

export const DeadlineSummaryItem = styled.div`
  color: ${({ color }) => color};
`;

export const DeadlineSkeletonItem = styled(Skeleton).attrs({
  className: "custom-skeleton",
  active: true,
  title: false,
  paragraph: {
    width: "100%",
    rows: 1
  },
})``;
