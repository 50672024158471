export const getAllMonths = (year) => {
  return [
    {
      text: 'Jan',
      fullText: 'Janeiro',
      base: '01',
      year
    },
    {
      text: 'Fev',
      fullText: 'Fevereiro',
      base: '02',
      year
    },
    {
      text: 'Mar',
      fullText: 'Março',
      base: '03',
      year
    },
    {
      text: 'Abr',
      fullText: 'Abril',
      base: '04',
      year
    },
    {
      text: 'Mai',
      fullText: 'Maio',
      base: '05',
      year
    },
    {
      text: 'Jun',
      fullText: 'Junho',
      base: '06',
      year
    },
    {
      text: 'Jul',
      fullText: 'Julho',
      base: '07',
      year
    },
    {
      text: 'Ago',
      fullText: 'Agosto',
      base: '08',
      year
    },
    {
      text: 'Set',
      fullText: 'Setembro',
      base: '09',
      year
    },
    {
      text: 'Out',
      fullText: 'Outubro',
      base: '10',
      year
    },
    {
      text: 'Nov',
      fullText: 'Novembro',
      base: '11',
      year
    },
    {
      text: 'Dez',
      fullText: 'Dezembro',
      base: '12',
      year
    },
  ]
}