import update from 'immutability-helper';

import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

import * as ACTIONS from './actions';

const DEFAULT_CACHE_KEY = CACHE_ACTIONS.pluginsForms;
const defaultState = {
    forms: [],
    isLoading: false,
};

const ACTION_HANDLERS = {
	[DEFAULT_CACHE_KEY]: (state, action) => {
        return action.payload;
    },

   [ACTIONS.LOAD.INIT]: (state) => {
        return update(state, {
            isLoading: { $set: true },
        });
    },

    [ACTIONS.LOAD.SUCCESS]: (state, action) => {
        const isLoaded = state.forms.length > 0;
        return update(state, {
            forms: { $set: action.payload.data },
            isLoading: { $set: false },
        });
    },

    [ACTIONS.CREATE.SUCCESS]: (state, action) => {
        return update(state, {
            forms: { $push: [action.payload.data.data] },
        });
    },

    [ACTIONS.UPDATE.SUCCESS]: (state, action) => {
        const formIndex = state.forms.findIndex((form) => form.id === action.payload.data.data.id);
        return update(state, {
            forms: { $splice: [[formIndex, 1, {
                ...state.forms[formIndex],
                ...action.payload.data.data,
            }]] },
        });
    },

    [ACTIONS.DELETE.SUCCESS]: (state, action) => {
        const formIndex = state.forms.findIndex((form) => form.id === action.payload.data.data.id);
        return update(state, {
            forms: { $splice: [[formIndex, 1]] },
        });
    },
   
};

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if(!handler) return state;

    const newState = handler(state, action);
  
    const updateCache = [
        ACTIONS.LOAD.SUCCESS,
        ACTIONS.CREATE.SUCCESS,
        ACTIONS.UPDATE.SUCCESS,
        ACTIONS.DELETE.SUCCESS,
    ].includes(action.type);

    if(updateCache){
        LocalCacheHandler.save({
            pathname: DEFAULT_CACHE_KEY,
            data: newState
        });
    }

    return newState;
};

export default reducer;