import { Button, Col, Form, Input, Modal, Row } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import uid from "uid";

import * as documentsActions from "../actions";
import { EmailLabel } from "../components/EmailLabel";
import { loadEmailsLogs } from "~/containers/EmailsLogs/actions";

export const ResendEmailModal = Form.create()(
  ({ isOpen, closeModal, data, loadLogs, form }) => {
    const { getFieldDecorator } = form;

    const [isLoading, setIsLoading] = useState(false);
    const [emailIds, setEmailIds] = useState([uid(4)]);

    const dispatch = useDispatch();

    async function resendEmailRequest(params) {
      await dispatch(documentsActions.resendEmail(params));
    }

    function resendEmail() {
      form.validateFields(async (error, values) => {
        if (error) return;

        const emails = Object.values(values).filter(Boolean);

        setIsLoading(true);
        try {
          const { emailTo, documentId } = data;
          await resendEmailRequest({
            documentId,
            email: emailTo,
            copyEmails: emails,
          });
          closeModal();
          await loadLogs();
          await loadEmailsLogs();
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      });
    }

    function addNewEmail() {
      setEmailIds((state) => [...state, uid(4)]);
    }

    function removeEmail(id) {
      setEmailIds((state) => state.filter((item) => item !== id));
    }

    return (
      <Modal
        title="E-mails em cópia"
        visible={isOpen}
        onCancel={closeModal}
        okText="Reenviar"
        onOk={resendEmail}
        okButtonProps={{ loading: isLoading }}
      >
        <Form layout="vertical">
          {emailIds.map((id, index) => {
            if (index === 0) {
              return (
                <Form.Item key={id} label={<EmailLabel index={index} />}>
                  {getFieldDecorator(`email-${id}`, {
                    rules: [
                      {
                        type: "email",
                        message: "Por favor, informe um e-mail válido",
                      },
                    ],
                  })(<Input placeholder="Informe o e-mail (opcional)" />)}
                </Form.Item>
              );
            }

            return (
              <Form.Item key={id} label={<EmailLabel index={index} />}>
                <Row gutter={8}>
                  <Col span={22}>
                    {getFieldDecorator(`email-${id}`, {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, informe o e-mail",
                        },
                        {
                          type: "email",
                          message: "Por favor, informe um e-mail válido",
                        },
                      ],
                    })(<Input placeholder="Informe o e-mail" />)}
                  </Col>
                  <Col span={2}>
                    <Button
                      type="danger"
                      icon="delete"
                      onClick={() => removeEmail(id)}
                    />
                  </Col>
                </Row>
              </Form.Item>
            );
          })}
          <Button icon="plus" onClick={addNewEmail}>
            Novo e-mail
          </Button>
        </Form>
      </Modal>
    );
  }
);
