import React from 'react';
import { Button } from 'antd';

const Status = ({value}) => {
    let style = {};
    switch(value){
        case 'Pendente': style = {borderColor: `#f5222d`, color: `#f5222d`}; break;
        case 'Pago': style = {borderColor: `#a0d911`, color: `#a0d911`}; break;
        case 'Inadimplente': style = {borderColor: `#ff9c6e`, color: `#ff9c6e`}; break;
        case 'Ajuizado': style = {borderColor: `#595959`, color: `#595959`}; break;
        default: console.log(`O status ${value} não foi definido.`); break;
    }   

    return <center><Button value={1} size="small" style={style}>{value}</Button></center>
}

export default Status;