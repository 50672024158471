import { Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import * as customersActions from "~/containers/Customer/actions";

export const UserInviteModal = Form.create()(
  ({ isVisible, hideModal, setUsers, customer, form }) => {
    const {
      getFieldDecorator,
      validateFields,
      getFieldsValue,
      resetFields,
    } = form;

    const [isLoading, setIsLoading] = useState(false);

    const { email } = getFieldsValue();

    const dispatch = useDispatch();

    function onHideModal() {
      resetFields();
      hideModal();
    }

    function handleSubmit() {
      validateFields(async (error, values) => {
        if (error) {
          return;
        }

        try {
          setIsLoading(true);
          const response = await inviteUserRequest({
            email: values.email,
            customerId: customer.id,
          });
          const { invited_user } = response.payload.data;
          onHideModal();
          setUsers((usersInState) => {
            return [
              ...usersInState,
              {
                id: invited_user.id,
                email: invited_user.email,
                last_access_at: null,
                name: "",
              },
            ];
          });
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      });
    }

    async function inviteUserRequest(params) {
      return dispatch(customersActions.inviteAppUser(params));
    }

    return (
      <Modal
        title="Convidar usuário"
        visible={isVisible}
        onCancel={onHideModal}
        cancelText="Voltar"
        onOk={handleSubmit}
        okText="Convidar"
        okButtonProps={{ disabled: !email, loading: isLoading }}
      >
        <Form>
          <Form.Item label="E-mail">
            {getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  message: "Por favor, informe o e-mail",
                },
                {
                  type: "email",
                  message: "Por favor, informe um e-mail válido",
                },
              ],
            })(<Input placeholder="Digite o e-mail" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);
