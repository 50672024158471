import { Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

import {
  CentralContent,
  ChartContent,
  ChartItem,
  ChartTitle,
  LeftArrow,
  RightArrow,
  TopContent,
  ChartMonthTitle,
  ChartMonth,
} from "./styles";

export function TaskChartByMonth({ title, data }) {
  if (!data) return null;

  const months = data.months;

  const [dataIndex, setDataIndex] = useState(0);

  useEffect(() => {
    setDataIndex(0);
  }, [data]);

  function handlePreviousMonth() {
    if (dataIndex === 0) return;
    setDataIndex(dataIndex - 1);
  }

  function handleNextMonth() {
    if (dataIndex === months.length - 1) return;
    setDataIndex(dataIndex + 1);
  }

  const currentData = months[dataIndex];

  return (
    <ChartItem>
      <Tooltip
        title={
          currentData
            ? `${currentData.executed_count} / ${currentData.total_count} (${currentData.percent}%)`
            : ""
        }
        align={{ offset: [0, 36] }}
      >
        <Chart
          type="radialBar"
          height={300}
          series={currentData ? [currentData.percent] : [0]}
          options={{
            chart: {
              height: 300,
              type: "radialBar",
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  size: "70%",
                },
                dataLabels: {
                  show: false,
                },
              },
            },
            responsive: [
              {
                breakpoint: 1600,
                options: {
                  chart: {
                    height: 250
                  }
                }
              }
            ],
            colors: ["#52c41a"],
          }}
        />
      </Tooltip>

      <ChartContent>
        <TopContent>
          <LeftArrow
            visible={dataIndex > 0 ? "true" : "false"}
            onClick={handlePreviousMonth}
          />
          <CentralContent>
            <ChartTitle>{title}</ChartTitle>
            {currentData && (
              <>
                <ChartMonthTitle>Mês/Ano</ChartMonthTitle>
                <ChartMonth>
                  {moment(currentData.date).format("MM/YYYY")}
                </ChartMonth>
              </>
            )}
          </CentralContent>
          <RightArrow
            visible={dataIndex < months.length - 1 ? "true" : "false"}
            onClick={handleNextMonth}
          />
        </TopContent>
      </ChartContent>
    </ChartItem>
  );
}
