import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const defaultState = {
    list: [],
    isLoading: true
}

const conventions = (state = defaultState, action) => {
    switch (action.type) {
        case CACHE_ACTIONS.conventions: {
            return {
                ...state,
                isLoading: false,
                list: action.payload
            }
        }
      
        case "LOAD_CONVENTIONS_SUCCESS":
            return {
                ...state,
                isLoading: false,
                list: action.payload.data,
            }

        case 'REGISTER_CONVENTION_SUCCESS':
            return {
                ...state,
                list: [
                    ...state.list,
                    ...action.payload.data.data
                ]
            };

        case 'DELETE_CONVENTION_SUCCESS':
            return {
                ...state,
                list: state.list.filter(item => item.id !== action.meta.previousAction.id)
            };

        case 'SAVE_CONVENTION_SUCCESS':
            return {
                ...state,
                list: state.list.map(item => item.id !== action.payload.data.data.id? item : action.payload.data.data)
            };

        default:
        return state
    }
}

export default conventions;