import { createAsyncActions } from "~/utils/store";

export const LOAD_DEFAULTERS = createAsyncActions("@@REPORTS/LOAD_DEFAULTERS");

export const loadDefaulters = (params) => ({
  type: LOAD_DEFAULTERS.INIT,
  payload: {
    request: {
      method: "GET",
      url: "/reports/defaulters",
      ...params,
    },
    showLoading: false,
  },
});