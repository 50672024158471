import { Icon } from "antd";
import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div.attrs({
  className: "ant-modal-title",
})``;

export const FullscreenButton = styled.button.attrs({
  className: "fullscreen-button",
  type: "button",
})`
  margin-right: 30px;
`;

export const FullscreenIcon = styled(Icon)`
  color: rgba(0, 0, 0, 0.45) !important;
  transition: color 500ms;

  &:hover {
    color: rgba(0, 0, 0) !important;
  }
`;
